import React , {useContext, useState, useEffect} from 'react'
import {BrowserRouter, useHistory} from 'react-router-dom';

import Layout from '../layout/Layout';

import { isSubStateC } from '../../Store';


function TreeToolUpdater (){
  const [fNameInput, setFNameInput] = useState("");
  const [URL, setURL] = useState(null);
  const [files, setFiles] = useState("");
  const [showFileContent, setShowFileContent] = useState(false);
  const [textShown, setTextShown] = useState("Click to show file content");
  const [csv, setCsv] = useState("noSelect");
  const [errorMessages, setErrorMessages] = useState({});


  const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);

  const history = useHistory();


  if (isSubmitted == true){
    console.log("true");
  } else {
    console.log("false");
  }

  const upload = async () => {
    
    let url = await fetch("https://cleanstreetserver.herokuapp.com/s3TreeTool/"+csv, {cache: "no-store"});
    url = await url.json();
    url = url.uploadURL;
    
    return fetch(url, {
      method: "PUT",
      body: files,
      headers: {
        "Content-Type": 'application/json'
      },
    });
    
   
  };

  const handleFileChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      //console.log("e.target.result", e.target.result);
      setFiles(e.target.result);
    };
  };

  function uploadWrapper(){
    if (csv != "noSelect"){
      upload();
      alert("File Uploaded");
    } else {
      alert("please select a csv to replace.");
    }
    
  }

  function toggleShow(){
    if (showFileContent == true){
      setShowFileContent(false);
      setTextShown("Click to show file content");
    } else {
      setShowFileContent(true);
      setTextShown("Click to hide file content");
    }
  }

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const getAuth = async (event) => {
    console.log("trig");
    event.preventDefault();
    var { uname, pass } = document.forms[0];
    let urrl = "https://cleanstreetserver.herokuapp.com/auth/"+uname.value + "/" + pass.value;
    console.log(urrl);

    let list = await fetch(urrl, {cache: "no-store"});
    list = await list.json();   
    list = list.valid;
    if (list == "true"){
      setIsSubmitted(true);
    } else {
      alert("Invalid auth");
    }
  }

  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );

  if (isSubmitted == false){
    return(
      <Layout >
        <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}}>
          <h1 style={{fontSize:"30px" }} className="main-title">Admin login</h1>
        </div>
        <div style={{paddingLeft:"19%", paddingRight:"19%", width:"100%", paddingTop:"46px", fontSize:"18px"}}>
          <div className="form" style={{display:"flex", justifyContent: "center"}}>
            <form style={{backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351"}} onSubmit={getAuth} >
              <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                <label>Username: </label>
                <input style={{float:"right"}} type="text" name="uname" required />
                {renderErrorMessage("uname")}
              </div>
              <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                <label>Password: </label>
                <input style={{float:"right"}} type="password" name="pass" required />
                {renderErrorMessage("pass")}
              </div>
              <div style={{margin:"20px 0px 20px 0px"}} className="button-container">
                <input type="submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  } else {
    return(
      <Layout >
        <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}}>
          <h1 style={{fontSize:"30px" }} className="main-title">Update a tree</h1>
        </div>
        <div style={{paddingLeft:"19%", paddingRight:"19%", width:"100%", paddingTop:"46px", fontSize:"18px"}}>
            <div style={{display:"flex"}}>
              <label style={{position: "relative", paddingRight:"20px"}}>Select a csv to replace:</label>
              <select style={{width:"40.2%", color:"#291351"}} name="selectList2" id="selectList2" value={csv}  onChange={e => setCsv(e.target.value)}>
                <option value="noSelect">Please select an option</option>
                <option value="ps2.csv">Full data audit</option>
                <option value="Retail.csv">Retail Spend Data</option>
                <option value="Horizon.csv">Horizon Scan</option>

                <option value="CY_ps2.csv">Welsh - Full data audit</option>
                <option value="CY_RetailSpendData.csv">Welsh - Retail Spend Data</option>
                <option value="CY_HorizonScan.csv">Welsh - Horizon Scan</option>
                
              </select>
            </div>          
            
            <br></br>
            <button style={{cursor:"pointer", width:"30%"}} className="nextButton">
              <label style={{cursor:"pointer"}} for="uploads">Choose file to upload (.csv)</label>
              
              <input id="uploads"  name="uploads" type="file" style={{display:"none"}} onChange={handleFileChange} />  
            </button>
            
            <br /><br />
            {files != "" ? <button onClick={e => toggleShow()} className="nextButton" style={{width:"30%"}}>{textShown}</button> : null}
            <br />
            {showFileContent == true? " \n\n\n\n\n\n " + files : null }
            <br /> <br /><br /> <br /><br /> <br />
            {files != ""? <button onClick={e => uploadWrapper()} className="nextButton">Upload!</button> : null} 
        </div>
      </Layout>
      
    )
  }
}


export default TreeToolUpdater