import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import MyPie from '../components/charts/MyPie.js';
import header from '../homepage/header.png';

import cphp from '../homepage/cphp.png';
import numbers from '../homepage/10101.png';
import cpr from '../homepage/cpr.png';

import logo from '../logo.png';
import logo_blue from '../logo_blue.png';

import { nameInputC, orgInputC, emailInputC } from '../Store';

function HomepageCPSS() {
    const [nameInput, setNameInput] = useContext(nameInputC);
    const [orgInput, setOrgInput] = useContext(orgInputC);
    const [emailInput, setEmailInput] = useContext(emailInputC);
    const [checked, setChecked] = React.useState(false);

    const [profOpen, setProfOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});

    const history = useHistory();

    const handleChange = () => {
        setChecked(!checked);
    };

    const errors = {
        name: "please enter",
        org: "please enter",
        email: "please enter"
    };
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );


    function handleProf() {
        setProfOpen(true);
    }

    const testForm = async (event) => {

        event.preventDefault();
        var { name, org, email, gdprCheck } = document.forms[0];
        console.log(gdprCheck);
        console.log(gdprCheck.checked);
        if (gdprCheck.checked == true) {
            if (name.value == "" || org.value == "" || email.value == "") {
                alert("Please enter a name, org and email before accessing");
            } else {
                setNameInput(name.value);
                setOrgInput(org.value);
                setEmailInput(email.value);
                history.push("/Areas")
            }
        } else {
            alert("please accept the gdpr statement if you want to use the tool");
        }

    }

    return (
        <Layout>

            <           header className='mobHeader' style={{ backgroundImage: `url(${header})` }}>
                <div style={{ position: 'relative' }}>
                    <div className='headerHeadingDiv'>
                        <h1 className='headerHeading'>
                            Charge Point Site Selector
                        </h1>

                        <p style={{ fontSize: '24px', width: '80%' }}>
                            For best results, these tools need to be accessed on a desktop monitor.
                        </p>
                        <br />
                        <a href='https://urbanforesight.org/'> <button className='newButton'>By Urban Foresight</button></a>


                    </div>
                </div>
            </header>

            <header className='desktopHeader' style={{ backgroundImage: `url(${header})` }}>
                <div style={{ position: 'relative' }}>
                    <div className='headerHeadingDiv'>
                        <h1 className='headerHeading2' style={{ fontSize: "94px" }}>
                            EV Charge Point Site Selector
                        </h1>
                        <br />
                        <p style={{ fontSize: "32px", width: "60%" }}>
                            Choose and evaluate the best locations for electric vehicle charging.
                        </p>
                    </div>

                </div>
            </header>

            <div className={'main elementBelowLoading'} id='tools'>
                <p className={"freightText"} style={{ fontSize: "20px", margin: "20px 0px 60px 0px" }}>
                    <div>
                        <p style={{ fontSize: "32px", fontFamily: 'FavoritStd_Regular', width: "75%" }}>
                            Welcome to our EV charge point site selection tool.
                        </p>
                    </div>
                    <div className={"freightText"} style={{ width: "70%" , fontSize:"24px"}}>
                        Developed by the experts at Urban Foresight, the tool guides you through a process of setting priorities, selecting sites that meet your needs, and generating a custom report.
                        <br /><br />
                        An interactive map and data overlays allow you to make informed decisions on potential charging locations. This includes hyper-local forecasts of the future demand for charging in your area.
                        <br /><br />
                        The tool also helps you to evaluate and prioritise the best sites in your area.
                        <br /><br />
                        Your sites and analysis will be sent to you in a custom digital report.
                    </div>
                </p>
                <div style={{ width: "70%", fontFamily: 'FavoritStd_Regular', backgroundColor: "#EDEDED" }}>
                    <div style={{ padding: "28px 32px 28px 32px", color: "#CC4884", fontSize:"24px"}}>
                        The tool has been designed to be customisable to specific projects and client needs.
                        <br /><br />
                        The data overlays and forecasts are currently limited to the United Kingdom.
                        <br /><br />
                        Any questions about the tool, requests to use it on specific projects, or queries about extending it to other territories should be sent to: <span style={{color:"#586EDB"}}>hello@urbanforesight.org</span>
                    </div>

                </div>                
            </div>
            <div style={{ visibility: "hidden", display: "none", margin: "60px 0px 0px 0px" }} className={true == true ? "viz modalVisible2" : ""}>
                    
                    <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 19%", margin: "0px 0px 0px 0px" }} onSubmit={testForm} >
                        <h2 style={{ fontFamily: 'FavoritStd_Regular', fontSize: "20px", marginTop:"20px" }}>To use the tool, please enter the following information: </h2>
                        <div style={{ margin: "30px 0px 30px 0px", height: "30px", fontFamily: 'FavoritStd_Regular', width: "100%" }}>
                            <input style={{width: "42%", height:"40px"}} type="text" name="name" placeholder='Full name' required />
                            {renderErrorMessage("name")}
                        </div>
                        <div style={{ margin: "30px 0px 30px 0px", height: "30px", fontFamily: 'FavoritStd_Regular', width: "100%" }}>
                            <input style={{width: "42%", height:"40px"}} type="text" name="org" placeholder='Organisation name' required />
                            {renderErrorMessage("org")}
                        </div>
                        <div style={{ margin: "30px 0px 30px 0px", height: "30px", fontFamily: 'FavoritStd_Regular', width: "100%" }}>
                            <input style={{width: "42%", height:"40px"}}  type="text" name="email" placeholder='Email Address' required />
                            {renderErrorMessage("email")}
                        </div>
                        <div style={{ margin: "40px 0px 30px 0px", fontSize: "18px" }} className={"freightText"}>
                            <label className={"freightText"} style={{fontSize:"20px"}}>
                                At Urban Foresight, we take your privacy seriously.
                                <br/><br/>
                                By giving us your email address, you are allowing us to send you any information requested, and potentially other related content.
                                <br/><br/>
                                You have the right to see, change, or remove your personal information. 
                                <br/><br/>
                                We will not share your email with third parties without your permission. 
                                <br/><br/>
                                To confirm your consent, please check the box before submitting your personal details on our website. 
                                </label>
                        </div>
                        <div style={{ margin: "20px 0px 20px 0px", height: "30px" }} className={"freightText"}>
                            <input type={"checkbox"} name="gdprCheck" checked={checked} onChange={handleChange}></input><span> I have read and agree to the terms of the GDPR statement </span>
                        </div>
                        <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                            <button style={{width:"200px"}} className = "nextButton">Start →</button>           
                        </div>
                    </form>
            </div>

            <footer>
                <div className='divContainer'>

                    <div style={{ width: '250px', marginRight: '25px' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <div style={{ position: 'absolute', top: '0' }}>
                                <a href='#'>
                                    <img src={logo} alt="Logo" style={{ height: '42px', width: '172px' }} />
                                </a>
                            </div>
                            <div style={{ position: 'absolute', bottom: '0' }}>
                                <p style={{ color: '#b2b2b2' }}>© Urban Foresight Limited, 2022</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>The Catalyst <br />
                            3 Science Square <br />
                            Newcastle upon Tyne <br />
                            NE4 5TG <br />
                            United Kingdom</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)191 814 2210</p>
                    </div>

                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>Flour Mill<br />
                            34 Commercial Street <br />
                            Dundee<br />
                            DD1 3EJ <br />
                            Scotland</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)1382 549 947</p>
                    </div>

                    <div className='navigationFooter'>
                        <p style={{ height: '25%', fontSize: '12px', color: '#cc4884' }}>
                            <a href="https://www.linkedin.com/company/urban-foresight" target="_blank" style={{ color: '#cc4884' }}> <i className="fa fa-linkedin"></i>  </a>
                            <a href="https://twitter.com/urbanforesight" target="_blank"> <i style={{ marginLeft: '12px', color: '#cc4884' }} className="fa fa-twitter"></i>  </a>
                            <a href="mailto:hello@urbanforesight.org" target="_blank"> <i style={{ fontSize: '12px', marginLeft: '12px', color: '#cc4884' }} className="material-icons">mail</i> </a>
                        </p>
                        <p style={{ height: '25%' }}><a href="/#tools" target="_blank" style={{ color: 'white' }}> Tools</a> </p>
                        <p style={{ height: '25%' }}> <a href="https://urbanforesight.org/about/" target="_blank" style={{ color: 'white' }}> About</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="https://urbanforesight.org/" target="_blank" style={{ color: 'white' }}> Urban Foresight</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="/AdminHome" target="_blank" style={{ color: 'white' }}> Admin</a></p>
                    </div>

                </div>
            </footer>
            <div className='footer2'>
                <div>
                    <a href='#'>
                        <img src={logo_blue} alt="Logo" className='footer2Logo' />
                    </a>
                </div>

                <div>
                    <p style={{ color: '#291351' }}>© Urban Foresight Limited, 2022</p>
                </div>
            </div>
        </Layout>
    );
}

export default HomepageCPSS;



