import React , {useContext, useState, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import L, { featureGroup } from 'leaflet';
import {Card} from 'react-bootstrap';
import Sliders2 from '../functional/Sliders2';

import {data2ReturnContext} from '../../Store';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";




function Cards2({card, index, toggleCardActive, activeElements, c, subData2Return, setSubData2Return, laIndex, sliderObjs, isLoading}) {
    const [slideVisibility, setslideVisibility] = useState(true);
    var c = 1000;
    function tog (){
        if(slideVisibility == true){
            setslideVisibility(false);
        } else {
            setslideVisibility(true);
        }
        
    }

    ////////////////console.log(card);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);

    const [sliderValues, setSliderValues] = useState([]); 
    useEffect(() => {
        window.scrollTo(0,226);
    }, []);

    useEffect(() => {
        //setup
        //////////////console.log("setup");
        var sVT = sliderValues;
        sliderObjs.forEach(obj => {
            sVT.push(obj.def);
        })
        setSliderValues(sVT);
        
    }, []);

    return(
        <div style={{height:'auto', overflowY:'scroll', width: "100%"}} className={"pinDiv hiddenScrollBar"} key ={900}>
            <div style={{padding:'20px 0px 4px 0px'}} key ={901}>
            
                 <h6 className="sub-title sub-sub-title" style={{ marginBottom:'0px', fontSize: "24px"}}>{typeof card[0][5] !== 'undefined' ? card[0][5] : typeof card[0][6].postcode !== 'undefined' ?  card[0][6].postcode : card[0][4]}</h6>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}} key ={902}>
                <div style={{"width":"47%"}}>
                        
                    {slideVisibility == true ?
                        <div class="mySlides" style={{"display":'block', position:"relative"}} >
                            <div class="numbertext" style={{color:"#cc4884"}}>1 / 2</div>
                                <div style={{width:'100%'}}>
                                    <img src={card[0][8]} alt="BigCo Inc. logo" style={{ maxWidth:'100%'}}/>
                                </div>
                            {/* <div class="text" style={{color:"#cc4884"}}>Default</div> */}
                        </div>
                        :
                        <div class="mySlides" style={{"display":'block', position: "relative"}} >
                            <div class="numbertext" style={{color:"white"}}>2 / 2</div>
                                <div style={{width:'100%'}}>
                                    <img src={card[0][7]} alt="BigCo Inc. logo" style={{ maxWidth:'100%'}}/>
                                </div>
                            {/* <div class="text" style={{color:"white"}}>Satellite</div> */}
                        </div>
                    }
                    <div style={{ display: 'flex', marginTop:'7px'}} key ={903}>
                        <div style={{width:'50%'}}>
                            <p className="tip">
                                <a href={"http://maps.google.com/maps?q&layer=c&cbll=" + card[0][2][1] + "," + card[0][2][0] + "&cbp=11,0,0,0,0"} target="_blank" className = "link" id="tooltip"><span data-tip data-for="registerTip"> Open Street View</span></a>
                            </p>
                        </div>

                        <div style={{width:'50%',textAlign:'right'}} key ={904}>
                            {/*<div class="switch-toggle">
                            <input type="checkbox" id="bluetooth" />
                            <label for="bluetooth" onClick={() => {tog()}}></label>
                        </div>*/}
                        <div class="button b2" id="button-10">
                            <input type="checkbox" onClick={() => {tog()}} class="checkbox" />
                            <div class="knobs2">
                                <span >Default</span>
                            </div>
                            <div class="layer"></div>
                        </div>
                    </div>

                </div>
                    
                </div>
                <div style={{"width":"47%", marginRight: "1.1%"}}>
                    <div style={{ margin:'0px 0px 30px 0px', display:"flex", flexDirection:'row', flexWrap: 'wrap', width: '100%'}}>
                        {sliderObjs.map((obj, index2) => {
                            c = c + 1;
                            return(
                            <div className="freightText" style={{padding:'10px 0px', color: '#291351',  width:"100%"}} key = {c}>
                                <label style={{width:'50%'}}>{obj.name}</label> 
                                {/* <input type="range" min="0" max="11" style={{width:'50%'}} /> */}

                                <Sliders2
                                
                                    subData2Return={subData2Return}
                                    setSubData2Return={setSubData2Return}
                                    sliderValues={sliderValues}
                                    setSliderValues={setSliderValues}
                                    index = {index}
                                    laIndex={laIndex}
                                    index2={index2}


                                /> 
                            </div>
                            );
                            
                        })}                    
                    </div>

                    <div style={{ color:'black'}}>
                        <form>
                            <div>
                                <textarea onChange={ (e) => {

                                var newD2R = [];
                                var tempSubData2Return = subData2Return;
                                //////////////console.log(tempSubData2Return);

                                var count = 0;
                                tempSubData2Return.forEach( la => {
                                newD2R.push([]);
                                newD2R[count].push(la[0]);
                                newD2R[count].push([]);
                                

                                let count2 = 0
                                la[1].forEach(pin => {
                                    newD2R[count][1].push([]);

                                    let count3 = 0;
                                    pin.forEach(dSet => {
                                    //////////////console.log(count3);
                                    //////////////console.log(0);
                                    //////////////console.log(count);
                                    //////////////console.log(laIndex);
                                    //////////////console.log(count2);
                                    //////////////console.log(index);
                                    
                                    if (count3 == 0 && count == laIndex && count2 == index){
                                        //////////////console.log("dddddddddddd");
                                        // let temp = newD2R[count][1][count2][0];
                                        // temp[8][index2] 
                                        newD2R[count][1][count2].push([]);
                                        var count4 = 0;
                                        dSet.forEach(d => {
                                        if(count4 == 11){
                                            //////////////console.log("edit");
                                            newD2R[count][1][count2][0].push(e.target.value);
                                        } else {
                                            newD2R[count][1][count2][0].push(d);
                                        }
                                        count4 = count4 + 1;
                                        })

                                    } else {
                                        newD2R[count][1][count2].push(dSet);
                                    }
                                    count3 = count3 + 1;
                                    })
                                    count2 = count2 +1;
                                });

                                count = count + 1;
                                })
                                setSubData2Return(newD2R); 
                            
                                }} name="message" rows="1" style={{width:'62%', height:"88px", border:'1px solid #291351', borderRadius: "5px"}} placeholder="Comment"></textarea>
                            </div>
                        </form>
                    </div>
                </div>
            </div>                         
        </div>
    )
}

export default React.memo(Cards2);

