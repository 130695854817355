import React from "react";
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedBarSeries,
  XYChart,
  Tooltip,
  buildChartTheme,
  DataContext
} from "@visx/xychart";
import { Axis } from '@visx/axis'
import { AnimatedAxis as AnAxis } from '@visx/react-spring'
import { scaleLinear } from "@visx/scale";
import { string } from "mathjs";





const accessors = {
  xAccessor: (d: typeof data2[0]) => d.x,
  yAccessor: (d: typeof data2[0]) => d.y
};

const customTheme = buildChartTheme({
  // colors for tooltip
  colors: ["#FF5092", "#291351"], // categorical colors, mapped to series via `dataKey`s
  backgroundColor: 'white',
  // labels
  // svgLabelBig?: SVGTextProps;
  // svgLabelSmall?: SVGTextProps;
  // htmlLabel?: HTMLTextStyles;

  // // lines
  // xAxisLineStyles?: LineStyles;
  // yAxisLineStyles?: LineStyles;
  // xTickLineStyles: { className: "ticks" }
  // yTickLineStyles?: LineStyles;
  tickLength: 4,

  // // grid
  gridColor: "rgba(255, 255, 255, 0)",
  gridColorDark: 'rgba(255, 255, 255, 0)', // used for axis baseline if x/yxAxisLineStyles not set
  // gridStyles?: CSSProperties;
});







function XYc({widget,index,width,height}){
  //////////console.log("////////////////////////////")
  //////////console.log(widget);
  //////////console.log(index);

  var xy = {};                                                          
  widget["laChartData"][index]["cData"].forEach(chart => {
      if(chart[0] == "xy"){
        xy = chart[2];
      }
  })

  //////////console.log(xy);
  var data2 = [];

  xy.forEach(dE => {
    //////////console.log(dE);
    let x = dE.x;
    let y = dE.y;
    //////////console.log(x);
    //////////console.log(y);
    x = x.slice(4);

    //////////console.log(x);
    //2021Q3
    var d = "1"
    var m = x.slice(4);
    if(m == "Q1"){
      m = "01"
    }
    if(m == "Q2"){
      m = "03"
    }
    if(m == "Q3"){
      m = "06"
    }
    if(m == "Q4"){
      m = "09"
    }
    var year = x.slice(0,4);
    let combi = m + "/" + d + "/" + year;
    //////////console.log(combi);
    data2.push({ x: new Date(combi), y: y });

    //1/09/2020
  })
  data2.reverse();
  //////////console.log(data2)


  

  // const data2 = [
  //   { x: new Date("1/09/2020"), y: 400 },
  //   { x: new Date("1/01/2021"), y: 650 },
  //   { x: new Date("1/03/2021"), y: 700 },
  //   { x: new Date("1/06/2021"), y: 800 },
  //   { x: new Date("1/09/2021"), y: 1100 },
  //   { x: new Date("1/01/2022"), y: 1200 },
  //   { x: new Date("1/03/2022"), y: 1500 },
  //   { x: new Date("1/06/2022"), y: 1700 },
  //   { x: new Date("1/09/2022"), y: 1900 },
  // ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const data = data2.reduce(([dates, positions], entry, i) => {
    const lastEntry = data2[i - 1];
    const partSize = 100 / (data2.length - 1)
    if (lastEntry?.x?.getFullYear() < entry.x.getFullYear()) {
      dates.push(new Date(`1/01/${lastEntry.x.getFullYear()}`));
      positions.push(((i * partSize) + ((i - 1) * partSize)) / 2)
    }
  
    return [dates, positions];
  }, [[],[]]);

  const Ax = () => {
    return (
      <AnimatedAxis
      // x ais months
        tickLabelProps={() => ({
          fontSize: 13,
          textAnchor: "middle",
          dy: "4",
          fill: '#291351'
        })}
        tickTransform="translate(0,7)"
        orientation="bottom"
        tickFormat={(date: Date, i: number) => `${months[date.getMonth()]}`}
      />
    );
  };

  const Ax2 = () => {
    const { innerWidth, innerHeight, margin } = React.useContext(DataContext);
    const scaleX = scaleLinear({
      domain: [0, 100],
      range: [0, innerWidth],
      fill: '#291351'
    });
  
    return (
      <AnAxis
      // x ais years
        tickLength={22}
        tickLabelProps={() => ({
          fontSize: 16,
          textAnchor: "middle",
          dy: "4",
          fill: '#291351'
        })}
        tickTransform="translate(0,7)"
        orientation="bottom"
        tickFormat={(_: number, i: number) => {
          return data[0][i].getFullYear()
        }}
        hideAxisLine={true}
        scale={scaleX}
        tickValues={data[1]}
        left={margin.left}
        top={innerHeight + margin.top}
        rangePadding={0.5}
      />
    );
  };

  return (
    <XYChart
    theme={customTheme}
    height={330 }
    xScale={{ type: "band", paddingInner: 0.5 }}
    yScale={{ type: "linear" }}
  >
   
    <Ax2 />
    <AnimatedAxis hideAxisLine={true} hideTicks={true} orientation="right" 
    tickLabelProps={() => ({
        fill: '#291351',
        fontSize: 11
      })}
       />
    <AnimatedGrid rows={true} columns={false} numTicks={16} />
    <AnimatedBarSeries
      dataKey="Line 2"
      data={data2}
      {...accessors}
    />

    <Tooltip
      // snapTooltipToDatumX
      // snapTooltipToDatumY
      // showVerticalCrosshair
      // showSeriesGlyphs
      renderTooltip={({ tooltipData, colorScale }) => (
        <div>
          <div style={{ color: '#666666', fontWeight:'500' }}>
          </div>
          <span style={{ color: '#666666', fontWeight:'500' }}>
          {accessors.xAccessor(tooltipData.nearestDatum.datum).toString()}
          {", "}
          {accessors.yAccessor(tooltipData.nearestDatum.datum)}
          </span>
        </div>
      )}
    />
  </XYChart>
  )
}



export default React.memo(XYc);
