import React , {useContext, useState, useRef, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import L, { featureGroup } from 'leaflet';

import {selectedPositionContext} from '../../Store';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";



    



function MarkerPin({marker, deleteMarker, updateMe, mp, temps}) {
    const [refReady, setRefReady] = useState(false);
    ////////////////console.log(marker);

    
    useEffect(() => {
        if (refReady) {
          popupRef.openOn(mp);
        }
    }, [refReady]);

    useEffect(() => {
       ////////////////console.log("clear");
        var tempLoadingS = temps;
        tempLoadingS.forEach(markk => {
            mp.removeLayer(markk);
        })
    }, []);

    let popupRef = useRef();

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: 'https://icon-library.com/images/charging-station-icon/charging-station-icon-26.jpg',
        iconUrl: 'https://icon-library.com/images/charging-station-icon/charging-station-icon-26.jpg',
        shadowUrl: '',
    });

    L.Icon.Default.imagePath='/';
    const iconEV = new L.Icon({
        iconUrl: "Pin3.png",
        iconRetinaUrl: "Pin3.png",
 

        shadowUrl: '',

        iconSize: new L.Point(40, 40),

    });
   

    return (
        <Marker
            key={marker.id}
            position={[marker[0],marker[1]]}
            icon={iconEV}
        >
            <Popup className={"pops"} autoPan={true} closeButton= {false} ref={(r) => {
                popupRef = r;
                setRefReady(true);
            }}>
                <p hidden>{updateMe}</p>
                <div style={{ marginBottom:'16px'}}>
                    <div style={{margin:"auto"}}>
                    <div style={{ marginBottom:'16px'}}>
                        {/* <p className={"favorit"} style={{margin:"auto"}}>Pin {marker[3][0][1] +1}  </p> */}
                        {typeof marker[3][0][6].road !== 'undefined'? <span> <span className={"favorit"} style={{margin:"auto", fontSize:"20px", color:'#291351'}}>{marker[3][0][6].road}, {marker[3][0][4]} </span><br></br></span>:<span><span className={"freightText"} style={{margin:"auto", fontSize:"18px"}}>{marker[3][0][4]} </span><br></br></span>}
                    </div>
                        <LoadScriptNext key={"AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY"} googleMapsApiKey={APIkey}>
                        <GoogleMap
                            key={"AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY"}
                            visible={false}
                            style={{ height: "200px", width: "600px" }}
                        >
                            <div style={{ height: "200px", width: "600px" }}>
                            <StreetViewPanorama
                                position={{
                                    lat: marker[3][0][2][1],
                                    lng: marker[3][0][2][0],
                                    
                                }}
                                options={{
                                    disableDefaultUI:true,
                                    enableCloseButton: false,
                                
                                    addressControl:false,
                                    
                                    clickToGo :false,
                                    visible:true,   
                                    radius:"15000"                    
                                    
                                }}
                                radius={1500}
                                       
                            />
                            </div>
                        </GoogleMap>
                        </LoadScriptNext>
                        
                    </div>
                
                    <div style={{marginTop:"0.5vh"}}>
                        {/* {typeof marker[3][0][6].road !== 'undefined'? <span> <span className={"bold freightText"} style={{margin:"auto"}}>Address: </span><span className={"freightText"}>{marker[3][0][6].road}, {marker[3][0][4]} </span><br></br></span>:<span><span className={"bold freightText"} style={{margin:"auto"}}>Address: </span><span className={"freightText"}>{marker[3][0][4]} </span><br></br></span>} */}
                        {typeof marker[3][0][6].postcode !== 'undefined'? <span> <span className={"favorit"} style={{margin:"auto", fontSize:"16px", color:'#291351'}}>Postcode: </span><span className={"favorit"} style={{ fontSize:"16px", color:'#291351'}}>{marker[3][0][6].postcode} <br></br></span></span>:null }
                        {/* <span className={"bold freightText"} style={{margin:"auto"}}>View: </span><a href={"http://maps.google.com/maps?q=&layer=c&cbll=" + marker[3][0][2][1] +","+ marker[3][0][2][0] + "&cbp=11,0,0,0,0"} target="_blank"> On Google</a> */}
                    </div>

                </div>
                
                <div style={{marginTop:"0.5vh", display: "flex", justifyContent: "space-between"}}>
                    <button 
                        onClick={() => {
                            deleteMarker(marker[3][0][1]);
                        }}
                        className={'generalButton'}
                        style={{maxWidth:"50%", flexGrow: 1, borderRadius: "50px 0px 0px 50px"}}
                    > Remove pin
                    </button>
                    <button 
                        onClick={() => {
                            mp.closePopup();
                            //popupRef
                            //deleteMarker(marker[3][0][1]);
                        }}
                        className={'generalButton'}
                        style={{width:"50%" , borderRadius: "0px 50px 50px 0px"}}
                    > Confirm
                    </button>
                </div>
                                            
            </Popup>
        
        </Marker>  
    )
}

export default React.memo(MarkerPin);

/*
var Stadia_AlidadeSmoothDark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
	maxZoom: 20,
	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
});

*/

