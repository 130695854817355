import React, {useState, useEffect, useContext, useRef} from 'react';
import Map from '../components/mapdetails/BaseMap';
import Layout from '../components/layout/Layout';
import '../pages/main.css';
import { Link } from 'react-router-dom';
import {typeofChargerStateContext} from '../Store';
import {LMC, priorityStateContext, countContext, data2ReturnContext, LAMarkerCountContext, LAStateContext,expandedContext, updateMeContext} from '../Store';
import MapMenuArea from '../components/MapMenuArea';
import LACounters from '../components/LACounters';
import Cards from '../components/mapdetails/Cards';
import {Card} from 'react-bootstrap';
import {BrowserRouter, useHistory} from 'react-router-dom';
import Modal from 'react-modal';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama,
    Autocomplete
  } from "@react-google-maps/api";

  const center = {
    lat: 37.5247596,
    lng: -122.2583719
  };
  const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";




function Location() {
    //const [chargerType, setchargerType] = useContext(typeofChargerStateContext);
    const [activeElements, setActiveElements] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
    const [count, setCount] = useContext(countContext);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [LAMC, setLAMC] = useContext(LAMarkerCountContext);
    const [LAs, setLAs] = useContext(LAStateContext);
    const [expanded, setExpanded] = useContext(expandedContext);
    const [updateMe, setUpdateMe] = useContext(updateMeContext);
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [LM, setLM] = useContext(LMC);

    const [namesSet, setNamesSet] = useState([]);
    const [names, setNames] = useState([]);
    const [tBools, setTBools] = useState([true,false,false,false]);
    const [tabDisabled, setTabDisabled] = useState(true);
    const [tabHighlighted, setTabHighlight] = useState(false);
    const [cBool, setCBool] = useState(false);
    const [load, setLoad] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [conModalOpen, setConModalOpen] = useState(false);
    const [reffReady , setReffReady] = useState(false);
    const [scrollRef , setScrollRef] = useState(useRef(null));
    const [loadingBarWidth , setLoadingBarWidth] = useState("0%");
    const [numDots , setNumDots] = useState(1);
   

    const [mp, setMap] = useState(null);
    const [mp2, setMap2] = useState(null);
    const [mp3, setMap3] = useState(null);
    const [mp4, setMap4] = useState(null);
    
    const history = useHistory();

    const modalRef = useRef(null);
    const conModalRef= useRef(null);
    const bgLoadingRef= useRef(null);
    const refRed = useRef(null);
    const barRef = useRef(null);

    // run this function from an event handler or an effect to execute scroll 
    console.log(LAMC);
    console.log(data2Return);
    
    var c  = 0;
    useEffect(() => {
        //setExpanded([]);
        
        //
        // var body = document.body,
        //  html = document.documentElement;
        // var height =body.scrollHeight;
        // window.scrollTo(0,height*0.17);
        if (reffReady == true) {
            ////console.log(refRed);
            refRed.current.scrollIntoView();
                // setTimeout(function(){
                //     //do what you need here
                //     window.scrollBy(0, -35); 
                // }, 200);
            
        }
        
        
    }, [reffReady]);

    useEffect(() => {
        setActiveElements([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    }, [data2Return]);
    
   
    console.log("data2Return");
    console.log(data2Return);  

    console.log("prioritiesLocation");
    console.log(priorities);  
    
    var keys = Object.keys(data2Return);
    //////////////////console.log("keys");
    //////////////////console.log(keys);

     
    ////////////////////////
    //array of all elements toggels


    function toggleCardActive(index){
        ////////////////console.log(index);
        ////////////////console.log(activeElements);
        var tempActiveElements = activeElements;
        if(tempActiveElements[index] == true){
            tempActiveElements[index] = false;
        } else {
            tempActiveElements[index] = true;
        }
        ////////////////console.log(tempActiveElements);
        setActiveElements(tempActiveElements);
        setUpdateMe(updateMe+1);
    }

    function handleTabButton(index){
        
        
        var doSomething = true;
        var tempTBools = tBools;
        
        var count = 0;
        tempTBools.forEach(TBool => {
            if (TBool == true && count == index){
                doSomething = false;
            }
            count = count + 1;
        })

        if (doSomething){
            tempTBools = [false, false, false , false];
            tempTBools[index] = true;
            setTBools(tempTBools);
        } else {
            tempTBools = [true, false, false , false];
            setTBools(tempTBools);
        }
    }

    const handleHistory = () => {
        history.push("/EvaluateSites");
    }



    function modalOpen2(){
        modalRef.current.classList.add("modalVisible");
        setModalOpen(true);
    }

    function modalClose(){
        modalRef.current.classList.remove("modalVisible");
        
        setModalOpen(false);
        //console.log( loadingBarWidth);
        setTimeout(function(){
            setLoadingBarWidth("20%");
            setTimeout(function(){
                setLoadingBarWidth("40%");
                setTimeout(function(){
                    setLoadingBarWidth("60%");
                    setTimeout(function(){
                        setLoadingBarWidth("80%");
                        setTimeout(function(){
                            setLoadingBarWidth("99%");
                            //looping to set the number of dots didnt work 
                            setTimeout(function(){
                                setNumDots(2);  
                                setTimeout(function(){
                                    setNumDots(3); 
                                    setTimeout(function(){
                                        setNumDots(1); 
                                        setTimeout(function(){
                                            setNumDots(2);   
                                            setTimeout(function(){
                                                setNumDots(3);  
                                                setTimeout(function(){
                                                    setNumDots(1); 
                                                    setTimeout(function(){
                                                        setNumDots(2);                           
                                                        setTimeout(function(){
                                                            setNumDots(3);  
                                                            setTimeout(function(){
                                                                setNumDots(1); 
                                                                setTimeout(function(){
                                                                    setNumDots(2);                           
                                                                    setTimeout(function(){
                                                                        setNumDots(3); 
                                                                        setTimeout(function(){
                                                                            setNumDots(1); 
                                                                            setTimeout(function(){
                                                                                setNumDots(2);                           
                                                                                setTimeout(function(){
                                                                                    setNumDots(3); 
                                                                                    setTimeout(function(){
                                                                                        setNumDots(1); 
                                                                                        setTimeout(function(){
                                                                                            setNumDots(2);                           
                                                                                            setTimeout(function(){
                                                                                                setNumDots(3);
                                                                                                setTimeout(function(){
                                                                                                    setNumDots(1); 
                                                                                                    setTimeout(function(){
                                                                                                        setNumDots(2);                           
                                                                                                        setTimeout(function(){
                                                                                                            setNumDots(3); 
                                                                                                            setTimeout(function(){
                                                                                                                setNumDots(1); 
                                                                                                                setTimeout(function(){
                                                                                                                    setNumDots(2);                           
                                                                                                                    setTimeout(function(){
                                                                                                                        setNumDots(3);        
                                                                                                                        setTimeout(function(){
                                                                                                                            setNumDots(1); 
                                                                                                                            setTimeout(function(){
                                                                                                                                setNumDots(2);                           
                                                                                                                                setTimeout(function(){
                                                                                                                                    setNumDots(3);
                                                                                                                                    setTimeout(function(){
                                                                                                                                        setNumDots(1); 
                                                                                                                                        setTimeout(function(){
                                                                                                                                            setNumDots(2);                           
                                                                                                                                            setTimeout(function(){
                                                                                                                                                setNumDots(3);                           
                                                                                                                                                setTimeout(function(){
                                                                                                                                                    setNumDots(1); 
                                                                                                                                                    setTimeout(function(){
                                                                                                                                                        setNumDots(2);                           
                                                                                                                                                        setTimeout(function(){
                                                                                                                                                            setNumDots(3);                
                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                setNumDots(1); 
                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                    setNumDots(2);                           
                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                        setNumDots(3);  
                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                            setNumDots(1); 
                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                setNumDots(2);                           
                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                    setNumDots(3);
                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                        setNumDots(1); 
                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                            setNumDots(2);                           
                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                setNumDots(3);
                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                    setNumDots(1); 
                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                        setNumDots(2);                           
                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                            setNumDots(3); 
                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                setNumDots(1); 
                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                    setNumDots(2);                           
                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                        setNumDots(3);            
                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                            setNumDots(1); 
                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                setNumDots(2);                           
                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                    setNumDots(3);       
                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                        setNumDots(1); 
                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                            setNumDots(2);                           
                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                setNumDots(3);                   
                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                    setNumDots(1); 
                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                        setNumDots(2);                           
                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                            setNumDots(3);       
                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                setNumDots(1); 
                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                    setNumDots(2);                           
                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                        setNumDots(3);   
                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                            setNumDots(1); 
                                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                                setNumDots(2);                           
                                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                                    setNumDots(3);                           
                                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                                        setNumDots(1); 
                                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                                            setNumDots(2);                           
                                                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                                                setNumDots(3);                           
                                                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                                                    setNumDots(1); 
                                                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                                                        setNumDots(2);                           
                                                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                                                            setNumDots(3);                           
                                                                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                                                                setNumDots(1); 
                                                                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                                                                    setNumDots(2);                           
                                                                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                                                                        setNumDots(3);                     
                                                                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                                                                            setNumDots(1); 
                                                                                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                setNumDots(2);                           
                                                                                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                    setNumDots(3);         
                                                                                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                        setNumDots(1); 
                                                                                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                            setNumDots(2);                           
                                                                                                                                                                                                                                                                                                                                            setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                                setNumDots(3); 
                                                                                                                                                                                                                                                                                                                                                setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                                    setNumDots(1); 
                                                                                                                                                                                                                                                                                                                                                    setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                                        setNumDots(2);                           
                                                                                                                                                                                                                                                                                                                                                        setTimeout(function(){
                                                                                                                                                                                                                                                                                                                                                            setNumDots(3);                           
                                                                                                                                                                                                                                                                                                                                                        }, 801);
                                                                                                                                                                                                                                                                                                                                                    }, 801);                          
                                                                                                                                                                                                                                                                                                                                                }, 801);                          
                                                                                                                                                                                                                                                                                                                                            }, 801);
                                                                                                                                                                                                                                                                                                                                        }, 801);                          
                                                                                                                                                                                                                                                                                                                                    }, 801);                  
                                                                                                                                                                                                                                                                                                                                }, 801);
                                                                                                                                                                                                                                                                                                                            }, 801);                          
                                                                                                                                                                                                                                                                                                                        }, 801);      
                                                                                                                                                                                                                                                                                                                    }, 801);
                                                                                                                                                                                                                                                                                                                }, 801);                          
                                                                                                                                                                                                                                                                                                            }, 801);
                                                                                                                                                                                                                                                                                                        }, 801);
                                                                                                                                                                                                                                                                                                    }, 801);                          
                                                                                                                                                                                                                                                                                                }, 801);
                                                                                                                                                                                                                                                                                            }, 801);
                                                                                                                                                                                                                                                                                        }, 801);                          
                                                                                                                                                                                                                                                                                    }, 801);
                                                                                                                                                                                                                                                                                }, 801);
                                                                                                                                                                                                                                                                            }, 801);                          
                                                                                                                                                                                                                                                                        }, 801);                        
                                                                                                                                                                                                                                                                    }, 801);
                                                                                                                                                                                                                                                                }, 801);                          
                                                                                                                                                                                                                                                            }, 801);                    
                                                                                                                                                                                                                                                        }, 801);
                                                                                                                                                                                                                                                    }, 801);                          
                                                                                                                                                                                                                                                }, 801);        
                                                                                                                                                                                                                                            }, 801);
                                                                                                                                                                                                                                        }, 801);                          
                                                                                                                                                                                                                                    }, 801);                    
                                                                                                                                                                                                                                }, 801);
                                                                                                                                                                                                                            }, 801);                          
                                                                                                                                                                                                                        }, 801);               
                                                                                                                                                                                                                    }, 801);
                                                                                                                                                                                                                }, 801);                          
                                                                                                                                                                                                            }, 801);                          
                                                                                                                                                                                                        }, 801);
                                                                                                                                                                                                    }, 801);                          
                                                                                                                                                                                                }, 801);                           
                                                                                                                                                                                            }, 801);
                                                                                                                                                                                        }, 801);                          
                                                                                                                                                                                    }, 801);                           
                                                                                                                                                                                }, 801);
                                                                                                                                                                            }, 801);                          
                                                                                                                                                                        }, 801);                         
                                                                                                                                                                    }, 801);
                                                                                                                                                                }, 801);                          
                                                                                                                                                            }, 801);           
                                                                                                                                                        }, 801);
                                                                                                                                                    }, 801);                          
                                                                                                                                                }, 801);
                                                                                                                                            }, 801);
                                                                                                                                        }, 801);                          
                                                                                                                                    }, 801);                           
                                                                                                                                }, 801);
                                                                                                                            }, 801);                          
                                                                                                                        }, 801);                   
                                                                                                                    }, 801);
                                                                                                                }, 801);                          
                                                                                                            }, 801);                          
                                                                                                        }, 801);
                                                                                                    }, 801);                          
                                                                                                }, 801);                           
                                                                                            }, 801);
                                                                                        }, 801);                          
                                                                                    }, 801);                          
                                                                                }, 801);
                                                                            }, 801);                          
                                                                        }, 801);                          
                                                                    }, 801);
                                                                }, 801);                          
                                                            }, 801);                         
                                                        }, 801);
                                                    }, 801);                          
                                                }, 801);                         
                                            }, 801);                        
                                        }, 801);                          
                                    }, 801);                          
                                }, 801);                         
                            }, 801);
                        }, 150);
                    }, 150);
                }, 150);
            }, 150);
        }, 150);

    }


    function confirationModalOpen(){
        conModalRef.current.classList.add("modalVisible");
        setConModalOpen(true);
    }

    function confirationModalOpenClose(){
        conModalRef.current.classList.remove("modalVisible");
        setConModalOpen(false);
    }
    
    return(        
        <Layout>

            {/* <button onClick={() => {modalOpen2()}}>Open Modal</button> */}
            <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}} >
                <h1 style={{fontSize:"30px" }} className="main-title" ref={refRed}>Place your charge points</h1>
            </div>

            <div style={{ marginTop: "60px",margin: "44px 19%", marginBottom: "15px",height: "10px", width: "62%", position: "relative"}} >
                <div class="favoritMono" style={{position: "absolute", color: "#b2b2b2", top: "-23px", left: "40.5%"}}>Step 3/5</div>
                <div class="favoritMono" style={{position: "absolute", color: "#261b3f", top: "-23px", right: "40.5%", float: "right", zIndex: "99"}}>60%</div>
                <div class="barz" style={{position: "absolute", height: "10px", width: "100%",}}></div>

                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"19%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"39.25%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"59.5%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"79.75%", top:"-4px"}}></div>

                <div style={{position:"absolute", height:"10px", width:"60%", zIndex:"98"}} className="barzTop"></div>
            </div>

            <div className={'main'} >
                <div ref={modalRef} style={{height:'100vh', position:'fixed', top:'0', left:'0', width:'100%', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex:'10000', display:'none', justifyContent:'center', alignItems:'center'}} className={modalOpen?'modalVisible':''}>
                    <div style={{backgroundColor: "#261b3f", zIndex: 1 ,width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex',}}>
                        <div style={{  position:'relative', marginLeft:'50px', backgroundColor: "#261b3f", zIndex: 2,width:'80%', height:'70%',}}>
                                <h1 style={{color:"#fff", marginBottom: "10px"}}>How to select sites</h1>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{width:'48%', fontSize:'2.5vh'}}>
                                        
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>Zoom in to street level at your chosen location.</div>
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>Double click on the map to place a pin.</div>
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>Use street view to explore the location further.</div>
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>Find areas by adding data layers and points of interest from the sidebar.</div>
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>Confirm your sites are suitable by reviewing scorecards from the sidebar.</div>
                                        <div className='modalList' style={{color:"#fff", margin:'15px 0'}}>When you’ve finished placing pins, click “Next” to analyse your sites and generate a report.</div>
                                    </div>
                                    <div style={{width:'48%'}}>
                                        <div style={{height:'300px', width:'100%', marginTop:"22px"}}>
                                            <img src="/instrucImg.png" style={{ width: "100%", height: "auto ", border: '1px solid #291351' }} />

                                        </div>
                                    </div>
                                </div>

                                <div style={{ position:'relative', top: "10%", left:'0px', cursor:'pointer', width:'24%'}}>
                                    <button className="generalButton gs" style={{borderRadius: "25px", backgroundColor: "#261b3f", border: "3px solid #CC4884", color: "#cc4884", lineHeight: "0px"}} onClick={() => {
                                        
                                        modalClose()

                                    }}>GET STARTED    →</button> 
                                </div>
                            
                        </div>
                    </div>
                </div>

                <div ref={conModalRef} style={{backgroundColor:'#261b3f', height:'100vh', position:'fixed', top:'0', left:'0', width:'100%', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex:'10000', display:'none', justifyContent:'center', alignItems:'center'}} className={conModalOpen?'modalVisible':''}>
                    <div style={{backgroundColor:'#261b3f', width:'100%', height:'100%', position:'relative', justifyContent:'center', alignItems:'center'}}>
                        
                            <h5 style={{textAlign: "center", position: "relative", color: "white", top: "40%", fontSize: "32px", fontFamily: "Favorit"}} >Have you finished selecting sites?</h5>
                                    
                            <div style={{display: "flex", justifyContent: "space-between", position: "relative", top: "49%"}}>
                                <button className="favoritMono noButton" style={{ position:'relative', backgroundColor:'#261b3f', cursor:'pointer', width:'15%', height: "3.5%", left: "33%", borderRadius: "39px", border:"3px solid #ededed", fontSize: "24px", color: "#ededed", lineHeight: "40px"}} onClick={() => {confirationModalOpenClose()}}>← NO</button> 
                                                                
                                <button className="favoritMono yesButton" style={{ position:'relative', backgroundColor:'#261b3f', cursor:'pointer', width:'15%', height: "3.5%", right: "33%", borderRadius: "39px", border:"3px solid #cc4884", fontSize: "24px", color: "#cc4884", lineHeight: "40px"}} onClick={() => {handleHistory()}}>YES →</button> 
                            </div>
                        
                    </div>
                </div>

                <div ref={bgLoadingRef} style={{height:'100vh', position:'fixed', top:'0', left:'0', width:'100%', backgroundColor: '#261b3f', zIndex:'9999', display: "none", justifyContent:'center', alignItems:'center'}} className={LM?'modalVisible':''}>
                    <div style={{   marginTop: "10vh", width:'60%', height:'15%', position:'relative', padding:'40px', marginBottom: "68px", paddingTop: "16px"}}>
                        <div style={{justifyContent:"center", alignContent: "center"}}>
                            <h5 style={{textAlign: "center", color: "#fff", visibility: "hidden", position:"absolute", width: "100%", top: "-28px"}} className={numDots == 1 ? "sub-title viz" : "sub-title"}>Data loading.</h5>
                            <h5 style={{textAlign: "center", color: "#fff", visibility: "hidden", position:"absolute", width: "100%", top: "-28px"}} className={numDots == 2 ? "sub-title viz" : "sub-title"}>Data loading..</h5>
                            <h5 style={{textAlign: "center", color: "#fff", visibility: "hidden", position:"absolute", width: "100%", top: "-28px"}} className={numDots == 3 ? "sub-title viz" : "sub-title"}>Data loading...</h5>
                            <div style={{position: "absolute", height:"10px", zIndex:"98"}} className={loadingBarWidth == "0%" ? "barzTop w0" : loadingBarWidth == "20%" ? "barzTop w20" : loadingBarWidth == "40%" ? "barzTop w40" : loadingBarWidth == "60%" ? "barzTop w60": loadingBarWidth == "80%" ? "barzTop w80" : "barzTop w99"}></div>
                            <div style={{position: "absolute", height:"10px", width:"100%", zIndex:"97", backgroundColor: "rgb(21 15 33)" }}></div>  
                         
                        </div>
                    </div>
                </div>

                <section className="tabContainer">
                    <div className="tab">
                        <button 
                            className={tBools[0] == true ? "tabSelected":
                                "tablinks"} 
                            onClick={() => {
                                handleTabButton(0);
                            }}
                        >
                            <img src="/MAPMapIcon.svg"  height='22' width='22' className={tBools[0] == true ? "activesidebarIcons":
                                "inActivesidebarIcons"}/>
                        </button>  

                        <button 
                            className={tBools[4] == true ? "tabSelected":
                                "tablinks"} 
                            onClick={() => {
                                handleTabButton(4);
                            }}
                        >
                            <img src="/MAPLayersIcon.svg" height='27' width='27' className={tBools[4] == true ? "activesidebarIcons":
                                "inActivesidebarIcons"} />
                        </button>  

                        <button 
                            className={tBools[2] == true ? "tabSelected":
                                "tablinks"} 
                            onClick={() => {
                                handleTabButton(2);
                            }}
                        >
                            <img src="/MAPStatsIcon.svg"  height='22' width='22' className={tBools[2] == true ? "activesidebarIcons":
                                "inActivesidebarIcons"}/>
                        </button>  

                        <button 
                            className={tBools[1] == true ? "tabSelected":
                                "tablinks"} 
                            onClick={() => {
                                handleTabButton(1);
                            }}
                        >
                            <img src="/MAPLocationIcon.svg"  height='22' width='22' className={tBools[1] == true ? "activesidebarIcons":
                                "inActivesidebarIcons"}/>
                        </button>  

                        <button     className= {tabDisabled == true? "disabledTab" :
                                            tabHighlighted == true ? "highlightTab":
                                            tBools[3] ? "tabSelected":
                                            "tablinks" }
                                    onClick={() => {
                                        handleTabButton(3);
                                        setTabHighlight(false);
                                    }}
                                    disabled={tabDisabled == true ? true :
                                        false}
                        >
                            <img src="/MAPPlacedPinsIcon.svg"  height='22' width='22' className={tBools[3] == true ? "activesidebarIcons":
                                "inActivesidebarIcons"}/>
                        </button>

                    </div>
                </section>

                <section  style={{  height:'75vh', marginBottom: '12px'}} >
                    <div style={{ width: '100%'}}>
            
                        <div className = { tBools[0] == true || tBools[1] == true || tBools[2] == true || tBools[3] == true || tBools[4] == true? 'classOn':'classOff'}>
                            <section id="maps">
                                <div>
                                    <Map mp={mp} setMap={setMap} mp2={mp2} setMap2={setMap2} mp3={mp3} setMap3={setMap3} mp4={mp4} setMap4={setMap4} tBools={tBools} setTBools={setTBools} c={c} toggleCardActive={toggleCardActive}
                                        keys={keys} data2Return={data2Return} activeElements={activeElements}
                                        tabDisabled={tabDisabled} setTabDisabled={setTabDisabled}
                                        tabHighlighted={tabHighlighted} setTabHighlight={setTabHighlight} 
                                        cBool={cBool} setCBool={setCBool} load={load} setLoad={setLoad} setReffReady={setReffReady} handleTabButton={handleTabButton}
                                    />
                                </div>
                            </section>
                        </div>                        
                    </div>
                </section>

                <div style={{ width:'48%', paddingBottom:"30px" }}>
                    <div style={{width:'48%'}}>
                        <button type={"button"} className={"nextButton"} style={{ width:"100%", textAlign:"center"}}   onClick={(e) => {
                                
                            if(load == false){
                                confirationModalOpen();
                            } else {
                                alert("Please wait for pin you just placed to finish loading");
                            }            
                        }}> 
                            <span className ="favorit">Next</span> {/*<span style={{marginLeft:"11px"}}><i className={"fa fa-arrow-right"}></i></span>*/}
                        </button>
                    </div>
                </div>                    
            </div>
        </Layout>
    );
}

export default Location;