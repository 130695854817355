import React, { useState, useRef, useContext } from "react";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { GradientPinkBlue } from "@visx/gradient";
import letterFrequency, {
    LetterFrequency
} from "@visx/mock-data/lib/mocks/letterFrequency";
import { timeParse, timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import { animated, useTransition, interpolate } from "react-spring";

import { BarGroupHorizontal, Bar } from "@visx/shape";

import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import { LegendOrdinal, LegendItem,LegendLabel } from '@visx/legend';
import { AxisLeft, AxisBottom } from "@visx/axis";
import {
    AnimatedAxis, // any of these can be non-animated equivalents
    AnimatedGrid,
    AnimatedLineSeries,
    buildChartTheme,
    XYChart,
    Tooltip,
} from '@visx/xychart';

import {LAStateContext} from '../../Store';


var count15 = 0;
var count16 = 0;

const legendGlyphSize = 15;




export default function Example({ height, background, marginTopV, marginLeftV, getDate, dateScale, cityScale, tempScale, colorScale, proData, keys,  green, formatDate, ordinalColorScale, handleSRSUpdate, checked }) {

    //////////////console.log(dataa);
    // var data = [];
    // dataa.forEach(dSet => {
    //     if(dSet[0] == "bar"){
    //        data.push(dSet[2]);
    //     }
    // });
    // //////////////console.log(data);
    const yMax = height - marginTopV - marginTopV;

    const data = [
        { 'date': '2011-10-01', 'New York': '63.4', 'San Francisco': '62.7', 'Austin': '20.2' },
        { 'date': '2011-10-02', 'New York': '58.0', 'San Francisco': '59.9', 'Austin': '67.7' },
        { 'date': '2011-10-03', 'New York': '53.3', 'San Francisco': '9.1', 'Austin': '2.4' },
        { 'date': '2011-10-04', 'New York': '5.7', 'San Francisco': '5.8', 'Austin': '5.0' }
    ]

    const [LAs, setLAs] = useContext(LAStateContext);

    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });
    
    var numberOfLAs = validatedLAs.length;

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, datum, dat, label) => {
        //////////console.log("mouse");
        ////////////console.log(event);
        ////////////////console.log(datum);
        //////////////console.log(dat)
        var sum = ""+label+" "+dat;
        ////////////console.log(containerBounds.left);
        ////////////console.log(containerBounds.top);
        
        
        const coords = localPoint(event.target.ownerSVGElement, event);
        ////////////console.log(coords);
        //////////////console.log(containerRef);
        
        showTooltip({
          tooltipLeft: event.pageX,
          tooltipTop: event.pageY,
          tooltipData: sum
        });
    };

    let { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
        detectBounds: true,
    
    });

    var events = false;

    const x = d => d.letter;
    const y = d => +d.frequency * 100;

    
//changes ive done to style "paddingBottm:"10"px
//width was 76%   for charts => 80%
//distance from "top" = "marginTopV-10" //   . Was previosuly "marginTopV"
    return (
        <div style={{ display: 'flex',paddingBottom:"10px" ,justifyContent: 'space-between', marginBottom: '20px', backgroundColor:'#ededed', alignItems:'center' }}>
                                                                          
            <div style={{ width: '100%', height:height}} >
                <>
                <svg width={'100%'} height={'100%'} ref={containerRef}>
                    
                    <rect
                        x={0}
                        y={0}
                        width={'100%'}
                        height={'100%'}
                        fill={background}
                        rx={0}
                    />
                    <Group top={marginTopV-10} left={marginLeftV}>
                        <BarGroupHorizontal
                            data={proData}
                            keys={keys}
                            width={"100%"}
                            y0={getDate}
                            y0Scale={dateScale}
                            y1Scale={cityScale}
                            xScale={tempScale}
                            color={colorScale}
                        >
                            {(barGroups) =>
                                barGroups.map((barGroup) => {
                                count15= count15 +1;
                                count16 = 0;
                                return(
                                    <Group
                                        key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                                        top={barGroup.y0}
                                    >
                                        {barGroup.bars.map((bar) => {
                                        count16 =count16 +1;
                                        let x = count16
                                        return(
                                            <Bar
                                                key={`${barGroup.index}-${bar.index}-${bar.key}`}
                                                x={bar.x}
                                                y={bar.y}
                                                width={bar.width}
                                                height={'30px'}
                                                fill={bar.color}
                                                rx={0}
                                                
                                                onMouseOver={(e,d) => {
                                                    let keysss = Object.keys(proData[0]);
                                                    handleMouseOver(e,d,bar.value,keysss[x]);
                                                }}
                                                onMouseOut={hideTooltip}
                                            />
                                        )})}
                                        
                                    </Group>
                                )})
                            }
                        </BarGroupHorizontal>
                        <AxisLeft
                            top={0}
                            scale={dateScale}
                            stroke={green}
                            tickStroke={green}
                            tickFormat={formatDate}
                            hideAxisLine = {true}
                            hideTicks = {true}
                            tickLabelProps={() => ({
                            fill: '#291351',
                            fontSize: 16,
                            width:'1px',
                            textAnchor: 'end',
                            dy: '0.33em',
                            })}
                        />
                        {/* <AxisBottom
                            top={0}
                            scale={tempScale}
                            stroke={green}
                            tickStroke={green}
                            tickFormat={formatDate}
                            hideAxisLine = {true}
                            hideTicks = {true}
                            tickLabelProps={() => ({
                            fill: '#291351',
                            fontSize: 16,
                            width:'1px',
                            textAnchor: 'end',
                            dy: '0.33em',
                            })}
                        /> */}
                        <AxisBottom
                            top={yMax-25}
                            scale={tempScale}
                            stroke={'#291351'}
                            tickStroke={'#291351'}
                            tickLabelProps={() => ({
                                fill: '#291351',
                                fontSize: 11,
                                textAnchor: 'middle',
                                opacity: 1,
                                dy: '0.33em',
                            })}
                        /> 
                        
                        
                        </Group>
                
                </svg>
                {tooltipOpen && (
                    <TooltipWithBounds
                    // set this to random so it correctly updates with parent bounds
                    key={Math.random()}
                    top={tooltipTop}
                    left={tooltipLeft}
                    >
                    {tooltipData}
                    </TooltipWithBounds>
                )}
                
                    
                
                </>
            </div>
            <div style={{ width: '400px', height:height, minHeight:'250px'}} >
                <div style={{padding:'20px', marginLeft: "25px", position:'relative'}}>
                        <div style={{position:'absolute', top:'0', right:'20px'}} onChange={handleSRSUpdate} ><label className="radioBContainer favorit"  style={{padding:'0'}}><input type="checkbox" value={0} name="overlaySelectedVar"  checked={checked == true? true : false}/> <span className="checkmark checkmarkWidget"></span></label></div>
                    <div style={{height:'250px', border:'0px solid #cccccc'}}>  
                        <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label}`}>
                            {(labels) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {labels.map((label, i) => (
                                    <LegendItem
                                    key={`legend-quantile-${i}`}
                                    margin="10px 5px"
                                    onClick={() => {
                                        if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                    }}
                                    >
                                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                                        <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                    </svg>
                                    <LegendLabel align="left" style={{margin:'0 0 0 10px', color:'#291351'}}>
                                        {label.text}
                                    </LegendLabel>
                                    </LegendItem>
                                ))}
                                </div>
                            )}
                        </LegendOrdinal>
                    </div> 
                </div>
            </div>
        </div>
    );
}
