import React , {useContext, useState, useEffect} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import {selectedPositionContext} from '../../Store';
import styled from 'styled-components';
import Slider, { createSliderWithTooltip } from 'rc-slider';

const style = { width: '100%' };
const SliderWithTooltip = createSliderWithTooltip(Slider);

function log(value) {
 ////////////////console.log(value); //eslint-disable-line
}

function percentFormatter(v) {
  return `${v}`;
}





function Sliders2 ({subData2Return, setSubData2Return, sliderValues, setSliderValues, index, laIndex, index2}){
  


  return(
   
    <div>
      <div style={style}>
        {/*<p>Slider with tooltip, with custom `tipFormatter`</p>*/}
        <SliderWithTooltip
          tipFormatter={percentFormatter}
          tipProps={{ overlayClassName: 'foo' }}

          onAfterChange={(value)=>{
            var val = value;
            var before = subData2Return[laIndex][1][index][0][9][index2];
            //////////////console.log(val+" "+before);
            var sliderValuesTemp = sliderValues;
            //////////////console.log("svChange");
            //////////////console.log(sliderValues); 
            sliderValuesTemp[index2] = value;
            //////////////console.log(sliderValues); 
            //setSliderValues(sliderValuesTemp);

            var newD2R = [];
            var tempSubData2Return = subData2Return;
            //////////////console.log(tempSubData2Return);

            var count = 0;
            tempSubData2Return.forEach( la => {
              newD2R.push([]);
              newD2R[count].push(la[0]);
              newD2R[count].push([]);
              

              let count2 = 0
              la[1].forEach(pin => {
                newD2R[count][1].push([]);

                let count3 = 0;
                pin.forEach(dSet => {
                  //////////////console.log(count3);
                  //////////////console.log(0);
                  //////////////console.log(count);
                  //////////////console.log(laIndex);
                  //////////////console.log(count2);
                  //////////////console.log(index);
                  
                  if (count3 == 0 && count == laIndex && count2 == index){
                    //////////////console.log("dddddddddddd");
                    // let temp = newD2R[count][1][count2][0];
                    // temp[8][index2] 
                    newD2R[count][1][count2].push([]);
                    var count4 = 0;
                    dSet.forEach(d => {
                      if(count4 == 9){
                        //////////////console.log("edit");
                        newD2R[count][1][count2][0].push(sliderValues);
                      } else {
                        newD2R[count][1][count2][0].push(d);
                      }
                      count4 = count4 + 1;
                    })

                  } else {
                    newD2R[count][1][count2].push(dSet);
                  }
                  count3 = count3 + 1;
                })
                count2 = count2 +1;
              });

              count = count + 1;
            })

            // tempSubData2Return[laIndex][1][index][0][8][index2] = value;
            // tempSubData2Return[laIndex][index].relatedDatasets.forEach(dSet => {
            //   var splitz = dSet.split(".");
            //   splitz[14] = value;
            //   var reconstruct = "";
            //   splitz.forEach(part =>{
            //     reconstruct += part + ".";
            //   })
            //   reconstruct = reconstruct.substring(0, reconstruct.length - 1);
            //   ////////////////console.log(reconstruct);
            //   newRelated.push(reconstruct);
            // })
            // tempFactors[factorId].relatedDatasets = newRelated;

            //////////////console.log(subData2Return);
            //////////////console.log(newD2R);
            setSubData2Return(newD2R); 


          }}
          dots
          step={1}
          defaultValue={5}
          
          dotStyle={{ borderColor: 'transparent', backgroundColor:'transparent' }}
          activeDotStyle={{ borderColor: 'transparent', backgroundColor:'transparent' }}
          min={1}
          max={5}
          defaultValue={0}
          railStyle={{ backgroundColor: '#cccccc', height:'5px' }}
          trackStyle={{ backgroundColor: '#db0052', height:'5px' }}
          handleStyle={{
            borderColor: 'white',
            height: 20,
            width: 20,
            marginTop: -8,
            backgroundColor: '#db0052',
          }}
        />
      </div>
    </div>
    
  )
}


export default Sliders2