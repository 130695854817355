import React , {useContext, useState} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import {selectedPositionContext} from '../../Store';
import styled from 'styled-components';
import Slider, { createSliderWithTooltip } from 'rc-slider';

const style = { width: '100%' };
const SliderWithTooltip = createSliderWithTooltip(Slider);

function log(value) {
 ////////////////console.log(value); //eslint-disable-line
}

function percentFormatter(v) {
  return `${v}`;
}





function Sliderr ({factors,setFactors,factorValues,setFactorValues,defaultValue,factorId}){
  


  return(
   
    <div>
      <div style={style}>
        {/*<p>Slider with tooltip, with custom `tipFormatter`</p>*/}
        <SliderWithTooltip
          tipFormatter={percentFormatter}
          tipProps={{ overlayClassName: 'foo' }}
          onChange={(value)=>{
            var tempFactorValues = factorValues;
            tempFactorValues[factorId] = value;
            ////////////////console.log(value); 
            setFactorValues(tempFactorValues);

            var newRelated = [];
            var tempFactors = factors;
            tempFactors[factorId].relatedDatasets.forEach(dSet => {
              var splitz = dSet.split(".");
              splitz[14] = value;
              var reconstruct = "";
              splitz.forEach(part =>{
                reconstruct += part + ".";
              })
              reconstruct = reconstruct.substring(0, reconstruct.length - 1);
              ////////////////console.log(reconstruct);
              newRelated.push(reconstruct);
            })
            tempFactors[factorId].relatedDatasets = newRelated;

            setFactors(tempFactors); 

          }}
          dots
          step={1}
          defaultValue={5}
          onAfterChange={log}
          dotStyle={{ backgroundColor:'#ccc', width:'1px', height:'40px', border:'1px solid #ccc', borderRadius:'0'}}
          activeDotStyle={{ backgroundColor:'#ccc', width:'1px', height:'40px', border:'1px solid #ccc', borderRadius:'0' }}
          min={1}
          max={5}
          defaultValue={0}
          railStyle={{ backgroundColor: '#cccccc', height:'5px' }}
          trackStyle={{ backgroundColor: '#db0052', height:'5px' }}
          handleStyle={{
            borderColor: 'white',
            height: 20,
            width: 20,
            marginTop: -8,
            backgroundColor: '#db0052',
          }}
        />
      </div>
    </div>
    
  )
}


export default Sliderr