import React, {useState, useEffect, useContext} from 'react';
import {legContext,priorityStateContext, datasetConfigStringsContext,   layerRefsContext,  factorsContext, legendsContext, dataBoolContext} from '../../Store';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';




function ClearLayers({mp, setMap, info, clearButtonRef, setClearButtonRef}){
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [datasetConfigStrings, setDatasetConfigStrings] = useContext(datasetConfigStringsContext);
    const [factors, setFactors] = useContext(factorsContext); 
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [legends, setLegends] = useContext(legendsContext);
    const [dataBool, setDataBool] = useContext(dataBoolContext);
    const [leg, setLeg] = useContext(legContext);


    
    const [POI, setPOI] = useState([]);
    
    const [counter1, setCounter1] = useState(105);
    const [counter2, setCounter2] = useState(150);

    var c = 705;
    var c = 770;
    
    useEffect(() => {
        
    }, []);
   
    
    function handleDSetSelector (event){
       ////////////////console.log("whereC");

        if(event.target.value == "satalite" || event.target.value == "normal"){
            layerRefs.forEach(layer => {
                if (layer[2] == "base"){
                    mp.removeLayer(layer[1]);
                    mp.removeControl(layer[1]);
                }  
            })
            layerRefs.forEach(layer => {
               ////////////////console.log(layer[0] + "//" + event.target.value);
                if(layer[0] == event.target.value){

                    layer[1].addTo(mp);
                }
                
            })
        } else {
            //////////////console.log("clear");
            var keys = Object.keys(legends);
            keys.forEach(key => {
                //mp.removeControl(legends[key][0]);
                //change
                
                if(legends[key][1] == "poly"){
                    legends[key][0].setClass("fadeout");
                    
                } 
            })
            setTimeout(function(){
                keys.forEach(key => {
                    mp.removeControl(legends[key][0]);
                    //change
                    layerRefs.forEach(layer => {
                        mp.removeLayer(layer[1]);
                        mp.removeControl(layer[1]);  
                    })
                    layerRefs.forEach(layer => {
                       ////////////////console.log(layer[0] + "//" + event.target.value);
                        if(layer[0] == event.target.value){
        
                            layer[1].addTo(mp);
                        }
                        
                    })
                    // keys.forEach(key => {
                    //     if(key == event.target.value){
                    //         legends[key][0].addTo(mp);
                    //     }
                    // })
                    
        
                })
            }, 1000);
            
            
           ////////////////console.log(layerRefs);
            
        } 
        if(dataBool == true){
            setDataBool(false);  
            info.setClass(false);  
            info.update();  
            // var inp = document.createElement("input");
            // inp.setAttribute("type", "radio");
            // inp.setAttribute("value", "clear2");
            // inp.setAttribute("name", "overlaySelectedVar");
            // inp.setAttribute("checked", "checked"); 
           ////////////////console.log("clear"); 
            clearButtonRef.current.checked = true;
            setLeg("out");
        }
    }
    var button;

    if (dataBool) {
      button = <span className='MapClearicon' title='Clear Map Data'><a className='closeDataBoxButton'>×</a></span>;
  } else {
      button = <span className='MapClearicon'><i class='fa fa-close'></i></span>;
  }

    return(
        <div  key ={701}>
            <div key ={702}>
                    <button key = {c+3} type={"button"} style={{ border:"0px", backgroundColor:'transparent' }} onClick={handleDSetSelector} name="overlaySelectedVar" value="clear"> <span className='favorit'>

                    <span className={ dataBool == false? "MapClearbutton x0" 
                    : "MapClearbutton x1"}>
                        {button}
                    </span>

                    </span></button>

            </div>          
        </div>
    )
}

export default ClearLayers
