import React , {useContext, useState, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import L, { featureGroup } from 'leaflet';
import {Card} from 'react-bootstrap';
import Sliders2 from '../functional/Sliders2';
import Layout from '../layout/Layout';

import {data2ReturnContext, minMaxObjContext, rankingsContext} from '../../Store';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";


import {
    Chart,
    Interval,
    Tooltip,
    Line,
    Axis,
    Coordinate,
    Legend,
    View,
    Geom 
} from 'bizcharts';


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

const scale = {
    indivData:{
      type:"linear",
      min:0,
      max:5,
    },
  }


function SummaryReportCards({card, handleSRSUpdate, value, checked}) {
    ////////console.log(card);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [rankings, setRankings] = useContext(rankingsContext);

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [totalScore, setTotalScore] = useState(null);




    const [sliderValues, setSliderValues] = useState([]); 
    const [slideVisibility, setslideVisibility] = useState(true);

    useEffect(() => {
        
        setData(card[0][12]);
        setLoading(false);
    }, []);

    function tog (){
        if(slideVisibility == true){
            setslideVisibility(false);
        } else {
            setslideVisibility(true);
        }
    }

    const currentUrl = (window.location.href).split('/');
    const currentPage = (currentUrl[currentUrl.length - 1])
    // ////console.log(currentUrl);
    // ////console.log(currentPage);

    const comment = card[0][11]

    if (isLoading) {
        return (
          <Layout>
          <section>
            <p>Loading...</p>
          </section>
          </Layout>
        );
    } else {
        ////////console.log(data);
        return(
            <div style={{  height:'auto ', overflowY:'scroll', marginBottom:'0', display: true == true ? 'block' : 'none', width: currentPage == 'printReport'? '100%':'' }} className={"pinDiv hiddenScrollBar"}>
                <div style={{borderBottom:'1px solid black', padding:'20px'}}>
                     <h6 className="sub-title sub-sub-title" style={{ marginBottom:'0px'}}>{typeof card[0][5] !== 'undefined' ? card[0][5] : card[0][4]}</h6>
                </div>
                <div style={{ padding:'20px 0px 0px'}}>
                
                <span  style={{ visibility: currentPage == 'report2'? 'visible': 'hidden'}}>
                <div style={{position:'relative'}}>
                    <div style={{position:'absolute', top:'0', right:'20px'}} onChange={handleSRSUpdate}><label className="radioBContainer favorit"  style={{padding:'0'}}><input type="checkbox" name="overlaySelectedVar" value= {value} defaultChecked = {checked}/> <span className="checkmark checkmarkWidget"></span></label></div>
                </div>
                </span>
                    <div style={{ backgroundColor:'#e7e9eb', border:'2px solid #cccccc', padding:'20px 20px 0px' }}>
                    
                        <div style={{width: '100%', textAlign:'center'}}>
                            <div style={{ height:'400px', width:'100%', display:'inline-block', paddingTop:'25px' }}>
                                <Chart height={"350px"} scale={scale} data={data} autoFit>
                                    <Coordinate type="polar" innerRadius={0.2} />
                                    {/*<Line position="sales" lineSize={2} />*/}
                                    <Axis visible={true} grid={{line: {style: {}}}} label={{style:{fill:'#291351', fontSize: 16, fontFamily: 'Favorit'}}} name="label"/>
                                    <Axis visible={false} grid={{line: null}} name="indivData"/>
                                    <Tooltip showTitle={false} />
                                    <Interval
                                        position="label*indivData"
                                        adjust="stack"
                                        color="label"
                                        element-highlight
                                        style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                        }}
                                        label={['', {
                                        offset: -15,
                                        }]}
                                        color={['label', ['#ff5092', '#ff9788', '#ffcb66', '#4affdf', '#55ccff']]}
                                    />
                                    <Legend />
                                    <Legend name='label' visible={false} />
                                    <View>
                                        <Geom color='name' />
                                    </View>
                                </Chart>
                            </div>
                        </div>
        
                        
        
                        <div style={{ margin:'20px 0'}}>
                            <div style={{ display:"flex", justifyContent:'space-between', flexWrap: 'wrap', width: '100%'}}>
            
                                <div className="freightText" style={{ color: '#291351',  width:"48%"}}>
                                    <h6 className="sub-title sub-sub-title">Total score<span style={{color:'#db0052', margin:'0 35px'}}>{card[0][13]}%</span></h6>
                                </div>
            
                                <div className="freightText" style={{ color: '#291351',  width:"48%"}}>
                                    <h6 className="sub-title sub-sub-title">Rank <span style={{color:'#db0052', margin:'0 35px'}}>{card[0][14]}</span></h6>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div style={{ color:'black'}}>

                        { comment ? (

                            <form>
                                <div style={{padding:'0px 20px', marginTop:'20px'}}>
                                    <h6 className="sub-title sub-sub-title">Comment</h6>
                                    <p style={{color:'#291351'}}>{card[0][11]}</p>
                                </div>
                            </form>

                            ) : (

                            <form style={{display: 'none'}}>
                                <div style={{padding:'0px 20px'}}>
                                    <h6 className="sub-title sub-sub-title">Comment</h6>
                                    <p style={{color:'#291351'}}>{card[0][11]}</p>
                                </div>
                            </form>

                            )}

                    </div>
    
                    
                </div>
                
            </div>
        )
    }
    
}

export default React.memo(SummaryReportCards);

