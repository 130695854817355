import React, {useState, useEffect, useContext} from 'react';
import Layout from '../components/layout/Layout';
import '../pages/main.css';
import { Link } from 'react-router-dom';
import {typeofChargerStateContext} from '../Store';
import {priorityStateContext, countContext, data2ReturnContext, LAMarkerCountContext, LAStateContext,expandedContext, updateMeContext} from '../Store';
import MapMenuArea from '../components/MapMenuArea';
import LACounters from '../components/LACounters';
import Cards2 from '../components/mapdetails/Cards2';
import {Card} from 'react-bootstrap';
import {BrowserRouter, useHistory} from 'react-router-dom';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
  } from "@react-google-maps/api";

  const center = {
    lat: 37.5247596,
    lng: -122.2583719
  };
  const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";




function Report1() {
    //const [chargerType, setchargerType] = useContext(typeofChargerStateContext);
    const [activeElements, setActiveElements] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
    const [count, setCount] = useContext(countContext);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [LAMC, setLAMC] = useContext(LAMarkerCountContext);
    const [LAs, setLAs] = useContext(LAStateContext);
    const [expanded, setExpanded] = useContext(expandedContext);
    const [updateMe, setUpdateMe] = useContext(updateMeContext);
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [subData2Return, setSubData2Return] = useState(null);

    const [namesSet, setNamesSet] = useState([]);
    const [names, setNames] = useState([]);
    const [tBools, setTBools] = useState([true,false,false,false]);
    const [tabDisabled, setTabDisabled] = useState(true);
    const [tabHighlighted, setTabHighlight] = useState(false);
    const [isLoading, setLoading] = useState(true);


    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });
    const history = useHistory();

    const handleHistory = () => {
        //////////////console.log("next");
        //////////////console.log(subData2Return);
        //history.push("/report2");
        history.push({ 
            pathname: '/SummaryReport',
            state: { 
                sD2R: subData2Return,
                original: true
            }
           });
    }
    
    var sliderObjs = [
        {name: "Strategic importance",def:3},
        {name: "Accessibility",def:3},
        {name: "Local amenities",def:3}
    ];

    const [mp, setMap] = useState(null);

    var c  = 0;
    useEffect(() => {
        //setup
        //////////////console.log(data2Return);
        //setSubData2Return(data2Return);
        setLoading(false);
        setExpanded([]);

       
        var sVT = [];
        var sL = [];
        sliderObjs.forEach(obj => {
            sVT.push(obj.def);
            sL.push(obj.name);
        })
        //setSliderValues(sVT);

        var tempSubData2Return = data2Return;
        //////////////console.log(tempSubData2Return);
        tempSubData2Return.forEach(la => {
            la[1].forEach(pin => {
                pin[0].push(sVT);
                pin[0].push(sL);
                pin[0].push("");
            })
        })
        //console.log(tempSubData2Return);
        setSubData2Return(tempSubData2Return);
    
        //window.scrollTo(0,226);
    }, []);

    useEffect(() => {
        setActiveElements([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    }, [data2Return]);
    
    //////////////console.log("data2Return");
    //////////////console.log(data2Return);  

    //////////////console.log("subData2Return");
    //////////////console.log(subData2Return); 

    ////////////////console.log("prioritiesLocation");
    ////////////////console.log(priorities);  
    
    var keys = Object.keys(data2Return);
    //////////////console.log("keys");
    //////////////console.log(keys);

     
    ////////////////////////
    //array of all elements toggels


    function toggleCardActive(index){
        ////////////////console.log(index);
        ////////////////console.log(activeElements);
        var tempActiveElements = activeElements;
        if(tempActiveElements[index] == true){
            tempActiveElements[index] = false;
        } else {
            tempActiveElements[index] = true;
        }
        ////////////////console.log(tempActiveElements);
        setActiveElements(tempActiveElements);
        setUpdateMe(updateMe+1);
    }

    function handleTabButton(index){
        
        
        var doSomething = true;
        var tempTBools = tBools;
        
        var count = 0;
        tempTBools.forEach(TBool => {
            if (TBool == true && count == index){
                doSomething = false;
            }
            count = count + 1;
        })

        if (doSomething){
            tempTBools = [false, false, false , false];
            tempTBools[index] = true;
            setTBools(tempTBools);
        } else {
            tempTBools = [true, false, false , false];
            setTBools(tempTBools);
        }
    }

    

    return(
        <Layout >
            <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}}>
                <h1 style={{fontSize:"30px" }} className="main-title">Evaluate Sites</h1>
            </div>
          
            
            <div style={{margin: "44px 19%", marginBottom: "35px",height: "10px", width: "62%", position: "relative"}}>

                <div style={{position:"absolute", color:"#b2b2b2", top:"-23px", left:"60.75%"}} className="favoritMono">Step 4/5</div>
                <div style={{position:"absolute", color:"#261b3f", top:"-23px", right:"20.25%", float:"right"}} className="favoritMono">80%</div>
                <div class="barz" style={{position: "absolute", height: "10px", width: "100%",}}></div>

                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"19%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"39.25%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"59.5%", top:"-4px"}}></div>
                <div style={{background:"#ffffff", position:"absolute", height:"20px", width:"1.25%", zIndex:"99", left:"79.75%", top:"-4px"}}></div>

                <div style={{position:"absolute", height:"10px", width:"80%", zIndex:"98"}} className="barzTop"></div>
            </div>
            
            <section className={'main'}>
                <section style={{margin:'0px 0px 40px 0px'}}>
                    {keys.map(aLA =>{
                        c = c+1;
                        return (
                        <div key={c+250}>

                            <div> 
                                <div style={{padding: '20px 0px 0px 0px'}} key={901}>
                                    <h6 className="sub-title" style={{ marginBottom: '0px', fontSize: "32px" }}>{validatedLAs[aLA]}</h6>
                            </div>
                            <div className='pinsContainer' style={{paddingTop: '20px'}}>
                                {data2Return[aLA][1].map((card, index) => {
                                    return(
                                        <Cards2 key={index} card={card} index={index} toggleCardActive={toggleCardActive} activeElements={activeElements} c={c} subData2Return={subData2Return} setSubData2Return={setSubData2Return} laIndex={aLA} sliderObjs={sliderObjs} isLoading={isLoading}/>
                                    )
                                })}
                            </div>
                            </div>
                        </div>
                        );
                    })}
                </section>
                <div style={{ width:'48%', paddingBottom: "20px" }}>
                    <div style={{width:'48%'}}>
                        <button type={"button"} className={"nextButton"} style={{ width:"100%", textAlign:"center"}}   onClick={(e) => {handleHistory();}}> 
                            <span className ="favorit">Next</span> 
                        </button>
                    </div>
                </div>
            </section>
        </Layout>    
    );
}

export default Report1;