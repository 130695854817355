import React , {useContext, useState, useEffect, useRef} from 'react'

import Layout from '../layout/Layout';

import {  isSubStateC } from '../../Store';


function SuperUser (){
  const [fNameInput, setFNameInput] = useState("");
  const [URL, setURL] = useState(null);
  const [files, setFiles] = useState("");
  const [showFileContent, setShowFileContent] = useState(false);
  const [textShown, setTextShown] = useState("Click to show file content:");
  const [items, setItems] = useState();
  const [laInputC, setLaInputC] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [addAnotherDSet, setAddAnotherDSet] = useState(true);
  const [dSet2Search, setDSet2Search] = useState("noSelect");
  const [dSetFound, setDSetFound] = useState(false);
  const [categoryDrop, setCategoryDrop] = useState("noSelect");
  const [dataKey, setDataKey] = useState("");
  const [geoScope, setGeoScope] = useState("noSelect");
  const [dataMenuName, setDataMenuName] = useState("");
  const [dataSuffix, setDataSuffix] = useState("");
  const [pointOrArea, setPointOrArea] = useState("noSelect");
  const [project2Add, setProject2Add] = useState(null);
  const [atLeastOneDSetAdded, setAtLeastOneDSetAdded] = useState(false);
  const [togOn, setTogOn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [foundKeys, setFoundKeys] = useState([]);
  const [list, setList] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);


  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };


  const getAuth = async (event) => {
    console.log("trig");
    event.preventDefault();
    var { uname, pass } = document.forms[0];
    let urrl = "https://cleanstreetserver.herokuapp.com/auth/"+uname.value + "/" + pass.value;
    console.log(urrl);

    let list = await fetch(urrl, {cache: "no-store"});
    list = await list.json();   
    list = list.valid;
    if (list == "true"){
      setIsSubmitted(true);
    } else {
      alert("Invalid auth");
    }
    
  }
  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );

  const resetLedger = async () => {

    let url = await fetch("https://cleanstreetserver.herokuapp.com/s3DataUploader/myLedger.json", {cache: "no-store"});
    url = await url.json();
    url = url.uploadURL;
    let reset = [];
    let tempJson = JSON.stringify(reset);
    return fetch(url, {
      method: "PUT",
      body: tempJson,
      headers: {
        "Content-Type": 'application/json'
      },
    });
  };


  useEffect(() => {
    getList();
    setIsLoading(false);
  }, []);

  const getList = async () => {
    let list = await fetch("https://cleanstreetserver.herokuapp.com/listBuckett/", {cache: "no-store"});
    list = await list.json();
    list = list.contents;
    setList(list);
  }

  

  function modalOpen2(){
    modalRef.current.classList.add("modalVisible");
    setModalOpen(true);
  }

  function modalClose(){
    modalRef.current.classList.remove("modalVisible");
    setModalOpen(false);
  }

  function modalOpen3(){
    modalRef2.current.classList.add("modalVisible");
    setModalOpen4(true);
  }

  function modalClose2(){
    modalRef2.current.classList.remove("modalVisible");
    setModalOpen4(false);
  }


  if (isLoading) {
    return ( 
      <section>
        <p>Loading...</p>
      </section>
    );
  }
  return(

    <Layout>
      {isSubmitted == false ? 
        <div style={{position:"absolute", height: "100vh", width: "100vw", background:"#fff", zIndex: "998"}}>
            <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center", zIndex: "999"}}>
              <h1 style={{fontSize:"30px" }} className="main-title">Admin login</h1>
            </div>
            <div style={{paddingLeft:"19%", paddingRight:"19%", width:"100%", paddingTop:"46px", fontSize:"18px", zIndex: "999"}}>
              <div className="form" style={{display:"flex", justifyContent: "center"}}>
                <form style={{backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351"}} onSubmit={getAuth} >
                  <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                    <label>Username: </label>
                    <input style={{float:"right"}} type="text" name="uname" required />
                    {renderErrorMessage("uname")}
                  </div>
                  <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                    <label>Password: </label>
                    <input style={{float:"right"}} type="password" name="pass" required />
                    {renderErrorMessage("pass")}
                  </div>
                  <div style={{margin:"20px 0px 20px 0px"}} className="button-container">
                    <input type="submit" />
                  </div>
                </form>
              </div>
            </div>
        </div>
      : " "}
      {isSubmitted == true ?
      <>
        <button onClick={resetLedger}>
          RESET LEDGER
        </button>
      </>
      : " "}
    </Layout>
  )
}


export default SuperUser