import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import MyPie from '../components/charts/MyPie.js';
import header from '../homepage/header.png';
import t1 from '../homepage/t1.png';
import t1Inactive from '../homepage/t1Inactive.png';
import t2 from '../homepage/t2.png';
import t3 from '../homepage/t3.png';
import t4 from '../homepage/t4.png';
import t5 from '../homepage/t5.png';
import tIMP from '../homepage/ParkingMap.png';
import t6 from '../homepage/ChargePointSiteRater.png';
import numbers from '../homepage/10101.png';
import cpr from '../homepage/cpr.png';
import t_FuturePlaces from '../homepage/Place-Kit-Thumbnail-FutureTrends.png';


import logo from '../logo.png';
import logo_blue from '../logo_blue.png';

function Main() {

    return (
        <Layout>

            <header className='mobHeader' style={{ backgroundImage: `url(${header})` }}>
                <div style={{ position: 'relative' }}>
                    <div className='headerHeadingDiv'>
                        <h1 className='headerHeading'>
                            Tools for better places
                        </h1>

                        <p style={{ fontSize: '24px', width: '80%' }}>
                            For best results, these tools need to be accessed on a desktop monitor.
                        </p>
                        <br />
                        <a href='https://urbanforesight.org/'> <button className='newButton'>By Urban Foresight</button></a>


                    </div>
                </div>
            </header>

            <header className='desktopHeader' style={{ backgroundImage: `url(${header})` }}>
                <div style={{ position: 'relative' }}>
                    {/*<img src={header} alt="Logo" className='headerImg'/>*/}
                    <div className='headerHeadingDiv'>
                        <h1 className='headerHeading'>
                            Tools for better places
                        </h1>
                        <br />
                        <a href='https://urbanforesight.org/'> <button className='newButton'>By Urban Foresight</button></a>


                    </div>
                    {/* <img src={header} style={{height:'auto', width:'100%'}} /> */}
                </div>
            </header>

            <div className={'main elementBelowLoading'} id='tools'>
                <h6 class="sub-title" style={{ color: "#b2b2b2", margin: '0', marginBottom: "20px", fontFamily: 'FavoritMonoStd_Light' }}>TOOLS</h6>
                <div className='toolContainer'>
					<div className='toolTile1'>
                        <a href="https://www.placekits.com/futureplaces" style={{ cursor: 'context-menu' }}><img src={t_FuturePlaces} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="https://www.placekits.com/futureplaces" className="link" style={{ textDecoration: 'none', cursor: 'context-menu' }}>
                                <h2 style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Future Places <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>

                            <p style={{ marginTop: '20px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>A horizon scan of future trends for UK towns and cities</p>
                        </div>
					</div>
                    

                    <div className='toolTile2'>
                        <a href="https://www.welshtowns.placekits.com/"><img src={t2} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="http://www.welshtowns.placekits.com/" className="link" style={{ textDecoration: 'none' }}>
                                <h2 style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Data for Welsh Towns <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>

                            <p style={{ marginTop: '16px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>A global summary of data about towns in Wales and where to find it</p>
                        </div>
                    </div>
					
					<div className='toolTile3'>
                        <a href="http://www.netzeroframework.co.uk/"><img src={t3} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="http://www.netzeroframework.co.uk/" className="link" style={{ textDecoration: 'none' }}>
                                <h2 style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Net Zero Framework <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>

                            <p style={{ marginTop: '16px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>Actions that can be taken by local authorities to get to net zero</p>
                        </div>
                    </div>
					<div className='toolTile1'>
                        <a href="/SiteSelectorHome"><img src={t1} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="/SiteSelectorHome" className="link" style={{ textDecoration: 'none' }}>
                                <h2 href="/SiteSelectorHome" style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Charge Point Site Selector <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>

                            {/* <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Coming soon!</p> */}
                            <p style={{ marginTop: '16px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>Identify and evaluate locations for electric vehicle charge points</p>
                        </div>
                    </div>
                    <div className='toolTile2'>
                        <a href="/ProjectMap"><img src={cpr} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="/ProjectMap" className="link" style={{ textDecoration: 'none' }}>
                                <h2 style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Charge Point Site Rater <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>
                            <p style={{ marginTop: '16px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>Review potential locations for electric vehicle charge points</p>
                        </div>
                    </div>
                    <div className='toolTile3'>
                        <a href="https://app.powerbi.com/view?r=eyJrIjoiYjg1Y2E5MTMtNzExMi00MTNkLThhMjAtYTBhNmJjMzZiZmE1IiwidCI6ImIyOWZiNGMwLTBkMDQtNGE5Zi1iNDYyLWMxMjk0YjFlMWI0NyIsImMiOjh9"><img src={tIMP} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a href="https://app.powerbi.com/view?r=eyJrIjoiYjg1Y2E5MTMtNzExMi00MTNkLThhMjAtYTBhNmJjMzZiZmE1IiwidCI6ImIyOWZiNGMwLTBkMDQtNGE5Zi1iNDYyLWMxMjk0YjFlMWI0NyIsImMiOjh9" className="link" style={{ textDecoration: 'none' }}>
                                <h2 style={{ fontSize: '32px', color: '#cc4884', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Interactive Parking Map <i class="fa fa-angle-right"></i>
                                </h2>
                            </a>

                            <p style={{ marginTop: '16px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>An interactive map of parking facilities in Newcastle upon Tyne</p>
                        </div>
                    </div>


                    {/* <div className='toolTile3'>
                        <a href="/DataRepositoryPublic"><img src={t3} style={{height:'auto', width:'100%'}} /></a>
                        <div>
                            <a href="/DataRepositoryPublic" className = "link" style={{textDecoration:'none'}}>
                                <h2 style={{fontSize:'32px', color:'#cc4884', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Data Repository <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Explore our datasets.</p>
                        </div>
                    </div> */}

                   

                    {/* <div className='toolTile5'>
                        <a href="#" style={{cursor:'context-menu'}}><img src={t4} style={{height:'auto', width:'100%'}} /></a>
                        <div>
                            <a href="#" className = "link" style={{textDecoration:'none', cursor:'context-menu'}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                EV Maturity Model
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Coming soon!</p>
                        </div>
                    </div> */}
                   			
					{/*<div className='toolTile1'>
                        <a style={{ cursor: 'context-menu' }}><img src={t5} style={{ height: 'auto', width: '100%' }} /></a>
                        <div>
                            <a className="link" style={{ textDecoration: 'none', cursor: 'context-menu' }}>
                                <h2 style={{ fontSize: '32px', color: '#9c98a7', margin: '0', height: '80px', marginTop: '32px', cursor: 'pointer', fontFamily: 'FavoritStd_Regular' }}>
                                    Green Community Buildings 
                                </h2>
                            </a>

                            <p style={{ marginTop: '20px', fontSize: '18px', color: '#261b3f', fontFamily: 'Freight_Text_Book' }}>Coming soon!</p>
                        </div>	
                    </div>*/}
                </div>
            </div>

            <div style={{ backgroundColor: '#f4f4f4', position: 'relative', display:"flex" }}>
                <div className='div2nd' style={{ display:"flex", height: "auto", width: "100%" }}>

                    <div style={{ width: "100%", height: "100%", position: "relative"}}>
                        <h1 class="main-title">Place data</h1>
                        <h6 class="sub-title" style={{ margin: '0', fontSize: '32px', fontFamily: 'FavoritStd_Regular' }}>Explore the data that powers our tools.</h6>
                        <a href="/DataRepositoryPublic"><button style={{ marginTop: '57px', color: "#291351" }} className='newButton'>Explore data</button></a>
                    </div>
                
                </div>
                <div style={{ height: "100%", position: "absolute", display:"flex", right: "0px"}}>
                    <div style={{display:"flex", position: "relative",  height: "100%", overflow:"hidden"}}>
                        <img src={numbers} style={{ position: "relative", height: '100%', width: 'auto', marginLeft:"auto", marginRight: "0px", marginTop:"90px"}} />
                    </div>
                        
                </div>
            </div>

            <footer>

                <div className='divContainer'>




                    <div style={{ width: '250px', marginRight: '25px' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <div style={{ position: 'absolute', top: '0' }}>
                                <a href='#'>
                                    <img src={logo} alt="Logo" style={{ height: '42px', width: '172px' }} />
                                </a>
                            </div>
                            <div style={{ position: 'absolute', bottom: '0' }}>
                                <p style={{ color: '#b2b2b2' }}>© Urban Foresight Limited, 2022</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>The Catalyst <br />
                            3 Science Square <br />
                            Newcastle upon Tyne <br />
                            NE4 5TG <br />
                            United Kingdom</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)191 814 2210</p>
                    </div>







                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>Flour Mill<br />
                            34 Commercial Street <br />
                            Dundee<br />
                            DD1 3EJ <br />
                            Scotland</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)1382 549 947</p>
                    </div>

                    <div className='navigationFooter'>
                        <p style={{ height: '25%', fontSize: '12px', color: '#cc4884' }}>
                            <a href="https://www.linkedin.com/company/urban-foresight" target="_blank" style={{ color: '#cc4884' }}> <i className="fa fa-linkedin"></i>  </a>
                            <a href="https://twitter.com/urbanforesight" target="_blank"> <i style={{ marginLeft: '12px', color: '#cc4884' }} className="fa fa-twitter"></i>  </a>
                            <a href="mailto:hello@urbanforesight.org" target="_blank"> <i style={{ fontSize: '12px', marginLeft: '12px', color: '#cc4884' }} className="material-icons">mail</i> </a>
                        </p>
                        <p style={{ height: '25%' }}><a href="/#tools" target="_blank" style={{ color: 'white' }}> Tools</a> </p>
                        <p style={{ height: '25%' }}> <a href="https://urbanforesight.org/about/" target="_blank" style={{ color: 'white' }}> About</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="https://urbanforesight.org/" target="_blank" style={{ color: 'white' }}> Urban Foresight</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="/AdminHome" target="_blank" style={{ color: 'white' }}> Admin</a></p>
                    </div>

                </div>

            </footer>

            <div className='footer2'>
                <div>
                    <a href='#'>
                        <img src={logo_blue} alt="Logo" className='footer2Logo' />
                    </a>
                </div>

                <div>
                    <p style={{ color: '#291351' }}>© Urban Foresight Limited, 2022</p>
                </div>

            </div>






        </Layout>
    );
}

export default Main;