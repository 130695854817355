
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import React, {useState, useContext, useEffect, useRef} from 'react';
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import ReactDOMServer from "react-dom/server";
import L, { featureGroup } from 'leaflet';
import axios from 'axios';
//import turf , { distance, point, featureCollection, nearestPoint } from '@turf/turf'
import * as turf from '@turf/turf'

import {aqmzPC, mapVarsC, allocatedPointsStateContext, selectedMarkerStateContext, screenObjC, screenObjC2, screenObjC3, allLaMapC, countContext, data2ReturnContext, selectedPositionContext, cSBSContext, LAMarkerCountContext, LAStateContext, markerArrContext, updateMeContext, lIconContext, preventMarkeContext} from '../../Store'
import { object } from 'prop-types';

import {features} from "./GBLayer.json";

import MarkerPin from './MarkerPin';

import {
GoogleMap,
LoadScript,
LoadScriptNext,
useGoogleMap,
StreetViewPanorama
} from "@react-google-maps/api";


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

function NewMapDraw({data, tabDisabled, setTabDisabled, tabHighlighted, setTabHighlight, latty, setLatty, longy, setLongy, mp4, mp3, mp2, mp, cBool, setCBool, load, setLoad, evs}) {
    const [sP, setSP] = useContext(selectedPositionContext);   
    const [selectedMarker, setselectedMarker] = useContext(selectedMarkerStateContext);
    const [count, setCount] = useContext(countContext);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [cSBS, setCSBS] = useContext(cSBSContext);
    const [LAMC, setLAMC] = useContext(LAMarkerCountContext);
    const [LAs, setLAs] = useContext(LAStateContext);
    const [markerArr, setMarkerArr] = useContext(markerArrContext);
    const [updateMe, setUpdateMe] = useContext(updateMeContext);
    const [screenObj, setScreenObj] = useContext(screenObjC);
    const [screenObj2, setScreenObj2] = useContext(screenObjC2);
    const [screenObj3, setScreenObj3] = useContext(screenObjC3);
    const [allLaMap, setAllLaMap] = useContext(allLaMapC);
    const [lIcons, setLIcons] = useContext(lIconContext);
    const [pms, setPMS] = useContext(preventMarkeContext);
    const [mapVars, setMapVars] = useContext(mapVarsC);
    const [aqmzP, setAQMZP] = useContext(aqmzPC); 


    const[popupContent, setPopupContent] = useState([]);
    const[textInputPlaceholder, setTextInputPlaceholder] = useState("Enter a name:");
    const[lookThroughLaFeatures, setLookThroughLaFeatures] = useState([]);
    const[totalArr, setTotalArr] = useState([]);
    const [names, setNames] = useState([]);
    const [markerStreets, setMarkerStreets] = useState([]);
    //const [updateMe, setUpdateMe] = useState(0);
    const [tc2, setTC2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [namesSet, setNamesSet] = useState([]);
    const [firstTime, setFistTime] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [temps, setTemps] = useState([]);
    const [turfEvs, setTurfEvs] = useState([]);
    const [aqmD, setAQMD] = useState([]);
    // const [refReady, setRefReady] = useState(false);

    useEffect(() => {
        mp2.setView([54.978252,-1.617780], 16);
        mp3.setView([54.978252,-1.617780], 16);
        mp4.setView([54.978252,-1.617780], 8);
    }, []);

    // let popupRef = useRef();
    


    console.log(LAMC);
    console.log(data2Return);



    //const [theArray, setTheArray] = useState([]);    
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: 'https://icon-library.com/images/charging-station-icon/charging-station-icon-26.jpg',
        iconUrl: 'https://icon-library.com/images/charging-station-icon/charging-station-icon-26.jpg',
        shadowUrl: '',
    });

    L.Icon.Default.imagePath='/';
    const iconEV = new L.Icon({
        iconUrl: "Pin3.png",
        iconRetinaUrl: "Pin3.png",
        shadowUrl: '',

        iconSize: new L.Point(40, 40),
    });

    const loadingIcon = new L.Icon({
        iconUrl: "Pin3.png",
        iconRetinaUrl: "Pin3.png",
        shadowUrl: '',

        iconSize: new L.Point(40, 40),
    });

    const maxPins = 10;
    var data = data;
    var count2 = count;

    var allLACardsInit = {};
    var lookThroughArr = [];

    //var keys = Object.keys(data2Return);
    //////console.log(data);
    data.forEach(datasetWithAttr =>{
        if (datasetWithAttr[2].type == "geo"){
            if (datasetWithAttr[2].typeSwitch != "genral"){
                lookThroughArr.push(datasetWithAttr)
            }
        }        
    })

    useEffect(() => {
        var tempLTF = [];
        LAs.forEach(la => {
            features.forEach(feature => {
                if(feature.properties.LAD13NM == la){
                    tempLTF.push([la,feature]);
                }
            })
        })
        setLookThroughLaFeatures(tempLTF);
        var tempTurfEvs=[];
        var tempTurfEvsArr = [];
        if (evs != []){
            //////console.log(evs);
            var countt = 0;
            evs.forEach(ev => {
                tempTurfEvs.push([ev[0],[]]);
                var tempPoints = []
                ev[1].forEach(point => {
                    tempPoints.push(turf.point([point["Longitude"], point["Latitude"]]))
                })
                var tempFC = turf.featureCollection(tempPoints);
                tempTurfEvs[countt][1] = tempFC;
                countt = countt +1; 
            })
            
            setTurfEvs(tempTurfEvs);
            //////console.log("EVS!");
            //////console.log(tempTurfEvs);
        }
        lookThroughArr.forEach(d => {
            if (d[1] == "AirQualManagementAreas"){
                //console.log("d[1] == airqualmanagmentareas")
                var tempp = [] 
                let cccc = 0 ;
                d[0].forEach(la => {

                    la.feature.forEach(poly => {
                        tempp.push(poly.geometry);
                    })
                })
                ////console.log(tempp);
                setAQMD(tempp);
            }
        })


    }, []);

    var bool4 = false;

   

    useEffect(() => {
        
    }, [temps]);
    
    
    ////////////////console.log(data2Return);

    const map = useMapEvents({
        dblclick(e) {   
            ////console.log(e.latlng.lat);
            var zoom = mp.getZoom();
            zoom = 19-zoom;
            var scaled = 0;
            if (zoom == 1){
                scaled = 0.00050124874403; 
            }
            else if (zoom == 2){
                scaled = 0.00100270846188;
            }
            else if (zoom == 3){
                scaled = 0.00200490399859;
            }
            else if (zoom == 4){
                scaled = 0.004002386949184; 
            }
            else if (zoom == 5){
                scaled = 0.00800598955041; 
            }
            else if (zoom == 6){
                scaled = 0.01593433454944; 
            }
            else if (zoom == 7){
                scaled = 0.0260671567729; 
            }
            else if (zoom == 8){
                scaled = 0.064081671610026; 
            }
            else if (zoom == 9){
                scaled = 0.12788021315371; 
            }
            ////console.log(zoom);
            mp.flyTo([(e.latlng.lat + scaled), (e.latlng.lng)]);
              
            //condition bool4
            if (false == false){
                if(cBool == false){
                    if (true == true){
                       console.log("click");
                        
                        
                        //check what la is selected
                        var laName = "";
                        var turfPoint = turf.point([e.latlng.lng, e.latlng.lat]);
                        var laFeature;
                        var turfLaFeature;
                        //tempIcon
                    
                        setLoad(true);
                        //load = true;
                        bool4 = true;
                        
                        
                        var bool2 = false;

                        lookThroughLaFeatures.forEach(laFeatureArr=> {
                            if (laFeatureArr[1].geometry.type == "Polygon"){
                                turfLaFeature = turf.polygon(laFeatureArr[1].geometry.coordinates);
                            }
                            else if (laFeatureArr[1].geometry.type == "MultiPolygon"){
                                turfLaFeature = turf.multiPolygon(laFeatureArr[1].geometry.coordinates);
                            }
                            if (turf.booleanPointInPolygon(turfPoint, turfLaFeature)){ 
                               ////////////////console.log(laFeatureArr[0]);
                                laName = laFeatureArr[0];
                                laFeature = laFeatureArr[1];
                                bool2 = true;
                                let mark = L.marker([e.latlng.lat, e.latlng.lng], {icon: loadingIcon});
                                
                                //mark.addTo(mp).bindPopup("Loading...");
                                let popup = L.popup("Loading...");
                                mark.bindPopup("Loading...");
                                //mark.addTo(mp).bindPopup
                                mark.addTo(mp);
                                mark.openPopup();
                                var tempIcons = temps;
                                tempIcons.push(mark);
                                setTemps(tempIcons);

                                
                            } else {
                                bool4 = false;
                            }
                        })
                        if (bool2 == true){
                            ///////////////////////
                            //get the laMarkerCounter
                            let laMarkerCounterInfo = LAMC;
                           ////////////////console.log(LAMC);
                            LAMC.forEach(laMarkerCountner => {
                                if(laMarkerCountner[0] == laName){
                                    laMarkerCounterInfo = laMarkerCountner;
                                }
                            })
                           ////////////////console.log(laMarkerCounterInfo);
                            
                            //check theres markers left to place
                           ////////////////console.log(laMarkerCounterInfo[1] +" "+ laMarkerCounterInfo[2] );
                            if(laMarkerCounterInfo[1] - laMarkerCounterInfo[2] > 0){
                                
                            //turf 
                                var data2ReturnAboutSingleMarker = [];

                                ////////////////console.log(count2);
                               ////////////////console.log(count3);
                                //count2 = count2 + 1;

                                ////////////////console.log(turfPoint);
                                var long = e.latlng.lng;
                                var lat = e.latlng.lat;
                                let mark2 = L.marker([e.latlng.lat, e.latlng.lng], {icon: loadingIcon});
                                let mark3 = L.marker([e.latlng.lat, e.latlng.lng], {icon: loadingIcon});
                                let mark4 = L.marker([e.latlng.lat, e.latlng.lng], {icon: loadingIcon});
                                mark2.addTo(mp2).bindPopup("Loading...");
                                //mark2.openPopup(mp2);
                                //mark2.;
                                mark3.addTo(mp3).bindPopup("Loading...");
                                mark4.addTo(mp4).bindPopup("Loading...");
                                

                                mp2.setView([lat,long], 16);
                                mp3.setView([lat,long], 16);
                                
                                ////////////console.log("retreive");
                                ////////////console.log(mapVars);
                                mp4.setView([mapVars[0],mapVars[1]], mapVars[2]);
                                

                                //.toFixed(3);
                                let markerStreetsTemp = markerStreets;
                                markerStreetsTemp.push(["",count3]);
                                setMarkerStreets(markerStreetsTemp);
                                fetch(
                                    `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${long}&zoom=18&format=jsonv2`
                                )
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    // let divv = document.createElement("div");
                                    // divv.setAttribute("id", "div4Map");
                                    // let root = document.getElementById("root");
                                    // root.appendChild(divv);
                                    //////////////////console.log(document.getElementById("root"));

                                    // let map2 = L.map('div4Map').setView([lat, long], 2);
                                    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map2);
                                    // let pluginOptions = {
                                    //     hidden: false, // hide screen icon
                                    //     onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
                                    //         return plugin._getPixelDataOfNormalMap(domtoimageOptions)
                                    //     }
                                    //  }
                                    // var screenShot = new L.simpleMapScreenshoter(pluginOptions);
                                    // //setScreenObj(screenShot);
                                    // screenShot.addTo(map2);


                                    let blobOut = null;
                                    let blobOut2 = null;
                                    let blobOut3 = null;
                                    var screenShot = screenObj;
                                    var screenShot2 = screenObj2;
                                    var screenShot3 = screenObj3;
                                    //'blob' // 'image' - return base64, 'canvas' - return canvas
                                    
                                    
                                        //do what you need here
                                    setTimeout(function(){
                                        //do what you need here
                                    
                                        screenShot.takeScreen('image')
                                        .then(image => {
                                            screenShot2.takeScreen('image')
                                            .then(image2 => {
                                                screenShot3.takeScreen('image')
                                                .then(image3 => {

                                                    blobOut = image;
                                                    blobOut2 = image2;
                                                    blobOut3 = image3;
                                        
                                                    let markerStreetsTemp = markerStreets;
                                                    markerStreetsTemp[count3] = [data.address.road,count3];
                                                    setMarkerStreets(markerStreetsTemp);
                                                    var markerArrTemp = markerArr;
                                                    setUpdateMe(updateMe+1);
                                                    setMarkerArr(markerArrTemp);
                            
                                                    var bool = false;
                                                    lookThroughArr.forEach(dataset => {
                                                        ////////////////console.log("lookThru");
                                                        var turfFeature;
                                
                                                        var type = dataset[2].name;
                                                        var label = dataset[2].overlayLabel;
                                                        var attr = dataset[1];
                                                        
                                                        dataset[0].forEach(singleLA =>{
                                                            var featureOrFeatures = singleLA.feature;
                                                            ////////////////console.log(featureOrFeatures);
                                                            featureOrFeatures.some(feature => {
                                                                if (feature.geometry.type == "Polygon"){
                                                                    turfFeature = turf.polygon(feature.geometry.coordinates);
                                                                }
                                                                else if (feature.geometry.type == "MultiPolygon"){
                                                                    turfFeature = turf.multiPolygon(feature.geometry.coordinates);
                                                                }
                                            
                                                                if (turf.booleanPointInPolygon(turfPoint, turfFeature)){                            
                                                                    var inZone = false;
                                                                    if (aqmzP == true){
                                                                        //console.log("11");
                                                                        aqmD.forEach(poly => {
                                                                            if (poly.type == "Polygon"){
                                                                                turfFeature = turf.polygon(feature.geometry.coordinates);
                                                                            }
                                                                            else if (poly.type == "MultiPolygon"){
                                                                                turfFeature = turf.multiPolygon(feature.geometry.coordinates);
                                                                            }
                                                                            if (turf.booleanPointInPolygon(turfPoint, turfFeature)){   
                                                                                
                                                                                inZone = true;
                                                                            } 

                                                                        })

                                                                        if (inZone == true){
                                                                            //console.log("inZ");
                                                                        } else {
                                                                            //console.log("outZ");
                                                                        }
                                                                        
                                                                    }
                                                                    else {
                                                                        //console.log("11NOT");
                                                                    }

                                                                    //
                                                                    var laTurfs;
                                                                    if (turfEvs != []){
                                                                        turfEvs.forEach(lA => {
                                                                            if (lA[0] == laName){
                                                                                laTurfs = lA[1];
                                                                            }
                                                                        })
                                                                        var options = {units: 'miles'};
                                                                        //console.log(turfPoint);
                                                                        //console.log(laTurfs);

                                                                        if (laTurfs.features.length == 0){
                                                                            if (aqmzP == true){
                                                                                //console.log(3);
                                                                                //console.log(feature);
                                                                                //console.log(attr);
                                                                                data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, null, inZone],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                            } else {
                                                                                //console.log(4);
                                                                                //console.log(feature);
                                                                                //console.log(attr);
                                                                                data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, null, null],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                            }
                                                                        } else {
                                                                            var nearest = turf.nearestPoint(turfPoint, laTurfs);
                                                                            var distance = turf.distance(turfPoint, nearest, options);
                                                                            //////console.log("Distance!");
                                                                            //////console.log(distance);
                                                                            if (aqmzP == true){
                                                                                //console.log(1);
                                                                                //console.log(feature);
                                                                                //console.log(attr);
                                                                                if (attr == "AirQualManagementAreas"){
                                                                                    //data2ReturnAboutSingleMarker.push([[label,9999, attr, distance, inZone],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                                }
                                                                                else {
                                                                                    data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, distance, inZone],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
        
                                                                                }
        
                                                                            } else {
                                                                                //console.log(2);
                                                                                //console.log(feature);
                                                                                //console.log(attr);
                                                                                data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, distance, null],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                            }
                                                                        }
                                                                        

                                                                    } else {
                                                                        if (aqmzP == true){
                                                                            //console.log(3);
                                                                            //console.log(feature);
                                                                            //console.log(attr);
                                                                            data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, null, inZone],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                        } else {
                                                                            //console.log(4);
                                                                            //console.log(feature);
                                                                            //console.log(attr);
                                                                            data2ReturnAboutSingleMarker.push([[label,feature[attr], attr, null, null],count3,[long, lat], data.address.road, laName, data.address.road, data.address, blobOut, blobOut2]);
                                                                        }
                                                                    }
                                                                    
                            
                                                                    ////////////////console.log([[label,feature[attr]],count3,[long, lat], data.address.road]);
                                                                    bool = true;
                                                                }
                                                            })         
                                                        })
                                                    })
                                                    
                                                    if (bool == true){
                                                        ////////////////console.log("Placed Point Data:");
                                                        ////////////////console.log(data2ReturnAboutSingleMarker);

                                                        
                            
                                                        var temp = data2Return;
                                                        ////////////////console.log(temp);
                                                        //allLACards[laName].push(data2Return)
                                                        var c = 0;
                                                        temp.forEach(laDataArr =>{
                                                            if(laDataArr[0] == laName){
                                                                //temp[c][1]
                                                                laDataArr[1].push(data2ReturnAboutSingleMarker)
                            
                                                            }
                                                        })
                                                        //temp[laName].push(data2ReturnAboutSingleMarker);
                                                        ////////////////console.log(temp);
                                
                                                        //cardData.push([laName,data2Return]);
                                                        ///////////////////////
                                                        var tempCount = 0;
                                                        var D2RTemp = temp;
                                                        var newD2R = [];
                                                        
                                                        var tempCount4 = 0;
                                                        D2RTemp.forEach(laData => {
                                                            newD2R.push([]);
                                                            newD2R[tempCount].push(laData[0]);
                                                            newD2R[tempCount].push([]);
                                                            var tempCount2 = 0;
                                                            laData[1].forEach(singlerMarkerData => {
                                                                //console.log("....");
                                                                //console.log(singlerMarkerData);
                                                                
                                                                newD2R[tempCount][1].push([]);
                                            
                                                                singlerMarkerData.forEach(singleLayerData=> {
                                                                    let tempSingleLayerData = singleLayerData;
                                                                    tempSingleLayerData[1] = tempCount4;
                                                                    newD2R[tempCount][1][tempCount2].push(tempSingleLayerData);
                                                                    ////////////////console.log(tempSingleLayerData);
                                                                })
                                            
                                                                tempCount2 = tempCount2 + 1;
                                                                tempCount4 = tempCount4 + 1;                    
                                                                
                                                                    ////////////////console.log(index +" /+"+ tc2);
                                                                    //setTC2(tc2+1);
                                                                    ////////////////console.log("tryed to delete index" +index);
                                                                    
                                                            })
                                                            
                                                            tempCount = tempCount +1;
                                                            
                                                        })
                                                        ///////////////////////
                                                        setData2Return(newD2R);
                            
                                                        var markerArrTemp = markerArr;
                                                        markerArrTemp.push([
                                                            e.latlng.lat,
                                                            e.latlng.lng,
                                                            laName,
                                                            data2ReturnAboutSingleMarker,
                                                            blobOut,
                                                            blobOut2
                                                        ]);
                                                        ////console.log(markerArrTemp);
                                                        setAllLaMap(blobOut3);
                                                        setMarkerArr(markerArrTemp);
                                                        setCount(count2);
                                                        setCount3(count3+1);
                                                        
                                                        // var tempNamesSet = namesSet;
                                                        // tempNamesSet.push(false);
                                                        // setNamesSet(tempNamesSet);
                            
                                                        //var keys = Object.keys(names);
                                                        //var lastIndex = keys.at(-1)
                                                        var namesTemp = names;
                                                        namesTemp.push(data2ReturnAboutSingleMarker[3]);
                                                        setNames(namesTemp);
                            
                                                        //setNames(namesTemp);
                            
                                                        let LAMCTemp = [];
                                                        laMarkerCounterInfo[2] = laMarkerCounterInfo[2]+1;
                                                        LAMC.forEach(laMarkerCountner => {
                                                            if(laMarkerCountner[0] == laName){
                                                                LAMCTemp.push(laMarkerCounterInfo);
                                                            } else {
                                                                LAMCTemp.push(laMarkerCountner);
                                                            }
                                                        })
                                                        setLAMC(LAMCTemp);
                                                        setUpdateMe(updateMe+1);
                                                        setLoad(false);
                                                    }
                                                    else{
                                                        let markerStreetsTemp = markerStreets;
                                                        let len = markerStreetsTemp.length;
                                                        markerStreetsTemp.splice(len, 1);
                                                        setMarkerStreets(markerStreetsTemp);
                                                        count2 = count2 - 1;
                                                    ////////////////console.log("err");
                                                        // map.removeLayer(e.propagatedFrom);
                                                    }
                                                    if (firstTime == true){
                                                        setTabDisabled(false);
                                                        setTabHighlight(true);
                                                        setFistTime(false);
                                                    }


                                                }).catch(e => {
                                                    console.error(e)
                                                })
                                            //
                                            }).catch(e => {
                                                console.error(e)
                                            })
                                        }).catch(e => {
                                            console.error(e)
                                        })
                                    }, 1000);
                                    
                                    /////////////////////////////////////////////////////////////////////////////
                                    
                                    //mp.removeLayer(mark);
                                });
                            
                            } else {
                                //alert ("Charger placed out of bounds");
                                //no pins left
                                alert ("No pins left");
                            }
                            
                            
                            
                        }
                        
                    } else {

                    }  
                }
            }
                      
        },
        
                
    })
    
    var indexedMarkers = markerArr.map((item, id) => Object.assign(item, {id}));    

    function deleteMarker(index){
        


        var markerArrTemp = markerArr;
        var newMarkerArr =[];
        markerArrTemp.forEach(singleMarker => {
           ////////////////console.log(singleMarker);
            if(singleMarker[3][0][1] != index){
                //markerArrTemp.splice(counter, 1);
                newMarkerArr.push(singleMarker);
            }
        })
       ////////////////console.log(newMarkerArr);
        //markerArrTemp.splice(index, 1);
        setMarkerArr([]);
        setMarkerArr(newMarkerArr);


        var tempNamesSet = namesSet;
        tempNamesSet.splice(index, 1);
        setNamesSet(tempNamesSet);

        //
       ////////////////console.log("names");
       ////////////////console.log(names);
        var tempNames = names;
        var newNames = [];
        var counter = 0;
        tempNames.forEach(singleMarker => {
            if (counter != index){
                newNames[counter] = names[counter] ;
                counter = counter + 1;
            }
        })
       ////////////////console.log(newNames);
        setNames(newNames);
        //


       ////////////////console.log("index2Remove " + index);
        var tempCount = 0;
        var D2RTemp = data2Return;
        var newD2R = [];
        count2 = count2 - 1;
        var laName ="";
        var streetName="";
        var tempCount4 = 0;
        D2RTemp.forEach(laData => {
            newD2R.push([]);
            newD2R[tempCount].push(laData[0]);
            newD2R[tempCount].push([]);
            var tempCount2 = 0;
            laData[1].forEach(singlerMarkerData => {
                ////////////////console.log("....");

                if((singlerMarkerData[0][1]) != index){
                    newD2R[tempCount][1].push([]);

                    singlerMarkerData.forEach(singleLayerData=> {
                        let tempSingleLayerData = singleLayerData;
                        tempSingleLayerData[1] = tempCount4;
                        newD2R[tempCount][1][tempCount2].push(tempSingleLayerData);
                        ////////////////console.log(tempSingleLayerData);
                    })

                    tempCount2 = tempCount2 + 1;
                    tempCount4 = tempCount4 + 1;                    
                } else{
                    ////////////////console.log(index +" /+"+ tc2);
                    //setTC2(tc2+1);
                   ////////////////console.log("tryed to delete index" +index);
                    laName = singlerMarkerData[0][4];
                    streetName = singlerMarkerData[0][3];
                }
            })
            tempCount = tempCount +1;
            
        })
       ////////////////console.log(newD2R);
        setData2Return(newD2R);
        setCount3(count3-1);

        
        let LAMCTemp = [];
       ////////////////console.log(LAMC);
        LAMC.forEach(laMarkerCountner => {
            if(laMarkerCountner[0] == laName){
                let tempLaMarkerCounter = laMarkerCountner;
                tempLaMarkerCounter[2] = tempLaMarkerCounter[2] - 1;
                LAMCTemp.push(tempLaMarkerCounter);
            } else {
                LAMCTemp.push(laMarkerCountner);
            }
        })
        setLAMC(LAMCTemp);


        let markerStreetsTemp = markerStreets;
        var newMarkerStreet = [];
        var nCounter = 0;
        markerStreetsTemp.forEach(singleMarker =>{
            if(singleMarker[1] != index ){
                newMarkerStreet.push([singleMarker[0],nCounter]);
                nCounter = nCounter + 1;
            }
            
        })
        setMarkerStreets(newMarkerStreet);

        setUpdateMe(updateMe+1);

        if (tabDisabled == false){
            if (markerArr.length == 0){
                setTabDisabled(true);
            }
            
        }


        

        //itterate through this, find data with the right index, remove all data entry for that index
    }
//dataAboutMarker = marker[3]
//cityName = marker[2]

//bool4 = false; 


    return (
            
            <FeatureGroup>
               
                {/* <p hidden>{updateMe}</p>   updateMe={updateMe}*/}
                    
                {indexedMarkers.map(marker => (
                    <MarkerPin key={marker.id} marker={marker} deleteMarker={deleteMarker} mp={mp} temps={temps} />
                ))} 
            </FeatureGroup>
                 
    )  
}

export default NewMapDraw;



/*


{data2Return.map(indivLA => (
                    indivLA[1].map(renderMarker)
                ))}

                        <b>{popupContent}</b><br/>


                  
                          {totalArr.map( singlerMarker =>{
                    return(
                        <Marker
                        
                    >
                        
                        
                        </Marker>
                    )
                    
                    
                    
                })}
                    
                
                
                <Marker           
                    key={sP[0]}
                    position={sP}
                >
                    <Popup>
                        <label>
                            <input type="text" placeholder={textInputPlaceholder}/>
                        </label>
                        <button onClick={saveName}>Save Name</button>
                        {popupContent.map(x => (
                            <p>{x}</p>
                        ))}

                        
                    </Popup>
                </Marker>
*/