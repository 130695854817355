import React, { useContext, useState, useEffect } from 'react'
import Layout from '../layout/Layout';
import GeneralAutoSearch from '../functional/GenralAutoSearch';

import { isSubStateC } from '../../Store';


function DatasetDelete() {
    const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);


    const [isLoading, setIsLoading] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});


    const [datasetList, setDatasetList] = useState([]);
    const [loadedLedger, setLoadedLedger] = useState([]);


    const [selectedTags, setSelectedTags] = useState([]);
    var tagList = [];


    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    useEffect(() => {
        getDatasets();
        getLedger();
        setIsLoading(false);
    }, []);

    const getAuth = async (event) => {
        console.log("trig");
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        let urrl = "https://cleanstreetserver.herokuapp.com/auth/" + uname.value + "/" + pass.value;
        console.log(urrl);

        let list = await fetch(urrl, {cache: "no-store"});
        list = await list.json();
        list = list.valid;
        if (list == "true") {
            setIsSubmitted(true);
        } else {
            alert("Invalid auth");
        }
    }

    const getLedger = async () => {
        let list = await fetch("https://urban-foresight-private-data-store.s3.eu-west-2.amazonaws.com/myLedger.json", {cache: "no-store"});
        list = await list.json();
        console.log(list);
        setLoadedLedger(list);
    }

    const getDatasets = async () => {
        let list = await fetch("https://cleanstreetserver.herokuapp.com/listBuckett/", {cache: "no-store"});
        list = await list.json();
        list = list.contents;
        console.log(list);

        let tempArr = [];
        list.forEach(element => {
            if (element != "myLedger.json") {
                tempArr.push(element);
            }
        });
        console.log(tempArr);

        setDatasetList(tempArr);
    }

    function handleDelete(keay){
        deleteDataset(keay);
        updateLedger(keay);
        alert(keay+" deleted.")
    }

    async function deleteDataset(keay) {
        var tempLedge = datasetList;
        var newTempLedger = [];

        tempLedge.forEach(element => {
            if(element != keay){
                newTempLedger.push(element);
            }
        })
        setDatasetList(newTempLedger);

        let res = await fetch("https://cleanstreetserver.herokuapp.com/s3DataUploader/s3Del/" + keay);
    }

    const updateLedger = async (keay) => {

        var tempLedge = loadedLedger;
        var newTempLedger = [];

        console.log(tempLedge);

        tempLedge.forEach(element => {
            if(element.datasetName != keay){
                newTempLedger.push(element);
            }
        })

        setLoadedLedger(newTempLedger);

        let tempJson = JSON.stringify(newTempLedger);

        console.log(tempJson);

        let url = await fetch("https://cleanstreetserver.herokuapp.com/s3DataUploader/myLedger.json", {cache: "no-store"});
        url = await url.json();
        url = url.uploadURL;

        return fetch(url, {
            method: "PUT",
            body: tempJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };


    if (isLoading) {
        return (
            <section>
                <p>Loading...</p>
            </section>
        );
    }
    return (
        <Layout >
            {isSubmitted == false ?
                <div style={{ position: "absolute", height: "100vh", width: "100vw", background: "#fff", zIndex: "998" }}>
                    <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center", zIndex: "999" }}>
                        <h1 style={{ fontSize: "30px" }} className="main-title">Admin login</h1>
                    </div>
                    <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px", zIndex: "999" }}>
                        <div className="form" style={{ display: "flex", justifyContent: "center" }}>
                            <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 40px", border: "2px solid #291351" }} onSubmit={getAuth} >
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Username: </label>
                                    <input style={{ float: "right" }} type="text" name="uname" required />
                                    {renderErrorMessage("uname")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Password: </label>
                                    <input style={{ float: "right" }} type="password" name="pass" required />
                                    {renderErrorMessage("pass")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                                    <input type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : " "}
            {isSubmitted == true ?
                <>
                    <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center" }}>
                        <h1 style={{ fontSize: "30px" }} className="main-title">Delete a dataset</h1>
                    </div>
                    <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px" }}>

                        {datasetList.map(keay => {
                            return (
                                <div style={{ display: "flex" }}>
                                    <button onClick={() => handleDelete(keay)}>X</button>
                                    <option value={keay}>{keay}</option>
                                </div>
                            )
                        })}


                        {/* <h2 style={{borderBottom: "1px solid #291351", fontSize:"22px", fontFamily:"favorit", marginTop:"20px"}}>Search for a dataset</h2>
          <GeneralAutoSearch itemsList={tagList} selectedList={selectedTags} setSelectedList={setSelectedTags} placeholderTxt={"Search for a tag"}/>
                 */}
                    </div>
                </> : " "}
        </Layout>

    )
}


export default DatasetDelete