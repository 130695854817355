import React, {useState, useEffect, useContext} from 'react';
import {LAStateContext, priorityStateContext, datasetConfigStringsContext,   layerRefsContext,  factorsContext, legendsContext} from '../Store';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';




function MapMenuArea2({mp, setMap, info, clearButtonRef, setClearButtonRef, atr, setatr}){
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [datasetConfigStrings, setDatasetConfigStrings] = useContext(datasetConfigStringsContext);
    const [factors, setFactors] = useContext(factorsContext); 
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [legends, setLegends] = useContext(legendsContext);
    const [LAs, setLAs] = useContext(LAStateContext);


    const [POI, setPOI] = useState([]);
    
    const [counter1, setCounter1] = useState(105);
    const [counter2, setCounter2] = useState(150);



    var c = 305;
    var c = 350;
    
    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });



    useEffect(() => {
        console.log("here"); 
        var poiTemp =[];
        var cloroplethTemp =[];
        factors.forEach(factor => {
            factor.relatedDatasets.forEach(dset => {
                ////console.log(dset)
                var splitz = dset.split(".");
                let useMe = true;
                if ( atr != null){
                    //console.log(atr);
                    atr.forEach(at => {
                        //console.log(at);
                        //console.log(splitz[3]); 
                        if (at == splitz[3]){
                            useMe = false;
                        }
                    })
                }
                if (useMe == true){
                    console.log(splitz);
                    var splitz2 = splitz[16].split("&")
                    if (splitz2[1] == "all"){
                        if (splitz2[0] == "POI"){
                            poiTemp.push(dset);
                        }
                    } else {
                        if (splitz2[0] == "POI"){
                            let valid = false;
                            
                            let splitLAs = splitz2[2].split("$");
                            splitLAs.forEach(lA =>{
                                validatedLAs.some(la => {
                                    if (la == lA){
                                        valid = true;
                                    }
                                })
                            })
    
                            if (valid == true){
                                poiTemp.push(dset); 
                            }
                            
                        }
                    }
                }
                               
            })
        })

        //placeholders
        var poiSubstationPlaceholder="EvChargers.Primary substations.GBLayer.  Primary substations.busIcon..........3.true.POI";
        var carParkPlaceholder=".Car parks.GBLayer.  Car parks.busIcon..........3.true.POI";
        var supermarkerPlaceholder="EvChargers.Supermarkers and shopping centers.GBLayer.  Supermarket and shopping centres.busIcon..........3.true.POI";
        var toruistPlaceholder="EvChargers.Leisure and tourist destinations.GBLayer.  Leisure and tourist destinations.busIcon..........3.true.POI";
        var transportPlaceholder="Stops.Transport interchanges.GBLayer.  Transport interchanges.busIcon..........3.true.POI";

        //poiTemp.push(poiSubstationPlaceholder);
        //poiTemp.push(carParkPlaceholder);
        //poiTemp.push(supermarkerPlaceholder);
        //poiTemp.push(toruistPlaceholder);
        //poiTemp.push(transportPlaceholder);
        setPOI(poiTemp);
        
    }, [atr]);

    function handleCheckbox (event){
       ////////////////console.log(event);

        var keys = Object.keys(legends);
        

        layerRefs.forEach(layer => {
            if(layer[0] == event.target.value){
                if (event.target.checked == true){
                    layer[1].addTo(mp);

                    keys.forEach(key => {
                        if(key == event.target.value){
                            legends[key][0].addTo(mp);
                        }
                    }) 

                } else {
                    mp.removeLayer(layer[1]);
                    mp.removeControl(layer[1]);
                    keys.forEach(key => {
                        if(key == event.target.value){
                            mp.removeControl(legends[key][0]);
                        }  
                    })
                }
            }
        })   
    }
   
    
    function handleDSetSelector (event){
       ////////////////console.log("whereC");

        var keys = Object.keys(legends);
        keys.forEach(key => {
            if(legends[key][1] == "poly"){
                mp.removeControl(legends[key][0]);
            }
        })
        
       ////////////////console.log(layerRefs);
        layerRefs.forEach(layer => {
            // if(layer[0] != event.target.value){

            // }
            if(layer[2] == "poly"){
                mp.removeLayer(layer[1]);
                mp.removeControl(layer[1]);
            }
             
        })
        layerRefs.forEach(layer => {
           ////////////////console.log(layer[0] + "//" + event.target.value);
            if(layer[0] == event.target.value){
                layer[1].addTo(mp);
            }
            
        })
        keys.forEach(key => {
            if(key == event.target.value){
                legends[key][0].addTo(mp);
            }
        })
    }

    return(
        <div  key ={301}>
            <div key ={302}>
            <h6 className="sub-title">Points of interest</h6>
                {POI.map(poi =>{
                    var splitz = poi.split(".");
                    
                    c = c+1;
                    
                    return(
                        <div key ={c} onChange={handleCheckbox} style={{padding:'10px 0px', fontSize: "18px"}}><label className="radioBContainer favorit" ><input type="checkbox" value={splitz[1]} name="overlaySelectedVar" /> {splitz[3]}<span className="checkmark"></span></label></div>
                    )
                })}
            </div>
           
            
           
        </div>
    )
}

export default MapMenuArea2
