import React, { useContext, useState, useEffect } from 'react'
import Layout from '../layout/Layout';
import GeneralAutoSearch from '../functional/GenralAutoSearch';

import { isSubStateC } from '../../Store';


function TagListUpload() {
    const [isLoading, setIsLoading] = useState(true);
    const [translatedBool, setTranslatedBool] = useState(false);

    const [projectList, setProjectList] = useState([]);
    const [tagList, setTagList] = useState([]);

    const [URL, setURL] = useState(null);
    const [files, setFiles] = useState("");
    const [showFileContent, setShowFileContent] = useState(false);
    const [textShown, setTextShown] = useState("Click to show file content");
    const [errorMessages, setErrorMessages] = useState({});
    const [showDropDowns, setShowDropDowns] = useState(false);//change


    const [locationType, setLocationType] = useState("all");
    const [specificLAs, setSpecificLAs] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [pointOrArea, setPointOrArea] = useState("noSelect");
    const [scope, setScope] = useState("noSelect");

    const [metaDataOptions, setMetaDataOptions] = useState("noSelect");
    const [metaDataChoice1, setMetaDataChoice1] = useState("noSelect");
    const [metaDataChoice2, setMetaDataChoice2] = useState("noSelect");
    const [metaDataChoice3, setMetaDataChoice3] = useState("noSelect");

    const [toBeRemoved, setToBeRemoved] = useState(false);
    const [selectedLinkedProject, setSelectedLinkedProject] = useState("noSelect");

    const [tag2Add, setTag2Add] = useState("noSelect");




    const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    useEffect(() => {
        getTaglist();
        setIsLoading(false);
    }, []);

    const getAuth = async (event) => {
        console.log("trig");
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        let urrl = "https://cleanstreetserver.herokuapp.com/auth/" + uname.value + "/" + pass.value;
        console.log(urrl);

        let list = await fetch(urrl, {cache: "no-store"});
        list = await list.json();
        list = list.valid;
        if (list == "true") {
            setIsSubmitted(true);
        } else {
            alert("Invalid auth");
        }

    }

    const getTaglist = async () => {
        let list = await fetch("https://urban-foresight-private-data-tags.s3.eu-west-2.amazonaws.com/tagList.json", {cache: "no-store"});
        list = await list.json();
        console.log(list);
        setTagList(list);
    }

    function uploadWrapper() {
        if (tag2Add != "noSelect"){
            updateLedger();
        } else {
            alert("non entered");
        }
        
        alert("Tag added: " + tag2Add);
    }


    const updateLedger = async () => {

        var tempLedge = tagList;

        tempLedge.push(tag2Add);

        let tempJson = JSON.stringify(tempLedge);

        setTagList(tempLedge);

        let url = await fetch("https://cleanstreetserver.herokuapp.com/s3TagsUploader/tagList.json", {cache: "no-store"});
        url = await url.json();
        url = url.uploadURL;

        return fetch(url, {
            method: "PUT",
            body: tempJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };

    if (isLoading) {
        return (
            <section>
                <p>Loading...</p>
            </section>
        );
    }
    return (
        <Layout >
            {isSubmitted == false ?
                <div style={{ position: "absolute", height: "100vh", width: "100vw", background: "#fff", zIndex: "998" }}>
                    <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center", zIndex: "999" }}>
                        <h1 style={{ fontSize: "30px" }} className="main-title">Admin login</h1>
                    </div>
                    <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px", zIndex: "999" }}>
                        <div className="form" style={{ display: "flex", justifyContent: "center" }}>
                            <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 40px", border: "2px solid #291351" }} onSubmit={getAuth} >
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Username: </label>
                                    <input style={{ float: "right" }} type="text" name="uname" required />
                                    {renderErrorMessage("uname")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Password: </label>
                                    <input style={{ float: "right" }} type="password" name="pass" required />
                                    {renderErrorMessage("pass")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                                    <input type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : " "}
            {isSubmitted == true ?
            <>  
                <div style={{margin:"20px 19% 0 19%"}}>
                    <div style={{display:"flex", justifyContent:"spaceBetween"}}>
                        <div style={{width:"45%"}}>
                            <label>Enter a tag to add:</label>
                            <input type="text" name="uname" onChange={(e) => setTag2Add(e.target.value)} />
                            <br />
                            <br />
                            <br />
                            
                            <button onClick={uploadWrapper}>Upload tag</button>
                        </div>

                        <div style={{width:"45%"}}>
                            {tagList.map(keay => {
                                return (
                                    <div style={{ display: "flex" }}>
                                        <option value={keay}>{keay}</option>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                   
            </> : " "}
        </Layout>

    )
}


export default TagListUpload