import React , {useContext, useState, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import L, { featureGroup } from 'leaflet';
import {Card} from 'react-bootstrap';
import Sliders2 from '../functional/Sliders2';
import Layout from '../layout/Layout';

import {data2ReturnContext, minMaxObjContext} from '../../Store';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";


import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate
} from 'bizcharts';


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";




function PinsRankList({card, ranks2skip, NR, lightTrue}) {
    ////////////console.log("prl")
    ////////////console.log(card);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [totalScore, setTotalScore] = useState(null);
//If you have any good ideas about what label to put on the pins for the ranking page let me know. Atm



    const [sliderValues, setSliderValues] = useState([]); 
    const [slideVisibility, setslideVisibility] = useState(true);

    // const data = [
    //     { year: '2001', population: 41.8 },
    //     { year: '2002', population: 38 },
    //     { year: '2003', population: 33.7 },
    //     { year: '2004', population: 30.7 },
    //     { year: '2005', population: 25.8 },
    //     { year: '2006', population: 31.7 },
    //     { year: '2007', population: 33 },
    //     { year: '2008', population: 46 },
    //     { year: '2009', population: 38.3 },
    //     { year: '2010', population: 28 },
    //     { year: '2011', population: 42.5 },
    //     { year: '2012', population: 30.3 },
    // ];

    useEffect(() => {
        
       
        setLoading(false);
        //card.forEach()
    }, []);

    function tog (){
        if(slideVisibility == true){
            setslideVisibility(false);
        } else {
            setslideVisibility(true);
        }
        
    }
    if (isLoading) {
        return (
          <Layout>
          <section>
            <p>Loading...</p>
          </section>
          </Layout>
        );
    } else {
        return(
            <div className={lightTrue == true ? "customer" : "customer pinsRankDarkBackground"}>
            {/*<div className={'rankListPinDiv'}>*/}
                {/*<div style={{width:'48%'}}>
                     <h6 className="sub-title" style={{ marginBottom:'0px'}}>{typeof card[0][5] !== 'undefined' ? card[0][5] : typeof card[0][6].postcode !== 'undefined' ? card[0][6].postcode : card[0][4]}</h6>
                </div>

                <div style={{width:'48%', display:"flex", justifyContent:'space-between', flexWrap: 'wrap'}}>
                    <div className="freightText" style={{ color: '#291351',  width:"48%"}}>
                        <h6 className="sub-title" style={{ marginBottom:'0px'}}>Total score<span style={{color:'#db0052', margin:'0 35px'}}>{card[0][13]}%</span></h6>
                    </div>

                    <div className="freightText" style={{ color: '#291351',  width:"48%"}}>
                        <h6 className="sub-title" style={{ marginBottom:'0px'}}>Rank <span style={{color:'#db0052', margin:'0 35px'}}>{card[0][14]}</span></h6>
                    </div> 
                </div>*/}

                
                    <div style={{width:'3%', marginBottom: '0px', color:'#291351', paddingLeft: "8px", display:"flex", alignItems:"center" }}>
                        {typeof NR !== 'undefined' ? NR : card[0][14]}
                    </div>
                    <div style={{width:'14%', marginBottom: '0px', color:'#291351', paddingLeft: "3px", display:"flex", alignItems:"center" }}>
                        {typeof card[0][5] !== 'undefined' && card[0][5] != null ? card[0][5] : typeof card[0][6].postcode !== 'undefined' && card[0][6].postcode !== null ?  card[0][6].postcode : card[0][4]}
                    </div>
                    <div style={{width:'20%', marginBottom: '0px', color:'#291351', paddingLeft: "3px", display:"flex", alignItems:"center" }}>
                        {card[0][4]}
                    </div>
                    <div style={{width:'8%', marginBottom: '0px', color:'#291351', paddingLeft: "3px", display:"flex", alignItems:"center"}}>
                        {card[0][13]}%
                    </div>
                    {card[0][12].map((d, id)=> {
                        
                        return(
                            <div style={{width:'14%', marginBottom: '0px', color:'#291351', paddingLeft: "3px", display:"flex", alignItems:"center"}} key = {id}>
                                <p style={{marginTop: "revert"}}>{(d.indivData.toFixed(2)*20)}</p>
                            </div>
                        )
                    })}
                    {/* <div style={{width:'11%'}}>
                        <p  style={{ marginBottom: '0px' }}>{card[0][9][0]}</p>
                    </div>
                    <div style={{width:'11%'}}>
                        <p  style={{ marginBottom: '0px', color:'#291351' }}>{card[0][9][1]}</p>
                    </div>
                    <div style={{width:'11%'}}>
                        <p  style={{ marginBottom: '0px', color:'#291351' }}>{card[0][9][2]}</p>
                    </div> */}
                
            </div>
        )
    }
    
}

export default React.memo(PinsRankList);

