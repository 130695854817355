import React, { useState, useRef, useEffect } from "react";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { GradientPinkBlue } from "@visx/gradient";
import letterFrequency, {
  LetterFrequency
} from "@visx/mock-data/lib/mocks/letterFrequency";
import { timeParse, timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { ParentSize  } from '@visx/responsive';

import browserUsage from "@visx/mock-data/lib/mocks/browserUsage";
import { animated, useTransition, interpolate } from "react-spring";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function Example({widget, index}) {
  ////////////////console.log(dataa);

  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [selectedAlphabetLetter, setSelectedAlphabetLetter] = useState(null);
  const [innerChoiceEV, setInnerChoiceEV] = useState(true);

    //////////////console.log(widget);
    //////////////console.log(index);
    //////////////console.log("sAL" + selectedAlphabetLetter);
    var innerPie1 = [];                                                          
    widget["laChartData"][index]["cData"].forEach(chart => {
        if(chart[0] == "innerPie1"){
            innerPie1 = chart[2];
        }
        //////////////console.log(chart[2]);
    })
    //////////////console.log(innerPie1);

    var innerPie2 = [];                                                          
    widget["laChartData"][index]["cData"].forEach(chart => {
        if(chart[0] == "innerPie2"){
            innerPie2 = chart[2];
        }
    })
    ////////////console.log(innerPie2);

    var outerPie = {};                                                          
    widget["laChartData"][index]["cData"].forEach(chart => {
        if(chart[0] == "outerPie"){
          outerPie = chart[2];
        }
    })
  
  var count20 = -1 ;
  var width = 642;
  var height = 350;
  var margin = defaultMargin;
  var animate = true;

  
  var z = innerPie1;


  const browserNames = Object.keys(outerPie).filter((k) => k !== "date");
  const browsers = browserNames.map((name) => ({
    label: name,
    usage: Number(outerPie[name])
  }));
  
  //////////////console.log(outerPie);
  
  const usage = (d) => d.usage;
  var outerCircle = [
      "rgba(255, 80, 146, 1)",
      "rgba(204, 204, 204, 1)"
      ]

  var outerCircle2 = [
    "rgba(204, 204, 204, 1)",
    "rgba(255, 80, 146, 1)"
    ]
  const frequency = (d) => d.frequency;

  var getBrowserColor = scaleOrdinal({
    // outer circle
    domain: browserNames,
  
    range:  selectedAlphabetLetter && selectedAlphabetLetter == "EV" ? outerCircle :
    selectedAlphabetLetter && selectedAlphabetLetter == "Non EV" ? outerCircle2:
    outerCircle 


  });

  const getLetterFrequencyColor = scaleOrdinal({
    domain: innerPie1.map((l) => l.letter),
    // inner circle
    range: [
      "rgba(255, 152, 136, 1)",
      "rgba(255, 204, 102, 1)",
      "rgba(255, 80, 147, 1)",
      "rgba(65, 255, 223, 1)"
    ]
  });

  const getLetterFrequencyColor2 = scaleOrdinal({
    domain: innerPie2.map((l) => l.letter),
    // inner circle
    range: [
      "rgba(85, 204, 255, 1)",
      "rgba(142, 155, 255, 1)",
      "rgba(186, 152, 244, 1)",
      "rgba(186, 152, 244, 1)",
    ]
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 20;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

// If you don't want to use a Portal, simply replace `TooltipInPortal` below with
// `Tooltip` or `TooltipWithBounds` and remove `containerRef`
const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
 })

const handleMouseOver = (event, arc) => {
    ////////////console.log(event);
    ////////////console.log("mouseover");
    //////////////console.log(dat);
    var sum = ""+arc.data.label+" "+arc.data.usage;
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: event.pageX,
      tooltipTop: event.pageY,
      tooltipData: sum
    });
};

const handleMouseOver2 = (event, arc) => {
  ////////////console.log(event);
  ////////////console.log("mouseover");
  //////////////console.log(dat);
  var sum = ""+arc.data.letter+" "+arc.data.frequency;
  const coords = localPoint(event.target.ownerSVGElement, event);
  showTooltip({
    tooltipLeft: event.pageX,
    tooltipTop: event.pageY,
    tooltipData: sum
  });
};

  if (width < 10) return null;
  ////////////console.log(browsers);
  var n = 0;

  //console.log(centerX);
  //console.log(centerY);
  //console.log(radius);
  //console.log(containerRef.current);
  return (
    <ParentSize>
      {parent => {
        //console.log(parent);
        return(
          <>
            <svg height={"100%"} width={"100%"} ref={containerRef}>
              <Group top={(parent.height/2)} left={(parent.width/2)}>
                <Pie
                  data={
                    browsers
                  }
                  pieValue={usage}
                  outerRadius={radius}
                  innerRadius={radius - donutThickness}
                  cornerRadius={3}
                  padAngle={0.005}
                >
                  {(pie) => {
                    count20 = count20+1;
                    let x = count20;
                    
                    
                    ////////////console.log(browsers[x]);
                    n = n + 1;
                    ////////////console.log(n);
                    return(
                    <AnimatedPie
                      {...pie}
                      animate={animate}
                      getKey={(arc) => arc.data.label}
                      onClickDatum={({ data: { label } }) =>{
                        animate &&
                        
                        
                        setSelectedAlphabetLetter(
                          selectedAlphabetLetter && selectedAlphabetLetter === label ? null : label
                        )
                        //////////////console.log(label);
                      }}
                      onMouseEnter={(e,arc) => {
                        ////////////console.log("entered");
                        handleMouseOver(e,arc);
                      }}
                      onMouseLeave={hideTooltip}

          
                      getColor={(arc) => getBrowserColor(arc.data.label)}
                    />
                  )
                }}
                </Pie>
                <Pie
                  data={
                    selectedAlphabetLetter && selectedAlphabetLetter == "EV" ? innerPie1 :
                    selectedAlphabetLetter && selectedAlphabetLetter == "Non EV" ? innerPie2:
                    innerPie1
                    
                  }
                  pieValue={frequency}
                  pieSortValues={() => -1}
                  outerRadius={radius - donutThickness * 1.3}
                >
                  {(pie) => (
                    <AnimatedPie
                      {...pie}
                      animate={animate}
                      getKey={({ data: { letter } }) => letter}
                      onClickDatum={({ data: { letter } }) =>
                        animate &&
                        setSelectedAlphabetLetter(
                          selectedAlphabetLetter && selectedAlphabetLetter === letter
                            ? null
                            : letter
                        )
                      }

                      onMouseEnter={(e, arc) => {
                        ////////////console.log("inner entered");
                        handleMouseOver2(e,arc);
                      }}
                      onMouseLeave={hideTooltip} 
                    
                      getColor={({ data: { letter } }) =>

                          selectedAlphabetLetter && selectedAlphabetLetter == "Non EV" ? getLetterFrequencyColor2(letter) :
                          getLetterFrequencyColor(letter)
                          
                      
                      }
                    />
                  )}
                </Pie>
              </Group>
              
            </svg>
            {tooltipOpen && (
              <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              >
              {tooltipData}
              </TooltipWithBounds>
            )}
          </>
        )
      }}
    </ParentSize>
  );
}

// react-spring transition definitions
//type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1
});

// type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
//   animate?: boolean;
//   getKey: (d: PieArcDatum<Datum>) => string;
//   getColor: (d: PieArcDatum<Datum>) => string;
//   onClickDatum: (d: PieArcDatum<Datum>) => void;
//   delay?: number;
// };
//\\//\\//////////////////////////////////////////////////////////////////////////////////////////
function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  onMouseEnter,
  onMouseLeave
}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
    

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle
              })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
          onMouseEnter={(e) => {
            ////////////console.log(arc);
            onMouseEnter(e,arc);
          }}
          onMouseLeave={() => onMouseLeave()}
          
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="#291351"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={13}
              textAnchor="middle"
              pointerEvents="none"
            >
              {getKey(arc)}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
