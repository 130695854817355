import React, {useState, useEffect, useContext, useRef} from 'react';
import { MapContainer, MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, GeoJSON} from 'react-leaflet';
import * as L from 'leaflet'
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';



import { LMC, aqmzPC, fDateC, mapVarsC, priorityStateContext,evCounterC, factorsContext, typeofChargerStateContext, LAStateContext, layerRefsContext, screenObjC,  screenObjC2, lIconContext, preventMarkeContext, cSBSContext, dataBoolContext, genralDataC, allLaMapC} from '../../Store';
import {features} from "./GBLayer.json";
import {laCords} from "./LACoords.json";

import MiddleLayer from './MiddleLayer'
import NewMapDraw from './NewMapDraw';
//import MapDraw from "./MapDraw";
import Layout from '../layout/Layout';
import { rhumbDistance } from '@turf/turf';
import { ConstantNodeDependencies } from 'mathjs';


function MapDataLoad( {mp, tabDisabled, setTabDisabled, tabHighlighted, setTabHighlight, latty, setLatty, longy, 
    setLongy, mp2, mp3, mp4, cBool, setCBool, info, load, setLoad, atr, setatr, setMyState}){
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [chargerType, setchargerType] = useContext(typeofChargerStateContext);   
    const [LAs, setLAs] = useContext(LAStateContext);
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [screenObj, setScreenObj] = useContext(screenObjC);
    const [screenObj2, setScreenObj2] = useContext(screenObjC2);
    const [lIcons, setLIcons] = useContext(lIconContext);
    const [pms, setPMS] = useContext(preventMarkeContext);
    const [cSBS, setCSBS] = useContext(cSBSContext);
    const [dataBool, setDataBool] = useContext(dataBoolContext);
    const [factors2, setFactors2] = useContext(factorsContext); 
    const [genralData, setGenralData] = useContext(genralDataC); 
    const [evCounter, setEvCounter] = useContext(evCounterC); 
    const [mapVars, setMapVars] = useContext(mapVarsC);
    const [fDate, setFDate] = useContext(fDateC); 
    const [aqmzP, setAQMZP] = useContext(aqmzPC);
    const [LM, setLM] = useContext(LMC);
   //


    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState();
    const [calledDatasets, setcalledDatasets] = useState([]);

    const [evs, setEVs] = useState([]);
    

    const [l1, sl1] = useState([]);
    const [l2, sl2] = useState([]);

    const[counter, setCounter] = useState(0);

    var missed = 0;

    var ress;
    ////////////////////console.log(LAs);
    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });
    
    useEffect(() => {

        //import widgetconetext
        var genralDatasets = [];
        factors2.forEach(factor => {
            factor.genralDatasets.forEach(dSet => {
                genralDatasets.push(dSet);
            })
        })
        ////console.log(genralDatasets);
        ////console.log(priorities);
        var count10 = 0;
        var other1 = "";
        var other2 = "";
        var other3 = "";
        var other4 = "";
        var dateSelected = true;
        
        if (fDate == "2025"){
            other1 = "2030";
            other2 = "2035";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2030") {
            other1 = "2025";
            other2 = "2035";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2035") {
            other1 = "2025";
            other2 = "2030";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2040") {
            other3 = "2025";
            other1 = "2030";
            other2 = "2035";
            other4 = "2045";
        } else if (fDate == "2045") {
            other3 = "2025";
            other1 = "2030";
            other2 = "2035";
            other4 = "2040";
        } else if (fDate == "") {
            //////console.log("noDate");
            dateSelected = false;
        }

        var alreadyLoadedDatasets2 = {};
        var dataWithAtt2 = [];
        
        var apiLink = 'https://cleanstreetserver.herokuapp.com/v1/';

        for ( var j=0; j < genralDatasets.length; j++) {
            var prioritiesIn = genralDatasets[j].split(".");
            let dsetToLoad = prioritiesIn[0];
            let attrToLoad = prioritiesIn[1];
            ////console.log(attrToLoad);
            let geoScope = prioritiesIn[2];
            let overlayLabel = prioritiesIn[3];

            let description = prioritiesIn[4];

            let colourScaleValue1 = prioritiesIn[5];
            let colourScaleValue2 = prioritiesIn[6];
            let colourScaleValue3 = prioritiesIn[7];
            let colourScaleValue4 = prioritiesIn[8];
            let colourScaleValue5 = prioritiesIn[9];
            let colourScaleValue6 = prioritiesIn[10];
            let colourScaleValue7 = prioritiesIn[11];
            let colourScaleValue8 = prioritiesIn[12];

            let units = prioritiesIn[13];
            let type = "geo";

            let x = j;

            dataWithAtt2.push([]);

            let allLAfeaturesForDSet = [];
            
            let count20 = 0;

            let apiPartialPath = "dataFetch/";

            let markerImg ="";
            
            let dFetchPathPrefix = apiLink + apiPartialPath + geoScope + "/";

            if (dsetToLoad == "AirQualManagementAreas" ){
                setAQMZP(true);
                //console.log("HERE");
            }

            if (dsetToLoad == "Stops" || dsetToLoad == "durhamCarParks" || dsetToLoad == "TCP" || dsetToLoad == "EvChargers"){
                apiPartialPath = "point/";
                type = "point";
                //////////////////////console.log("Marker PRIOITY");
                markerImg = prioritiesIn[4] + ".png";
            } else {
                apiPartialPath = "dataFetch/";
                type = "geo";
                //////////////////////console.log("GEO PRIOITY");
            }
            let colorScaleValues = [colourScaleValue1,colourScaleValue2,colourScaleValue3,colourScaleValue4,colourScaleValue5,colourScaleValue6,colourScaleValue7,colourScaleValue8];
            //////////////////////console.log(colorScaleValues);
            let propPre = {name: dsetToLoad, id: x, type:type, overlayLabel: overlayLabel, geoScope: geoScope, markerImg: markerImg, colourScaleValues:colorScaleValues, units: units};

            var counter = -1;
            validatedLAs.forEach(la => {
                counter = counter + 1;
                let cc = counter;
                //for each LA load that into allLAfeaturesForDset
                let dFetchPath = dFetchPathPrefix + la + "/" + dsetToLoad;
                if (dsetToLoad == "EvChargers"){
                        
                    dFetchPath = "https://cleanstreetserver.herokuapp.com/v1/EvChargers/" +la; 
                }
                allLAfeaturesForDSet.push([])
                console.log(dFetchPath);
                fetch(
                    `${dFetchPath}`, {cache: "no-store"}
                )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // var te = l2;
                    // te.push(x);
                    // sl2(te);
                    // //////////////////console.log(data);
                    //allLAfeaturesForDSet.push(data);
                    allLAfeaturesForDSet[cc] = data;
                    //////////console.log(data);
                    count20 = count20 + 1;
                    // //////////////////////console.log("Count2: " + count2 + " /Out of " +numberOfLAs);
                    if (count20  == numberOfLAs){
                        dataWithAtt2[x] = [allLAfeaturesForDSet, attrToLoad, propPre];
                    //     //dataWithAtt.push([allLAfeaturesForDSet, attrToLoad, propPre]);
                    //     //////////////////////////////////////////////////////////////////<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                        alreadyLoadedDatasets[dsetToLoad] = [allLAfeaturesForDSet, attrToLoad, propPre];

                        count10 = count10 + 1; 
                    //     //////////////////////console.log("Count: " + dataWithAtt.length + " /Out of " +priorities.length);
                        
                    //     if (count10 == genralDatasets.length){
                    //         // ////////////////////console.log("all Loaded");
                    //         // ////////////////////console.log(dataWithAtt.length);
                    //         // ////////////////////console.log(dataWithAtt[0]);
                    //         //setIsLoading(false);                                                               
                    //     }                           
                    }                       
                });
                
            })

        }
        ///////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\//////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\
        //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\//////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\//////////////////////////
        var numberOfLAs = validatedLAs.length;
     
        

        setIsLoading(true);        
        var alreadyLoadedDatasets = {};
        var dataWithAtt = [];
        var count = 0;
        ////////console.log("prio after2");
        ////////console.log(priorities);

        var tempTotalMinMaxs = [];

        var skipped = 0;
        
        

        
        for ( var j=0; j < priorities.length; j++) {
            //////console.log(priorities[j])
            var te = l1;
            te.push(j-skipped);
            sl1(te);
            var prioritiesIn = priorities[j].split(".");
            let dsetToLoad = prioritiesIn[0];
            let attrToLoad = prioritiesIn[1];
            let endBit = attrToLoad.slice(attrToLoad.length - 4);
            //////console.log(attrToLoad);
            //////console.log(endBit);
            let cont = false;
            if (endBit == "2025" || endBit == "2030" || endBit == "2035" || endBit == "2040" || endBit == "2045"){
                if (dateSelected == true){
                    if (endBit == other1 || endBit == other2 || endBit == other3 || endBit == other4 ){
                        count = count + 1; 
                        cont = true;
                    } 
                } else {
                    ////////console.log("false");
                    count = count + 1; 
                    cont = true;

                }
            }
            if (cont == true){
                //////console.log("true");
                skipped = skipped + 1;
                continue;   
            }else {
                //////console.log("false");
            }

            //////console.log(j);
            
            let geoScope = prioritiesIn[2];
            let overlayLabel = prioritiesIn[3];

            let description = prioritiesIn[4];

            let colourScaleValue1 = prioritiesIn[5];
            let colourScaleValue2 = prioritiesIn[6];
            let colourScaleValue3 = prioritiesIn[7];
            let colourScaleValue4 = prioritiesIn[8];
            let colourScaleValue5 = prioritiesIn[9];
            let colourScaleValue6 = prioritiesIn[10];
            let colourScaleValue7 = prioritiesIn[11];
            let colourScaleValue8 = prioritiesIn[12];

            let units = prioritiesIn[13];

            let typeSwitch = prioritiesIn[16];
            let splitz2 = typeSwitch.split("&")
            typeSwitch = splitz2[0];
            let filteredOrAll = splitz2[1];
            //////console.log(filteredOrAll);


            //////////////////////console.log(dsetToLoad, attrToLoad);
           ////////console.log(typeSwitch);

            let alreadyLoaded = false;
            let alreadyLoadedDatasetsKeys = Object.keys(alreadyLoadedDatasets);
            alreadyLoadedDatasetsKeys.forEach(dsetName =>{
                if (dsetName == dsetToLoad){
                    alreadyLoaded = true;
                }
            })

            let type;
            let markerImg ="";
            let apiPartialPath = "";
            
            // if(typeSwitch = "genral"){
            //     apiPartialPath = "dataFetch/";
            //     type = "genral";
            // } else {
                
            // }
            
            //////console.log(attrToLoad);
            //////console.log(typeSwitch);
            //dsetToLoad == "Stops" || dsetToLoad == "durhamCarParks" || dsetToLoad == "TCP" || dsetToLoad == "EvChargers"
            if (typeSwitch == "POI"){
                apiPartialPath = "point/";
                type = "point";
                //////////////////////console.log("Marker PRIOITY");
                markerImg = prioritiesIn[4] + ".png";
            } else {
                apiPartialPath = "dataFetch/";
                type = "geo";
                //////////////////////console.log("GEO PRIOITY");
            }
            
            //do not remove!
            let x = j;
            let colorScaleValues = [colourScaleValue1,colourScaleValue2,colourScaleValue3,colourScaleValue4,colourScaleValue5,colourScaleValue6,colourScaleValue7,colourScaleValue8];
            
            let propPre = {name: dsetToLoad, id: x, type:type, overlayLabel: overlayLabel, geoScope: geoScope, markerImg: markerImg, colourScaleValues:colorScaleValues, units: units, typeSwitch: typeSwitch};
            dataWithAtt.push([]); /////////////////////////////////////////////////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
            //////console.log([propPre, dataWithAtt]);
            //////console.log(x);
            let skip = skipped;
            //////console.log(skipped);
            if (filteredOrAll == "all"){
                if (alreadyLoaded == true){
                    //get that dataset and cont
                    ///////////////////////////////////////////////////////////////////////////////////<<<<<<<<<<<<<<<<<<<<<<<
                    dataWithAtt[x-skip] = [alreadyLoadedDatasets[dsetToLoad], attrToLoad, propPre];
                    //dataWithAtt.push([alreadyLoadedDatasets[dsetToLoad], attrToLoad, propPre]) ;  
                } else {
                    //load that dataset
                    
                    let allLAfeaturesForDSet = [];
    
                    let count2 = 0;
    
                    let dFetchPathPrefix = apiLink + apiPartialPath + geoScope + "/";
                    //if dset is only for one LA, 
                    
                    validatedLAs.forEach(la => {
                        //for each LA load that into allLAfeaturesForDset
                        let dFetchPath = dFetchPathPrefix + la + "/" + dsetToLoad;
                        if (dsetToLoad == "EvChargers"){
                            
                            dFetchPath = "https://cleanstreetserver.herokuapp.com/v1/EvChargers/" +la; 
                        }
                        
                        //////console.log("url");
                        //////console.log(dFetchPath);
    
                        fetch(
                            `${dFetchPath}`, {cache: "no-store"}
                        )
                        .then((response) => {
                            //console.log("////////////////////////////////////////////////")
                            //console.log(response.ok);

                            if(response.ok)
                            {
                                return response.json();        
                            }
                            missed = missed + 1;
                            throw new Error('Something went wrong.');

                            
                        })
                        .then((data) => {
                            if (dsetToLoad == "EvChargers"){
                                ////console.log(data);
                                let tempEvCounter = evCounter;
                                tempEvCounter.push([la, data.length]);
                                setEvCounter(tempEvCounter);
                                //setEvsForALA
                                ////console.log(data);
                                var tempEvs = evs;
                                tempEvs.push([la,data])
                                setEVs(tempEvs);
                            } 
                            
                            allLAfeaturesForDSet.push(data);

                            count2 = count2 + 1;
                            
                            if (count2  == numberOfLAs){

                                dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                                
                                alreadyLoadedDatasets[dsetToLoad] = [allLAfeaturesForDSet, attrToLoad, propPre];
    
                                count = count + 1; 

                                if (count == priorities.length){
                                    setIsLoading(false);  
                                    setLM(false);                                                             
                                }                           
                            }                       
                        })
                        .catch(function(error) {                        // catch
                            //console.log('Request failed', error);

                            count2 = count2 + 1;

                            if (count2  == numberOfLAs){

                                //if theres other las that use this data succesfully
                                if (allLAfeaturesForDSet.length != 0){
                                    //console.log("erorz");
                                    dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                                } else {
                                    //add this attr to a list to not include
                                    //console.log(propPre.overlayLabel);
                                    let tempatr = atr;
                                    tempatr.push(propPre.overlayLabel);
                                    setatr(tempatr);
                                }
                                
                                count = count + 1; 
                                
                                if (count == priorities.length){
                                    setIsLoading(false);  
                                    setLM(false);                                                             
                                }                           
                            }  
                        });
                        
                    })
                }
            
            } else if (filteredOrAll == "whiteList") {
                let unsplitLAs = splitz2[2];
                let splitLAs = unsplitLAs.split("$");
                ////console.log("split");
                ////console.log(splitLAs);
                let allLAfeaturesForDSet = [];

                //////console.log(splitLAs);
    
                let count2 = 0;

                let dFetchPathPrefix = apiLink + apiPartialPath + geoScope + "/";
                //if dset is only for one LA, 
                
                splitLAs.forEach(la => {
                    //for each LA load that into allLAfeaturesForDset
                    let valid = false;
                    validatedLAs.forEach(lA => {
                        if (lA == la){
                            valid = true;
                        }
                    })
                    
                    if (valid){

                    
                        let dFetchPath = dFetchPathPrefix + la + "/" + dsetToLoad;
                        if (dsetToLoad == "EvChargers"){
                            
                            dFetchPath = "https://cleanstreetserver.herokuapp.com/v1/EvChargers/" +la; 
                        }
                        //////console.log("url");
                        //////console.log(dFetchPath);

                        fetch(
                            `${dFetchPath}`, {cache: "no-store"}
                        )
                        .then((response) => {
                            //console.log("////////////////////////////////////////////////")
                            //console.log(response.ok);

                            if(response.ok)
                            {
                                return response.json();        
                            }
                            missed = missed + 1;
                            throw new Error('Something went wrong.');
                        })
                        .then((data) => {
                            if (dsetToLoad == "EvChargers"){
                                //////////////console.log(la + " " + data.length);
                                let tempEvCounter = evCounter;
                                tempEvCounter.push([la, data.length]);
                                setEvCounter(tempEvCounter);
                            } 
                            // var te = l2;
                            // te.push(x);
                            // sl2(te);
                            //////////////////console.log(data);
                            allLAfeaturesForDSet.push(data);
                            count2 = count2 + 1;
                            //////////////////////console.log("Count2: " + count2 + " /Out of " +numberOfLAs);
                            if (count2  == splitLAs.length){
                                dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                                //dataWithAtt.push([allLAfeaturesForDSet, attrToLoad, propPre]);
                                //////////////////////////////////////////////////////////////////<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                                //alreadyLoadedDatasets[dsetToLoad] = [allLAfeaturesForDSet, attrToLoad, propPre];

                                count = count + 1; 

                                
                                //////////////////////console.log("Count: " + dataWithAtt.length + " /Out of " +priorities.length);
                                
                                if (count == priorities.length){
                                    // ////////////////////console.log("all Loaded");
                                    // ////////////////////console.log(dataWithAtt.length);
                                    // ////////////////////console.log(dataWithAtt[0]);
                                    //////////console.log(dataWithAtt);
                                    setIsLoading(false);                                                               
                                }                           
                            }                       
                        })
                        .catch(function(error) {                        // catch
                            //console.log('Request failed', error);
                            count2 = count2 + 1;
                            //////////////////////console.log("Count2: " + count2 + " /Out of " +numberOfLAs);
                            if (count2  == numberOfLAs){
                                if (allLAfeaturesForDSet.length !=0 ){
                                    //console.log("erorz");
                                    dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                                }  else {
                                    //add this attr to a list to not include
                                    //console.log(propPre.overlayLabel);
                                    let tempatr = atr;
                                    tempatr.push(propPre.overlayLabel);
                                    setatr(tempatr);
                                }
                                
    
                                count = count + 1; 
                                //////////////////////console.log("Count: " + dataWithAtt.length + " /Out of " +priorities.length);
                                
                                if (count == priorities.length){
                                    // ////////////////////console.log("all Loaded");
                                    // ////////////////////console.log(dataWithAtt.length);
                                    // ////////////////////console.log(dataWithAtt[0]);
                                    //////////console.log(dataWithAtt);
                                    setIsLoading(false);  
                                    setLM(false);                                                             
                                }                           
                            }  
                        });
                    }
                    else {
                        
                        count2 = count2 + 1;
                        if (count2  == splitLAs.length){
                            if (allLAfeaturesForDSet.length !=0 ){
                                //console.log("erorz");
                                dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                            } else {
                                //console.log(propPre.overlayLabel);
                                let tempatr = atr;
                                tempatr.push(propPre.overlayLabel);
                                setatr(tempatr);
                            }
                            //just changed dataWithAtt[x-skip] = [allLAfeaturesForDSet, attrToLoad, propPre];
                            count = count + 1; 

                            if (count == priorities.length){
                                setIsLoading(false);   
                                setLM(false);                                                                  
                            }  
                        }
                    }

                })
                    
            } else if (filteredOrAll == "blackList") {

            } else {
                //////console.log("error");
            }   

             
        }
        
        console.log("goodStuff");
        console.log(dataWithAtt);
        console.log(dataWithAtt2);
        
        setcalledDatasets(dataWithAtt);
        setGenralData(dataWithAtt2);
        

    }, [priorities]);
    //change on priorities
    // ////////////////////console.log(calledDatasets);

    useEffect(() => {
        
        if (isLoading != true){
            if (validatedLAs.length == 1){
                //one LA
                let url = validatedLAs[0];
                var laCOORDS = [];
                laCords.some(laa => {
                    if(laa.lad17nm == url){
                       ////////////////////console.log([laa.lat, laa.long]);
                        laCOORDS = [laa.lat, laa.long];
                    }
                })
                // if ( validatedLAs[0] == "Bristol, City of"){
                //     url = "Bristol";
                // } else
                // if ( validatedLAs[0] == "Dundee City"){
                //     url = "Dundee";
                // }  else
                // if ( validatedLAs[0] == "County Durham"){
                //     url = "Durham";
                // }  
                // fetch(`https://nominatim.openstreetmap.org/?format=json&city=${url}`)
                // .then((response) => {
                //     return response.json();
                // })
                // .then(res => {  
                //     //////////////////////console.log(res);             
                //     // const all_addresses = res.data;
                //     //var results = res.data;
                    
                   
                // }); 
                mp.flyTo([laCOORDS[0], laCOORDS[1]], 11);
                setMapVars([laCOORDS[0], laCOORDS[1], 11]);
                function getColour(d) {
                    return validatedLAs[0] == d ? '#F2f2f2' :
                    '#e7e6ef' ;
                }
                
                function getFillOpacity(d){
                    //////////////////////console.log(d);
                    return validatedLAs[0] == d ? 0.05 :
                                            0.75 ;
                }
    
                const style = (feature => {
                    return ({
                        fillColor: getColour(feature.properties.LAD13NM),
                        weight: 1.5,
                        opacity: 1,
                        fillOpacity: getFillOpacity(feature.properties.LAD13NM),
                        color: '#a3a1b2',
                        
                    });
                });
    
                const feature = features.map(feature=>{
                    return(feature);
                });
                
                var nGeo = L.geoJson(feature, {style: style}).addTo(mp);
            } else {
                //longlat
                var laCOORDS = [];
                validatedLAs.forEach(la => {
                    laCords.some(laa => {
                        if(laa.lad17nm == la){
                           ////////////////////console.log([laa.lat, laa.long]);
                            laCOORDS.push([laa.lat, laa.long]);
                        }
                    })
                })
                var len = laCOORDS.length;
               ////////////////////console.log(len);
                var avrLong = 0;
                var avrLat = 0;;
                laCOORDS.forEach(coord=>{
                    avrLong = avrLong +coord[0];
                    avrLat = avrLat +coord[1];
                })
                avrLong = avrLong/len;
                avrLat = avrLat/len;
                var sdLongs = [];
                var sdLats = [];

                laCOORDS.forEach(coord=>{
                    var sdLong = coord[0] - avrLong;
                    var sdLat = coord[1] - avrLat;
                    sdLongs.push((sdLong*sdLong));
                    sdLats.push((sdLat*sdLat));
                })

                var avrSDLONG = 0;
                sdLongs.forEach(sdlon=>{
                    avrSDLONG = avrSDLONG+sdlon;
                })
                avrSDLONG = avrSDLONG/len;
                
                var avrSDLat = 0;
                sdLats.forEach(sdlat=>{
                    avrSDLat = avrSDLat+sdlat;
                })
                avrSDLat = avrSDLat/len;

                var sdLong = Math.sqrt(avrSDLONG);
                var sdLat = Math.sqrt(avrSDLat);
                
               ////////////////////console.log(sdLongs);
               //////////////console.log("set");
               ////////////////////console.log([sdLong, sdLat]);
                var scale = ((avrSDLONG + avrSDLat)/2)*3;
               //////////////console.log([avrLong, avrLat]);
               //////////////console.log(scale);
                mp.flyTo([avrLong,avrLat], (10-scale));
                setMapVars([avrLong,avrLat,(10-scale)]);
                //mp4.flyTo([avrLong,avrLat], (10-scale));


                //multipleLAs
                function getColour(d) {
                    var toRe = '#e7e6ef';
                    validatedLAs.forEach(selLA => {
                        //////////////////////console.log(selLA);
                        //////////////////////console.log("d:"+d);
                        //if (selLA != "null"){
                            if(selLA == d){
                                //////////////////////console.log("highlight");
                                toRe = '#F2f2f2';
                            }
                        //}
                    })
                    return toRe;
                }
    
                function getFillOpacity(d){
                    var toRe = 0.75;
                    validatedLAs.forEach(selLA => {
                        //if (selLA != "null"){
                            if(selLA == d){
                                toRe = 0.05;
                            }
                        //}
                    })
                    return toRe ;
                }
    
                const stylee = (feature => {
                    return ({
                        fillColor: getColour(feature.properties.LAD13NM),
                        weight: 1.5,
                        opacity: 1,
                        fillOpacity: getFillOpacity(feature.properties.LAD13NM),
                        color: '#a3a1b2',
                        
                    });
                });
    
                const feature = features.map(feature=>{
                    return(feature);
                });
    
                var nGeo = L.geoJson(feature, {style: stylee}).addTo(mp);
            }
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // let pluginOptions = {
            //     cropImageByInnerWH: true,
            //     hidden: false, // hide screen icon
                
            //     // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
            //     // and screenshot not created
            //     onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
            //         // Solutions:
            //         // decrease size of map
            //         // or decrease zoom level
            //         // or remove elements with big distanses
            //         // and after that return image in Promise - plugin._getPixelDataOfNormalMap
            //         return plugin._getPixelDataOfNormalMap(domtoimageOptions)
            //     }
            //  }
            // var screenShot = new L.simpleMapScreenshoter(pluginOptions);
            // setScreenObj(screenShot);
            // screenShot.addTo(mp);
            //screenShot.takeScreen();
           
            info.addTo(mp);
            info.setClass(false);
            info.update();

        }
    }, [isLoading]);
    const clearRef = useRef();
    const sataliteRef = useRef();

    if (isLoading) {
        return (
          
          <section>
            <p>Loading...</p>
          </section>
          
        );
    } else {
        //console.log(calledDatasets);
        //console.log(atr);
        setatr(atr);
        setMyState(atr);
        let newAr = [];
        
        var newC = 0;
        calledDatasets.forEach(toTest => {
           // //console.log(toTest);
            if (toTest.length == 0){
                //dataWithAtt.splice(newC, 1);
                
            }else {
               // //console.log(toTest);
                newAr.push(toTest);
            }
            newC = newC + 1;
        })
       // //console.log(newAr);
        
        const indexed = newAr.map((item, id) => Object.assign(item, {id}));
        
        return (
            
       
                <LayerGroup>
                     
                    
                    {indexed.map(dataWithAtt => ( 
                        <MiddleLayer key = {dataWithAtt.id} data = {dataWithAtt[0]} attr = {dataWithAtt[1]} propPre = {dataWithAtt[2]} mp = {mp} info={info}/>
                    ))} 
                    <FeatureGroup name = {"Clear"} ref={(element) => {
                        var bool = false;
                        var layerRefsTemp = layerRefs;
                        layerRefs.forEach(ref =>{
                            if (ref[0] == "clear"){
                                bool = true;
                            }
                        })
                        if (bool == false){
                            layerRefsTemp.push(["clear", element,"poly"]);
                            ////////////////////console.log(layerRefsTemp);
                            setLayerRefs(layerRefsTemp);
                        }
                    }}>                     
                        <GeoJSON ref={clearRef}>

                        </GeoJSON>
                    </FeatureGroup>
                    <LayersControl.BaseLayer  name={"satalite"} >
                        <FeatureGroup name = {"satalite"} ref={(element) => {
                            var bool = false;
                            var layerRefsTemp = layerRefs;
                            layerRefs.forEach(ref =>{
                                if (ref[0] == "satalite"){
                                    bool = true;
                                }
                            })
                            if (bool == false){
                                layerRefsTemp.push(["satalite", element,"base"]);
                                ////////////////////console.log(layerRefsTemp);
                                setLayerRefs(layerRefsTemp);
                            }
                        }}>
                            
                            <TileLayer
                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </FeatureGroup>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer  name={"transport"} >
                        <FeatureGroup name = {"transport"} ref={(element) => {
                            var bool = false;
                            var layerRefsTemp = layerRefs;
                            layerRefs.forEach(ref =>{
                                if (ref[0] == "transport"){
                                    bool = true;
                                }
                            })
                            if (bool == false){
                                layerRefsTemp.push(["transport", element,"base"]);
                                ////////////////////console.log(layerRefsTemp);
                                setLayerRefs(layerRefsTemp);
                            }
                        }}>
                            
                            <TileLayer
                                url='https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png'
                                attribution=  'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'       

    

                            />
                        </FeatureGroup>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer  name={"normal"} >
                        <FeatureGroup name = {"normal"} ref={(element) => {
                            var bool = false;
                            var layerRefsTemp = layerRefs;
                            layerRefs.forEach(ref =>{
                                if (ref[0] == "normal"){
                                    bool = true;
                                }
                            })
                            if (bool == false){
                                layerRefsTemp.push(["normal", element,"base"]);
                                ////////////////////console.log(layerRefsTemp);
                                setLayerRefs(layerRefsTemp);
                            }
                        }}>
                            
                            <TileLayer
                                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                            />
                        </FeatureGroup>
                    </LayersControl.BaseLayer>
                    <NewMapDraw data = {newAr} tabDisabled={tabDisabled} setTabDisabled={setTabDisabled}
                                    tabHighlighted={tabHighlighted} setTabHighlight={setTabHighlight} 
                                    latty={latty} setLatty={setLatty} longy={longy} setLongy={setLongy} mp4 = {mp4} mp3 = {mp3} mp2 = {mp2} mp={mp} 
                                    cBool={cBool} setCBool={setCBool} load={load} setLoad={setLoad} evs={evs}/>
                </LayerGroup>
              
        );
    }
}

export default MapDataLoad;