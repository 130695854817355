import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './MainNavigation.module.css';
import logo from '../../logo.png';
import Hamburger from 'hamburger-react';
import logo_blue from '../../logo_blue.png';

function MainNavigation() {

  const [isOpen, setOpen] = useState(false);

  return (
    <div>
  {/*navlink*/}
  <div style={{ position: 'relative' }}>
  <div className={classes.topnav} id="myTopnav" >

  <div>
  <a href = 'https://www.placekits.com/'>
  <img src={logo} alt="Logo"  className={classes.whiteLogo} />
  <img src={logo_blue} alt="Logo" className={classes.blueLogo} />
  </a>
  </div>


  <div className={classes.icon}>
    {/*<div className={classes.container} id="hamburgerContainer" onclick="hamburgerAnimation(this)">
    <div className={classes.bar1}></div>
    <div className={classes.bar2}></div>
    <div className={classes.bar3}></div>
  </div>*/}

  <span className={classes.whiteLogo} ><Hamburger color="white" toggled={isOpen} toggle={setOpen} size={20} duration={0.4} easing="ease" /> </span>
  <span className={classes.blueHam} ><Hamburger color="#291351" toggled={isOpen} toggle={setOpen} size={20} duration={0.4} easing="ease" /> </span>
  <span className={classes.vp650} ><Hamburger color="#291351" toggled={isOpen} toggle={setOpen} size={15} duration={0.4} easing="ease" /> </span>
  </div>
  </div>

{/* mob nav */}
<div className={isOpen ? classes.myNavOverlayHeight : classes.myNavOverlay} id="myMobNav">
<ul>
  <li onClick={()=>setOpen(false)}><a href="/#tools">Tools</a></li>
  <li><a href="https://urbanforesight.org/about/">About</a></li>
  <li><a href="https://urbanforesight.org/">Urban Foresight</a></li>
  <li><a href="/AdminHome">Admin</a></li>
</ul>
</div>
</div>
{/* mob nav ends */}
{/*navlink ends*/}
</div>


);
}

export default MainNavigation;
