import React, {useState, useEffect, useContext} from 'react';
import {BrowserRouter, useHistory} from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import '../../pages/main.css';
import {LAStateContext, LAsConfirmedContext} from '../../Store';



function ConditionalLAInput ({itemsList, laInputC, setLaInputC}){
    const [LAs, setLAs] = useContext(LAStateContext);
    const [confrimed, setConfrimed] = useState(false);
    const [selectedLAsCount, setSelectedLAsCount] = useState(0);
    
    const [laIn1, setLaIn1] = useState(false);
    const [laIn2, setLaIn2] = useState(false);
    const [laIn3, setLaIn3] = useState(false);
    const [laIn4, setLaIn4] = useState(false);
    const [keyy, setKey] = useState(0);
    const [items, setItems] = useState(itemsList);
    const [indexC, setIndexC] = useState(400);
    const [NOL, setNOL] = useState(0);
    var numberOfLAs = LAs.length;

    var LALimit = 4;

    //var items = items;

    const history = useHistory();

    useEffect(() => {
        var numberOfLAs = LAs.length;
        setNOL(numberOfLAs);
        setSelectedLAsCount(0);
        setItems(items);
        setLaInputC(numberOfLAs);
    }, []);

    useEffect(() => {
       
    }, [LAs]);

    const handleHistory = () => {
        //set a context for laC
        //set how many laCs have been set 

        history.push("/priorities");
    }


    //tempToTest
    var projects = [
        {
            projectID: "#3909",
            projectLA: 'York'
        },
        {
            projectID: "#9999",
            projectLA: 'Leeds'
        }
    ]

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }
    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        // the item selected
        ////////////////console.log("laInputC" + laInputC);
  

        var lasTemp = LAs;
        if (lasTemp[laInputC] == 'undefined'){
            lasTemp.push("");
        }
        lasTemp[laInputC] = item.name;
        //setLAs(lasTemp);

        //setLAs(LAs => [...LAs, item.name]);
        

        
        setLaInputC(laInputC + 1);
        setKey(keyy +1); 
        setConfrimed(true);
        

        var itemsTemp = items;
        var c = 0;
        var index2Remove=0;
        itemsTemp.forEach(i =>{
            if(i.name == item.name){
                index2Remove = c;
            }
            c= c+1;
        })
        ////////////////console.log(index2Remove);
        itemsTemp.splice(index2Remove, 1);
        ////////////////console.log(itemsTemp);
        setItems(itemsTemp);
    }

    function handleDelete (index){
        var itemsTemp = items;
        itemsTemp.push({name:LAs[index], id:indexC});
        setItems(itemsTemp);
        setIndexC(indexC+1);
        setKey(keyy +1); 


        var lasTemp = LAs;
        lasTemp.splice(index, 1);
        //setLAs(lasTemp);
        

        


        if(laInputC-1 < 1){
            setConfrimed(false);
        }
        
        setLaInputC(laInputC - 1);
        
    }

    const handleOnFocus = () => {
    }
    const formatResult = (item) => {
        return item;
    }

    
    return(
        <div className='searchInputContainer'>
        
            <div >
                


                <div className='searchInputHalf' style={{ width:'100%', height:'10px', position:'relative' }}>
                    <div className='LaInputContainer'>
                        <ReactSearchAutocomplete
                            items={items}
                            key={keyy}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            //autoFocus
                            disabled={true}
                            formatResult={formatResult}
                            placeholder={'Local authority name'}
                            styling={{
                                height: "49px",
                                border: "1px solid #291351",
                                borderRadius: "0px",
                                backgroundColor: "white",
                                boxShadow: "rgba(32, 33, 36, 0) 0px 1px 6px 0px",
                                hoverBackgroundColor: "#eee",
                                color: "#291351",
                                fontSize: "16px",
                                fontFamily: "Favorit",
                                iconColor: "grey",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: '3px 14px 0 0',
                                searchIconMargin: '0 0 0 16px',
                                zIndex: '3',
                                display: 'inline-block',
                                width: "20px",
                            }}
                            />
                    </div>

                    {/* {<div type={"button "} className='generalButton' style={{ display:"inline-block", marginLeft:"6px"}} onClick={increaseCLIStage}><i className={"fa fa-plus"}></i></div>} */}
                    
                       

                    {/*<div className={"box2"}>*/}

                    {/*<div className={"container-2"}>
                            <button type={"button "} id={"newLa"} onClick={increaseCLIStage}><span style={{marginRight: "20px"}}><i className={"fa fa-plus"}></i></span> Add LA...</button>
                        </div>*/}

                   

                    
                    {/*</div>*/}
                </div>

            

                {/*laOutpput*/}
                <div className='searchInputHalf' style={{ width:'48%', height:'100px', marginTop:'13px' }}>    
                        {LAs[0+NOL] != null ? 
                            <p style={{ width:'100%', textAlign:'right', display: "inline-block" }}> <span style={{ float:'left' }}><h6 className="sub-title sub-sub-title"> {LAs[0+NOL]}</h6> </span> <span onClick={(e) => { handleDelete(0+NOL) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[1+NOL] != null ? 
                            <p style={{ width:'100%', textAlign:'right', display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[1+NOL]} </h6> </span> <span onClick={(e) => { handleDelete(1+NOL) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[2+NOL] != null ? 
                            <p style={{ width:'100%', textAlign:'right' , display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[2+NOL]} </h6> </span> <span onClick={(e) => { handleDelete(2+NOL) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[3+NOL] != null ? 
                            <p style={{ width:'100%', textAlign:'right' , display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[3+NOL]} </h6> </span> <span onClick={(e) => { handleDelete(3+NOL) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                </div>


            </div>

        </div>
    )
   
    
}
export default ConditionalLAInput;