import React, { useContext, useState, useEffect, useRef } from 'react'
import { BrowserRouter, useHistory } from 'react-router-dom';
import { MapContainer, Marker, MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup, useMapEvent, GeoJSON } from 'react-leaflet';
import ReactStars from "react-rating-stars-component";
import * as L from 'leaflet'
import * as turf from '@turf/turf'
import 'leaflet/dist/leaflet.css';

import header from '../homepage/header.png';
import logo from '../logo.png';
import logo_blue from '../logo_blue.png';

import Layout from '../components/layout/Layout';

import { isSubStateC } from '../Store';
import { laCords } from "../components/mapdetails/LACoords.json";
import { features } from "../components/mapdetails/GBLayer.json";

import { ExportToCsv } from 'export-to-csv';

import { parse } from 'json2csv';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";

const APIkey = "AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

function ProjectMap() {
    //const [, ] = useContext();
    //const [, ] = useState();

    const [projectFile, setProjectFile] = useState([]);
    const [projectSub, setProjectSub] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});
    const [mp, setMap] = useState(null);
    const [refReady, setRefReady] = useState(false);
    const [reffReady, setReffReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updateMe2, setUpdateMe2] = useState(0);
    const [indexedMarkers, setIndexedMarkers] = useState([]);
    const [urlForProj, setURLFP] = useState("");
    const [ratings, setRatings] = useState([]);
    const [ratings2, setRatings2] = useState([]);
    const [ratings3, setRatings3] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [lastCoords, setLastCoords] = useState([]);
    const [newComment, setNewComment] = useState("");

    const [commentMatrix, setCM] = useState([]);
    const history = useHistory();
    const refRed = useRef(null);
    let popupRef = useRef();

    L.Icon.Default.imagePath = '/';
    const iconEV = new L.Icon({
        iconUrl: "Pin3.png",
        iconRetinaUrl: "Pin3.png",
        shadowUrl: '',

        iconSize: new L.Point(40, 40),
    });

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };



    const getAuth = async (event) => {
        console.log("trig");
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        let urrl = "https://cleanstreetserver.herokuapp.com/auth/" + uname.value + "/" + pass.value;


        let list = await fetch(urrl, {cache: "no-store"});
        list = await list.json();

        list = list.valid;
        if (list == "true") {
            setIsSubmitted(true);
            setUpdateMe2(updateMe2 + 1);
        } else {
            alert("Invalid auth");
        }
    }
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    useEffect(() => {

        if (isSubmitted == true) {

        }
    }, [projectSub]);

    useEffect(() => {
        if (reffReady == true) {
            refRed.current.scrollIntoView();
        }
    }, [reffReady]);

    const uploadPinsToProject = async (url, json) => {
        // console.log("jsonB4");
        // console.log(json);
        const newJson = JSON.stringify(json);
        // console.log("jsonSent");
        // console.log(newJson);

        // console.log(url);

        return fetch(url, {
            method: "PUT",
            body: newJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };

    const testForm = async (event) => {

        event.preventDefault();
        var { namee } = document.forms[1];

        if (namee.value == "") {
            alert("Please enter a project number");
        } else {
            try {
                let tURL = "https://clean-street-json-store.s3.eu-west-2.amazonaws.com/" + namee.value;
                let res = await fetch(tURL, {cache: "no-store"});
                res = await res.json();

                let tURL2 = "https://urban-foresight-project-store.s3.eu-west-2.amazonaws.com/" + namee.value;
                let resy = await fetch(tURL2, {cache: "no-store"});
                resy = await resy.json();

                let tURL3 = "https://cleanstreetserver.herokuapp.com/v1/s3URL/" + namee.value;
                let resyy = await fetch(tURL3, {cache: "no-store"});
                resyy = await resyy.json();


                var validatedLAs = resy.Config.relatedLAs.map(element => {
                    //var stringed = element.replace(/\s/g, '%20');
                    return element;
                });


                console.log("validPNLoad");
                console.log(res);
                console.log(validatedLAs);
                console.log(resyy.uploadURL);
                loadProjectFile(res, validatedLAs, resyy.uploadURL);
            } catch {
                alert("Please enter a valid project number");
            }

        }
    }


    function loadProjectFile(projFile, validatedLAs, projUrl) {
        console.log("lol");
        var indexedMarkerss = projFile.map((item, id) => Object.assign(item, { id }));
        console.log(indexedMarkerss);

        let assignedRankings = [];
        let assignedRankings2 = [];
        let assignedRankings3 = [];

        let emptyComments = [];
        let c = 0;
        projFile.forEach(pin => {
            assignedRankings.push([parseInt(pin.avgScore), parseInt(pin.avgScore), parseInt(pin.ratingCounter), parseInt(pin.ratingCounter)]);
            assignedRankings2.push([parseInt(pin.avgScore2), parseInt(pin.avgScore2), parseInt(pin.ratingCounter2), parseInt(pin.ratingCounter2)]);
            assignedRankings3.push([parseInt(pin.avgScore3), parseInt(pin.avgScore3), parseInt(pin.ratingCounter3), parseInt(pin.ratingCounter3)]);

            emptyComments.push([]);
            let v = 0;

            pin.comment.forEach(comm => {
                if (v == 0) {
                    emptyComments[c].push(true);
                } else {
                    emptyComments[c].push(false);
                }
                v = v + 1;
            })

            c = c + 1;
        })




        if (validatedLAs.length == 1) {
            //one LA
            let url = validatedLAs[0];
            var laCOORDS = [];
            laCords.some(laa => {
                if (laa.lad17nm == url) {
                    laCOORDS = [laa.lat, laa.long];
                }
            })
            mp.flyTo([laCOORDS[0], laCOORDS[1]], 11);
            function getColour(d) {
                return validatedLAs[0] == d ? '#F2f2f2' :
                    '#e7e6ef';
            }
            function getFillOpacity(d) {
                return validatedLAs[0] == d ? 0.05 :
                    0.75;
            }
            const style = (feature => {
                return ({
                    fillColor: getColour(feature.properties.LAD13NM),
                    weight: 1.5,
                    opacity: 1,
                    fillOpacity: getFillOpacity(feature.properties.LAD13NM),
                    color: '#a3a1b2',

                });
            });
            const feature = features.map(feature => {
                return (feature);
            });
            var nGeo = L.geoJson(feature, { style: style }).addTo(mp);
        } else {
            //longlat
            var laCOORDS = [];
            validatedLAs.forEach(la => {
                laCords.some(laa => {
                    if (laa.lad17nm == la) {
                        laCOORDS.push([laa.lat, laa.long]);
                    }
                })
            })
            var len = laCOORDS.length;
            var avrLong = 0;
            var avrLat = 0;;
            laCOORDS.forEach(coord => {
                avrLong = avrLong + coord[0];
                avrLat = avrLat + coord[1];
            })
            avrLong = avrLong / len;
            avrLat = avrLat / len;
            var sdLongs = [];
            var sdLats = [];
            laCOORDS.forEach(coord => {
                var sdLong = coord[0] - avrLong;
                var sdLat = coord[1] - avrLat;
                sdLongs.push((sdLong * sdLong));
                sdLats.push((sdLat * sdLat));
            })
            var avrSDLONG = 0;
            sdLongs.forEach(sdlon => {
                avrSDLONG = avrSDLONG + sdlon;
            })
            avrSDLONG = avrSDLONG / len;

            var avrSDLat = 0;
            sdLats.forEach(sdlat => {
                avrSDLat = avrSDLat + sdlat;
            })
            avrSDLat = avrSDLat / len;

            var sdLong = Math.sqrt(avrSDLONG);
            var sdLat = Math.sqrt(avrSDLat);
            var scale = ((avrSDLONG + avrSDLat) / 2) * 3;

            mp.flyTo([avrLong, avrLat], (10 - scale));
            //multipleLAs
            function getColour(d) {
                var toRe = '#e7e6ef';
                validatedLAs.forEach(selLA => {
                    if (selLA == d) {
                        toRe = '#F2f2f2';
                    }
                })
                return toRe;
            }

            function getFillOpacity(d) {
                var toRe = 0.75;
                validatedLAs.forEach(selLA => {
                    //if (selLA != "null"){
                    if (selLA == d) {
                        toRe = 0.05;
                    }
                    //}
                })
                return toRe;
            }

            const stylee = (feature => {
                return ({
                    fillColor: getColour(feature.properties.LAD13NM),
                    weight: 1.5,
                    opacity: 1,
                    fillOpacity: getFillOpacity(feature.properties.LAD13NM),
                    color: '#a3a1b2',

                });
            });

            const feature = features.map(feature => {
                return (feature);
            });

            var nGeo = L.geoJson(feature, { style: stylee }).addTo(mp);
        }
        setCM(emptyComments);
        console.log(emptyComments);
        setProjectFile(projFile);
        console.log("projfile");
        console.log(projFile);
        setRatings(assignedRankings);
        setRatings2(assignedRankings2);
        setRatings3(assignedRankings3);
        console.log(assignedRankings);
        console.log(assignedRankings2);
        console.log(assignedRankings3);
        setIndexedMarkers(indexedMarkerss);
        setUpdateMe2(updateMe2 + 1);
        setProjectSub(true);
        setURLFP(projUrl);


    }

    function downloadTxt() {

        let proj = projectFile;
        let csv = parse(proj);
        console.log(csv);
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Stations',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(projectFile);



    }

    function updateStartRating(newR, coords1, coords0) {
        let tempProjFile = projectFile;
        let newTempProjFile = [];

        let oldRate = ratings;
        let newRate = [];

        let c = 0;
        tempProjFile.forEach(pin => {
            if (pin.coords[1] == coords1 && pin.coords[0] == coords0) {
                console.log(pin.ratingCounter);
                console.log(parseInt(ratings[c][3]));

                if (pin.ratingCounter == parseInt(ratings[c][3])) {
                    let newAvg = ((parseInt(pin.avgScore) * parseInt(pin.ratingCounter)) + newR) / (parseInt(pin.ratingCounter) + 1);
                    let newInt = parseInt(pin.ratingCounter) + 1;
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": newAvg,
                            "avgScore2": parseInt(pin.avgScore2),
                            "avgScore3": parseInt(pin.avgScore3),
                            "ratingCounter": newInt,
                            "ratingCounter2": parseInt(pin.ratingCounter2),
                            "ratingCounter3": parseInt(pin.ratingCounter3),
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, (parseInt(oldRate[c][2]) + 1), parseInt(oldRate[c][3])])
                } else {
                    //undo
                    let newAvg = ((parseInt(ratings[c][0]) * parseInt(ratings[c][2])) + newR) / (parseInt(ratings[c][2]) + 1);
                    let newInt = parseInt(pin.ratingCounter);
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": newAvg,
                            "avgScore2": parseInt(pin.avgScore2),
                            "avgScore3": parseInt(pin.avgScore3),
                            "ratingCounter": newInt,
                            "ratingCounter2": parseInt(pin.ratingCounter2),
                            "ratingCounter3": parseInt(pin.ratingCounter3),
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
                }



            } else {
                newTempProjFile.push(pin);
                newRate.push([oldRate[c][0], oldRate[c][1], parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
            }

            c = c + 1;
        })

        console.log(newTempProjFile);
        setProjectFile(newTempProjFile);
        uploadPinsToProject(urlForProj, newTempProjFile);
        setRatings(newRate);
    }

    function updateStartRating2(newR, coords1, coords0) {
        let tempProjFile = projectFile;
        let newTempProjFile = [];

        let oldRate = ratings2;
        let newRate = [];

        let c = 0;
        tempProjFile.forEach(pin => {
            if (pin.coords[1] == coords1 && pin.coords[0] == coords0) {
                console.log(pin.ratingCounter2);
                console.log(parseInt(ratings2[c][3]));

                if (pin.ratingCounter2 == parseInt(ratings2[c][3])) {
                    let newAvg = ((parseInt(pin.avgScore2) * parseInt(pin.ratingCounter2)) + newR) / (parseInt(pin.ratingCounter2) + 1);
                    let newInt = parseInt(pin.ratingCounter2) + 1;
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": parseInt(pin.avgScore),
                            "avgScore2": newAvg,
                            "avgScore3": parseInt(pin.avgScore3),
                            "ratingCounter": parseInt(pin.ratingCounter),
                            "ratingCounter2": newInt,
                            "ratingCounter3": parseInt(pin.ratingCounter3),
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, (parseInt(oldRate[c][2]) + 1), parseInt(oldRate[c][3])])
                } else {
                    //undo
                    let newAvg = ((parseInt(ratings2[c][0]) * parseInt(ratings2[c][2])) + newR) / (parseInt(ratings2[c][2]) + 1);
                    let newInt = parseInt(pin.ratingCounter2);
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": parseInt(pin.avgScore),
                            "avgScore2": newAvg,
                            "avgScore3": parseInt(pin.avgScore3),
                            "ratingCounter": parseInt(pin.ratingCounter),
                            "ratingCounter2": newInt,
                            "ratingCounter3": parseInt(pin.ratingCounter3),
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
                }



            } else {
                newTempProjFile.push(pin);
                newRate.push([oldRate[c][0], oldRate[c][1], parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
            }

            c = c + 1;
        })

        console.log(newTempProjFile);
        setProjectFile(newTempProjFile);
        uploadPinsToProject(urlForProj, newTempProjFile);
        setRatings2(newRate);
    }

    function updateStartRating3(newR, coords1, coords0) {
        let tempProjFile = projectFile;
        let newTempProjFile = [];

        let oldRate = ratings3;
        let newRate = [];

        let c = 0;
        tempProjFile.forEach(pin => {
            if (pin.coords[1] == coords1 && pin.coords[0] == coords0) {
                console.log(pin.ratingCounter3);
                console.log(parseInt(ratings3[c][3]));

                if (pin.ratingCounter3 == parseInt(ratings3[c][3])) {
                    let newAvg = ((parseInt(pin.avgScore3) * parseInt(pin.ratingCounter3)) + newR) / (parseInt(pin.ratingCounter3) + 1);
                    let newInt = parseInt(pin.ratingCounter3) + 1;
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": parseInt(pin.avgScore),
                            "avgScore2": parseInt(pin.avgScore2),
                            "avgScore3": newAvg,
                            "ratingCounter": parseInt(pin.ratingCounter),
                            "ratingCounter2": parseInt(pin.ratingCounter2),
                            "ratingCounter3": newInt,
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, (parseInt(oldRate[c][2]) + 1), parseInt(oldRate[c][3])])
                } else {
                    //undo
                    let newAvg = ((parseInt(ratings3[c][0]) * parseInt(ratings3[c][2])) + newR) / (parseInt(ratings3[c][2]) + 1);
                    let newInt = parseInt(pin.ratingCounter3);
                    newTempProjFile.push(
                        {
                            "streetName": pin.streetName,
                            "laName": pin.laName,
                            "coords": [pin.coords[0], pin.coords[1]],
                            "avgScore": parseInt(pin.avgScore),
                            "avgScore2": parseInt(pin.avgScore2),
                            "avgScore3": newAvg,
                            "ratingCounter": parseInt(pin.ratingCounter),
                            "ratingCounter2": parseInt(pin.ratingCounter2),
                            "ratingCounter3": newInt,
                            "nameOfPlacer": pin.nameOfPlacer,
                            "orgOfPlacer": pin.orgOfPlacer,
                            "emailOfPlacer": pin.emailOfPlacer,
                            "dateOfPlacement": pin.dateOfPlacement,
                            "comment": pin.comment

                        },
                    );
                    newRate.push([oldRate[c][0], newR, parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
                }



            } else {
                newTempProjFile.push(pin);
                newRate.push([oldRate[c][0], oldRate[c][1], parseInt(oldRate[c][2]), parseInt(oldRate[c][3])])
            }

            c = c + 1;
        })

        console.log(newTempProjFile);
        setProjectFile(newTempProjFile);
        uploadPinsToProject(urlForProj, newTempProjFile);
        setRatings3(newRate);
    }

    function deletePin(coords1, coords0) {


        ////////////////////////////////////////
        let tempProjFile = projectFile;

        let newTempProjFile = [];

        let oldRate = ratings;
        let oldRate2 = ratings2;
        let oldRate3 = ratings3;
        console.log(oldRate);
        let newRate = [];
        let newRate2 = [];
        let newRate3 = [];

        let c = 0;
        tempProjFile.forEach(pin => {
            if (pin.coords[1] == coords1 && pin.coords[0] == coords0) {
                //nothing
            } else {
                newTempProjFile.push(pin);
                newRate.push([oldRate[c][0], oldRate[c][1], parseInt(oldRate[c][2]), parseInt(oldRate[c][3])]);
                newRate2.push([oldRate2[c][0], oldRate2[c][1], parseInt(oldRate2[c][2]), parseInt(oldRate2[c][3])])
                newRate3.push([oldRate3[c][0], oldRate3[c][1], parseInt(oldRate3[c][2]), parseInt(oldRate3[c][3])]);
            }

            c = c + 1;
        })

        console.log(newTempProjFile);
        setProjectFile(newTempProjFile);
        uploadPinsToProject(urlForProj, newTempProjFile);
        setRatings(newRate);
        setRatings2(newRate2);
        setRatings3(newRate3);

        var indexedMarkerss = newTempProjFile.map((item, id) => Object.assign(item, { id }));
        setIndexedMarkers(indexedMarkerss);
    }

    const modalRef = useRef(null);

    function modalOpen2(coords1, coords0) {
        modalRef.current.classList.add("modalVisible");
        setModalOpen(true);
        setLastCoords([coords1, coords0]);
    }

    function modalClose() {
        modalRef.current.classList.remove("modalVisible");
        setModalOpen(false);
    }

    function uploadComment() {
        console.log("trig");
        let tempProjFile = projectFile;
        let newTempProjFile = [];

        let oldCommentI = commentMatrix;
        let newCommentI = [];

        let c = 0;
        tempProjFile.forEach(pin => {
            newCommentI.push([]);

            if (pin.coords[1] == lastCoords[0] && pin.coords[0] == lastCoords[1]) {
                //nothing
                let oldComments = [];
                pin.comment.forEach(comm => {
                    oldComments.push(comm);
                })

                oldComments.push(newComment);

                console.log(oldComments);

                oldCommentI[c].forEach(comm => {
                    newCommentI[c].push(comm);
                })

                if (oldCommentI[c].length == 0){
                    newCommentI[c].push(true);
                } else {
                    newCommentI[c].push(false);
                }
                

                newTempProjFile.push(
                    {
                        "streetName": pin.streetName,
                        "laName": pin.laName,
                        "coords": [pin.coords[0], pin.coords[1]],
                        "avgScore": parseInt(pin.avgScore),
                        "avgScore2": parseInt(pin.avgScore2),
                        "avgScore3": parseInt(pin.avgScore3),
                        "ratingCounter": parseInt(pin.ratingCounter),
                        "ratingCounter2": parseInt(pin.ratingCounter2),
                        "ratingCounter3":  parseInt(pin.ratingCounter3),
                        "comment": oldComments,
                        "nameOfPlacer": pin.nameOfPlacer,
                        "orgOfPlacer": pin.orgOfPlacer,
                        "emailOfPlacer": pin.emailOfPlacer,
                        "dateOfPlacement": pin.dateOfPlacement

                    },
                );
            } else {
                newTempProjFile.push(pin);

                oldCommentI[c].forEach(comm => {
                    newCommentI[c].push(comm);
                })
            }




            c = c + 1;
        })
        console.log(newCommentI);
        setCM(newCommentI);

        setProjectFile(newTempProjFile);
        uploadPinsToProject(urlForProj, newTempProjFile);

        var indexedMarkerss = newTempProjFile.map((item, id) => Object.assign(item, { id }));
        setIndexedMarkers(indexedMarkerss);
        modalClose();
    }

    function handleRender(id, dir) {
        console.log("render");
        let c = 0;

        var newMatrix = [];
        commentMatrix.forEach(pin => {
            newMatrix.push([]);

            if (c == id) {
                let v = 0;
                let findLastShow = 0;
                commentMatrix[id].forEach(com => {
                    if (com == true) {
                        findLastShow = v;
                    }
                    v = v + 1;
                })

                //
                console.log("findLastShow");
                console.log(findLastShow);
                if (dir == ">") {

                    if (commentMatrix[id][findLastShow + 1] != undefined) {
                        pin.forEach(com => {
                            newMatrix[c].push(false);
                        })
                        newMatrix[c][findLastShow + 1] = true;
                    } else {
                        pin.forEach(com => {
                            newMatrix[c].push(false);
                        })
                        newMatrix[c][0] = true;
                    }


                }
                if (dir == "<") {
                    if (commentMatrix[id][findLastShow - 1] != undefined) {
                        pin.forEach(com => {
                            newMatrix[c].push(false);
                        })
                        newMatrix[c][findLastShow - 1] = true;
                    } else {
                        pin.forEach(com => {
                            newMatrix[c].push(false);
                        })
                        let i = newMatrix[c].length - 1
                        newMatrix[c][i] = true;
                    }

                }
            } else {
                pin.forEach(com => {
                    newMatrix[c].push(com);
                })
            }



            c = c + 1;
        })
        console.log(newMatrix);
        setCM(newMatrix);
    }
    //main render

    console.log(indexedMarkers);
    var c = 0;
    return (
        <Layout>
            <div className='hidezFull'>
                {updateMe2}
            </div>

            <div ref={modalRef} style={{ height: '38vh', position: 'fixed', top: '40vh', left: '0', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: '10000', display: 'none', justifyContent: 'center', alignItems: 'center' }} className={modalOpen ? 'modalVisible' : ''}>
                <div style={{ marginLeft: '50px', backgroundColor: 'rgba(255, 255, 255, 1)', width: '80%', height: '85%', position: 'relative', boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', padding: '40px', marginBottom: "68px" }}>
                    <div style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }} onClick={() => { modalClose() }}>
                        <i class="fa fa-close" style={{ fontSize: '20px', color: '#ddd' }}></i>
                    </div>
                    <div>
                        <h1 style={{ fontSize: "30px", marginTop: "0px", marginBottom: "20px" }} className="main-title">Add a comment</h1>
                        <input type="textarea"
                            onChange={(e) => { setNewComment(e.target.value) }}
                            style={{ width: "100%", height: "10vh" }}
                        >
                        </input>
                        <button
                            onClick={() => {
                                uploadComment();
                            }}
                            className={'generalButton'}
                            style={{ width: "20%", borderRadius: "10px 10px 10px 10px", float: "right", marginTop: "20px" }}
                        > Add Comment
                        </button>

                    </div>
                </div>
            </div>



            <div className={isSubmitted == false ? "viz" : "hidezFull"} >
                <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center" }}>
                    <h1 style={{ fontSize: "30px" }} className="main-title">Admin login</h1>
                </div>

                <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px" }}>
                    <div className="form" style={{ display: "flex", justifyContent: "center" }}>
                        <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 40px", border: "2px solid #291351" }} onSubmit={getAuth} >
                            <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                <label>Username: </label>
                                <input style={{ float: "right" }} type="text" name="uname" required />
                                {renderErrorMessage("uname")}
                            </div>
                            <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                <label>Password: </label>
                                <input style={{ float: "right" }} type="password" name="pass" required />
                                {renderErrorMessage("pass")}
                            </div>
                            <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                                <input type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <div className={isSubmitted == true ? "viz" : "hidez"} >
                <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center" }}>
                    <h1 style={{ fontSize: "30px" }} className="main-title" ref={refRed}>Review Chargepoints</h1>
                </div>

                <header className={projectSub == false? "viz desktopHeader" : "hidezFull desktopHeader"}  style={{ backgroundImage: `url(${header})` }}>
                    <div style={{ position: 'relative' }}>
                        <div className='headerHeadingDiv'>
                            <h1 className='headerHeading2' style={{ fontSize: "94px" }}>
                                Charge Point Site Rater
                            </h1>
                            <br />
                            <p style={{ fontSize: "22px" }}>
                                Review and rate chargepoints placed for a specific project.
                            </p>
                        </div>

                    </div>
                </header>



                <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "26px", fontSize: "18px" }}>
                    <div className={'elementBelowLoading'} id='tools'>
                        <p className={projectSub == false? "viz freightText" : "hidezFull freightText"} style={{ fontSize: "20px", margin: "0px 0px 60px 0px" }}>

                            Welcome to our digital tool for reviewing EV chargepoint locations.
                            <br /><br />
                            This tool only works if you have be provided a project number.
                            <br /><br />
                            After chargepoints have been placed under a project number using our site selector tool they can be reviewed here.
                            <br /><br />
                            Potential sites can be rated on 3 metrics and comments can be added.
                            <br /><br />
                            Any questions about the tool or requests to extend it to other territories should be sent to: hello@urbanforesight.org
                        </p>
                        <div className={projectSub == false && isSubmitted == true ? "viz" : "hidezFull"}>
                            <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 40px", border: "2px solid #291351" }} onSubmit={testForm} >
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px", fontFamily: 'FavoritStd_Regular' }}>
                                    <label>Enter your project number: </label>
                                    <input style={{ float: "right" }} type="text" name="namee" required />
                                    {renderErrorMessage("namee")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                                    <input type="submit" value="Access" />
                                </div>
                            </form>
                        </div>
                        <div className={projectSub == true ? "viz" : "hidez"}>
                            <MapContainer
                                center={[54.059388, -3.793332]}
                                zoom={6.5}
                                scrollWheelZoom={true}
                                style={{ height: "calc(75vh - 2px)", transform: "translate(0.1%, 0.1%)" }}
                                whenCreated={(m) => {
                                    setMap(m);
                                    setReffReady(true);
                                }}
                                doubleClickZoom={false}
                            >)
                                <TileLayer
                                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                                />
                                <LayerGroup>
                                    <FeatureGroup>
                                        {/* <Marker
                                            key={111111}
                                            position={[53.9614, -1.0739]}
                                            icon={iconEV}
                                        ></Marker> */}
                                        {indexedMarkers.map(pin => (

                                            <Marker
                                                key={pin.id}
                                                position={[pin.coords[1], pin.coords[0]]}
                                                icon={iconEV}
                                            >
                                                <Popup className={"pops"} autoPan={true} closeButton={false} ref={(r) => {
                                                    popupRef = r;
                                                    setRefReady(true);
                                                }}>
                                                    <div style={{ marginBottom: '16px' }}>
                                                        <div style={{ margin: "auto" }}>
                                                            <div style={{ marginBottom: '16px', display: "flex", justifyContent: "space-between", width: "100%" }}>

                                                                <div style={{ right: "0px", position: "relative" }}>
                                                                    <span className={"favorit"} style={{ fontSize: "20px", color: '#291351', maxWidth: "50%" }}>{pin.streetName}



                                                                    </span>
                                                                </div>


                                                                <br></br>
                                                            </div>
                                                            <LoadScriptNext key={"AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY"} googleMapsApiKey={APIkey}>
                                                                <GoogleMap
                                                                    key={"AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY"}
                                                                    visible={false}
                                                                    style={{ height: "200px", width: "600px" }}
                                                                >
                                                                    <div style={{ height: "200px", width: "600px" }}>
                                                                        <StreetViewPanorama
                                                                            position={{
                                                                                lat: pin.coords[1],
                                                                                lng: pin.coords[0],
                                                                            }}
                                                                            options={{
                                                                                disableDefaultUI: true,
                                                                                enableCloseButton: false,
                                                                                addressControl: false,
                                                                                clickToGo: false,
                                                                                visible: true,
                                                                                radius: "15000"
                                                                            }}
                                                                            radius={1500}
                                                                        />
                                                                    </div>
                                                                </GoogleMap>
                                                            </LoadScriptNext>
                                                        </div>

                                                        <div style={{ marginTop: "1vh", display: "flex", justifyContent: "space-between" }}>
                                                            <span className={"freightText"} style={{ fontSize: "18px", color: '#291351', float: "left" }}>Placed by: {pin.nameOfPlacer} </span>
                                                            <span className={"freightText"} style={{ fontSize: "18px", color: '#291351', float: "right" }}>{pin.dateOfPlacement} </span>

                                                        </div>

                                                        <div style={{ marginTop: "1vh", display: "flex", alignItems: "center" }}>
                                                            <label style={{ color: '#291351', fontSize: "18px" }}>Strategic importance</label>
                                                            <div style={{ right: "19px", position: "absolute", display: "flex", alignItems: "center" }}>
                                                                <label style={{ "marginRight": "5px", "marginTop": "3px", color: '#291351' }}>({ratings[pin.id][2]})</label>
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={(e) => {
                                                                        updateStartRating(e, pin.coords[1], pin.coords[0])
                                                                    }}
                                                                    size={24}
                                                                    isHalf={true}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    color="grey"
                                                                    activeColor="#FF9900"
                                                                    value={ratings[pin.id][1]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div style={{ marginTop: "0.5vh", display: "flex", alignItems: "center" }}>
                                                            <label style={{ color: '#291351', fontSize: "18px" }}>Accessibility</label>
                                                            <div style={{ right: "19px", position: "absolute", display: "flex", alignItems: "center" }}>
                                                                <label style={{ "marginRight": "5px", "marginTop": "3px" }}>({ratings2[pin.id][2]})</label>
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={(e) => {
                                                                        updateStartRating2(e, pin.coords[1], pin.coords[0])
                                                                    }}
                                                                    size={24}
                                                                    isHalf={true}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    color="grey"
                                                                    activeColor="#FF9900"
                                                                    value={ratings2[pin.id][1]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div style={{ marginTop: "0.5vh", display: "flex", alignItems: "center" }}>
                                                            <label style={{ color: '#291351', fontSize: "18px" }}>Local amenities</label>
                                                            <div style={{ right: "19px", position: "absolute", display: "flex", alignItems: "center" }}>
                                                                <label style={{ "marginRight": "5px", "marginTop": "3px" }}>({ratings3[pin.id][2]})</label>
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={(e) => {
                                                                        updateStartRating3(e, pin.coords[1], pin.coords[0])
                                                                    }}
                                                                    size={24}
                                                                    isHalf={true}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    color="grey"
                                                                    activeColor="#FF9900"
                                                                    value={ratings3[pin.id][1]}
                                                                />
                                                            </div>
                                                        </div>





                                                    </div>
                                                    {
                                                        pin.comment[0] != undefined ?
                                                            <div style={{ border: "solid #291351", marginTop: "0.5vh", marginBottm: "-1px", borderWidth: "1px 1px 0px 1px", borderRadius: "10px 10px 0px 0px", display: "flex", justifyContent: "center" }}>

                                                                {pin.comment.length != 1 ?
                                                                    <div style={{ fontSize: "30px", display: "flex", alignItems: "center", width: "10%" }}>
                                                                        <i class="fa fa-angle-left"
                                                                            style={{ display: "block", margin: "auto", cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                handleRender(pin.id, "<");
                                                                            }}></i>
                                                                    </div>
                                                                    : null}

                                                                <div style={{ margin: "10px 5px 10px 5px", width: "80%" }}>

                                                                    <div style={{ marginTop: "0.5vh" }}>
                                                                        {pin.comment.map((com, id2) => {
                                                                            // console.log(com);
                                                                            return (
                                                                                <span className={commentMatrix[pin.id][id2] == true ? "favorit viz" : "favorit hidezFull"} style={{ margin: "auto", fontSize: "18px", color: '#291351' }}>{com} </span>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                </div>

                                                                {pin.comment.length != 1 ?
                                                                    <div style={{ fontSize: "30px", display: "flex", alignItems: "center", width: "10%" }}>
                                                                        <i class="fa fa-angle-right"
                                                                            style={{ display: "block", margin: "auto", cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                handleRender(pin.id, ">");
                                                                            }}></i>
                                                                    </div>
                                                                    : null}


                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        pin.comment[0] != undefined ?
                                                            <div style={{ marginTop: "0vh", display: "flex", justifyContent: "space-between" }}>

                                                                <button
                                                                    onClick={() => {
                                                                        deletePin(pin.coords[1], pin.coords[0]);
                                                                    }}
                                                                    className={'generalButton'}
                                                                    style={{ maxWidth: "50%", flexGrow: 1, borderRadius: "0px 0px 0px 10px" }}
                                                                > Remove pin
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        modalOpen2(pin.coords[1], pin.coords[0]);
                                                                    }}
                                                                    className={'generalButton'}
                                                                    style={{ width: "50%", borderRadius: "0px 0px 10px 0px" }}
                                                                > Add Comment
                                                                </button>
                                                            </div>
                                                            :
                                                            <div style={{ marginTop: "0vh", display: "flex", justifyContent: "space-between" }}>

                                                                <button
                                                                    onClick={() => {
                                                                        deletePin(pin.coords[1], pin.coords[0]);
                                                                    }}
                                                                    className={'generalButton'}
                                                                    style={{ maxWidth: "50%", flexGrow: 1, borderRadius: "10px 0px 0px 10px" }}
                                                                > Remove pin
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        modalOpen2(pin.coords[1], pin.coords[0]);
                                                                    }}
                                                                    className={'generalButton'}
                                                                    style={{ width: "50%", borderRadius: "0px 10px 10px 0px" }}
                                                                > Add Comment
                                                                </button>
                                                            </div>
                                                    }

                                                </Popup>


                                            </Marker>
                                        ))}






                                    </FeatureGroup>
                                </LayerGroup>
                            </MapContainer>
                        </div>
                    </div>
                </div>
                <div  className={projectSub == true? "viz" : "hidezFull"} style={{ paddingLeft: "19%", margin: "20px 0px 0px 0px" }}>
                    <button
                        onClick={() => { downloadTxt() }}
                    >
                        Download Project as CSV
                    </button>
                </div>
            </div>

        </Layout>
    )



}


export default ProjectMap