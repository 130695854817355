import React, {useState, useEffect, useContext, useRef} from 'react';
import {BrowserRouter, useHistory} from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import '../../pages/main.css';
import {PNLAStateContext, LAsConfirmedContext, PNC, projectNumberC} from '../../Store';



function ConditionalLAInputPN ({itemsList, laInputC, setLaInputC}){
    const [LAs, setLAs] = useContext(PNLAStateContext);
    const [PN, setPN] = useState(PNC);
    const [projectNumber, setProjectNumber] = useContext(projectNumberC);


    const [confrimed, setConfrimed] = useState(false);
    const [selectedLAsCount, setSelectedLAsCount] = useState(0);  
    const [laIn1, setLaIn1] = useState(false);
    const [laIn2, setLaIn2] = useState(false);
    const [laIn3, setLaIn3] = useState(false);
    const [laIn4, setLaIn4] = useState(false);
    const [keyy, setKey] = useState(0);
    const [items, setItems] = useState(itemsList);
    const [indexC, setIndexC] = useState(400);
    const [selectedPN, setSelectedPN] = useState(0);
    const [tempString, setTempString] = useState(null);
    
    //either find and set LAs or do that on prioities
    
    const [conModalOpen, setConModalOpen] = useState(false);


    function confirationModalOpen(){
        conModalRef.current.classList.add("modalVisible");
        setConModalOpen(true);
    }

    function confirationModalOpenClose(){
        conModalRef.current.classList.remove("modalVisible");
        setConModalOpen(false);
        setPN(null);
        
    }

    var LALimit = 4;

    //var items = items;

    const history = useHistory();
    const conModalRef= useRef(null);

    useEffect(() => {

        setSelectedLAsCount(0);
        setItems(items);
    }, []);

    useEffect(() => {
       
    }, [LAs]);

    const handleHistory = () => {
        //set a context for laC
        //set how many laCs have been set 

        history.push("/priorities");
    }

    const handleHistory2 = () => {

        history.push("/priorities");
        
    }

    async function fetchProjectConfig(url){ 
        let res;
        try {
            res = await fetch("https://urban-foresight-project-data.s3.eu-west-2.amazonaws.com/projectNumber"+url+".txt", {cache: "no-store"});
        } 
        catch {
            alert("Please enter a Local Authority by typing the clicking, or enter a project number.")
        }
       
        try {
            setPN(url);
            setProjectNumber(url);
            res = await res.json();
            //console.log(res);   
            confirationModalOpen();       
    
        } catch {
          //console.log("PN failed to load")
          alert("Please enter a Local Authority by typing the clicking, or enter a project number.")
        }  
    }
   

    const handleOnSearch = (string, results) => {
        //console.log("setString" + string);
        setTempString(string);
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }
    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        // the item selected
        ////////////////console.log("laInputC" + laInputC);
        //console.log(item.name);
        let isNumber = false;
        let pn = 0;

        try {
            var int = parseInt(item.name)
            isNumber = true;
            
        } catch {

        }
        

        //console.log("hm");
  

        if (isNaN(int) !== true){
            let x = item.name;
            //console.log("pn detected");
            //console.log(x);
            confirationModalOpen();
            setSelectedPN(item.name);
            setPN(item.name);
            setProjectNumber(x);
        } else{
            var lasTemp = LAs;
            if (lasTemp[laInputC] == 'undefined'){
                lasTemp.push("");
            }
            lasTemp[laInputC] = item.name;
            setLAs(lasTemp);
    
            //setLAs(LAs => [...LAs, item.name]);
            
    
            
            setLaInputC(laInputC + 1);
            setKey(keyy +1); 
            setConfrimed(true);
            
    
            var itemsTemp = items;
            var c = 0;
            var index2Remove=0;
            itemsTemp.forEach(i =>{
                if(i.name == item.name){
                    index2Remove = c;
                }
                c= c+1;
            })
            ////////////////console.log(index2Remove);
            itemsTemp.splice(index2Remove, 1);
            ////////////////console.log(itemsTemp);
            setItems(itemsTemp);
        }        
    }

    function handleDelete (index){
        var itemsTemp = items;
        itemsTemp.push({name:LAs[index], id:indexC});
        setItems(itemsTemp);
        setIndexC(indexC+1);
        setKey(keyy +1); 


        var lasTemp = LAs;
        lasTemp.splice(index, 1);
        setLAs(lasTemp);
        

        


        if(laInputC-1 < 1){
            setConfrimed(false);
        }
        
        setLaInputC(laInputC - 1);
        
    }

    const handleOnFocus = () => {
    }
    const formatResult = (item) => {
        return item;
    }

    
    return(
        <div className='searchInputContainer'>
        
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                
                <div className='searchInputHalf' style={{ width:'48%', height:'100px', position:'relative' }}>
                    <div className='LaInputContainer'>
                        <ReactSearchAutocomplete
                            items={items}
                            key={keyy}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                            disabled={true}
                            formatResult={formatResult}
                            placeholder={'Enter UK local authority name'}
                            styling={{
                                height: "49px",
                                border: "1px solid #291351",
                                borderRadius: "0px",
                                backgroundColor: "white",
                                boxShadow: "rgba(32, 33, 36, 0) 0px 1px 6px 0px",
                                hoverBackgroundColor: "#eee",
                                color: "#291351",
                                fontSize: "16px",
                                fontFamily: "Favorit",
                                iconColor: "grey",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: '3px 14px 0 0',
                                searchIconMargin: '0 0 0 16px',
                                zIndex: '3',
                                display: 'inline-block',
                                width: "20px",
                            }}
                            />
                    </div>

                </div>

            

                {/*laOutpput*/}
                <div className='searchInputHalf' style={{ width:'48%', height:'100px', marginTop:'13px' }}>    
                        {LAs[0] != null ? 
                            <p style={{ width:'100%', textAlign:'right', display: "inline-block" }}> <span style={{ float:'left' }}><h6 className="sub-title sub-sub-title"> {LAs[0]}</h6> </span> <span onClick={(e) => { handleDelete(0) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[1] != null ? 
                            <p style={{ width:'100%', textAlign:'right', display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[1]} </h6> </span> <span onClick={(e) => { handleDelete(1) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[2] != null ? 
                            <p style={{ width:'100%', textAlign:'right' , display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[2]} </h6> </span> <span onClick={(e) => { handleDelete(2) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                        {LAs[3] != null ? 
                            <p style={{ width:'100%', textAlign:'right' , display: "inline-block" }}> <span style={{ float:'left' }}> <h6 className="sub-title sub-sub-title">{LAs[3]} </h6> </span> <span onClick={(e) => { handleDelete(3) }}><i className="fa fa-close" style={{color: '#db0052'}}></i></span></p> :
                            null
                        }
                </div>

                <div ref={conModalRef} style={{height:'100vh', position:'fixed', top:'0', left:'0', width:'100%', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex:'10000', display:'none', justifyContent:'center', alignItems:'center'}} className={conModalOpen?'modalVisible':''}>
                     <div style={{   marginLeft:'50px', backgroundColor:'rgba(255, 255, 255, 1)', width:'40%', height:'18%', position:'relative', boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', padding:'40px', marginBottom: "68px", paddingTop: "16px", paddingBottom: "25vh"}}>
                         <div style={{ position:'absolute', top:'15px', right:'15px', cursor:'pointer'}} onClick={() => {confirationModalOpenClose()}}>
                             <i class="fa fa-close" style={{fontSize:'20px', color:'#ddd'}}></i>
                         </div>
                         <div >
                             <h5 style={{paddingBottom: "30vh", textAlign: "center"}} className="sub-title">Are you sure you want to use the {tempString} project number?</h5>
                            
                             <div style={{ position:'absolute', bottom:'15px', right:'20.2vw', cursor:'pointer', width:'42%'}}>
                                 <button className="generalButton" onClick={() => {confirationModalOpenClose()}}>Return</button> 
                             </div>

                             <div style={{ position:'absolute', bottom:'15px', left:'20.2vw', cursor:'pointer', width:'42%'}}>
                                 <button className="generalButton" onClick={() => {handleHistory2()}}>Continue</button> 
                             </div>
                            
                         </div>
                     </div>
                 </div>


            </div>

        </div>
    )
   
    
}
export default ConditionalLAInputPN;