import React , {useContext, useState, useEffect} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import L, { layerGroup } from 'leaflet';

import {selectedPositionContext, LAStateContext} from '../../Store';
import {features} from "./GBLayer.json";

import AllLayers from './AllLayers';
import NewMapDraw from './NewMapDraw';
import Layout from '../layout/Layout';

import {laCords} from "./LACoords.json";






function Map2Handler({map2, laInputC, setLaInputC}) {
    
    const [LAs, setLAs] = useContext(LAStateContext);
    const [sP, setSP] = useContext(selectedPositionContext); 

    const [isLoading, setIsLoading] = useState(true);
    const [geo, setGeo] = useState(null);

    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });

    useEffect(() => {
       
        
        setIsLoading(false);
    }, []);

    useEffect(() => {
       
        
        ////////////////console.log("sda");
    }, [laInputC]);


    useEffect(() => {
        ////////////////console.log("here");

        if (isLoading != true){
            

            var chosenLA = "";
            validatedLAs.forEach(la => {
                chosenLA = la; 
            })
            if(validatedLAs.length > 0){
                let laCOORDS = [];
                laCords.some(laa => {
                    if(laa.lad17nm == chosenLA){
                       //////////////////console.log([laa.lat, laa.long]);
                        laCOORDS = [laa.lat, laa.long];
                    }
                })
            
                // //one LA
                // let url = chosenLA;
                // if ( chosenLA == "Bristol, City of"){
                //     url = "Bristol";
                // } else if ( chosenLA == "Dundee City"){
                //     url = "Dundee";
                // } else if ( chosenLA == "County Durham"){
                //     url = "Durham";
                // }  

                // fetch(`https://nominatim.openstreetmap.org/?format=json&city=${url}`)
                // .then((response) => {
                //     return response.json();
                // })
                // .then(res => {  
                    ////////////////////console.log(res);             
                    // const all_addresses = res.data;
                    //var results = res.data;
                    
                    map2.flyTo([laCOORDS[0], laCOORDS[1]], 10.5);
                    
                    function getColour(d) {
                        return chosenLA == d ? '#F2f2f2' :
                        '#e7e6ef' ;
                    }
                    
                    function getFillOpacity(d){
                        ////////////////////console.log(d);
                        return chosenLA == d ? 0.05 :
                                                0.65 ;
                    }
        
                    const style = (feature => {
                        return ({
                            fillColor: getColour(feature.properties.LAD13NM),
                            weight: 1.5,
                            opacity: 1,
                            fillOpacity: getFillOpacity(feature.properties.LAD13NM),
                            color: '#a3a1b2',
                            
                        });
                    });
        
                    const feature = features.map(feature=>{
                        return(feature);
                    });
                    if (geo != null){
                        ////////////////console.log("remove");
                        map2.removeLayer(geo);
                    }
                    var newGeo = L.geoJson(feature, {style: style}).addTo(map2);
                    
                    setGeo(newGeo);
                    
                // }); 
            } else {
                if (geo != null){
                    ////////////////console.log("remove");
                    map2.removeLayer(geo);
                }
             
                map2.flyTo([54.559388, -3.793332], 5.5);
            }
             
        }
    }, [laInputC]);

     
    if (isLoading) {
        return (
          
            <section>
              <p>Loading...</p>
            </section>
          
        );
    }
    return (
        <>
            <p hidden>{laInputC}</p>
        </>
            
            
            
        
    )
}

export default Map2Handler;

