import React, {useState, useEffect, useContext, useRef} from 'react';
import { MapContainer, MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, GeoJSON} from 'react-leaflet';
import * as L from 'leaflet';
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';


import {screenObjC2} from '../../Store';


function ScreenHandler2( {mp3}){
    const [screenObj2, setScreenObj2] = useContext(screenObjC2);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(false);
    }, []); 

    useEffect(() => {
        if (isLoading != true){
            let pluginOptions = {
                cropImageByInnerWH: true,
                hidden: false, // hide screen icon
                
                // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
                // and screenshot not created
                onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
                    // Solutions:
                    // decrease size of map
                    // or decrease zoom level
                    // or remove elements with big distanses
                    // and after that return image in Promise - plugin._getPixelDataOfNormalMap
                    return plugin._getPixelDataOfNormalMap(domtoimageOptions)
                }
            }
            var screenShot = new L.simpleMapScreenshoter(pluginOptions);
            setScreenObj2(screenShot);
            screenShot.addTo(mp3);
        }
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        //screenShot.takeScreen();
    }, [isLoading]); 
    

    if (isLoading) {
        return (
            <>
            </>  
        );  
    } else {
        return (
            <>
            </>  
        );
    }   
    
    
}

export default ScreenHandler2;