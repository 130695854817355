import React, {useState, useEffect, useContext} from 'react';
import {priorityStateContext, datasetConfigStringsContext,   layerRefsContext,  factorsContext, legendsContext} from '../../Store';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';




function SataliteBaseLayerSelector({mp, setMap, clearButtonRef, setClearButtonRef}){
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [datasetConfigStrings, setDatasetConfigStrings] = useContext(datasetConfigStringsContext);
    const [factors, setFactors] = useContext(factorsContext); 
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [legends, setLegends] = useContext(legendsContext);

    const [POI, setPOI] = useState([]);
    
    const [counter1, setCounter1] = useState(105);
    const [counter2, setCounter2] = useState(150);

    var c = 705;
    var c = 750;
    
    useEffect(() => {
        
    }, []);

    function handleCheckbox (event){
       ////////////////console.log(event);

        var keys = Object.keys(legends);
        

        layerRefs.forEach(layer => {
            if(layer[0] == event.target.value){
                if (event.target.checked == true){
                    layer[1].addTo(mp);

                    keys.forEach(key => {
                        if(key == event.target.value){
                            legends[key][0].addTo(mp);
                        }
                    }) 

                } else {
                    mp.removeLayer(layer[1]);
                    mp.removeControl(layer[1]);
                    keys.forEach(key => {
                        if(key == event.target.value){
                            mp.removeControl(legends[key][0]);
                        }  
                    })
                }
            }
        })   
    }
   
    
    function handleDSetSelector (event){
        //console.log(event.target.attributes.value.value);
        if(event.target.attributes.value.value == "satalite" || event.target.attributes.value.value == "normal"){
            layerRefs.forEach(layer => {
                if (layer[2] == "base"){
                    mp.removeLayer(layer[1]);
                    mp.removeControl(layer[1]);
                }  
            })
            layerRefs.forEach(layer => {
               ////////////////console.log(layer[0] + "//" + event.target.value);
                if(layer[0] == event.target.attributes.value.value){

                    layer[1].addTo(mp);
                }
                
            })
        } else {
            var keys = Object.keys(legends);
            keys.forEach(key => {
                mp.removeControl(legends[key][0]);
            })
            
           ////////////////console.log(layerRefs);
            layerRefs.forEach(layer => {
                mp.removeLayer(layer[1]);
                mp.removeControl(layer[1]);  
            })
            layerRefs.forEach(layer => {
               ////////////////console.log(layer[0] + "//" + event.target.value);
                if(layer[0] == event.target.attributes.value.value){

                    layer[1].addTo(mp);
                }
                
            })
            keys.forEach(key => {
                if(key == event.target.attributes.value.value){
                    legends[key][0].addTo(mp);
                }
            })
        }        
    }

    return(
        <div  key ={701} style={{position: "relative", width: "100%", height: "100%"}}>
            <div key ={702} style={{position: "relative", width: "100%", height: "100%"}}>
                    <div key ={c} onChange={handleDSetSelector} style={{padding:'10px 0px', color:'#291351', fontSize:'18px', position: "relative", height :"33%", cursor: "pointer"}}>
                        <img onClick={handleDSetSelector} value="normal" src="/normal.png" style={{ backgroundColor:'grey', width:'100%', height:"90%", border: '1px solid #291351', objectFit: "cover" }} />
                        <label className="radioBContainer favorit" style={{paddingLeft: "0px", height: "10%"}}> 
                                <input type="radio" value="normal" name="overlaySelectedVar" />Normal
                        </label>
                    </div>

                    <div key ={c+1} onChange={handleDSetSelector} style={{padding:'10px 0px', color:'#291351', fontSize:'18px', position: "relative", height :"33%", cursor: "pointer"}}>
                        <img onClick={handleDSetSelector}  value="satalite" src="/satellite.png" style={{ backgroundColor:'grey', width:'100%', height:"90%", border: '1px solid #291351', objectFit: "cover" }} />
                        <label className="radioBContainer favorit" style={{paddingLeft: "0px", height: "10%"}}>
                            <div>
                                <input type="radio" value="satalite" name="overlaySelectedVar" />Satellite
                            </div>
                        </label>
                    </div>

                    <div key ={c+2} onChange={handleDSetSelector} style={{padding:'10px 0px', color:'#291351', fontSize:'18px', position: "relative", height :"33%", cursor: "pointer"}}>
                        <img onClick={handleDSetSelector} value="transport" src="/transport.png" style={{ backgroundColor:'grey', width:'100%', height:"90%", border: '1px solid #291351', objectFit: "cover" }} />
                        <label className="radioBContainer favorit" style={{paddingLeft: "0px", height: "10%"}}>
                            <div>
                                <input type="radio" value="transport" name="overlaySelectedVar" />Transport
                            </div>
                        </label>
                    </div>

                    {/* <div key ={c+2} onChange={handleDSetSelector} style={{padding:'10px 0px'}}><label className="radioBContainer favorit" ><input type="radio" value="clear" name="overlaySelectedVar" /> Clear Data<span className="checkmark"></span></label></div> */}

            </div>          
        </div>
    )
}

export default SataliteBaseLayerSelector
