import { feature } from '@turf/helpers';
import React, {useContext, useEffect, useState, useRef, forwardRef } from 'react';
import { Marker, Popup, LayersControl, FeatureGroup, GeoJSON } from 'react-leaflet';
import {legContext,priorityStateContext, typeofChargerStateContext, LAStateContext, legendsAddedContext,cSBSContext,layerRefsContext, legendsContext} from '../../Store';
import L from 'leaflet';
import math, { typeOf } from 'mathjs';
import { object } from 'prop-types';



function Layergeo(props) {
    var dataSrc = props.dataName;
    var data = props.mapdata;
    var proPre = props.proPre;
    var attr = props.attr;
    var geoScope = props.geoScope;
    var minMax = props.minMax;
    //////console.log(attr,dataSrc);
    //////console.log(minMax);
    var info = props.info;

    //an array of colours from darkest to lightest 
    var colorScaleValues = proPre.colourScaleValues;
    var units = proPre.units;

    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [chargerType, setchargerType] = useContext(typeofChargerStateContext);   
    const [LAs, setLAs] = useContext(LAStateContext);
    const [cSBS, setCSBS] = useContext(cSBSContext);
    const [legendsAdded, setLegendsAdded] = useContext(legendsAddedContext);
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [legends, setLegends] = useContext(legendsContext);
    const [leg, setLeg] = useContext(legContext);



    // const leafletObjects = useRef();

    // useEffect(() => {
    // leafletObjects.current = {
    //     map: L.map(ref.current),
    //     basemapLayers: {
    //     OSM: L.tileLayer(osmUrlTemplate),
    //     Aerial: L.tileLayer(aerialUrlTemplate)
    //     }
    // };
    // }, []);

    // useEffect(() => {
    //     leafletObjects.current.basemapLayers[selectedBasemap].addTo(leafletObjects.current.map);
    // }, [selectedBasemap]);


    var myFeatures = [];
    //var totalMinMax;

    if (geoScope == "GBLayer"){
        data.forEach(la => {
            myFeatures.push(la.feature[0]);
        })
    } else {
        data.forEach(la => {
            la.feature.forEach(msoalsoafeature =>{
                myFeatures.push(msoalsoafeature);
            })
        })
    }

    
    

    //////console.log(data);
    var key = Object.keys(minMax);
    ////////console.log(minMax[attr][0]);
    ////////console.log("minMax"+key);
    //////console.log("attr" + attr);

    var min = minMax[attr][0]; 
    var max = minMax[attr][1]; 

    var numberOfScaleSplits = 7;
    var perScaleLvl = (max - min) / numberOfScaleSplits;

    // ////////////////////console.log("min:" +min);
    // ////////////////////console.log("max:" +max);
    // ////////////////////console.log("psl:" +perScaleLvl);

    var maxLength = max.toString().length;
    if (maxLength == 1){
        max = 10;
    }

    var perScaleLvlLen = parseInt(perScaleLvl).toString().length;

    var scale = Math.pow(10, maxLength-1);
    //////////////////////console.log("Scale:" + scale);

    var perScaleScale = Math.pow(10, perScaleLvlLen-1);
    //////////////////////console.log("per scale scale:" +perScaleScale);

    min = Math.floor(min/scale)*scale;
    max = Math.ceil(max/scale)*scale;
    perScaleLvl =  Math.ceil(perScaleLvl/perScaleScale)*perScaleScale;

    // ////////////////////console.log("min end:" +min);
    // ////////////////////console.log("max end:" +max);
    // ////////////////////console.log("per scale end:" +perScaleLvl);
    

    //min = Math.round(min/1000)*1000;

    //  max = Math.round(max/1000)*1000;

    useEffect(() => {
        var legendsTemp = legends;

        var legeAdd = legendsAdded;
        var bool = false;
        legeAdd.forEach(legeAddChecker =>{
            if (attr == legeAddChecker){
                bool = true;
            }
        })
        var addLegend = true;
        if (bool == true){
            addLegend = false;
        } else {
            legeAdd.push(attr);
            setLegendsAdded(legeAdd);
        }
        if (attr == "AirQualManagementAreas"){
            addLegend = false;
        }
        if(addLegend){
            var geoLegend = L.control({position: "bottomright"});
            geoLegend.onAdd = function(map) {
                
                let classs = (leg == "fadein" ? 
                    "legend2 x1 leaflet-control" : leg == "fadeout" ? 
                     "legend2 x0 leaflet-control" : leg == "out" ?
                     "legend2 x2 leaflet-control" : "legend2 x0 leaflet-control" 
                     );

                var div = L.DomUtil.create("div", classs); 
                // div.innerHTML += "<h4 className='favorit'>" + proPre.overlayLabel + "</h4>";
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[7] + '"></i><div style="display:inline-block;" class="favorit">'+ min+' - '+ perScaleLvl +'</div></span><br>';  
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[6] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl)+' - '+(min + perScaleLvl*2)+'</div></span><br>';
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[5] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*2)+' - '+(min + perScaleLvl*3)+'</div></span><br>';  
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[4] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*3)+' - '+(min + perScaleLvl*4)+'</div></span><br>';                           
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[3] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*4)+' - '+(min + perScaleLvl*5)+'</div></span><br>';  
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[2] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*5)+' - '+(min + perScaleLvl*6)+'</div></span><br>';  
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[1] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*6)+' - '+(min + perScaleLvl*7)+'</div></span><br>';  
                div.innerHTML += '<span className="freightText"><i className="" style="background: ' + colorScaleValues[0] + '"></i><div style="display:inline-block;" class="favorit">'+(min + perScaleLvl*7)+'+</span><br>'; 

                

                return div;
            };

            
            
            geoLegend.setClass = function (props) {
                //////////////////console.log("setClass");
                let classs = (props == "fadein" ? 
                    "legend2 x1 leaflet-control" : props == "fadeout" ? 
                     "legend2 x0 leaflet-control" : props == "out" ?
                     "legend2 x2 leaflet-control" : "legend2 x0 leaflet-control" 
                     );

                //////////////////console.log(classs);
                
                
                var cont = geoLegend.getContainer()
                if (typeof cont != 'undefined'){
                    //////////////////console.log(geoLegend.getContainer());
                    cont.setAttribute("class", classs);
                }
                //cont.setAttribute("class", classs);
                //L.DomUtil.addClass(this.getContainer(),"x0")
                //  this.setStyle({"className": classs});
            }
            geoLegend.setClass("fadeout");
            setLeg("fadeout");
            legendsTemp[attr] = [geoLegend,"poly"];
            setLegends(legendsTemp);

            // props.mp.on("baselayerchange", function(e){
            //    ////////////////////console.log(e);
                
            //     if (e.name == proPre.overlayLabel){
            //         ////////////////////console.log("added: " + proPre.overlayLabel);
            //         geoLegend.addTo(props.mp);
            //     }
            //     else {
            //         //////////////////////console.log("removed:" + proPre.overlayLabel);
            //         props.mp.removeControl(geoLegend);
            //     }
                
            // })
        
            // props.mp.on("overlayremove", function(e){
            //     if (e.name == proPre.overlayLabel){
            //         ////////////////////console.log("removed:" + proPre.overlayLabel);
            //         props.mp.removeControl(geoLegend);
            //     }
            // })
        }
        
        
    }, []); // Only re-run the effect if count changes

    

    function getColour(d) {
        //////////////////////console.log(colorScaleValues[0])   ;
        return d == null ? "#596ffe" :
            d > (min + (perScaleLvl * 6))  ? colorScaleValues[0] :
                d > (min + (perScaleLvl * 5))   ? colorScaleValues[1] :
                d > (min + (perScaleLvl * 4))   ?colorScaleValues[2] :
                d > (min + (perScaleLvl * 3))  ? colorScaleValues[3] :
                d > (min + (perScaleLvl * 2))    ? colorScaleValues[4] :
                d > (min + (perScaleLvl))   ? colorScaleValues[5] :
                d > min + 1   ? colorScaleValues[6] :
                            colorScaleValues[7];
    }

    const style = (feature => {
        return ({
            fillColor: getColour(feature[attr]),
            weight: 1,
            opacity: 0.5,
            color: '#a3a1b2',
            // dashArray: '2',
            fillOpacity: 1,
            // transition: "2s"
            
        });
    });

    const geoJsonRef = useRef();
    // const featuresRef = useRef();
    // useEffect(() => {
    //     // leafletObjects.current.basemapLayers[selectedBasemap].addTo(leafletObjects.current.map);
    //     ////////////////////console.log(featuresRef.current);
    // }, [layerRefs]);


    const onEachFeat = (feature, layer) =>{        
 
        layer.on('mouseout', function (e) {
            
            geoJsonRef.current.resetStyle(e.target);
            props.mp.closePopup();
            

                

            var updateJson = {
                full: false,
                attType: proPre.overlayLabel,
            }

            info.update(updateJson);//layer.feature.properties
        });

        layer.on('mouseover', function (e) {
            if (cSBS == false){
                var layer = e.target;
                var updateJson = {
                    full: true,
                    attType: proPre.overlayLabel,
                    dataa: feature[attr],
                    units: units
                }
                info.update(updateJson);//layer.feature.properties
//                info.setClass(false);

                var layer = e.target;
                //layer.bindPopup(attr+": "+feature[attr]).openPopup();
                layer.setStyle({
                    weight: 2,
                    color: '#DB0052',
                    dashArray: '',
                    fillOpacity: 0,
                    // transition: "2s"
                });
            
                // if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                //     layer.bringToF();
                // }
            }
 
        });

        // layer.on('click', function (e) {
        //     if (cSBS == false){
        //         var layer = e.target;
        //         layer.bindPopup(proPre.overlayLabel+": "+feature[attr]).openPopup();
        //     }
        // });
    }

    return(
        <LayersControl.BaseLayer  name={proPre.overlayLabel} >
            <FeatureGroup name = {attr} ref={(element) => {
                    var bool = false;
                    var layerRefsTemp = layerRefs;
                    layerRefs.forEach(ref =>{
                        if (ref[0] == attr){
                            bool = true;
                        }
                    })
                    if (bool == false){
                        layerRefsTemp.push([attr, element,"poly"]);
                        ////////////////////console.log(layerRefsTemp);
                        setLayerRefs(layerRefsTemp);
                    }
                    
                    
                    // var newLayerz = [];
                    // layerRefsTemp.forEach(layer => {
                    //     var attrNew = layer[0];
                    //     var featureFromLayer = layer[1];
                    //     newLayerz.push([attrNew,featureFromLayer]);
                    // })
                    // setLayerz(newLayerz);
                    // ////////////////////console.log(newLayerz);
                }}>
                {myFeatures && (
                    <GeoJSON 
                        ref={geoJsonRef}
                        data={myFeatures} 
                        style={style} 
                        onEachFeature={onEachFeat}
                    >      
                    </GeoJSON>
                )}
            </FeatureGroup>
        </LayersControl.BaseLayer >
    );
}

export default Layergeo;