import React , {useContext, useState, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import Layout from '../layout/Layout';

import {data2ReturnContext, minMaxObjContext, rankingsContext} from '../../Store';


import {
    Chart,
    Interval,
    Tooltip,
    Line,
    Axis,
    Coordinate,
    Legend,
    View,
    Geom 
} from 'bizcharts';


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

const scale = {
    indivData:{
      type:"linear",
      min:0,
      max:5,
    },
  }


function SummaryReportCards({card, handleSRSUpdate, value, checked, ranks2skip, NR}) {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [slideVisibility, setslideVisibility] = useState(true);

    useEffect(() => {
        setData(card[0][12]);
        setLoading(false);
    }, []);

    function tog (){
        if(slideVisibility == true){
            setslideVisibility(false);
        } else {
            setslideVisibility(true);
        }
    }
    console.log(card);
    const currentUrl = (window.location.href).split('/');
    const currentPage = (currentUrl[currentUrl.length - 1])
    const comment = card[0][11];

    console.log("summaryReportCardUpdted");

    if (isLoading) {
        return (
          <Layout>
          <section>
            <p>Loading...</p>
          </section>
          </Layout>
        );
    } else {
        return(
            <div style={{  height:'auto ', overflowY:'scroll', marginBottom:'0', display: true == true ? 'block' : 'none', width: currentPage == 'printReport'? '48%':'' }} className={"SRPinDiv hiddenScrollBar"}>
                <div style={{ padding:'20 0px 0px 0px'}}>
                     <h6 className="sub-title sub-sub-title" style={{ marginBottom:'0px'}}>{typeof card[0][5] !== 'undefined' && card[0][5] != null ? card[0][5] : typeof card[0][6].postcode !== 'undefined' && card[0][6].postcode !== null ?  card[0][6].postcode : card[0][4]}</h6>
                </div>
                <div style={{ padding:'20px 0px 0px'}}>
                
                    <span  style={{ visibility: currentPage == 'SummaryReport'? 'visible': 'hidden'}}>
                        <div style={{position:'relative'}}>
                            <div style={{position:'absolute', top:'0', right:'20px'}} onChange={handleSRSUpdate}><label className="radioBContainer favorit"  style={{padding:'0'}}><input type="checkbox" name="overlaySelectedVar" value= {value} checked = {checked}/> <span className="checkmark checkmarkWidget"></span></label></div>
                        </div>
                    </span>
                    <div style={{ backgroundColor:'#ededed', padding:'25px 0px 0px' }}>
                        
                        <div style={{width: '100%', textAlign:'center'}}>
                            <div style={{ height:'280px', width:'100%', display:'inline-block', paddingTop:'25px' }}>
                                <Chart height={"200px"} scale={scale} data={data} autoFit>
                                    <Coordinate type="polar" innerRadius={0.2} />
                                    {/*<Line position="sales" lineSize={2} />*/}
                                    <Axis visible={true} grid={{line: {style: {}}}} label={{style:{fill:'#291351', fontSize: 15, fontFamily: 'Favorit'}}} name="label"/>
                                    <Axis visible={false} grid={{line: null}} name="indivData"/>
                                    <Tooltip showTitle={false} />
                                    <Interval
                                        position="label*indivData"
                                        adjust="stack"
                                        color="label"
                                        element-highlight
                                        style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                        }}
                                        label={['', {
                                        offset: -15,
                                        }]}
                                        color={['label', ['#ff5092', '#ff9788', '#ffcb66', '#4affdf', '#55ccff']]}
                                    />
                                    <Legend />
                                    <Legend name='label' visible={false} />
                                    <View>
                                        <Geom color='name' />
                                    </View>
                                </Chart>
                            </div>
                        </div>
            
                            
            
                        <div style={{ margin:'20px 0', padding: "0px 20px"}}>
                            <div style={{ display:"flex", justifyContent:'space-between', width: '100%'}}>
            
                                <div className="freightText" style={{ color: '#291351', fontSize: "20px !important"}}>
                                    <h6 className="sub-title" style={{fontSize: "20px"}}>Total score<span style={{color:'#db0052', margin:'0 35px', fontSize: "20px !important"}}>{card[0][13]}%</span></h6>
                                </div>
            
                                <div className="freightText" style={{ color: '#291351', fontSize: "20px !important", right:"0px"}}>
                                    <h6 className="sub-title" style={{fontSize: "20px"}}>Rank <span style={{color:'#db0052', margin:'0 35px', fontSize: "20px !important"}}>{typeof NR !== 'undefined' ? NR : card[0][14]}</span></h6>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div style={{ color:'black'}}>

                        { comment ? (

                            <form>
                                <div style={{padding:'0px 20px', marginTop:'20px'}}>
                                    <h6 className="sub-title sub-sub-title">Comment</h6>
                                    <p style={{color:'#291351'}}>{card[0][11]}</p>
                                </div>
                            </form>

                            ) : (

                            <form style={{display: 'none'}}>
                                <div style={{padding:'0px 20px'}}>
                                    <h6 className="sub-title sub-sub-title">Comment</h6>
                                    <p style={{color:'#291351'}}>{card[0][11]}</p>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    
}

export default React.memo(SummaryReportCards);

