import React, { useContext, useState, useEffect } from 'react'
import Layout from '../layout/Layout';
import GeneralAutoSearch from '../functional/GenralAutoSearch';

import { isSubStateC } from '../../Store';


function TagListDelete() {
    const [isLoading, setIsLoading] = useState(true);

    const [tagList, setTagList] = useState([]);

    const [errorMessages, setErrorMessages] = useState({});


    const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    useEffect(() => {
        getTagList();
        setIsLoading(false);
    }, []);

    const getAuth = async (event) => {
        console.log("trig");
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        let urrl = "https://cleanstreetserver.herokuapp.com/auth/" + uname.value + "/" + pass.value;
        console.log(urrl);

        let list = await fetch(urrl, {cache: "no-store"});
        list = await list.json();
        list = list.valid;
        if (list == "true") {
            setIsSubmitted(true);
        } else {
            alert("Invalid auth");
        }

    }
    
    const getTagList = async () => {
        let list = await fetch("https://urban-foresight-private-data-tags.s3.eu-west-2.amazonaws.com/tagList.json", {cache: "no-store"});
        list = await list.json();
        console.log(list);
        setTagList(list);
    }

    function handleDelete(keay){
        updateLedger(keay);
        alert(keay+" deleted.")

    }

    const updateLedger = async (keay) => {

        var tempLedge = tagList;
        var newTempLedger = [];

        tempLedge.forEach(element => {
            if(element != keay){
                newTempLedger.push(element);
            }
        })

        setTagList(newTempLedger);

        let tempJson = JSON.stringify(newTempLedger);

        let url = await fetch("https://cleanstreetserver.herokuapp.com/s3TagsUploader/tagList.json", {cache: "no-store"});
        url = await url.json();
        url = url.uploadURL;

        return fetch(url, {
            method: "PUT",
            body: tempJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };

    if (isLoading) {
        return (
            <section>
                <p>Loading...</p>
            </section>
        );
    }
    return (
        <Layout >
            {isSubmitted == false ?
                <div style={{ position: "absolute", height: "100vh", width: "100vw", background: "#fff", zIndex: "998" }}>
                    <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center", zIndex: "999" }}>
                        <h1 style={{ fontSize: "30px" }} className="main-title">Admin login</h1>
                    </div>
                    <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px", zIndex: "999" }}>
                        <div className="form" style={{ display: "flex", justifyContent: "center" }}>
                            <form style={{ backgroundColor: "rgb(237, 237, 237)", padding: "20px 40px", border: "2px solid #291351" }} onSubmit={getAuth} >
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Username: </label>
                                    <input style={{ float: "right" }} type="text" name="uname" required />
                                    {renderErrorMessage("uname")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px", height: "30px" }}>
                                    <label>Password: </label>
                                    <input style={{ float: "right" }} type="password" name="pass" required />
                                    {renderErrorMessage("pass")}
                                </div>
                                <div style={{ margin: "20px 0px 20px 0px" }} className="button-container">
                                    <input type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : " "}
            {isSubmitted == true ?
            <>
                <div style={{margin:"20px 19% 0 19%"}}>
                    {tagList.map(keay => {
                        return (
                            <div style={{ display: "flex" }}>
                                <button onClick={() => handleDelete(keay)}>X</button>
                                <option value={keay}>{keay}</option>
                            </div>
                        )
                    })}
                </div>
                    
            </> : " "}
        </Layout>

    )
}


export default TagListDelete