import LayergeoPolygon from './LayergeoPolygon';
import LayergeoMarker from "./LayergeoMarker";
import Layout from '../layout/Layout';

import React, { useState, useContext, useEffect } from 'react';
import {minMaxObjContext} from '../../Store'



function MiddleLayer(props) {
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [mMS, setMMS] = useState(null);
    const [isLoading, setLoading] = useState(true);


    var dataset = props.data
    var proPre = props.propPre;
    var attr = props.attr;
    //////console.log("mid");
    //////console.log(props);
    //////////console.log(dataset);
    var minMax;
    var info = props.info;
    //////////console.log(attr);
    //////////console.log(proPre.type);
    // var keys = Object.keys(data[0]["minMax"]);
    
    // totalMinMax = data[0]["minMax"];

    // data.forEach(entry => {
                
    //     keys.forEach(key => {
    //         //res.send(["return",entry[key], entry,key]);
    //         //res.send([entry.key, minMax[key][0]]);
    //         if (entry[key] < totalMinMax[key][0]){
    //             //
    //             totalMinMax[key][0] = entry[key];
    //         }

    //         if (entry[key] > totalMinMax[key][1]){
    //             totalMinMax[key][1] = entry[key];
    //         }
            
    //     })
    // })
    useEffect(() => {
        if (proPre.type != "point"){
            if (proPre.geoScope == "GBLayer"){
                ////////////////console.log("minMax");
                ////////////////console.log(dataset[0].minMax);
                minMax = dataset[0].minMax;
            } else {
                var keys = Object.keys(dataset[0]["minMax"]);
                ////////////////console.log(keys);
    
                //init minMax
                minMax = dataset[0]["minMax"];
                ////////////////console.log("minMax Innit");
                ////////////////console.log(minMax);
                dataset.forEach(entryy => {
                    let entry = entryy["minMax"];
                    ////////////////console.log(entry);
                    
                    keys.forEach(key => {
                        //res.send(["return",entry[key], entry,key]);
                        //res.send([entry.key, minMax[key][0]]);
                        if (entry[key][0] < minMax[key][0]){
                            //
                            minMax[key][0] = entry[key][0];
                        }
            
                        if (entry[key][1] > minMax[key][1]){
                            minMax[key][1] = entry[key][1];
                        } 
                    })
                })
    
            }
            //////////////////console.log("minmax end");
            //////////////////console.log(minMax);
            
            //////////////////console.log(dataset[0]);
            //////////////////console.log(dataset[0][0]);
            var tempMinMax = minMaxObj;
            tempMinMax.push(minMax);
            setMinMaxObj(tempMinMax);
            setMMS(minMax);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
        
    }, []);
    if (isLoading) {
        return (
          <Layout>
          <section>
            <p>Loading...</p>
          </section>
          </Layout>
        );
    } else {
        if (proPre.type == "point"){
            return(
                <LayergeoMarker  key = {dataset.name} dataName={dataset.name} mapdata={dataset} mp ={props.mp} proPre={proPre} attr={props.attr} minMax={props.minMax}/> 
            )
            
        } else {
            //switch on geoscope
            // //////////////console.log(dataset);
            // if (proPre.geoScope == "GBLayer"){
            //     ////////////////console.log("minMax");
            //     ////////////////console.log(dataset[0].minMax);
            //     minMax = dataset[0].minMax;
            // } else {
            //     var keys = Object.keys(dataset[0]["minMax"]);
            //     ////////////////console.log(keys);
    
            //     //init minMax
            //     minMax = dataset[0]["minMax"];
            //     ////////////////console.log("minMax Innit");
            //     ////////////////console.log(minMax);
            //     dataset.forEach(entryy => {
            //         let entry = entryy["minMax"];
            //         ////////////////console.log(entry);
                    
            //         keys.forEach(key => {
            //             //res.send(["return",entry[key], entry,key]);
            //             //res.send([entry.key, minMax[key][0]]);
            //             if (entry[key][0] < minMax[key][0]){
            //                 //
            //                 minMax[key][0] = entry[key][0];
            //             }
            
            //             if (entry[key][1] > minMax[key][1]){
            //                 minMax[key][1] = entry[key][1];
            //             } 
            //         })
            //     })
    
            // }
            //////////////////console.log("minmax end");
            //////////////////console.log(minMax);
            
            //////////////////console.log(dataset[0]);
            //////////////////console.log(dataset[0][0]);
            // var tempMinMax = minMaxObj;
            // tempMinMax.push(minMax);
            // setMinMaxObj(tempMinMax);
    
            return (
                <LayergeoPolygon  key = {proPre.name} dataName={proPre.name} mapdata={dataset} proPre={proPre} attr = {attr} mp ={props.mp} minMax = {mMS} info={info}/> 
            )
            
        }
    }
}


export default MiddleLayer;

