import React , {useContext, useState, useEffect} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';

import {selectedPositionContext, LAStateContext} from '../../Store';

import Map2Handler from './Map2Handler';
import Layout from '../layout/Layout';






function MapLaInput({laInputC, setLaInputC}) {
    
    const [LAs, setLAs] = useContext(LAStateContext);
    const [sP, setSP] = useContext(selectedPositionContext); 

    const [map2, setMap2] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
    
        setIsLoading(false);
    }, []);

    
    if (isLoading) {
        return (
         
            <section>
              <p>Loading...</p>
            </section>
          
        );
    }
    return (
        <div style={{marginTop:"1px", marginLeft: "1px", margin:"1px"}}>
            <MapContainer 
                center={ [54.559388, -3.793332] ||  sP } 
                zoom={5.5} 
                scrollWheelZoom={true}
                style={{ height: "549px"}}
                whenCreated = {setMap2}
                
            >
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                />
                <Map2Handler map2 = {map2} laInputC={laInputC} setLaInputC={setLaInputC}/>
            </MapContainer>
        </div>
    )
}

export default MapLaInput;

