import React, { useContext, useState, useEffect } from 'react'
import Layout from '../layout/Layout';
import GeneralAutoSearch from '../functional/GenralAutoSearch';

import { isSubStateC } from '../../Store';

import { BrowserRouter, useHistory } from 'react-router-dom';

function DataRepositoryPublic() {
    const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);


    const [isLoading, setIsLoading] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});

    const [datasetList, setDatasetList] = useState([]);
    const [loadedLedger, setLoadedLedger] = useState([]);
    const [usedLedger, setUsedLedger] = useState([]);

    var tagList = [];
    const [selectedTags, setSelectedTags] = useState([]);
    const [query, setQuery] = useState("");

    var lightTrue = true;

    const history = useHistory();

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    useEffect(() => {
        getDatasets();
        getLedger();

        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (query == "") {
            setUsedLedger(loadedLedger)
        } else {
            console.log(query);
            var len = query.length;

            var testedQuery = query.toLowerCase();
            var tempLL = [];

            loadedLedger.forEach(e => {

                if (e.selectedTags.some(tag => {
                    console.log(tag.name);
                    let matched = true;
                    let testedTag = tag.name.toLowerCase();
                    for (let i = 0; i < len; i++) {
                        if (testedTag[i] != testedQuery[i]) {
                            matched = false;
                        }
                    }
                    if (matched == true) {
                        return true;
                    }
                })) {
                    tempLL.push(e);
                }
            })

            setUsedLedger(tempLL);
        }

    }, [query]);

    const getAuth = async (event) => {
        console.log("trig");
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        let urrl = "https://cleanstreetserver.herokuapp.com/auth/" + uname.value + "/" + pass.value;
        console.log(urrl);

        let list = await fetch(urrl, {cache: "no-store"});
        list = await list.json();
        list = list.valid;
        if (list == "true") {
            setIsSubmitted(true);
        } else {
            alert("Invalid auth");
        }

    }

    const getLedger = async () => {
        let list = await fetch("https://urban-foresight-private-data-store.s3.eu-west-2.amazonaws.com/myLedger.json", {cache: "no-store"});
        console.log(list);
        list = await list.json();
        console.log(list);

        let loadedLedgerOnlyPublic = [];

        list.forEach(element => {
            if (element.datasetIsPublic == true) {
                loadedLedgerOnlyPublic.push(element);
            }
        });

        setLoadedLedger(loadedLedgerOnlyPublic);
        setUsedLedger(loadedLedgerOnlyPublic);
    }

    const getDatasets = async () => {
        let list = await fetch("https://cleanstreetserver.herokuapp.com/listBuckett/", {cache: "no-store"});
        list = await list.json();

        list = list.contents;
        setDatasetList(list);
    }

    if (isLoading) {
        return (
            <section>
                <p>Loading...</p>
            </section>
        );
    }
    return (
        <Layout >
            <>
                <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center" }}>
                    <h1 style={{ fontSize: "30px" }} className="main-title">Data Repository</h1>
                </div>
                <div style={{ paddingLeft: "19%", paddingRight: "19%", width: "100%", paddingTop: "46px", fontSize: "18px" }}>

                    <label style={{ borderBottom: "1px solid #291351", fontSize: "22px", fontFamily: "favorit", marginTop: "20px", marginBottom: "20px" }}>Search for a dataset:
                        <input
                            type="text"
                            placeholder='Search...'
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ marginLeft: "20px", marginBottom: "5px" }}
                        />
                    </label>


                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        {usedLedger.map((keay, id) => {
                            let hRef = "https://urban-foresight-private-data-store.s3.eu-west-2.amazonaws.com/" + keay.datasetName;
                            let srcRef = keay.sourceLink;

                            return (
                                <div style={keay.removeable == true ? { "width": "49%", borderTop: "solid 2px red", marginBottom: "20px" } :
                                    { "width": "calc(50% - 10px)", borderTop: "solid 2px #291351", marginBottom: "20px" }
                                }>
                                    <div style={keay.removeable == true ? { backgroundColor: "#ededed", borderBottom: "solid 1px #291351", padding: "5px 10px 5px", fontSize: "22px", borderLeft: "solid 2px red", borderRight: "solid 2px red", overflowWrap: "break-word" } : { backgroundColor: "#ededed", borderBottom: "solid 1px #291351", padding: "5px 10px 5px", fontSize: "22px", borderLeft: "solid 2px #291351", borderRight: "solid 2px #291351", overflowWrap: "break-word" }}>
                                        {keay.datasetName}
                                    </div>
                                    <div style={keay.removeable == true ? { padding: "10px 10px", borderLeft: "solid 2px red", borderRight: "solid 2px red" } : { padding: "10px 10px", borderLeft: "solid 2px #291351", borderRight: "solid 2px #291351" }}>

                                        <div>
                                            {keay.selectedTags.map((la) => {
                                                return (
                                                    <div style={{ marginRight: "10px", marginBottom: "10px", display: "inline-block", backgroundColor: "lightgrey", padding: "2px 13px" }}>{la.name} </div>
                                                )

                                            })}
                                        </div>
                                        <div style={{ margin: "10px 0px", fontFamily: "Freight_Text_Book" }}>
                                            {keay.description}
                                        </div>

                                        <div style={{ margin: "10px 0px", fontFamily: "Freight_Text_Book", display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ fontSize: "16px", fontFamily: "Freight_Text_Book" }}>Published by: {keay.datasetPublisher}</div>
                                            <div style={{ fontSize: "16px", fontFamily: "Freight_Text_Book" }}>Uploaded: {keay.date}</div>
                                        </div>

                                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <div style={{ width: "100%", borderBottom: "solid 1px #291351" }}></div>
                                        </div>

                                        <div style={{ marginTop: "10px" }}>
                                            {
                                                keay.locationType == "all" ? <>This dataset is for all UK locations. </> :
                                                    keay.locationType == "specific" ? <>For specific locations: </> :
                                                        keay.locationType == "invert" ? <>Any UK locations <b>except</b>: </> :
                                                            null
                                            }
                                            {
                                                keay.locationType != "all" ?
                                                    keay.specificLAs.map((la, id) => {
                                                        let commarBefore = false;
                                                        if (id > 0) {
                                                            commarBefore = true;
                                                        }
                                                        return (
                                                            <>{commarBefore == true ? <>, </> : null}{la.name}</>
                                                        )

                                                    })
                                                    : null
                                            }
                                        </div>

                                    </div>

                                    <div style={keay.removeable == true ? { background: "grey", display: "flex", padding: "5px", borderLeft: "solid 2px red", borderRight: "solid 2px red", borderBottom: "solid 2px red" } : { background: "grey", display: "flex", padding: "5px", borderLeft: "solid 2px #291351", borderRight: "solid 2px #291351", borderBottom: "solid 2px #291351" }}>
                                        {keay.datasetIsPublic == false ?
                                            <div style={{ marginLeft: "0", marginRight: "auto", marginTop: "0px" }}>
                                                <button className='smallButton'>Private</button>
                                            </div>
                                        : null}

                                        {keay.sourceLink != undefined && keay.sourceLink != "noSelect" ?
                                            <div style={{ marginRight: "0px", marginTop: "0px" }}>
                                                <a href={srcRef}><button  className='smallButton'>Dataset source</button></a>
                                            </div>
                                        : null}

                                        <div style={{ marginLeft: "auto", marginRight: "0px", marginTop: "0px" }}>
                                            <a href={hRef}><button className='smallButton'>Access dataset</button></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </>
        </Layout>

    )
    
}


export default DataRepositoryPublic