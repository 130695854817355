import React , {useContext, useState, useEffect, useRef} from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import * as L from 'leaflet'

import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import AllLayers from './AllLayers';
import NewMapDraw from './NewMapDraw';
import MapMenuArea from '../MapMenuArea';
import MapMenuArea2 from '../MapMenuArea2';
import SataliteBaseLayerSelector from './SataliteBaseLayerSelector';
import ClearLayers from './ClearLayers'
import LACounters from '../LACounters';
import Cards from './Cards';
import ScreenHandler from './ScreenHandler';
import ScreenHandler2 from './ScreenHandler2';
import ScreenHandler3 from './ScreenHandler3';


import {selectedPositionContext} from '../../Store';



function Map({mp, setMap, mp2, setMap2, mp3, setMap3, mp4, setMap4, tBools, setTBools, c, toggleCardActive, keys, data2Return, activeElements, tabDisabled,setTabDisabled,tabHighlighted,setTabHighlight, cBool, setCBool, load, setLoad, setReffReady, handleTabButton }) {
    
    const [sP, setSP] = useContext(selectedPositionContext);  

    const [latty, setLatty] = useState(54.059388);  
    const [longy, setLongy] = useState(-3.793332);  
    const [clearButtonRef, setClearButtonRef] = useState(null); 

    

    const clearButtonRefIn = useRef();
    const [info, setInfo] = useState(L.control());
    const [atr, setatr] = useState([]);
    const [myState, setMyState] = useState(null);
    const [newState, setNewState] = useState(null);
    useEffect(() => {
        
        setClearButtonRef(clearButtonRefIn);

        info.onAdd = function (mp) {
            this._div = L.DomUtil.create('div', "mapInfo"); // create a div with a class "info"
            this.update();
            return this._div;
        };
        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
            if (props){
                let tempz = "";
                if (props.full == true){
                    if (typeof props.dataa != 'undefined'){
                        tempz = props.dataa
                    }
                    this._div.innerHTML = '<h6 className="sub-title favorit">' + props.attType + '</h6><p classsName="freightText floatR"><b>' + tempz + "</b> " + " </p>"
                        
                } else {
                    this._div.innerHTML = '<h6 className="sub-title">' + props.attType + '</h6><p>' + " </p>";
                }
            } else {
                this._div.innerHTML = '<span>Add data</span>';
            }
            
            
        };

        info.setClass = function (props) {
            ////////////////console.log("help");
            var classs = (props == false? 
                "mapInfo inactiveClearDataButton"
                : "mapInfo");
            this._div.setAttribute("class", classs);
        }

        

        setInfo(info);
        
        
    }, []);

    useEffect(() => {
        //console.log("updated");
        //console.log(atr);
        setNewState(atr)
    }, [atr, myState]);

    return (
        <div style={{position: 'relative', height:'100%'}}>
            {/* <div
                style={{
                position: 'absolute',
                bottom: '3%',
                left: '1%',
                zIndex: '10000',
                border: "5px",
                borderColor: "#A6E581",
                color: '#291351'
                }}  
                className= { tBools[3] == true ? 'classOff':'classOn'}>
                    <LACounters />
            </div> */}
            <div className= { tBools[1] == true ? 'classOn scrollEffect':'classOff scrollEffect'} >
                <div> 
                    
                    {<MapMenuArea2 mp={mp} setMap={setMap} info={info} clearButtonRef={clearButtonRef} setClearButtonRef={setClearButtonRef}
                     atr={myState} setatr={setatr}/>}
                    
                </div>

            </div>
            <div  className= { tBools[2] == true ? 'classOn scrollEffect':'classOff scrollEffect'} >
                <div> 
                   
                    {<MapMenuArea mp={mp} setMap={setMap} info={info} clearButtonRef={clearButtonRef} setClearButtonRef={setClearButtonRef}
                     atr={myState} setatr={setatr} />}
                   
                </div>
            </div>

            <div  className= { tBools[3] == true ? 'classOn pinContainer':'classOff pinContainer'} >
                
                <h6 className="sub-title" style={{padding:"0px 40px", paddingTop:"18px", marginBottom:"0px", height: "7%"}}>Placed Pins</h6>
                <div style={{margin:"20px 20px 0px 20px", height: "calc(93% - 40px)", backgroundColor:"#ededed"}}>
                    <div style={{position: "relative", height: "calc(100% - 40px)", top: "20px", padding:"0px 20px", overflowY:"auto"}}>
                        {keys.map(aLA =>{
                            c = c+1;
                            return (
                                <div key={c+250}>
                                    {data2Return[aLA][1].map((card, index) => {
                                        return(
                                            <Cards key={index} card={card} index={index} toggleCardActive={toggleCardActive} activeElements={activeElements} c={c}/>
                                        )
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
               
            </div>

            <div style={{ position: 'absolute', width: '350px', height: '84%', top: "8%", left: 0, zIndex: 9999, backgroundColor:'rgba(255,255,255, 1)', padding:'20px 40px', fontFamily:'Favorit, Helvetica, Arial, sans-serif', color: '#291351', boxShadow: "0px 0px 12px 4px #00000029" }} className= { tBools[4] == true ? 'classOn':'classOff'} >
                <div style={{position: "relative", width: "100%", height: "100%"}}> 
                    
                    <div style={{position: "relative", width: "100%", height: "100%"}}><SataliteBaseLayerSelector mp={mp} setMap={setMap} clearButtonRef={clearButtonRef} setClearButtonRef={setClearButtonRef} /></div>
                    
                </div>

            </div>
            <div onClick={() => {handleTabButton(0)}} textContent="<" style={{ position: 'absolute', width:"50px", height:"70px", top: "20%", left:"330px", zIndex: 9998, color:"#fff", backgroundColor: "#261B3F", borderRadius:"15px", cursor:"pointer"}} className= { tBools[1] == true || tBools[2] == true || tBools[4] == true ? 'classOn':'classOff'} >
                <div style={{position: "relative", fontFamily:"monospace", fontSize: "45px", lineHeight: "63px", right: "-20px"}}>&lt;</div>
            </div>

            <div onClick={() => {handleTabButton(0)}} textContent="<" style={{ position: 'absolute', width:"50px", height:"70px", top: "20%", left:"calc(100% - 20px)", zIndex: 9998, color:"#fff", backgroundColor: "#261B3F", borderRadius:"15px", cursor:"pointer"}} className= { tBools[3] == true ? 'classOn':'classOff'} >
                <div style={{position: "relative", fontFamily:"monospace", fontSize: "45px", lineHeight: "63px", right: "-20px"}}>&lt;</div>
            </div>
            
            
            <div style={{ position: 'absolute', top: '13px', right: "-3px", zIndex: 10000, border: "0px solid rgba(219, 0, 82, 0.4)", backgroundColor: 'rgba(255, 255, 255, 0)'}} >
                <ClearLayers mp={mp} setMap={setMap} info={info} clearButtonRef={clearButtonRef} setClearButtonRef={setClearButtonRef} />    
            </div>
            {/* <div style={{ position: 'absolute', top: '20.5%', right: "1%", zIndex: 10000, border: "0px solid rgba(219, 0, 82, 0.4)", backgroundColor: 'rgba(255, 255, 255, 0)'}} >
            <button type={"button"} className={"generalButton"} style={{ width:"100%", textAlign:"center"}}   onClick={(e) => {
                        var center = mp.getCenter();
                        ////console.log(center);
                    }}> Center</button> 
            </div> */}
            
            
            <MapContainer 
                center={ [54.059388, -3.793332] ||  sP } 
                zoom={6.5} 
                scrollWheelZoom={true}
                style={{ height: "calc(75vh - 2px)", transform: "translate(0.1%, 0.1%)"}}
                whenCreated ={(m)=> {
                    setMap(m);
                    setReffReady(true);
                }}
                doubleClickZoom={false}
            >)
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                />
              <AllLayers mp = {mp}  tabDisabled={tabDisabled} setTabDisabled={setTabDisabled}
                tabHighlighted={tabHighlighted} setTabHighlight={setTabHighlight} latty={latty} setLatty={setLatty} longy={longy} 
                setLongy={setLongy} mp2 = {mp2} mp3 = {mp3} mp4 = {mp4} cBool={cBool} setCBool={setCBool} info={info} load={load}
                setLoad={setLoad}
                atr={atr} setatr={setatr} setMyState = {setMyState}/>

            </MapContainer>
            <div style={{position: 'fixed', top: "-600px", left: 0, zIndex: 0}}>
            <MapContainer 
                center={[54.059388, -3.793332]} 
                zoom={16} 
                scrollWheelZoom={true}
                style={{ height: "500px", width:"800px"}}
                whenCreated ={setMap2}
            >
                
                <TileLayer
                    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
            
                <ScreenHandler mp2 = {mp2}/>
            </MapContainer>
            </div>
            <div style={{position: 'fixed', top: "-600px", left: 0, zIndex: 0}}>
            <MapContainer 
                center={[54.059388, -3.793332]} 
                zoom={16} 
                scrollWheelZoom={true}
                style={{ height: "500px", width:"800px"}}
                whenCreated ={setMap3}
            >
                
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                />
            
                <ScreenHandler2 mp3 = {mp3}/>
            </MapContainer>
            </div>
            <div style={{position: 'fixed', top: "-600px", left: 0, zIndex: 0}}>
            <MapContainer 
                center={[54.059388, -3.793332]} 
                zoom={11} 
                scrollWheelZoom={true}
                style={{ height: "600px", width:"1000px"}}
                whenCreated ={setMap4}
            >
                
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=2e793f41-bdcc-4739-b992-3b7afec6db20"
                />
            
                <ScreenHandler3 mp4 = {mp4}/>
            </MapContainer>
            </div>
            
            
        </div>
    )
}

export default Map;

/*
var Stadia_AlidadeSmoothDark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
	maxZoom: 20,
	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
});

*/

/*MAP TYPE STUFF REMOVED 27/01/2022     
            <div style={{ width:'48%'}}>
                        <h6 className="sub-title">Map Type</h6>

                        <div style={{margin:'10px 0', width:'45%', display:'inline-block'}}>
                            <div style={{ width:'100%', height:'100px', backgroundColor:'grey', border: '3px solid #db0052'}}></div>
                            <div className="freightText" style={{margin:'10px 0px'}}><label className="radioBContainer"><input type="radio"  name="overlaySelectedVar" /> Dummy Text<span className="checkmark"></span></label></div>
                        </div>

                        <div style={{margin:'10px 0', marginLeft:'10%', width:'45%', display:'inline-block'}}>
                            <div style={{ width:'100%', height:'100px', backgroundColor:'grey', border: '3px solid #db0052'}}></div>
                            <div className="freightText" style={{margin:'10px 0px'}}><label className="radioBContainer"><input type="radio"  name="overlaySelectedVar" /> Dummy Text<span className="checkmark"></span></label></div>
                        </div>
                    </div>

*/