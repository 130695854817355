import React, { useState, useEffect, useContext, useRef } from 'react';

import Layout from '../components/layout/Layout';
import '../pages/main.css';
import { sumReportStatusC, allLaMapC, factorsContext, minMaxObjContext, evCounterC, priorityStateContext, countContext, data2ReturnContext, LAMarkerCountContext, LAStateContext, expandedContext, updateMeContext, genralDataC } from '../Store';
import MapMenuArea from '../components/MapMenuArea';
import LACounters from '../components/LACounters';
import SummaryReportCards from '../components/mapdetails/summaryReportCardsPrintReport';
import ReportPieCards from '../components/mapdetails/ReportPieCards';
import PinsRankList from '../components/mapdetails/PinsRankList';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import MyPie from '../components/charts/MyPie2.js';
import MyBar from '../components/charts/MyBar2.js';
import Example from '../components/charts/XYChart2';
import ReactDOMServer from 'react-dom/server';

import { BarGroupHorizontal, Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { LegendOrdinal, LegendItem,LegendLabel } from '@visx/legend';

import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import { useTooltip, useTooltipInPortal, TooltipWithBounds, withTooltip  } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import {
    AnimatedAxis, // any of these can be non-animated equivalents
    AnimatedGrid,
    AnimatedLineSeries,
    buildChartTheme,
    XYChart,
    Tooltip,
  } from '@visx/xychart';

const legendGlyphSize = 15;

const ordinalColorScale = scaleOrdinal({
domain: ['a', 'b', 'c', 'd'],
range: ['#66d981', '#71f5ef', '#4899f1', '#7d81f6'],
});


const data1 = [
    { x: '2020-01-01', y: 50 },
    { x: '2020-01-02', y: 10 },
    { x: '2020-01-03', y: 20 },
];

const data2 = [
    { x: '2020-01-01', y: 30 },
    { x: '2020-01-02', y: 40 },
    { x: '2020-01-03', y: 80 },
];

// timesereiscolors#
const customTheme = buildChartTheme({
    // hover (tooltip) box background
    backgroundColor: "#ff9788",
    // colors for datalines
    colors: ["dodgerBlue", "#ff5238"],
    // grid lines
    gridColor: "#30475e",
    // x-axis color
    gridColorDark: "#222831",
    // x-axis text(labels) color 
    // svgLabelSmall: { fill: "#30475e" },
    svgLabelSmall: { fill: "#291351" },
    // hover (tooltip) box text color
    svgLabelBig: { fill: "#30475e" },
    // gap btw labels and x axis
    tickLength: 4
});

const accessors = {
    xAccessor: d => d.x,
    yAccessor: d => d.y,
};
  
const APIkey = "AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

function PrintReport(props) {
    //const [chargerType, setchargerType] = useContext(typeofChargerStateContext);
    const [activeElements, setActiveElements] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    const [count, setCount] = useContext(countContext);
    const [data2Return, setData2Return] = useContext(data2ReturnContext);
    const [LAMC, setLAMC] = useContext(LAMarkerCountContext);
    const [LAs, setLAs] = useContext(LAStateContext);
    const [expanded, setExpanded] = useContext(expandedContext);
    const [updateMe, setUpdateMe] = useContext(updateMeContext);
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [genralData, setGenralData] = useContext(genralDataC);
    const [evCounter, setEvCounter] = useContext(evCounterC); 
    const [allLaMap, setAllLaMap] = useContext(allLaMapC);
    const [factors2, setFactors2] = useContext(factorsContext); 
    const [sumReportStatus, setSumReportStatus] = useContext(sumReportStatusC); 
    


    const [subData2Return, setSubData2Return] = useState(null);
    const [processedData2Return, setProcessedData2Return] = useState(null);
    const [processedRankData2Return, setProcessedRankData2Return] = useState(null);
    const [namesSet, setNamesSet] = useState([]);
    const [names, setNames] = useState([]);
    const [hBools, setHBools] = useState([true, false, false, false, false, false, false, false, false, false, false, false]);
    const [tabDisabled, setTabDisabled] = useState(true);
    const [tabHighlighted, setTabHighlight] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [widgetGrouppp, setWidgetGrouppp] = useState([]);
    const [currentHeadingOrder, setCurrentHeadingOrder] = useState([]);
    const [extraHeadings, setExtraHeadings] = useState([]);
    const [laCounts, setLaCounts] = useState([]);
    

    const history = useHistory();
    const location = useLocation();

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();
    let { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
        detectBounds: true,

    });

    const handleMouseOver = (event, datum, dat, label) => {
        ////////////console.log(event);
        ////////////////console.log(datum);
        //////////////console.log(dat)
        var sum = ""+label+" "+dat;
        ////////////console.log(containerBounds.left);
        ////////////console.log(containerBounds.top);
        
        
        const coords = localPoint(event.target.ownerSVGElement, event);
        ////////////console.log(coords);
        //////////////console.log(containerRef);
        
        showTooltip({
          tooltipLeft: event.pageX,
          tooltipTop: event.pageY,
          tooltipData: sum
        });
      };

    // If you don't want to use a Portal, simply replace `TooltipInPortal` below with
  // `Tooltip` or `TooltipWithBounds` and remove `containerRef`
    // const { containerRef, TooltipInPortal } = useTooltipInPortal({
    //     // use TooltipWithBounds
    //     detectBounds: false,
    //     // when tooltip containers are scrolled, this will correctly update the Tooltip position
    //     scroll: false,
    //  })

     

    const handleHistory = () => {
        history.push({ 
            pathname: '/printReport',
            state: { sD2R: subData2Return }
           });
    }
   

    //to make bar width dynamic 
    
    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });

    var numberOfLAs = validatedLAs.length;

    var c = 0;
    var count15 = -1;
    var count16 = 0;
    
    useEffect(() => {

        // //////////////console.log("\n\n\n\n\nPRINCEwidth", ref.current.offsetWidth);

        ////////////////console.log(location.state.sD2R);
        if (true) {
            
            
            setWidgetGrouppp(location.state.widgetGrouppp);
            setLaCounts(location.state.laCounts);
            setProcessedRankData2Return(location.state.processedRankData2Return);
            setExtraHeadings( extraHeadings);


            // for printreportwrapper
            // setWidgetGrouppp(props.widgetGrouppp);
            // setLaCounts(props.laCounts);
            // setProcessedRankData2Return(props.processedRankData2Return);
            // setExtraHeadings( props.extraHeadings);


            setLoading(false);
        }
    }, [sumReportStatus]);

    function rankHeading(selectedHeading, index){
       // ////////////console.log(selectedHeading);
        //////////////console.log(index);

        var doSomething = true;
        var tempHBools = hBools;

        var count = 0;
        tempHBools.forEach(HBool => {
            if (HBool == true && count == index) {
                doSomething = false;
            }
            count = count + 1;
        })

        if (doSomething) {
            tempHBools = [false, false, false, false, false, false, false, false, false, false, false, false];
            tempHBools[index] = true;
            setHBools(tempHBools);
        } else {
            tempHBools = [true, false, false, false, false, false, false, false, false, false, false, false];
            setHBools(tempHBools);
        }

        var tempCurrentHeadingOrder = currentHeadingOrder;
        if (currentHeadingOrder == []){
            tempCurrentHeadingOrder = [selectedHeading, 'down'];
            setCurrentHeadingOrder(tempCurrentHeadingOrder);
        }

        if(currentHeadingOrder[0]==selectedHeading){
            if(currentHeadingOrder[1]=='down'){
                tempCurrentHeadingOrder = [selectedHeading, 'up'];
            }else{
                tempCurrentHeadingOrder = [selectedHeading, 'down'];
            }
        }
        else {
            tempCurrentHeadingOrder = [selectedHeading, 'down'];
            setCurrentHeadingOrder(tempCurrentHeadingOrder);
        }
        ////////////console.log(tempCurrentHeadingOrder);

        sortMe(tempCurrentHeadingOrder[0],[1])
    }

    function sortMe(selectedHeading, direction){
        var unsortedRankings = [];
        var newD2R = [];
        var tempSubData2Return = processedRankData2Return;
        ////////////console.log("here")
        //////////console.log(direction);
        
        var count = 0;
        tempSubData2Return.forEach(la => {
            newD2R.push([]);
            newD2R[count].push(la[0]);
            newD2R[count].push([]);

            unsortedRankings.push([]);


            let count2 = 0
            la[1].forEach(card => {
                newD2R[count][1].push([]);
                card[0][12].forEach(dSet => {
                    if(dSet.label == selectedHeading){
                        ////////////console.log(selectedHeading);
                        ////////////console.log(dSet.indivData);
                        unsortedRankings[count].push(dSet.indivData);
                    }
                })
            });

            count = count + 1;
        })
        ////////////console.log("un"+unsortedRankings);
        ////////////console.log(unsortedRankings);

         var sortedRankings = [];
         let count8 = 0;
        unsortedRankings.forEach(la => {
            let count7 = 0;
            sortedRankings.push([]);

            var numbers = la;
            ////////////////console.log(numbers);
            numbers.sort(function (a, b) {
                return a - b;
            });

            numbers.reverse();
            ////////////////console.log(numbers);
            numbers.forEach(number => {
                sortedRankings[count8].push([count7, number]);
                count7 = count7 + 1;
            })
            count8 = count8 + 1;
        })
        ////////////console.log(sortedRankings);

        var newNewD2R = [];
        var tempSubData2Return = processedRankData2Return;
         ////////////console.log(tempSubData2Return);

        var count = 0;
        tempSubData2Return.forEach(la => {
            newNewD2R.push([]);
            newNewD2R[count].push(la[0]);
            newNewD2R[count].push([]);


            let count2 = 0
            la[1].forEach(card => {
                newNewD2R[count][1].push([]);


                let count3 = 0;
                card.forEach(dSet => {

                    if (count3 == 0) {
                        newNewD2R[count][1][count2].push([]);
                        var count4 = 0;
                        dSet.forEach(d => {
                            newNewD2R[count][1][count2][0].push(d);

                            count4 = count4 + 1;
                        })
                        var thisData;
                        card[0][12].forEach(dSet => {
                            if (dSet.label == selectedHeading){
                                thisData = dSet;
                            }
                        })
                        var found = false;
                        sortedRankings[count].some(pinRank => {
                            if (found == false){
                                if (thisData.indivData == pinRank[1]){
                                    found = true;
                                    ////////////console.log("match");
                                    ////////////console.log(thisData.indivData);
                                    ////////////console.log(pinRank[1]);
                                    ////////////console.log(newNewD2R[count][1][count2][0]);
                                    //removePastScores
                                    while (typeof newNewD2R[count][1][count2][0][14] != 'undefined'){
                                        newNewD2R[count][1][count2][0].pop();
                                    }
    
    
                                    newNewD2R[count][1][count2][0].push((pinRank[0] + 1));
                                }
                            
                            }
                            
                            
                        })

                    } else {
                        newNewD2R[count][1][count2].push(dSet);
                    }
                    count3 = count3 + 1;
                })
                count2 = count2 + 1;
            });

            count = count + 1;
        })
        ////////////console.log(newNewD2R);
        
        var newNewNewD2R = [];
        var tempSubData2Return = newNewD2R;
        ////////////console.log(tempSubData2Return);

        var count = 0;
        tempSubData2Return.forEach(la => {
            newNewNewD2R.push([]);
            newNewNewD2R[count].push(la[0]);
            newNewNewD2R[count].push([]);

            let len = la[1].length;
            ////////////console.log("len:" + len);
            let count2 = 0;

            la[1].forEach(card => {
                newNewNewD2R[count][1].push([]);
            })

            while (count2 < len) {

                la[1].forEach(card => {


                    if (count2 == (card[0][14] - 1)) {
                        ////////////console.log(newNewNewD2R);
                        ////////////console.log("count2 : " + count2);
                        if (newNewNewD2R[count][1][count2].length !== 0) {
                            var cc = count2 + 1;
                            ////////////console.log("cc : " + cc);

                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                var cc = count2 + 2;
                                card.forEach(dSet => {
                                    newNewNewD2R[count][1][cc].push(dSet);
                                })

                            } else {
                                card.forEach(dSet => {
                                    newNewNewD2R[count][1][cc].push(dSet);
                                })
                            }

                        } else {
                            card.forEach(dSet => {
                                newNewNewD2R[count][1][count2].push(dSet);
                            })

                        }

                    }
                    //

                });
                count2 = count2 + 1;
            }


            count = count + 1;
        })
        ////////////console.log('\n\n\n\n\n');
        ////////////console.log(newNewNewD2R);
        setProcessedRankData2Return(newNewNewD2R);

    }


    if (isLoading) {
        return (
            <Layout>
                <section>
                    <p>Loading...</p>
                </section>
            </Layout>
        );
    } else {
        
        var keyss = Object.keys(processedRankData2Return);
        let indx = 0;
        //////////console.log(factors2);
        let indx2 = -1;     
        let indxCounter = -1;   

        return (
            <div key={c + 850}>

                    <div style={{ width: '100%'}}>
                        
                        {/* ----tabs code start---- */}
                        <section>

                            {/* ----first tab code start---- */}
                            <div style={{marginBottom:'40px'}}>

                                <h6 className="sub-title" style={{marginTop:'20px'}}>Background data</h6>
                                
                                <div style={{margin: '20px 0'}}>        
                                    {widgetGrouppp.map((widget) => {
                                        c = c + 1;

                                        let index = indx;
                                        indx = indx + 1;

                                        //////////////console.log("indexx: "+index);
                                        let t = -1;
                                        

                                        if (typeof widget != "undefined"){
                                            indxCounter = indxCounter + 1;
                                            if(widget.type == "0"){
                                                c = c+1;
                                                var allLaData = [];
                                                var allLaData2 = [];
                                                ////////////console.log(widget);
                                                ////////////console.log(evCounter);
                                                widget["laChartData"].forEach(localA => {
                                                    //laName
                                                    localA["cData"].forEach(chart => {
                                                        if(chart[0] == "combinedBar"){
                                                            var tempArr = [];
                                                            tempArr.push(["date",localA["laName"]]);
                                                            let keyz = Object.keys(chart[2]);
                                                            keyz.forEach(key => {
                                                                if (key != "date"){
                                                                    tempArr.push([key,chart[2][key]]);
                                                                    
                                                                }
                                                            })
                                                            evCounter.forEach(lA => {
                                                                if ( lA[0] == localA["laName"] ){
                                                                    tempArr.push([["Existing charging points"],lA[1]]);
                                                                }
                                                            })
                                                            var newObj = {};
                                                            tempArr.forEach(temp => {
                                                                newObj[temp[0]] = temp[1];
                                                            })
                                                            allLaData.push(newObj);
                                                        }
                                                    })
                                                    
                                                })
                                                widget["laChartData"].forEach(localA => {
                                                    //laName
                                                    localA["cData"].forEach(chart => {
                                                        if(chart[0] == "combinedBar2"){
                                                            var tempArr = [];
                                                            tempArr.push(["date",localA["laName"]]);
                                                            let keyz = Object.keys(chart[2]);
                                                            keyz.forEach(key => {
                                                                if (key != "date"){
                                                                    tempArr.push([key,chart[2][key]]);
                                                                    
                                                                }
                                                            })
                                                            var newObj = {};
                                                            tempArr.forEach(temp => {
                                                                newObj[temp[0]] = temp[1];
                                                            })
                                                            allLaData2.push(newObj);
                                                        }
                                                    })
                                                    
                                                })
                                                ////////////console.log("combined");
                                                ////////////console.log(allLaData);
                                                ////////////console.log("combined2");
                                                ////////////console.log(allLaData2);

                                                var width = 900;
                                                var height = 180 * numberOfLAs;

                                                var height2 = 95 * numberOfLAs;

                                                var proData = allLaData;
                                                var proData2 = allLaData2;
                                        
                                                var events = true;
                                            
                                                const blue = "#FF5092";
                                                const green = "#FF9788";
                                                const purple = "#FFCB66";
                                                const background = "rgba(255, 255, 255, 0)";
                                                const defaultMargin = { top: 0, right: 20, bottom: 0, left: 95 };
                                        
                                              
                                                
                                                function max(arr, fn) {
                                                    return Math.max(...arr.map(fn));
                                                }
                                                const keys = Object.keys(proData[0]).filter((d) => d !== "date");

                                                const keys2 = Object.keys(proData2[0]).filter((d) => d !== "date");
                                                const formatDate = (date) => date;
                                                const getDate = (d) => d.date;
                                                const dateScale = scaleBand({
                                                    domain: proData.map(getDate),
                                                    padding: 0.2
                                                });
                                                const dateScale2 = scaleBand({
                                                    domain: proData2.map(getDate),
                                                    padding: 0.2
                                                });

                                                const cityScale = scaleBand({
                                                    domain: keys,
                                                    padding: 0.1
                                                });
                                                const cityScale2 = scaleBand({
                                                    domain: keys2,
                                                    padding: 0.1
                                                });

                                                const tempScale = scaleLinear({
                                                    domain: [0, max(proData, (d) => max(keys, (key) => Number(d[key])))]
                                                });
                                                const tempScale2 = scaleLinear({
                                                    domain: [0, max(proData2, (d) => max(keys2, (key) =>Number (d[key])))]
                                                });
                                                ////////////console.log("scale");
                                                ////////////console.log(tempScale2);

                                                const colorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: [blue, green, purple]
                                                });
                                                const colorScale2 = scaleOrdinal({
                                                    domain: keys2,
                                                    range: [blue, green, purple]
                                                });

                                                var margin = defaultMargin;

                                                const xMax = 580 - margin.left - margin.right;
                                                const yMax = height - margin.top - margin.bottom;

                                                const yMax2 = height2 - margin.top - margin.bottom;

                                                dateScale.rangeRound([0, yMax]);
                                                cityScale.rangeRound([0, dateScale.bandwidth()]);
                                                tempScale.rangeRound([0, xMax]);

                                                dateScale2.rangeRound([0, yMax2]);
                                                cityScale2.rangeRound([0, dateScale2.bandwidth()]);
                                                tempScale2.rangeRound([0, xMax]);

                                                const x = d => d.letter;
                                                const y = d => +d.frequency * 100;

                                                // bounds
                                                // scales

                                                // const xScale = scaleBand({
                                                //     rangeRound: [0, xMax],
                                                //     domain: data.map(x),
                                                //     padding: 0.4
                                                // });
                                                // const yScale = scaleLinear({
                                                //     rangeRound: [yMax, 0],
                                                //     domain: [0, max(data, y)]
                                                // });
                                                const ordinalColorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: ['#FF5092', '#FF9788', '#FFCB66', '#7d81f6'],
                                                });

                                                const ordinalColorScale2 = scaleOrdinal({
                                                    domain: keys2,
                                                    range: ['#FF5092', '#FF9788', '#FFCB66', '#7d81f6'],
                                                });
                                    
                                                return(
                                                    <>
                                                        <div key={c+90001}>
                                                            {/* summarReportStatus[0] */}
                                                            <span style={{display: sumReportStatus[0].bool == true ? 'inline' : 'none'}}>

                                                                <div style={{ borderBottom: '1px solid black', padding: '20px', width:'590px'}} key={Math.random()}>
                                                                    <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px' }}>{widget.label}</h6>
                                                                </div>
                                                                <div key={904} style={{paddingTop: '20px'}}>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', backgroundColor:'#e8e9eb', border:'2px solid #cccccc', alignItems:'center', flexWrap:'wrap', width:'590px' }}>
                                                                                
                                                                        <div style={{ width: '100%', height:height}} >
                                                                            <>
                                                                            <svg width={'590px'} height={'100%'} ref={containerRef}>
                                                                                
                                                                                <rect
                                                                                    x={0}
                                                                                    y={0}
                                                                                    width={'100%'}
                                                                                    height={'100%'}
                                                                                    fill={background}
                                                                                    rx={0}
                                                                                />
                                                                                <Group top={margin.top} left={margin.left}>
                                                                                    <BarGroupHorizontal
                                                                                        data={proData}
                                                                                        keys={keys}
                                                                                        width={"100%"}
                                                                                        y0={getDate}
                                                                                        y0Scale={dateScale}
                                                                                        y1Scale={cityScale}
                                                                                        xScale={tempScale}
                                                                                        color={colorScale}
                                                                                    >
                                                                                        {(barGroups) =>
                                                                                            barGroups.map((barGroup) => {
                                                                                            count15= count15 +1;
                                                                                            count16 = 0;
                                                                                            return(
                                                                                                <Group
                                                                                                    key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                                                                                                    top={barGroup.y0}
                                                                                                >
                                                                                                    {barGroup.bars.map((bar) => {
                                                                                                    count16 =count16 +1;
                                                                                                    let x = count16
                                                                                                    return(
                                                                                                        <Bar
                                                                                                            key={`${barGroup.index}-${bar.index}-${bar.key}`}
                                                                                                            x={bar.x}
                                                                                                            y={bar.y}
                                                                                                            width={bar.width}
                                                                                                            height={'30px'}
                                                                                                            fill={bar.color}
                                                                                                            rx={0}
                                                                                                            
                                                                                                            onMouseOver={(e,d) => {
                                                                                                                let keysss = Object.keys(proData[0]);
                                                                                                                handleMouseOver(e,d,bar.value,keysss[x]);
                                                                                                            }}
                                                                                                            onMouseOut={hideTooltip}
                                                                                                        />
                                                                                                    )})}
                                                                                                    
                                                                                                </Group>
                                                                                            )})
                                                                                        }
                                                                                    </BarGroupHorizontal>
                                                                                    <AxisLeft
                                                                                        top={0}
                                                                                        scale={dateScale}
                                                                                        stroke={green}
                                                                                        tickStroke={green}
                                                                                        tickFormat={formatDate}
                                                                                        hideAxisLine = {true}
                                                                                        hideTicks = {true}
                                                                                        tickLabelProps={() => ({
                                                                                        fill: '#291351',
                                                                                        fontSize: 16,
                                                                                        width:'1px',
                                                                                        textAnchor: 'end',
                                                                                        dy: '0.33em',
                                                                                        })}
                                                                                    />
                                                                                    
                                                                                    
                                                                                    </Group>
                                                                            
                                                                            </svg>
                                                                            {tooltipOpen && (
                                                                                <TooltipWithBounds
                                                                                // set this to random so it correctly updates with parent bounds
                                                                                key={Math.random()}
                                                                                top={tooltipTop}
                                                                                left={tooltipLeft}
                                                                                >
                                                                                {tooltipData}
                                                                                </TooltipWithBounds>
                                                                            )}
                                                                            
                                                                                
                                                                            
                                                                            </>
                                                                        </div>
                                                                        <div style={{ width: '590px'}} >
                                                                            <div style={{padding:'20px', marginLeft: "25px"}}>
                                                                                    
                                                                                <div style={{border:'0px solid #cccccc'}}>  
                                                                                    <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label}`}>
                                                                                        {(labels) => (
                                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            {labels.map((label, i) => (
                                                                                                <LegendItem
                                                                                                key={`legend-quantile-${i}`}
                                                                                                margin="10px 5px"
                                                                                                onClick={() => {
                                                                                                    if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                                                                                }}
                                                                                                >
                                                                                                <svg width={legendGlyphSize} height={legendGlyphSize}>
                                                                                                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                                                                                </svg>
                                                                                                <LegendLabel align="left" style={{margin:'0 0 0 10px', color:'#291351'}}>
                                                                                                    {label.text}
                                                                                                </LegendLabel>
                                                                                                </LegendItem>
                                                                                            ))}
                                                                                            </div>
                                                                                        )}
                                                                                    </LegendOrdinal>
                                                                                </div> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </span>

                                                            {validatedLAs.map(la => {
                                                                indxCounter = indxCounter + 2;
                                                                let ind1= indxCounter - 1;
                                                                let ind2= indxCounter;
                                                                var width = 900;
                                                                var height = 300;

                                                                t=t+1;
                                                                var barData = [];
                                                                //////////////console.log("t:: " + t);
                                                                ////////////console.log(widget["laChartData"][t]["cData"]);                     
                                                                widget["laChartData"][t]["cData"].forEach(chart => {
                                                                    if(chart[0] == "bar"){
                                                                        barData.push(chart[2]);
                                                                    }
                                                                })
                                                                var proData = barData;
                                                                
                                                                //////////////console.log(barData);
                                                        
                                                                var events = true;
                                                                const data = [
                                                                    { 'date': '2011-10-01', 'New York': '63.4', 'San Francisco': '62.7', 'Austin': '20.2' },
                                                                    { 'date': '2011-10-02', 'New York': '58.0', 'San Francisco': '59.9', 'Austin': '67.7' },
                                                                    { 'date': '2011-10-03', 'New York': '53.3', 'San Francisco': '9.1', 'Austin': '2.4' },
                                                                    { 'date': '2011-10-04', 'New York': '5.7', 'San Francisco': '5.8', 'Austin': '5.0' }
                                                                ]
                                                        
                                                                // barchartcolors#
                                                                const blue = "#FF5092";
                                                                const green = "#FF9788";
                                                                const purple = "#FFCB66";
                                                                const background = "rgba(255, 255, 255, 0)";
                                                                const defaultMargin = { top: 0, right: 20, bottom: 0, left: 50 };
                                                        
                                                               
                                                                
                                                                function max(arr, fn) {
                                                                    return Math.max(...arr.map(fn));
                                                                }
                                                                const keys = Object.keys(proData[0]).filter((d) => d !== "date");
                                                                const getDate = (d) => d.date;
                                                                const dateScale = scaleBand({
                                                                    domain: proData.map(getDate),
                                                                    padding: 0.2
                                                                });
                                                                const cityScale = scaleBand({
                                                                    domain: keys,
                                                                    padding: 0.1
                                                                });
                                                                const tempScale = scaleLinear({
                                                                    domain: [0, max(proData, (d) => max(keys, (key) => Number(d[key])))]
                                                                });
                                                                const colorScale = scaleOrdinal({
                                                                    domain: keys,
                                                                    range: [blue, green, purple]
                                                                });
                                                                var margin = defaultMargin;

                                                                const xMax = 530 - margin.left - margin.right;
                                                                const yMax = 150 - margin.top - margin.bottom;

                                                                dateScale.rangeRound([0, yMax]);
                                                                cityScale.rangeRound([0, dateScale.bandwidth()]);
                                                                tempScale.rangeRound([0, xMax]);

                                                                const x = d => d.letter;
                                                                const y = d => +d.frequency * 100;

                                                                // bounds
                                                                // scales

                                                                const xScale = scaleBand({
                                                                    rangeRound: [0, xMax],
                                                                    domain: data.map(x),
                                                                    padding: 0.4
                                                                });
                                                                const yScale = scaleLinear({
                                                                    rangeRound: [yMax, 0],
                                                                    domain: [0, max(data, y)]
                                                                });
                                                                ////console.log(sumReportStatus);

                                                                var BackgroundHeadingStyle;
                                                                var marginTopForXyPie

                                                                if (sumReportStatus[ind1].class == 'Background data' && sumReportStatus[ind1].bool == false ) {
                                                                  BackgroundHeadingStyle = { borderBottom: '1px solid black', padding: '20px', width: '590px', display:'none'};
                                                                } else {
                                                                  BackgroundHeadingStyle = { borderBottom: '1px solid black', padding: '20px', width: '590px'};
                                                                }

                                                                if (sumReportStatus[0].bool == false ) {
                                                                  marginTopForXyPie = { borderBottom: '1px solid black', padding: '20px', width: '590px'}
                                                                } else {
                                                                  marginTopForXyPie = { borderBottom: '1px solid black', padding: '20px', width: '590px', marginTop: numberOfLAs==1 ? '70px':'350px'}
                                                                }
                                                                
                                                                return(
                                                                    <div style={BackgroundHeadingStyle}>

                                                                        {/*<h6>{la}</h6>*/}
                                                                        <div style={marginTopForXyPie} key={9000001}>
                                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px' }}>Registered ultra low emission cars and vans</h6>
                                                                            </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px', flexDirection:'column', width: '590px'}}>
                                                                            
                                                                            <div style={{ width: '100%', backgroundColor:'#e8e9eb', border:'2px solid #cccccc', display:  sumReportStatus[ind1].bool == true ? 'block' : 'none'  }} >
                                                                                <div style={{width:'100%', height:'350px', backgroundColor:'#e8e9eb'}}>
                                                                                    <Example widget={widget} index={t} width={350} height={350}/>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                             <div style={{width: '100%', backgroundColor:'#e8e9eb', border:'2px solid #cccccc', display:  sumReportStatus[ind2].bool == true ? 'block' : 'none', marginTop:'50px'  }}>
                                                                                 <div style={{width:'100%', height:'350px', backgroundColor:'grey'}}>
                                                                                    <MyPie widget={widget} index={t}/>
                                                                                 </div> 
                                                                             </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                               
                                                        </div>
                                                    </>

                                                );


                                            }                                                       
                                        }
                                    })}
                                </div>


                            {/* ----second tab code start---- */}

                                <div style={{display:  sumReportStatus[indxCounter+1].bool == true ? 'block' : 'none', marginTop: numberOfLAs==1 ? '20px':'380px' }}>

                                    <h6 className="sub-title" >Maps</h6>

                                    <div style={{ margin: '20px 0', width:'590px' }} key={90000}>
                                        <div style={{ borderBottom: '1px solid #291351', padding: '20px', width:'590px'}} key={9001}>
                                            <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px' }}>Sites selected</h6>
                                        </div>

                                        <div style={{padding: '20px 0px 0px'}}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', border:'2px solid #cccccc', backgroundColor:'#e8e9eb', flexDirection:'row' }}>
                                                <div style={{ width: '80%', height: '350px' }}>
                                                    <img src={allLaMap} alt="BigCo Inc. logo" style={{ width:'100%', height:'100%'}}/>
                                                </div>

                                                <div style={{ width: '20%', paddingLeft:'10px' }}>
                                                    <div>
                                                        {LAs.map((la,id) => {
                                                            //////////console.log(la);
                                                            //////////console.log(id);
                                                            return(
                                                                <div className="freightText" style={{ margin: '40px 0px', color: '#291351' }}> <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px' }}>{la}: {laCounts[id]}</h6></div>

                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            {/* ----third tab code start---- */}

                                <h6 className="sub-title">Site score</h6>

                                <div style={{margin: '20px 0'}}>
                                    {keyss.map(aLA => {
                                        indxCounter = indxCounter + 1;
                                        c = c + 1;
                                        return (
                                            <div key={c + 250}>

                                                <div>
                                                    {/*<h6 className="sub-title">{data2Return[aLA][1].length == 0 ? null : data2Return[aLA][0]}</h6>*/}
                                                    <div className='pinsContainer' style={{width:'590px'}}>
                                                        {processedRankData2Return[aLA][1].map((card, index) => {
                                                            return (
                                                                <SummaryReportCards key={index} card={card} index={index} c={c} subData2Return={location.state.sD2R} setSubData2Return={setSubData2Return} laIndex={aLA} isLoading={isLoading} />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>


                            {/* ----fourth tab code start---- */}

                                <h6 className="sub-title">Site Scores</h6>

                                <div style={{ margin: '20px 0', width:'590px' }} key={90000}>
                                    <div style={{ borderBottom: '1px solid #291351', padding: '20px', display:'flex', justifyContent:'space-between'}} key={900001}>
                                        <div style={{width:'3%'}}>
                                            <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px' }}>#</h6>
                                        </div>
                                        <div style={{width:'18%'}}>
                                            <h6 className={hBools[0] == true ? 'sub-title sub-sub-title selectedHeading' : 'sub-title sub-sub-title'} style={{ marginBottom: '0px', cursor:'pointer' }} onClick={(e) => { rankHeading('Site name', 0); }}>Site name</h6>
                                        </div>
                                        <div style={{width:'18%'}}>
                                            <h6 className={hBools[1] == true ? 'sub-title sub-sub-title selectedHeading' : 'sub-title sub-sub-title'} style={{ marginBottom: '0px', cursor:'pointer' }} onClick={(e) => { rankHeading('Local authority', 1); }}>Local authority</h6>
                                        </div>
                                        <div style={{width:'9.8%'}}>
                                            <h6 className={hBools[2] == true ? 'sub-title sub-sub-title selectedHeading' : 'sub-title sub-sub-title'} style={{ marginBottom: '0px', cursor:'pointer' }} onClick={(e) => { rankHeading('Score', 2); }}>Score</h6>
                                        </div>
                                        {extraHeadings.map(heading => {
                                            indx2 = indx2 + 1;
                                            //////////console.log(indx2);
                                            let temp = indx2;
                                            //////////console.log(temp);
                                            
                                            return(
                                                <div style={{width:'9.8%'}} key = {Math.random()}>
                                                    <h6 className={hBools[3+temp] == true ? 'sub-title sub-sub-title selectedHeading' : 'sub-title sub-sub-title'} style={{ marginBottom: '0px', cursor:'pointer' }} onClick={(e) => { rankHeading(heading.label, 3+temp)}}>{heading.label}</h6>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div style={{padding: '20px 0px'}}>
                                        {keyss.map(aLA =>{
                                            c = c+1;
                                            return (
                                                <>
                                                {processedRankData2Return[aLA][1].map((card, index) => {
                                                            return (
                                                                <PinsRankList key={index} card={card}/>
                                                            )
                                                        })}
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>


                            {/* ----fifth tab code start---- */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ width: '48%' }}>
                                        <button type={"button"} className={"generalButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { window.print(); }}>
                                            <span className="favorit">Print</span>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </section>

                    </div>

            </div>


        );
    }
}

export default PrintReport;