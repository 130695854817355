import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';

import Layout from '../components/layout/Layout';
import '../pages/main.css';
import MapMenuArea from '../components/MapMenuArea';
import LACounters from '../components/LACounters';
import SummaryReportCards from '../components/mapdetails/summaryReportCards';
import ReportPieCards from '../components/mapdetails/ReportPieCards';
import PinsRankList from '../components/mapdetails/PinsRankList';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import MyPie from '../components/charts/MyPie.js';
import MyBar from '../components/charts/MyBar.js';
import XYChartt from '../components/charts/XYChart';
import XYChart3 from '../components/charts/XYChart3';
import EVBarCustom from '../components/charts/EVBarCustom.js';

import { BarGroupHorizontal, Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import { useTooltip, useTooltipInPortal, TooltipWithBounds, withTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import {
    AnimatedAxis, // any of these can be non-animated equivalents
    AnimatedGrid,
    AnimatedLineSeries,
    buildChartTheme,
    XYChart,
    Tooltip,
} from '@visx/xychart';

import { vtsC, sumReportStatusC, allLaMapC, factorsContext, minMaxObjContext, evCounterC, priorityStateContext, countContext, data2ReturnContext, LAMarkerCountContext, LAStateContext, expandedContext, updateMeContext, genralDataC, nameInputC, orgInputC, emailInputC, projectNumberC } from '../Store';

//dont remove !!!
const oneLong = ["sample"];

const APIkey = "AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";

var tempSRSj = {};

var lightTrue = true;

function Location(props) {
    const [LAs, setLAs] = useContext(LAStateContext);
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [genralData, setGenralData] = useContext(genralDataC);
    const [evCounter, setEvCounter] = useContext(evCounterC);
    const [allLaMap, setAllLaMap] = useContext(allLaMapC);
    const [factors2, setFactors2] = useContext(factorsContext);
    const [sumReportStatus, setSumReportStatus] = useContext(sumReportStatusC);
    const [vts, setVTS] = useContext(vtsC);
    const [nameInput, setNameInput] = useContext(nameInputC);
    const [orgInput, setOrgInput] = useContext(orgInputC);
    const [emailInput, setEmailInput] = useContext(emailInputC);
    const [projectNumber, setProjectNumber] = useContext(projectNumberC);


    const [subData2Return, setSubData2Return] = useState(null);
    const [processedData2Return, setProcessedData2Return] = useState(null);
    const [processedRankData2Return, setProcessedRankData2Return] = useState(null);
    const [tBools, setTBools] = useState([true, false, false]);
    const [hBools, setHBools] = useState([true, false, false, false, false, false, false, false, false, false, false, false]);
    const [isLoading, setLoading] = useState(true);
    const [widgetGrouppp, setWidgetGrouppp] = useState([]);
    const [currentHeadingOrder, setCurrentHeadingOrder] = useState([]);
    const [extraHeadings, setExtraHeadings] = useState([]);
    const [laCounts, setLaCounts] = useState([]);
    const [srsGroup1, setSRSGroup1] = useState([]);
    const [srsGroup2, setSRSGroup2] = useState([]);
    const [srsGroup3, setSRSGroup3] = useState([]);
    const [srsGroup4, setSRSGroup4] = useState([]);
    const [evWidth, setEvWidth] = useState(1100);
    const [updateMee, setUpdateMee] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [URL, setURL] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [validatedLAs2Send, setValidatedLAs2Send] = useState(false);

    const location = useLocation();
    const modalRef = useRef(null);

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const {
        showTooltip
    } = useTooltip();
    //to show url to save/send
    function modalOpen2() {
        modalRef.current.classList.add("modalVisible");
        setModalOpen(true);
    }

    function modalClose() {
        modalRef.current.classList.remove("modalVisible");
        setModalOpen(false);
    }

    const handleMouseOver = (event, datum, dat, label) => {
        var sum = "" + label + " " + dat;

        const coords = localPoint(event.target.ownerSVGElement, event);

        showTooltip({
            tooltipLeft: event.pageX,
            tooltipTop: event.pageY,
            tooltipData: sum
        });
    };

    const uploadReport = async (url, json) => {
        console.log("jsonB4");
        console.log(json);
        const newJson = JSON.stringify(json);
        console.log("jsonSent");
        console.log(newJson);

        modalOpen2();

        console.log(url);

        return fetch(url, {
            method: "PUT",
            body: newJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };

    const uploadPinsToProject = async (url, json) => {
        console.log("jsonB4");
        console.log(json);
        const newJson = JSON.stringify(json);
        console.log("jsonSent");
        console.log(newJson);

        console.log(url);

        return fetch(url, {
            method: "PUT",
            body: newJson,
            headers: {
                "Content-Type": 'application/json'
            },
        });
    };

    async function getURL(data) {
        let url = await fetch("https://cleanstreetserver.herokuapp.com/v1/s3URL", {cache: "no-store"});
        url = await url.json();
        //setURL(url, data);
        //console.log(url.uploadURL);
        //console.log(data);
        //split url, set a value to be used as a link
        var splitz = url.uploadURL.split("?");
        splitz = splitz[0].split("/");
        splitz = splitz[splitz.length - 1];
        var url2send = "https://www.placekits.com/MyReport/" + splitz;
        setURL(url2send);
        //console.log(url2send);

        await uploadReport(url.uploadURL, data);
    }

    async function getProjURL(tempSD2R) {
        let tempUrl = "https://cleanstreetserver.herokuapp.com/v1/s3URL/" + projectNumber;
        let url = await fetch(tempUrl, {cache: "no-store"});
        url = await url.json();
        getPreviousProjPins(url.uploadURL, tempSD2R);
    }

    async function getPreviousProjPins(projURL, tempSD2R) {
        //fetch project pin list (if exists)
        try {
            let tURL = "https://clean-street-json-store.s3.eu-west-2.amazonaws.com/" + projectNumber;
            let res = await fetch(tURL, {cache: "no-store"});
            res = await res.json();
            console.log("1");
            getPinsForProj(res, projURL, tempSD2R);
        } catch {

            let emptyList = [];
            console.log("2");
            getPinsForProj(emptyList, projURL, tempSD2R);

        }
    }

    async function getPinsForProj(previousList, projURL, tempSD2R) {
        //old list of pins (or empty)
        console.log(previousList);
        var oldList = previousList;

        console.log(tempSD2R);
        //collects pins to upload to a project number
        //populate newPins

        tempSD2R.forEach(lA => {
            lA[1].forEach(pin => {
                let int1 = 0;
                let int2 = 0;
                let int3 = 0;
                let intCount1 = 0;
                let intCount2 = 0;
                let intCount3 = 0;
                let scoreSkipped = false;

                if (pin[0][9][0] == 3 && pin[0][9][1] == 3 && pin[0][9][2] == 3) {
                    scoreSkipped = true;

                } else {
                    int1 = pin[0][9][0];
                    int2 = pin[0][9][1];
                    int3 = pin[0][9][2];
                    intCount1 = 1;
                    intCount2 = 1;
                    intCount3 = 1;
                }

                let comment;
                if (pin[0][11] == ""){
                    comment = [];
                } else {
                    comment = [pin[0][11]];
                }

                let tempPin = {
                    "streetName": pin[0][3],
                    "laName": pin[0][4],
                    "coords": [pin[0][2][0], pin[0][2][1]],
                    "avgScore": int1,
                    "avgScore2": int2,
                    "avgScore3": int3,
                    "ratingCounter": intCount1,
                    "ratingCounter2": intCount2,
                    "ratingCounter3": intCount3,
                    "nameOfPlacer": nameInput,
                    "orgOfPlacer": orgInput,
                    "emailOfPlacer": emailInput,
                    "dateOfPlacement": date,
                    "comment": comment
                }
                oldList.push(tempPin);
            })
        })

        uploadPinsToProject(projURL, oldList);
    }




    async function uploadNewPins(tempSD2R) {
        await getProjURL(tempSD2R);

    }




    function handleHistory() {
        if (tabIndex == 4) {
            //change  sub later
            //console.log(srsGroup3);
            // //console.log(srsGroup4);
            //console.log(location.state.sD2R);

            let indexsToRemove = [];
            let initIndex = 0;
            let initIndexSet = false;
            srsGroup3.forEach(pin => {
                if (initIndexSet == false) {
                    initIndex = pin.index;
                    initIndexSet = true;
                }
                if (pin.bool == false) {
                    let t = pin.index - initIndex;
                    indexsToRemove.push(t);
                }
            })
            //console.log("indexs2remove:");
            //console.log(indexsToRemove);

            //remove images from sD2R and processedRankData2Return

            let tempSD2R = [];
            let tempC = 0;
            location.state.sD2R.forEach(lA => {
                tempSD2R.push([]);
                tempSD2R[tempC].push(lA[0]);
                tempSD2R[tempC].push([]);

                let tempPinC = 0;
                lA[1].forEach(pin => {
                    //console.log(pin);
                    tempSD2R[tempC][1].push([]);

                    pin.forEach(dSet => {
                        let tempDSet = dSet;

                        tempDSet[7] = "";
                        tempDSet[8] = "";

                        //console.log(tempDSet);
                        tempSD2R[tempC][1][tempPinC].push(tempDSet);
                    })


                    tempPinC = tempPinC + 1;
                })
                tempC = tempC + 1;
            })



            let tempProcessedRankData2Return = [];
            tempC = 0;
            processedData2Return.forEach(lA => {
                tempProcessedRankData2Return.push([]);
                tempProcessedRankData2Return[tempC].push(lA[0]);
                tempProcessedRankData2Return[tempC].push([]);

                let tempPinC = 0;
                lA[1].forEach(pin => {
                    //console.log(pin);
                    tempProcessedRankData2Return[tempC][1].push([]);

                    pin.forEach(dSet => {
                        let tempDSet = dSet;

                        tempDSet[7] = "";
                        tempDSet[8] = "";

                        //console.log(tempDSet);
                        tempProcessedRankData2Return[tempC][1][tempPinC].push(tempDSet);
                    })


                    tempPinC = tempPinC + 1;
                })
                tempC = tempC + 1;
            })
            //console.log(processedRankData2Return);
            //console.log(tempProcessedRankData2Return);



            // var json2Send = {sD2R: tempSD2R, widgetGrouppp: widgetGrouppp, laCounts: laCounts,
            //     processedRankData2Return: processedRankData2Return, extraHeadings: extraHeadings,
            //     group1:srsGroup1, group2:srsGroup2, group3:srsGroup3, group4:srsGroup4, validatedLAs2Send:validatedLAs2Send,
            //     allLaMap:allLaMap, indexsToRemove: indexsToRemove, evCounter:evCounter};
            var json2Send = {
                LAs: LAs,
                minMaxObj: minMaxObj,
                genralData: genralData,
                evCounter: evCounter,
                allLaMap: allLaMap,
                factors2: factors2,
                sumReportStatus: sumReportStatus,
                vts: vts,
                sD2R: tempSD2R
            }

            getURL(json2Send);
            if (projectNumber != null) {
                uploadNewPins(tempSD2R);
            }

            console.log(json2Send);

            // history.push({ 
            //     pathname: '/grt/1',
            //     state: {sD2R: location.state.sD2R, widgetGrouppp: widgetGrouppp, laCounts: laCounts,
            //     processedRankData2Return: processedRankData2Return, extraHeadings: extraHeadings, group1:srsGroup1, group2:srsGroup2, group3:srsGroup3, group4:srsGroup4}
            // });
        } else {
            if (location.state.original == false) {
                if (tabIndex == 3) {
                    handleTabButton(0);
                    setTabIndex(0);
                } else {
                    handleTabButton(tabIndex + 1);
                    setTabIndex(tabIndex + 1);
                }
            } else {
                handleTabButton(tabIndex + 1);
                setTabIndex(tabIndex + 1);
            }

        }
    }
    //to make bar width dynamic 

    var validatedLAs = LAs.map(element => {
        //var stringed = element.replace(/\s/g, '%20');
        return element;
    });

    var numberOfLAs = validatedLAs.length;

    var c = 0;
    var count15 = -1;
    var count16 = 0;

    ////////////
    // 0  bool           //       False deafult 
    // 1 class           //       Bar   deafult 
    // 2 component title //       Electric vehicles and charge points // conidtional but there 
    // 3 related LAs     //       Newcastle Gates York //todo
    // 4 index           //       0 // func
    // SRS = [[0,1,2,3,4]]

    const evBarRef = useRef(null);

    useEffect(() => {
        // The 'current' property contains info of the reference:
        // align, title, ... , width, height, etc.
        if (evBarRef.current) {
            setEvWidth(evBarRef.current.offsetWidth);
        }
    }, [evBarRef, updateMee]);

    useEffect(() => {
        if (typeof location.state.sD2R !== 'undefined' && location.state.sD2R != null) {

            var validatedLAs = LAs.map(element => {
                return element;
            });

            setValidatedLAs2Send(validatedLAs);

            var newFactors = [];

            factors2.forEach(factor => {
                if (factor["importance"] > 2) {
                    if (factor["genralDatasets"].length == 0 && factor["relatedDatasets"].length == 0) {

                    } else {
                        newFactors.push(factor);
                    }
                }
            })

            var tempAllChartData = [];
            var count11 = 0;
            genralData.forEach(dSet => {
                tempAllChartData.push([]);
                let count12 = 0;
                validatedLAs.forEach(la => {
                    tempAllChartData[count11].push([]);
                    tempAllChartData[count11][count12].push(la);
                    tempAllChartData[count11][count12].push([]);
                    tempAllChartData[count11][count12][1].push(dSet[1]);
                    let temp = dSet[1];
                    tempAllChartData[count11][count12][1].push(dSet[0][count12].feature[0][temp]);

                    count12 = count12 + 1;

                })
                count11 = count11 + 1;
            })
            ////console.log(tempAllChartData);

            var widgetGroupData = [];
            widgetGroupData.push({});
            widgetGroupData[0]["label"] = "Electric vehicles and charge points";
            widgetGroupData[0]["charts"] = {};
            //widgetGroupData[0]["charts"]["pie"] = {"cName": "Vehicles", "cData": []};

            widgetGroupData[0]["charts"]["innerPie1"] = { "varName": "innerPie1", "cName": "Vehicles", "cData": [] };
            widgetGroupData[0]["charts"]["innerPie2"] = { "varName": "innerPie2", "cName": "nonEv", "cData": [] };
            widgetGroupData[0]["charts"]["outerPie"] = { "varName": "outerPie", "cName": "allVehicles", "cData": [] };
            widgetGroupData[0]["charts"]["bar"] = { "varName": "bar", "cName": "Vehicles", "cData": [] };
            widgetGroupData[0]["charts"]["combinedBar"] = { "varName": "combinedBar", "cName": "Vehicles", "cData": [] };
            widgetGroupData[0]["charts"]["combinedBar2"] = { "varName": "combinedBar2", "cName": "Vehicles", "cData": [] };
            widgetGroupData[0]["charts"]["xy"] = { "varName": "xy", "cName": "ulev", "cData": [] };


            var count13 = 0;
            validatedLAs.forEach(la => {
                widgetGroupData[0]["charts"]["innerPie1"]["cData"].push([]);
                widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13].push([]);


                widgetGroupData[0]["charts"]["innerPie2"]["cData"].push([]);
                widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13].push([]);

                widgetGroupData[0]["charts"]["outerPie"]["cData"].push([]);
                widgetGroupData[0]["charts"]["outerPie"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["outerPie"]["cData"][count13].push([]);

                widgetGroupData[0]["charts"]["bar"]["cData"].push([]);
                widgetGroupData[0]["charts"]["bar"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["bar"]["cData"][count13].push([]);

                widgetGroupData[0]["charts"]["combinedBar"]["cData"].push([]);
                widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13].push([]);

                widgetGroupData[0]["charts"]["combinedBar2"]["cData"].push([]);
                widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13].push([]);

                widgetGroupData[0]["charts"]["xy"]["cData"].push([]);
                widgetGroupData[0]["charts"]["xy"]["cData"][count13].push(la);
                widgetGroupData[0]["charts"]["xy"]["cData"][count13].push([]);





                let dSetName = "Plug-in hybrid electric vehicles";
                let dSetAtt = "CurULEV";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetAtt) {
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1][0].push(dSetName);
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1][0].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][0].push(dSetName);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][0].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][0].push(dSetName);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][0].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1][0].push(dSetName);
                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1][0].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1][0].push(dSetName);
                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1][0].push(dSet[count13][1][1]);
                    }
                })
                let dSetName2 = "Battery Electric Vehicles";
                let dSetAtt2 = "CurBEV";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName2);
                    if (dSet[0][1][0] == dSetAtt2) {
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1][1].push(dSetName2);
                        widgetGroupData[0]["charts"]["innerPie1"]["cData"][count13][1][1].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][1].push(dSetName2);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][1].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][1].push(dSetName2);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][1].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1][1].push(dSetName2);
                        widgetGroupData[0]["charts"]["bar"]["cData"][count13][1][1].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1][1].push(dSetName2);
                        widgetGroupData[0]["charts"]["combinedBar"]["cData"][count13][1][1].push(dSet[count13][1][1]);
                    }
                })

                let dSetName3 = "Cars";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName3);
                    if (dSet[0][1][0] == dSetName3) {
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][2].push(dSetName3);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][2].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][2].push(dSetName3);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][2].push(dSet[count13][1][1]);
                    }
                })

                let dSetName4 = "DieselCars";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName4);
                    if (dSet[0][1][0] == dSetName4) {
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][3].push(dSetName4);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][3].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][3].push(dSetName4);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][3].push(dSet[count13][1][1]);
                    }
                })

                let dSetName5 = "DieselVans";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName5);
                    if (dSet[0][1][0] == dSetName5) {
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][4].push(dSetName5);
                        widgetGroupData[0]["charts"]["innerPie2"]["cData"][count13][1][4].push(dSet[count13][1][1]);

                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][4].push(dSetName5);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][4].push(dSet[count13][1][1]);
                    }
                })

                // let dSetName6 ="TotalULEVs2045";
                // tempAllChartData.some(dSet => {
                //     //////////////console.log(dSet[0][1][0]+ " " + dSetName6);
                //     if (dSet[0][1][0] == dSetName6){
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1].push([]);
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1][5].push(dSetName6);
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1][5].push(dSet[count13][1][1]);

                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][5].push(dSetName6);
                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][5].push(dSet[count13][1][1]);
                //     }
                // })

                // let dSetName7 ="TotalBEVs2045";
                // tempAllChartData.some(dSet => {
                //     //////////////console.log(dSet[0][1][0]+ " " + dSetName7);
                //     if (dSet[0][1][0] == dSetName7){
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1].push([]);
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1][6].push(dSetName7);
                //         widgetGroupData[0]["charts"]["pie"]["cData"][count13][1][6].push(dSet[count13][1][1]);

                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][6].push(dSetName7);
                //         widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][6].push(dSet[count13][1][1]);
                //     }
                // })

                let dSetName8 = "Total";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName8);
                    if (dSet[0][1][0] == dSetName8) {
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][5].push(dSetName8);
                        widgetGroupData[0]["charts"]["outerPie"]["cData"][count13][1][5].push(dSet[count13][1][1]);
                        //////////console.log(dSet[count13][1][1]);
                        //////////console.log(dSet);

                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1][0].push(dSetName8);
                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1][0].push(dSet[count13][1][1]);
                    }
                })

                let dSetName9 = "Population";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName8);
                    if (dSet[0][1][0] == dSetName9) {
                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1][1].push(dSetName9);
                        widgetGroupData[0]["charts"]["combinedBar2"]["cData"][count13][1][1].push(dSet[count13][1][1]);
                    }
                })

                let dSetName10 = "ULEV2021Q3";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName8);
                    if (dSet[0][1][0] == dSetName10) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][0].push(dSetName10);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][0].push(dSet[count13][1][1]);
                    }
                })

                let dSetName11 = "ULEV2021Q2";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName8);
                    if (dSet[0][1][0] == dSetName11) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][1].push(dSetName11);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][1].push(dSet[count13][1][1]);
                    }
                })

                let dSetName12 = "ULEV2021Q1";
                tempAllChartData.some(dSet => {
                    //////////////console.log(dSet[0][1][0]+ " " + dSetName8);
                    if (dSet[0][1][0] == dSetName12) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][2].push(dSetName12);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][2].push(dSet[count13][1][1]);
                    }
                })



                let dSetName13 = "ULEV2020Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName13) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][3].push(dSetName13);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][3].push(dSet[count13][1][1]);
                    }
                })
                let dSetName14 = "ULEV2020Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName14) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][4].push(dSetName14);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][4].push(dSet[count13][1][1]);
                    }
                })
                let dSetName15 = "ULEV2020Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName15) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][5].push(dSetName15);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][5].push(dSet[count13][1][1]);
                    }
                })
                let dSetName16 = "ULEV2020Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName16) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][6].push(dSetName16);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][6].push(dSet[count13][1][1]);
                    }
                })
                let dSetName17 = "ULEV2019Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName17) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][7].push(dSetName17);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][7].push(dSet[count13][1][1]);
                    }
                })
                let dSetName18 = "ULEV2019Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName18) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][8].push(dSetName18);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][8].push(dSet[count13][1][1]);
                    }
                })
                let dSetName19 = "ULEV2019Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName19) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][9].push(dSetName19);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][9].push(dSet[count13][1][1]);
                    }
                })
                let dSetName20 = "ULEV2019Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName20) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][10].push(dSetName20);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][10].push(dSet[count13][1][1]);
                    }
                })
                let dSetName21 = "ULEV2018Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName21) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][11].push(dSetName21);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][11].push(dSet[count13][1][1]);
                    }
                })
                let dSetName22 = "ULEV2018Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName22) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][12].push(dSetName22);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][12].push(dSet[count13][1][1]);
                    }
                })
                let dSetName23 = "ULEV2018Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName23) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][13].push(dSetName23);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][13].push(dSet[count13][1][1]);
                    }
                })
                let dSetName24 = "ULEV2018Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName24) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][14].push(dSetName24);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][14].push(dSet[count13][1][1]);
                    }
                })
                let dSetName25 = "ULEV2017Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName25) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][15].push(dSetName25);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][15].push(dSet[count13][1][1]);
                    }
                })
                let dSetName26 = "ULEV2017Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName26) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][16].push(dSetName26);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][16].push(dSet[count13][1][1]);
                    }
                })
                let dSetName27 = "ULEV2017Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName27) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][17].push(dSetName27);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][17].push(dSet[count13][1][1]);
                    }
                })
                let dSetName28 = "ULEV2017Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName28) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][18].push(dSetName28);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][18].push(dSet[count13][1][1]);
                    }
                })
                let dSetName29 = "ULEV2016Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName29) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][19].push(dSetName29);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][19].push(dSet[count13][1][1]);
                    }
                })
                let dSetName30 = "ULEV2016Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName30) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][20].push(dSetName30);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][20].push(dSet[count13][1][1]);
                    }
                })
                let dSetName31 = "ULEV2016Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName31) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][21].push(dSetName31);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][21].push(dSet[count13][1][1]);
                    }
                })
                let dSetName32 = "ULEV2016Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName32) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][22].push(dSetName32);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][22].push(dSet[count13][1][1]);
                    }
                })
                let dSetName33 = "ULEV2015Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName33) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][23].push(dSetName33);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][23].push(dSet[count13][1][1]);
                    }
                })
                let dSetName34 = "ULEV2015Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName34) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][24].push(dSetName34);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][24].push(dSet[count13][1][1]);
                    }
                })
                let dSetName35 = "ULEV2015Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName35) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][25].push(dSetName35);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][25].push(dSet[count13][1][1]);
                    }
                })
                let dSetName36 = "ULEV2015Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName36) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][26].push(dSetName36);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][26].push(dSet[count13][1][1]);
                    }
                })
                let dSetName37 = "ULEV2014Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName37) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][27].push(dSetName37);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][27].push(dSet[count13][1][1]);
                    }
                })
                let dSetName38 = "ULEV2014Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName38) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][28].push(dSetName38);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][28].push(dSet[count13][1][1]);
                    }
                })
                let dSetName39 = "ULEV2014Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName39) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][29].push(dSetName39);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][29].push(dSet[count13][1][1]);
                    }
                })
                let dSetName40 = "ULEV2014Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName40) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][30].push(dSetName40);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][30].push(dSet[count13][1][1]);
                    }
                })
                let dSetName41 = "ULEV2013Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName41) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][31].push(dSetName41);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][31].push(dSet[count13][1][1]);
                    }
                })
                let dSetName42 = "ULEV2013Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName42) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][32].push(dSetName42);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][32].push(dSet[count13][1][1]);
                    }
                })
                let dSetName43 = "ULEV2013Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName43) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][33].push(dSetName43);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][33].push(dSet[count13][1][1]);
                    }
                })
                let dSetName44 = "ULEV2013Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName44) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][34].push(dSetName44);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][34].push(dSet[count13][1][1]);
                    }
                })
                let dSetName45 = "ULEV2012Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName45) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][35].push(dSetName45);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][35].push(dSet[count13][1][1]);
                    }
                })
                let dSetName46 = "ULEV2012Q3";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName46) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][36].push(dSetName46);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][36].push(dSet[count13][1][1]);
                    }
                })
                let dSetName47 = "ULEV2012Q2";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName47) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][37].push(dSetName47);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][37].push(dSet[count13][1][1]);
                    }
                })
                let dSetName49 = "ULEV2012Q1";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName49) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][38].push(dSetName49);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][38].push(dSet[count13][1][1]);
                    }
                })
                let dSetName50 = "ULEV2011Q4";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName50) {
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1].push([]);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][39].push(dSetName50);
                        widgetGroupData[0]["charts"]["xy"]["cData"][count13][1][39].push(dSet[count13][1][1]);
                    }
                })

                count13 = count13 + 1;

            })
            var extraWidgetCounter = 0;

            if (vts == true) {
                extraWidgetCounter = extraWidgetCounter + 1;
                widgetGroupData.push({});
                widgetGroupData[1]["label"] = "Vehicle types";
                widgetGroupData[1]["charts"] = {};

                widgetGroupData[1]["charts"]["bodyTypeTabel"] = { "varName": "bodyTypeTabel", "cName": "Body types ", "cData": [] };

                var count13 = 0;
                validatedLAs.forEach(la => {
                    widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"].push([]);
                    widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13].push(la);
                    widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13].push([]);

                    let dSetAtt = "Cars";
                    tempAllChartData.some(dSet => {
                        if (dSet[0][1][0] == dSetAtt) {
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1].push([]);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][0].push(dSetAtt);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][0].push(dSet[count13][1][1]);
                        }
                    })
                    let dSetAtt2 = "Motorcycles";
                    tempAllChartData.some(dSet => {
                        if (dSet[0][1][0] == dSetAtt2) {
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1].push([]);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][1].push(dSetAtt2);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][1].push(dSet[count13][1][1]);
                        }
                    })
                    let dSetAtt3 = "LightGoodsVehicles";
                    tempAllChartData.some(dSet => {
                        if (dSet[0][1][0] == dSetAtt3) {
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1].push([]);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][2].push(dSetAtt3);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][2].push(dSet[count13][1][1]);
                        }
                    })
                    let dSetAtt4 = "HeavyGoodsVehicles";
                    tempAllChartData.some(dSet => {
                        if (dSet[0][1][0] == dSetAtt4) {
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1].push([]);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][3].push(dSetAtt4);
                            widgetGroupData[1]["charts"]["bodyTypeTabel"]["cData"][count13][1][3].push(dSet[count13][1][1]);
                        }
                    })

                    count13 = count13 + 1;
                })
            }
            extraWidgetCounter = extraWidgetCounter + 1;
            widgetGroupData.push({});
            widgetGroupData[extraWidgetCounter]["label"] = "Forcast Data";
            widgetGroupData[extraWidgetCounter]["charts"] = {};
            widgetGroupData[extraWidgetCounter]["charts"]["forcast"] = { "varName": "forcast", "cName": "forcast", "cData": [] };

            var count13 = 0;
            validatedLAs.forEach(la => {
                widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"].push([]);
                widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13].push(la);
                widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13].push([]);

                //////////////////////////////////
                let dSetName51 = "OnStreetInfrastructureRequired2025";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName51) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][0].push(dSetName51);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][0].push(dSet[count13][1][1]);
                    }
                })
                let dSetName52 = "OnStreetInfrastructureRequired2030";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName52) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][1].push(dSetName52);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][1].push(dSet[count13][1][1]);
                    }
                })
                let dSetName53 = "OnStreetInfrastructureRequired2035";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName53) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][2].push(dSetName53);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][2].push(dSet[count13][1][1]);
                    }
                })
                let dSetName54 = "OnStreetInfrastructureRequired2040";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName54) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][3].push(dSetName54);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][3].push(dSet[count13][1][1]);
                    }
                })
                let dSetName55 = "OnStreetInfrastructureRequired2045";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName55) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][4].push(dSetName55);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][4].push(dSet[count13][1][1]);
                    }
                })
                let dSetName56 = "PredictedOnStreetEVs2025";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName56) {
                        ////console.log(dSet);      
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        //////console.log(widgetGroupData[0]["charts"]["forcast"]["cData"][count13][1]);
                        //widgetGroupData[0]["charts"]["forcast"]["cData"][count13][1][5].push(dSetName55);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][5].push(dSetName56);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][5].push(dSet[count13][1][1]);
                    }
                })
                let dSetName57 = "PredictedOnStreetEVs2030";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName57) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][6].push(dSetName57);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][6].push(dSet[count13][1][1]);
                    }
                })
                let dSetName58 = "PredictedOnStreetEVs2035";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName58) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][7].push(dSetName58);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][7].push(dSet[count13][1][1]);
                    }
                })
                let dSetName59 = "PredictedOnStreetEVs2040";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName59) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][8].push(dSetName59);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][8].push(dSet[count13][1][1]);
                    }
                })
                let dSetName60 = "PredictedOnStreetEVs2045";
                tempAllChartData.some(dSet => {
                    if (dSet[0][1][0] == dSetName60) {
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1].push([]);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][9].push(dSetName60);
                        widgetGroupData[extraWidgetCounter]["charts"]["forcast"]["cData"][count13][1][9].push(dSet[count13][1][1]);
                    }
                })
                count13 = count13 + 1;
            })



            ////console.log(widgetGroupData);
            var tempWidgetGroup = [];
            var count14 = 0;
            widgetGroupData.forEach(widget => {
                tempWidgetGroup.push({});
                tempWidgetGroup[count14]["label"] = widget["label"];
                tempWidgetGroup[count14]["type"] = [count14];
                tempWidgetGroup[count14]["laChartData"] = [];


                var count15 = 0;
                validatedLAs.forEach(la => {
                    tempWidgetGroup[count14]["laChartData"].push({ "laName": la, "cData": [] });
                    let cKeys = Object.keys(widget["charts"]);

                    var count18 = 0;

                    cKeys.forEach(chart => {
                        tempWidgetGroup[count14]["laChartData"][count15]["cData"].push([]);
                        tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(widget["charts"][chart]["varName"]);
                        tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(widget["charts"][chart]["cName"]);

                        //bespokeChartsDataProcessing 
                        if (widget["charts"][chart]["cName"] == "allVehicles") {


                            var outerPieData = {};
                            outerPieData["date"] = '2015 Jun 15';

                            var totalEv = 0;
                            var totalNonEv = 0;
                            //////////console.log(widget["charts"][chart]["cData"][count15][1]);
                            widget["charts"][chart]["cData"][count15][1].forEach(dSet => {

                                if (dSet[0] == "Plug-in hybrid electric vehicles" || dSet[0] == "Battery Electric Vehicles") {
                                    //////////console.log("addEV: "+dSet[1]);
                                    totalEv = totalEv + dSet[1];
                                    //////////console.log("totalEV: "+totalEv);
                                    //////////console.log("");
                                }
                                //|| dSet[0] == "DieselCars" || dSet[0] == "Cars" || dSet[0] == "DieselCars"
                                if (dSet[0] == "Total") {
                                    //////////console.log("Total: "+dSet[1]);

                                    totalNonEv = dSet[1];
                                }


                            })

                            totalNonEv = totalNonEv - totalEv;

                            outerPieData["EV"] = totalEv;
                            outerPieData["Non EV"] = totalNonEv;
                            // //////////console.log("ev/nonev");
                            // //////////console.log(totalEv);
                            // //////////console.log(totalNonEv);
                            tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(outerPieData);

                        } else if (widget["charts"][chart]["cName"] == "nonEv") {


                            var innerPie3 = [];


                            let diesel = 0;
                            let totalEv = 0;
                            let cars = 0;
                            widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                if (dSet[0] == "DieselCars" || dSet[0] == "DieselVans") {
                                    //////////console.log(totalEv);
                                    diesel = diesel + dSet[1];
                                }
                                //|| dSet[0] == "DieselCars" || dSet[0] == "Cars" || dSet[0] == "DieselCars"
                                if (dSet[0] == "Cars") {
                                    //////////////console.log("yes");
                                    cars = dSet[1];
                                }

                                if (dSet[0] == "Plug-in hybrid electric vehicles" || dSet[0] == "Battery Electric Vehicles") {
                                    totalEv = totalEv + dSet[1];
                                }


                            })
                            // //////////console.log(totalEv);
                            // //////////console.log(diesel);
                            // //////////console.log(cars);

                            cars = cars - diesel - totalEv



                            innerPie3.push({ "letter": "Petrol", "frequency": cars });
                            innerPie3.push({ "letter": "Diesel", "frequency": diesel });


                            tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(innerPie3);
                        } else if (widget["charts"][chart]["varName"] == "bodyTypeTabel") {
                            ////console.log(widget["charts"][chart]["cData"]);


                            let thisLA = {}
                            widget["charts"][chart]["cData"][count18][1].forEach(dSet => {
                                thisLA[dSet[0]] = dSet[1];

                            })
                            tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(thisLA);

                        }
                        //genralDataprocessing
                        else {
                            if (widget["charts"][chart]["varName"] == "innerPie1") {
                                var innerPie1 = [];
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    innerPie1.push({ "letter": dSet[0], "frequency": dSet[1] });
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(innerPie1);
                            }

                            if (widget["charts"][chart]["varName"] == "innerPie2") {
                                var innerPie2 = [];
                                innerPie2["date"] = '2015 Jun 15';
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    innerPie2.push({ "letter": dSet[0], "frequency": dSet[1] });
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(innerPie2);
                            }

                            if (widget["charts"][chart]["varName"] == "outerPie") {
                                var outerPieData = {};
                                outerPieData["date"] = '2015 Jun 15';
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    outerPieData[dSet[0]] = dSet[1];
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(outerPieData);
                            }

                            if (widget["charts"][chart]["varName"] == "bar") {

                                var bar = {};
                                bar["date"] = '2015 Jun 15';
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    bar[dSet[0]] = dSet[1];
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(bar);
                            }

                            if (widget["charts"][chart]["varName"] == "combinedBar") {
                                var bar = {};
                                bar["date"] = '2015 Jun 15';
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    bar[dSet[0]] = dSet[1];
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(bar);
                            }

                            if (widget["charts"][chart]["varName"] == "combinedBar2") {
                                var bar = {};
                                bar["date"] = '2015 Jun 15';
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    ////////////console.log("ee");
                                    ////////////console.log(dSet);
                                    bar[dSet[0]] = dSet[1];
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(bar);
                            }

                            if (widget["charts"][chart]["varName"] == "xy") {
                                var xyD = [];
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    xyD.push({ "x": dSet[0], "y": dSet[1] });
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(xyD);
                            }

                            if (widget["charts"][chart]["varName"] == "forcast") {
                                var xyD = [];
                                widget["charts"][chart]["cData"][count15][1].forEach(dSet => {
                                    xyD.push({ "x": dSet[0], "y": dSet[1] });
                                })
                                tempWidgetGroup[count14]["laChartData"][count15]["cData"][count18].push(xyD);
                            }

                        }

                        count18 = count18 + 1;
                    })

                    count15 = count15 + 1;
                })


                count14 = count14 + 1;
            })
            setWidgetGrouppp(tempWidgetGroup);
            ////console.log(tempWidgetGroup);

            var tempLaCounts = [];

            var tempRankings = [];
            var count5 = 0;

            var unsortedRankings = [];
            var newD2R = [];
            var tempSubData2Return = location.state.sD2R;
            //console.log("heere")
            //console.log(tempSubData2Return);

            var count = 0;
            tempSubData2Return.forEach(la => {
                tempLaCounts.push(0);
                var count20 = 0;
                newD2R.push([]);
                newD2R[count].push(la[0]);
                newD2R[count].push([]);

                unsortedRankings.push([]);


                let count2 = 0
                la[1].forEach(card => {
                    count20 = count20 + 1;
                    newD2R[count][1].push([]);
                    let dataTable = [];

                    var count6 = 0;
                    card[0][9].forEach(slider => {
                        dataTable.push({ label: card[0][10][count6], indivData: slider });

                        count6 += 1;
                    })

                    card.forEach(dset => {
                        let d = dset[0][1];
                        let a = dset[0][2];
                        let l = dset[0][0];
                        minMaxObj.forEach(obj => {
                            let keyy = Object.keys(obj);
                            if (keyy == a) {
                                let min = obj[keyy][0];
                                let max = obj[keyy][1];
                                let newD = d - min;
                                max = max - min;
                                newD = (newD / max) * 5;
                                dataTable.push({ label: l, indivData: newD });
                                ////////////console.log("dtI"); 
                                ////////////console.log([l,newD]);
                            }
                        })
                    })

                    //////////console.log(dataTable);
                    //////////console.log(factors2);

                    let newDataTable = [];
                    let ccc = 0;
                    let toRemove = [];
                    dataTable.forEach(datum => {
                        if (datum["label"] == "Strategic importance" || datum["label"] == "Accessibility" || datum["label"] == "Local amenities") {
                            newDataTable.push(datum);
                            toRemove.push(ccc);
                        }
                        ccc = ccc + 1;
                    })
                    let downC = 0;
                    toRemove.forEach(removeMe => {
                        dataTable.splice((removeMe - downC), 1);
                        //////////console.log(removeMe);
                        downC = downC + 1;
                    })



                    //////////console.log("subjectiveAdded");
                    //////////console.log(dataTable);
                    //////////console.log(newDataTable);

                    //*** reformatDataTabelHere ***

                    var newFactorGroups = [];
                    dataTable.forEach(datum => {
                        let lab = datum["label"];
                        let val = datum["indivData"];

                        let foundBool = false;
                        newFactors.forEach(factor => {
                            if (factor["genralDatasets"].length != 0) {
                                factor["genralDatasets"].forEach(dSet => {
                                    let splitz = dSet.split(".");
                                    if (splitz[3] == lab) {
                                        //newFactorGroups[factor["label"]] = {"label": factor["label"], "data":[]}
                                        newFactorGroups.push([factor["label"], lab, val]);
                                        foundBool = true;
                                    }
                                })
                            }
                            if (factor["relatedDatasets"].length != 0) {
                                factor["relatedDatasets"].forEach(dSet => {
                                    let splitz = dSet.split(".");
                                    if (splitz[3] == lab) {
                                        //newFactorGroups[factor["label"]] = {"label": factor["label"], "data":[]}
                                        newFactorGroups.push([factor["label"], lab, val]);
                                        foundBool = true;
                                    }
                                })
                            }
                        })


                    })
                    //////////console.log(newFactorGroups);

                    let tFactors = {};

                    newFactorGroups.forEach(f => {
                        tFactors[f[0]] = "";
                    })
                    tFactors = Object.keys(tFactors);

                    //////////console.log(tFactors);

                    let tempObj = {};
                    tFactors.forEach(factor => {
                        tempObj[factor] = [];
                    })

                    var newC = 0;
                    newFactorGroups.forEach(f => {
                        tFactors.forEach(l => {
                            if (f[0] == l) {
                                tempObj[l].push(f[2]);
                            }
                        })
                    })

                    //////////console.log(" // ");
                    //////////console.log(tempObj);

                    let newTempObj = [];
                    tFactors.forEach(factor => {
                        let numberOf = 0;
                        let totalC = 0;
                        tempObj[factor].forEach(num => {
                            numberOf = numberOf + 1;
                            totalC = totalC + num;
                        })
                        let averg = totalC / numberOf;
                        newTempObj.push({ "label": factor, "indivData": averg });
                    })

                    //////////console.log(newTempObj);
                    newTempObj.forEach(t => {
                        newDataTable.push(t);
                    })
                    //////////console.log(newDataTable);

                    dataTable = newDataTable;

                    var maximumScore = 0;
                    var tempTotalScore = 0;
                    dataTable.forEach(indivData => {
                        maximumScore += 5;
                        tempTotalScore += indivData.indivData;
                        ////////////////console.log(indivData.indivData)
                    })
                    ////////////console.log(tempTotalScore);
                    ////////////console.log(maximumScore);
                    var scorePercent = (tempTotalScore / maximumScore) * 100;
                    var intvalue = Math.round(scorePercent);
                    //

                    let count3 = 0;
                    card.forEach(dSet => {

                        if (count3 == 0) {
                            newD2R[count][1][count2].push([]);
                            var count4 = 0;
                            dSet.forEach(d => {
                                newD2R[count][1][count2][0].push(d);

                                count4 = count4 + 1;
                            })
                            newD2R[count][1][count2][0].push(dataTable);
                            newD2R[count][1][count2][0].push(intvalue);
                            unsortedRankings[count].push(intvalue)

                        } else {
                            newD2R[count][1][count2].push(dSet);
                        }
                        count3 = count3 + 1;
                    })

                    count2 = count2 + 1;
                    tempLaCounts[count] = count2;
                });

                count = count + 1;
            })
            //////////console.log(tempLaCounts);
            setLaCounts(tempLaCounts);
            ////////////console.log("un"+unsortedRankings);
            ////////////console.log(unsortedRankings);

            var sortedRankings = [];
            let count8 = 0;
            unsortedRankings.forEach(la => {
                let count7 = 0;
                sortedRankings.push([]);

                var numbers = la;
                ////////////////console.log(numbers);
                numbers.sort(function (a, b) {
                    return a - b;
                });

                numbers.reverse();
                ////////////////console.log(numbers);
                numbers.forEach(number => {
                    sortedRankings[count8].push([count7, number]);
                    count7 = count7 + 1;
                })
                count8 = count8 + 1;
            })

            var newNewD2R = [];
            var tempSubData2Return = newD2R;
            //console.log(tempSubData2Return);

            var count = 0;
            tempSubData2Return.forEach(la => {
                newNewD2R.push([]);
                newNewD2R[count].push(la[0]);
                newNewD2R[count].push([]);


                let count2 = 0
                la[1].forEach(card => {
                    newNewD2R[count][1].push([]);

                    //

                    let count3 = 0;
                    card.forEach(dSet => {

                        if (count3 == 0) {
                            newNewD2R[count][1][count2].push([]);
                            var count4 = 0;
                            dSet.forEach(d => {
                                newNewD2R[count][1][count2][0].push(d);

                                count4 = count4 + 1;
                            })
                            sortedRankings[count].some(pinRank => {
                                if (pinRank[1] == card[0][13]) {
                                    newNewD2R[count][1][count2][0].push((pinRank[0] + 1));
                                }
                            })

                        } else {
                            newNewD2R[count][1][count2].push(dSet);
                        }
                        count3 = count3 + 1;
                    })
                    count2 = count2 + 1;
                });

                count = count + 1;
            })
            ////console.log(newNewD2R);
            setProcessedData2Return(newNewD2R);


            let newNewNewD2R = [];
            var tempSubData2Return = newNewD2R;
            //console.log(tempSubData2Return);

            var count = 0;

            //this works just needs to fill in the array in order of best to worst that equals top to b
            tempSubData2Return.forEach(la => {
                newNewNewD2R.push([]);
                newNewNewD2R[count].push(la[0]);
                newNewNewD2R[count].push([]);

                let len = la[1].length;
                let pinCount = 0;

                la[1].forEach(card => {
                    newNewNewD2R[count][1].push([]);
                })


                let foundPins = 0;
                let tempPinsOrdered = []
                while (foundPins < len) {



                    la[1].forEach(card => {
                        if (pinCount == (card[0][14] - 1)) {
                            tempPinsOrdered.push(card);
                            foundPins = foundPins + 1;
                        }
                    });

                    pinCount = pinCount + 1;
                    //console.log(pinCount);
                    //console.log(tempPinsOrdered);
                }

                let newC = 0;
                tempPinsOrdered.forEach(pin => {
                    pin.forEach(dSet => {
                        newNewNewD2R[count][1][newC].push(dSet);
                    })
                    newC = newC + 1;
                })

                //console.log(newNewNewD2R);

                count = count + 1;
            })
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //let newNewNewD2R = newNewD2R;
            // let newNewNewD2R = [];
            // var tempSubData2Return = newNewD2R;
            // //console.log(tempSubData2Return);

            // var count = 0;

            // //this works just needs to fill in the array in order of best to worst that equals top to b
            // tempSubData2Return.forEach(la => {
            //     newNewNewD2R.push([]);
            //     newNewNewD2R[count].push(la[0]);
            //     newNewNewD2R[count].push([]);

            //     let len = la[1].length;
            //     let pinCount = 0;

            //     la[1].forEach(card => {
            //         newNewNewD2R[count][1].push([]);
            //     })

            //     while (pinCount < len) {

            //         la[1].forEach(card => {
            //             //console.log("card1");
            //             //console.log(card[0]);
            //             if (pinCount == (card[0][14] - 1)) {
            //                 //console.log("card2");
            //                 //console.log(card[0][14]);
            //                 if (newNewNewD2R[count][1][pinCount].length !== 0) {
            //                     var cc = pinCount + 1;

            //                     if (newNewNewD2R[count][1][cc].length !== 0) {
            //                         var cc = pinCount + 2;
            //                         card.forEach(dSet => {
            //                             newNewNewD2R[count][1][cc].push(dSet);
            //                         })

            //                     } else {
            //                         card.forEach(dSet => {
            //                             newNewNewD2R[count][1][cc].push(dSet);
            //                         })
            //                     }

            //                 } else {
            //                     card.forEach(dSet => {
            //                         newNewNewD2R[count][1][pinCount].push(dSet);
            //                     })

            //                 }

            //             }

            //         });
            //         pinCount = pinCount + 1;
            //     }

            //     count = count + 1;
            // })

            ////////////console.log('\n\n\n\n\n');
            //console.log(newNewNewD2R);
            setProcessedRankData2Return(newNewNewD2R);
            ////console.log(newNewNewD2R[0][1][0][0][12]);
            var filteredHeadings = [];
            // newNewNewD2R[0][1][0][0][12].forEach(t => {
            //     if(t["label"] != 'Strategic importance' && t["label"] != "Accessibility" && t["label"] != 'Local amenities'){
            //         filteredHeadings.push(t["label"]);
            //     }
            // })
            //////console.log(newNewNewD2R);
            try {
                setExtraHeadings(newNewNewD2R[0][1][0][0][12]);
            } catch (e) {
                try {
                    setExtraHeadings(newNewNewD2R[1][1][0][0][12]);
                } catch (e) {
                    try {
                        setExtraHeadings(newNewNewD2R[2][1][0][0][12]);
                    } catch (e) {
                        try {
                            setExtraHeadings(newNewNewD2R[3][1][0][0][12]);
                        } catch (e) {
                            //////console.log("no pins in any LAs")
                        }
                    }
                }
            }


            let lass = "";
            LAs.forEach(localA => {
                lass = lass + localA + ", ";
            })
            lass = lass.slice(0, -2)
            let fullLas = lass;
            let tempSRS = sumReportStatus;
            var count21 = 0;
            tempWidgetGroup.forEach(wid => {
                if (wid.label == "Electric vehicles and charge points") {
                    tempSRS[0] = { "bool": false, "class": "Background data", "componentTitle": "Electric vehicles and charge points", "relatedLAs": fullLas, "index": 0 };
                    //////////console.log("SRS Index: " + "0");

                    LAs.forEach(localA => {
                        count21 = count21 + 1;
                        let lass = localA;
                        tempSRS[count21] = { "bool": false, "class": "Background data", "componentTitle": "Registered ultra low emission cars and vans by year", "relatedLAs": lass, "index": count21 };
                        //////////console.log("SRS Index: " + count21);

                        count21 = count21 + 1;
                        tempSRS[count21] = { "bool": false, "class": "Background data", "componentTitle": "Registered ultra low emission cars and vans pie chart", "relatedLAs": lass, "index": count21 };
                        //////////console.log("SRS Index: " + count21);

                    })
                } else if (wid.label == "Vehicle types") {
                    LAs.forEach(localA => {
                        count21 = count21 + 1;
                        tempSRS[count21] = { "bool": false, "class": "Background data", "componentTitle": "Vehicle types", "relatedLAs": fullLas, "index": count21 };
                    })
                }
                else if (wid.label == "Forcast Data") {

                    count21 = count21 + 1;
                    tempSRS[count21] = { "bool": false, "class": "Background data", "componentTitle": "Predicted number of EVs on the road requiring on-street charge points", "relatedLAs": fullLas, "index": count21 };

                    count21 = count21 + 1;
                    tempSRS[count21] = { "bool": false, "class": "Background data", "componentTitle": "Predicted number of on-street charge points required", "relatedLAs": fullLas, "index": count21 };

                }
            })

            count21 = count21 + 1;
            tempSRS[count21] = { "bool": false, "class": "maps", "componentTitle": "Maps", "relatedLAs": fullLas, "index": count21 };

            //console.log(newNewNewD2R);
            let memSwap = newNewNewD2R
            LAs.forEach((localA, id) => {
                //console.log(memSwap[id][1]);
                ////console.log(newNewD2R[0][1]);

                ////console.log(newNewD2R[0][1][0]);

                memSwap[id][1].forEach(card => {
                    let n = card;
                    //console.log(n);
                    //console.log("card");
                    //console.log(n[0][4]);
                    count21 = count21 + 1;
                    try {
                        tempSRS[count21] = { "bool": false, "class": "siteScores", "componentTitle": typeof card[0][5] !== 'undefined' ? card[0][5] : card[0][4], "relatedLAs": localA, "index": count21 };
                    } catch (e) {
                        try {
                            tempSRS[count21] = { "bool": false, "class": "siteScores", "componentTitle": card[0][4], "relatedLAs": localA, "index": count21 };
                            //tempSRS[count21] = { "bool": false, "class": "siteScores", "componentTitle": typeof card[0][5] !== 'undefined' ? card[0][5] : card[0][4], "relatedLAs": localA, "index": count21};

                        } catch (e) {
                            //console.log(e);
                        }
                    }
                })

            })




            setSumReportStatus(tempSRS);
            //setTempSRS(tempSRS);
            //tempSRSj=tempSRS;


            //console.log("useEffectSRS");
            //console.log(tempSRSj);

            let keyzz = Object.keys(sumReportStatus);

            var backgroundArr = [];
            keyzz.forEach(keyyy => {
                if (sumReportStatus[keyyy].class == "Background data") {
                    backgroundArr.push(sumReportStatus[keyyy]);
                }
            })

            var mapsArr = [];
            keyzz.forEach(keyyy => {
                if (sumReportStatus[keyyy].class == "maps") {
                    mapsArr.push(sumReportStatus[keyyy]);
                }
            })

            var siteScores = [];
            keyzz.forEach(keyyy => {
                if (sumReportStatus[keyyy].class == "siteScores") {
                    siteScores.push(sumReportStatus[keyyy]);
                }
            })

            var rankings = [];
            keyzz.forEach(keyyy => {
                if (sumReportStatus[keyyy].class == "rankings") {
                    rankings.push(sumReportStatus[keyyy]);
                }
            })

            setSRSGroup1(backgroundArr);
            setSRSGroup2(mapsArr);
            setSRSGroup3(siteScores);
            setSRSGroup4(rankings);

            setLoading(false);

        }
    }, [subData2Return]);

    function handleTabButton(index) {

        var doSomething = true;
        var tempTBools = tBools;

        var count = 0;
        tempTBools.forEach(TBool => {
            if (TBool == true && count == index) {
                doSomething = false;
            }
            count = count + 1;
        })

        if (doSomething) {
            tempTBools = [false, false, false, false];
            tempTBools[index] = true;
            setTBools(tempTBools);
        } else {
            tempTBools = [true, false, false, false];
            setTBools(tempTBools);
        }
    }

    function rankHeading(selectedHeading, index) {
        ////console.log(selectedHeading);
        ////console.log(index);

        var doSomething = true;
        var tempHBools = hBools;

        var count = 0;
        tempHBools.forEach(HBool => {
            if (HBool == true && count == index) {
                doSomething = false;
            }
            count = count + 1;
        })

        if (doSomething) {
            tempHBools = [false, false, false, false, false, false, false, false, false, false, false, false];
            tempHBools[index] = true;
            setHBools(tempHBools);
        } else {
            tempHBools = [true, false, false, false, false, false, false, false, false, false, false, false];
            setHBools(tempHBools);
        }

        var tempCurrentHeadingOrder = currentHeadingOrder;
        if (currentHeadingOrder == []) {
            tempCurrentHeadingOrder = [selectedHeading, 'down'];
            setCurrentHeadingOrder(tempCurrentHeadingOrder);
        }

        if (currentHeadingOrder[0] == selectedHeading) {
            if (currentHeadingOrder[1] == 'down') {
                tempCurrentHeadingOrder = [selectedHeading, 'up'];
            } else {
                tempCurrentHeadingOrder = [selectedHeading, 'down'];
            }
        }
        else {
            tempCurrentHeadingOrder = [selectedHeading, 'down'];
            setCurrentHeadingOrder(tempCurrentHeadingOrder);
        }
        ////////////console.log(tempCurrentHeadingOrder);

        sortMe(tempCurrentHeadingOrder[0], [1])
    }

    function sortMe(selectedHeading, direction) {
        var unsortedRankings = [];
        var newD2R = [];
        var tempSubData2Return = processedRankData2Return;
        console.log("here");
        console.log(tempSubData2Return);
        //////////console.log(direction);

        var count = 0;
        tempSubData2Return.forEach(la => {
            newD2R.push([]);
            newD2R[count].push(la[0]);
            newD2R[count].push([]);

            unsortedRankings.push([]);


            let count2 = 0
            la[1].forEach(card => {
                newD2R[count][1].push([]);
                card[0][12].forEach(dSet => {
                    if (dSet.label == selectedHeading) {
                        ////////////console.log(selectedHeading);
                        ////////////console.log(dSet.indivData);
                        unsortedRankings[count].push(dSet.indivData);
                    }
                })
            });

            count = count + 1;
        })
        ////////////console.log("un"+unsortedRankings);
        ////////////console.log(unsortedRankings);

        var sortedRankings = [];
        let count8 = 0;
        unsortedRankings.forEach(la => {
            let count7 = 0;
            sortedRankings.push([]);

            var numbers = la;
            ////////////////console.log(numbers);
            numbers.sort(function (a, b) {
                return a - b;
            });

            numbers.reverse();
            ////////////////console.log(numbers);
            numbers.forEach(number => {
                sortedRankings[count8].push([count7, number]);
                count7 = count7 + 1;
            })
            count8 = count8 + 1;
        })
        console.log(sortedRankings);

        var newNewD2R = [];
        var tempSubData2Return = processedRankData2Return;
        ////////////console.log(tempSubData2Return);

        //la count
        var count = 0;
        tempSubData2Return.forEach(la => {
            newNewD2R.push([]);
            newNewD2R[count].push(la[0]);
            newNewD2R[count].push([]);

            //card Count
            let count2 = 0
            la[1].forEach(card => {
                newNewD2R[count][1].push([]);

                //dset count
                let count3 = 0;
                card.forEach(dSet => {

                    if (count3 == 0) {
                        newNewD2R[count][1][count2].push([]);
                        var count4 = 0;
                        dSet.forEach(d => {
                            newNewD2R[count][1][count2][0].push(d);

                            count4 = count4 + 1;
                        })
                        //bugMarker
                        var thisData;
                        card[0][12].forEach(dSet => {
                            if (dSet.label == selectedHeading) {
                                thisData = dSet;
                            }
                        })
                        var found = false;
                        sortedRankings[count].some(pinRank => {
                            if (found == false) {
                                if (thisData.indivData == pinRank[1]) {
                                    found = true;

                                    while (typeof newNewD2R[count][1][count2][0][14] != 'undefined') {
                                        newNewD2R[count][1][count2][0].pop();
                                    }

                                    newNewD2R[count][1][count2][0].push((pinRank[0] + 1));
                                }
                            }
                        })

                    } else {
                        newNewD2R[count][1][count2].push(dSet);
                    }
                    count3 = count3 + 1;
                })
                count2 = count2 + 1;
            });

            count = count + 1;
        })
        ////////////console.log(newNewD2R);

        var newNewNewD2R = [];
        var tempSubData2Return = newNewD2R;
        ////////////console.log(tempSubData2Return);#

        //seems good so far slot 14 is where the rank is stored 

        //la count
        var count = 0;
        tempSubData2Return.forEach(la => {
            newNewNewD2R.push([]);
            newNewNewD2R[count].push(la[0]);
            newNewNewD2R[count].push([]);

            let len = la[1].length;
            ////////////console.log("len:" + len);


            let count2 = 0;

            la[1].forEach(card => {
                newNewNewD2R[count][1].push([]);
            })

            while (count2 < len) {
                //for each pin
                la[1].forEach(card => {

                    //if the next pin is the next in order of ranking
                    if (count2 == (card[0][14] - 1)) {
                        //the code below is to account fo situations where you are sorting the pins and there needs to be x amount of pins that are being sorted 
                        // with the same ranking, The code below accounts for 30 pins in a row that have the same ranking (will probably break over 30 pins)
                        if (newNewNewD2R[count][1][count2].length !== 0) {
                            var cc = count2 + 1;
                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                var cc = count2 + 2;
                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                    var cc = count2 + 3;
                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                        var cc = count2 + 4;
                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                            var cc = count2 + 3;
                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                var cc = count2 + 4;
                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                    var cc = count2 + 5;
                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                        var cc = count2 + 6;
                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                            var cc = count2 + 7;
                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                var cc = count2 + 8;
                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                    var cc = count2 + 9;
                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                        var cc = count2 + 10;
                                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                            var cc = count2 + 11;
                                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                var cc = count2 + 12;
                                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                    var cc = count2 + 13;
                                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                        var cc = count2 + 14;
                                                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                            var cc = count2 + 15;
                                                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                var cc = count2 + 16;
                                                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                    var cc = count2 + 17;
                                                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                        var cc = count2 + 18;
                                                                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                            var cc = count2 + 19;
                                                                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                var cc = count2 + 20;
                                                                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                    var cc = count2 + 21;
                                                                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                        var cc = count2 + 22;
                                                                                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                            var cc = count2 + 23;
                                                                                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                var cc = count2 + 24;
                                                                                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                    var cc = count2 + 25;
                                                                                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                        var cc = count2 + 26;
                                                                                                                                        if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                            var cc = count2 + 27;
                                                                                                                                            if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                                var cc = count2 + 28;
                                                                                                                                                if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                                    var cc = count2 + 29;
                                                                                                                                                    if (newNewNewD2R[count][1][cc].length !== 0) {
                                                                                                                                                        var cc = count2 + 30;
                                                                                                                                                    } else {
                                                                                                                                                        card.forEach(dSet => {
                                                                                                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                                        })
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                    card.forEach(dSet => {
                                                                                                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            } else {
                                                                                                                                                card.forEach(dSet => {
                                                                                                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        } else {
                                                                                                                                            card.forEach(dSet => {
                                                                                                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    } else {
                                                                                                                                        card.forEach(dSet => {
                                                                                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    card.forEach(dSet => {
                                                                                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                card.forEach(dSet => {
                                                                                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                                })
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            card.forEach(dSet => {
                                                                                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                            })
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        card.forEach(dSet => {
                                                                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                        })
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    card.forEach(dSet => {
                                                                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                    })
                                                                                                                }
                                                                                                            } else {
                                                                                                                card.forEach(dSet => {
                                                                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                                                                })
                                                                                                            }
                                                                                                        } else {
                                                                                                            card.forEach(dSet => {
                                                                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                                                                            })
                                                                                                        }
                                                                                                    } else {
                                                                                                        card.forEach(dSet => {
                                                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                                                        })
                                                                                                    }
                                                                                                } else {
                                                                                                    card.forEach(dSet => {
                                                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                                                    })
                                                                                                }
                                                                                            } else {
                                                                                                card.forEach(dSet => {
                                                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                                                })
                                                                                            }
                                                                                        } else {
                                                                                            card.forEach(dSet => {
                                                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                                                            })
                                                                                        }
                                                                                    } else {
                                                                                        card.forEach(dSet => {
                                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                                        })
                                                                                    }
                                                                                } else {
                                                                                    card.forEach(dSet => {
                                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                                    })
                                                                                }
                                                                            } else {
                                                                                card.forEach(dSet => {
                                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                                })
                                                                            }
                                                                        } else {
                                                                            card.forEach(dSet => {
                                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                                            })
                                                                        }
                                                                    } else {
                                                                        card.forEach(dSet => {
                                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                                        })
                                                                    }
                                                                } else {
                                                                    card.forEach(dSet => {
                                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                                    })
                                                                }
                                                            } else {
                                                                card.forEach(dSet => {
                                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                                })
                                                            }
                                                        } else {
                                                            card.forEach(dSet => {
                                                                newNewNewD2R[count][1][cc].push(dSet);
                                                            })
                                                        }
                                                    } else {
                                                        card.forEach(dSet => {
                                                            newNewNewD2R[count][1][cc].push(dSet);
                                                        })
                                                    }
                                                } else {
                                                    card.forEach(dSet => {
                                                        newNewNewD2R[count][1][cc].push(dSet);
                                                    })
                                                }
                                            } else {
                                                card.forEach(dSet => {
                                                    newNewNewD2R[count][1][cc].push(dSet);
                                                })
                                            }
                                        } else {
                                            card.forEach(dSet => {
                                                newNewNewD2R[count][1][cc].push(dSet);
                                            })
                                        }
                                    } else {
                                        card.forEach(dSet => {
                                            newNewNewD2R[count][1][cc].push(dSet);
                                        })
                                    }
                                } else {
                                    card.forEach(dSet => {
                                        newNewNewD2R[count][1][cc].push(dSet);
                                    })
                                }
                            } else {
                                card.forEach(dSet => {
                                    newNewNewD2R[count][1][cc].push(dSet);
                                })
                            }
                        } else {
                            card.forEach(dSet => {
                                newNewNewD2R[count][1][count2].push(dSet);
                            })
                        }
                    }
                });
                count2 = count2 + 1;
            }
            count = count + 1;
        })
        console.log('\n\n\n\n\nHere 2');
        console.log(newNewNewD2R);
        setProcessedRankData2Return(newNewNewD2R);
    }

    function handleSRSUpdate(event) {
        ////console.log( event.target.value);
        ////console.log( event.target.checked);
        let tempSRS = sumReportStatus;
        //console.log("BEFORE SRS");
        //console.log(tempSRS);

        if (typeof event.target.value != 'undefined') {
            tempSRS[event.target.value].bool = event.target.checked
        }

        let keyzz = Object.keys(sumReportStatus);

        var newSRS = {};
        keyzz.forEach(keyyy => {

            newSRS[keyyy] = sumReportStatus[keyyy];

        })
        //console.log("AFTER");
        //console.log(newSRS);
        setSumReportStatus(newSRS);


        var backgroundArr = [];
        keyzz.forEach(keyyy => {
            if (sumReportStatus[keyyy].class == "Background data") {
                backgroundArr.push(sumReportStatus[keyyy]);
            }
        })

        var mapsArr = [];
        keyzz.forEach(keyyy => {
            if (sumReportStatus[keyyy].class == "maps") {
                mapsArr.push(sumReportStatus[keyyy]);
            }
        })

        var siteScores = [];
        keyzz.forEach(keyyy => {
            if (sumReportStatus[keyyy].class == "siteScores") {
                siteScores.push(sumReportStatus[keyyy]);
            }
        })

        var rankings = [];
        keyzz.forEach(keyyy => {
            if (sumReportStatus[keyyy].class == "rankings") {
                rankings.push(sumReportStatus[keyyy]);
            }
        })

        setSRSGroup1(backgroundArr);
        setSRSGroup2(mapsArr);
        setSRSGroup3(siteScores);
        setSRSGroup4(rankings);
        //console.log(siteScores);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    if (isLoading) {
        return (
            <div style={{ height: "100vh", width: "100vw", backgroundColor: "#261B3F", position: "fixed", justifyContent: "center", textAlign: "center" }}>
                <p style={{ textAlign: "center", color: "#fff", fontSize: "32px", lineHeight: "100vh" }}>Loading...</p>
            </div>
        );
    } else {
        //////console.log("reportReload");
        var keyss = Object.keys(processedData2Return);
        let indx = 0;
        ////////console.log(factors2);
        let indx2 = -1;
        let indxCounter = -1;
        //console.log(processedRankData2Return);

        ////console.log(widgetGrouppp);
        let lass = "";
        LAs.forEach(localA => {
            lass = lass + localA + ", ";
        })
        lass = lass.slice(0, -2)
        let fullLas = lass;

        return (
            <Layout >
                <div ref={modalRef} style={{ height: '50vh', position: 'fixed', top: '25vh', left: '0', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: '10000', display: 'none', justifyContent: 'center', alignItems: 'center' }} className={modalOpen ? 'modalVisible' : ''}>
                    <div style={{ marginLeft: '50px', backgroundColor: 'rgba(255, 255, 255, 1)', width: '80%', height: '85%', position: 'relative', boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', padding: '40px', marginBottom: "68px" }}>
                        <div style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }} onClick={() => { modalClose() }}>
                            <i class="fa fa-close" style={{ fontSize: '20px', color: '#ddd' }}></i>
                        </div>
                        <div>
                            <h6 className="sub-title">A saved version of your personal report can be found at:</h6>
                            <h6 className="sub-title">{URL}</h6>
                            <br />
                            {
                                projectNumber != null ?
                                    <h6 className="sub-title">Please view chargepoint reviews under our tools for all the pins placed under this project number.</h6>
                                    :
                                    null
                            }
                            


                            {/* <div style={{ position:'absolute', bottom:'15px', right:'40px', cursor:'pointer', width:'24%'}}>
                                <button className="nextButton" onClick={() => {modalClose()}}>close</button> 
                            </div> */}

                        </div>
                    </div>
                </div>

                <div style={{ background: "#ededed", height: "90px", display: "flex", paddingLeft: "19%", alignItems: "center" }}>
                    <h1 style={{ fontSize: "30px" }} className="main-title">Summary Report
                        <span style={{ float: 'right', fontSize: '16px' }} className={tBools[2] == true ? 'classOn' : 'classOff'}>
                            {/* <form style={{ lineHeight:'3.5rem'}}>
                                <label for="cars">Filter By :&nbsp;</label>
                                <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="opel">Opel</option>
                                <option value="audi">Audi</option>
                                </select>
                                </form> */}
                        </span>
                    </h1>
                </div>

                {location.state.original == true ?
                    <div style={{ margin: "44px 19%", marginBottom: "35px", height: "10px", width: "62%", position: "relative" }}>

                        <div style={{ position: "absolute", color: "#b2b2b2", top: "-23px", left: "81%" }} className="favoritMono">Step 5/5</div>
                        <div style={{ position: "absolute", color: "#261b3f", top: "-23px", right: "0", float: "right" }} className="favoritMono">100%</div>
                        <div class="barz" style={{ position: "absolute", height: "10px", width: "100%", }}></div>

                        <div style={{ background: "#ffffff", position: "absolute", height: "20px", width: "1.25%", zIndex: "99", left: "19%", top: "-4px" }}></div>
                        <div style={{ background: "#ffffff", position: "absolute", height: "20px", width: "1.25%", zIndex: "99", left: "39.25%", top: "-4px" }}></div>
                        <div style={{ background: "#ffffff", position: "absolute", height: "20px", width: "1.25%", zIndex: "99", left: "59.5%", top: "-4px" }}></div>
                        <div style={{ background: "#ffffff", position: "absolute", height: "20px", width: "1.25%", zIndex: "99", left: "79.75%", top: "-4px" }}></div>

                        <div style={{ position: "absolute", height: "10px", width: "100%", zIndex: "98" }} className="barzTop"></div>
                    </div>
                    : null
                }


                <div key={c + 850} className={'main'}>

                    <div className="tab" style={{ position: 'fixed', top: '275px', left: "calc( 19% - 102px)" }}>
                        <button
                            className={tBools[0] == true ? "tabSelected2" :
                                "tablinks"}
                            onClick={() => {
                                handleTabButton(0);
                                setTabIndex(0);
                            }}
                        >
                            <img src="/REPORTBackgroundDataIcon.svg" height='27' width='27' className={tBools[0] == true ? "activesidebarIcons" :
                                "inActivesidebarIcons"} />
                        </button>

                        <button
                            className={tBools[1] == true ? "tabSelected2" :
                                "tablinks"}
                            onClick={() => {
                                handleTabButton(1);
                                setTabIndex(1);
                            }}
                        >
                            <img src="/REPORTMapIcon.svg" height='27' width='27' className={tBools[1] == true ? "activesidebarIcons" :
                                "inActivesidebarIcons"} />
                            {/* <span className={tBools[1] == true ? "activesidebarIconsIcon":"inActivesidebarIconsIcon"}></span> */}
                        </button>

                        <button
                            className={tBools[2] == true ? "tabSelected2" :
                                "tablinks"}
                            onClick={() => {
                                handleTabButton(2);
                                setTabIndex(2);
                            }}
                        >
                            <img src="/REPORTSiteScoreIcon.svg" height='27' width='27' className={tBools[2] == true ? "activesidebarIcons" :
                                "inActivesidebarIcons"} />
                            {/* <span className={tBools[2] == true ? "activesidebarIconsIcon":"inActivesidebarIconsIcon"}><i class="fa fa-chrome"></i></span> */}
                        </button>

                        <button
                            className={tBools[3] == true ? "tabSelected2" :
                                "tablinks"}
                            onClick={() => {
                                handleTabButton(3);
                                setTabIndex(3);
                            }}
                        >
                            <img src="/REPORTSiteRankingIcon.svg" height='27' width='27' className={tBools[3] == true ? "activesidebarIcons" :
                                "inActivesidebarIcons"} />
                            {/* <span className={tBools[3] == true ? "activesidebarIconsIcon":"inActivesidebarIconsIcon"}><i class="fa fa-list-ul"></i></span> */}
                        </button>

                        {location.state.original == true ?
                            <button
                                className={tBools[4] == true ? "tabSelected2" :
                                    "tablinks"}
                                onClick={() => {
                                    handleTabButton(4);
                                    setTabIndex(4);
                                }}
                            >
                                <img src="/REPORTDataIcon.svg" height='27' width='27' className={tBools[4] == true ? "activesidebarIcons" :
                                    "inActivesidebarIcons"} />
                                {/* <span className={tBools[4] == true ? "activesidebarIconsIcon":"inActivesidebarIconsIcon"}><i class="fa fa-file-text-o"></i></span> */}
                            </button>
                            : null
                        }




                    </div>

                    <div style={{ width: '100%' }}>

                        {/* ----tabs code start---- */}
                        <section>

                            {/* ----first tab code start---- */}
                            <div className={tBools[0] == true ? 'classOn' : 'classOff'} style={{ marginBottom: '40px' }}>

                                <h6 className="sub-title" style={{ fontSize: "32px" }}>Background data</h6>

                                <div style={{ margin: '40px 0' }} ref={evBarRef} >
                                    {widgetGrouppp.map((widget) => {
                                        c = c + 1;

                                        let index = indx;
                                        indx = indx + 1;



                                        //////////////console.log("indexx: "+index);
                                        let t = -1;
                                        ////////////
                                        // 0  bool           //       False deafult 
                                        // 1 class           //       Bar   deafult 
                                        // 2 component title //       Electric vehicles and charge points // conidtional but there 
                                        // 3 related LAs     //       Newcastle Gates York //todo
                                        // 4 index           //       0 // func
                                        // SRS = [[0,1,2,3,4]]

                                        if (typeof widget != "undefined") {
                                            if (widget.type == 0) {

                                                ////console.log(widgetGrouppp);
                                                indxCounter = indxCounter + 1;

                                                c = c + 1;
                                                var allLaData = [];
                                                var allLaData2 = [];
                                                ////////////console.log(widget);
                                                ////////////console.log(evCounter);
                                                widget["laChartData"].forEach(localA => {
                                                    //laName
                                                    localA["cData"].forEach(chart => {
                                                        if (chart[0] == "combinedBar") {
                                                            var tempArr = [];
                                                            tempArr.push(["date", localA["laName"]]);
                                                            let keyz = Object.keys(chart[2]);
                                                            keyz.forEach(key => {
                                                                if (key != "date") {
                                                                    tempArr.push([key, chart[2][key]]);

                                                                }
                                                            })
                                                            evCounter.forEach(lA => {
                                                                if (lA[0] == localA["laName"]) {
                                                                    tempArr.push([["Existing charge points"], lA[1]]);
                                                                }
                                                            })
                                                            var newObj = {};
                                                            tempArr.forEach(temp => {
                                                                newObj[temp[0]] = temp[1];
                                                            })
                                                            allLaData.push(newObj);
                                                        }
                                                    })

                                                })
                                                widget["laChartData"].forEach(localA => {
                                                    //laName
                                                    localA["cData"].forEach(chart => {
                                                        if (chart[0] == "combinedBar2") {
                                                            var tempArr = [];
                                                            tempArr.push(["date", localA["laName"]]);
                                                            let keyz = Object.keys(chart[2]);
                                                            keyz.forEach(key => {
                                                                if (key != "date") {
                                                                    tempArr.push([key, chart[2][key]]);

                                                                }
                                                            })
                                                            var newObj = {};
                                                            tempArr.forEach(temp => {
                                                                newObj[temp[0]] = temp[1];
                                                            })
                                                            allLaData2.push(newObj);
                                                        }
                                                    })

                                                })



                                                var width = 900;
                                                var height = 180 * numberOfLAs;

                                                var height2 = 95 * numberOfLAs;

                                                var proData = allLaData;
                                                ////console.log("data1");
                                                ////console.log(proData);

                                                var events = true;

                                                const blue = "#FF5092";
                                                const green = "#FF9788";
                                                const purple = "#FFCB66";
                                                const background = "#ededed";
                                                const defaultMargin = { top: 0, right: 20, bottom: 0, left: 95 };

                                                function max(arr, fn) {
                                                    return Math.max(...arr.map(fn));
                                                }
                                                const keys = Object.keys(proData[0]).filter((d) => d !== "date");

                                                const formatDate = (date) => date;
                                                const getDate = (d) => d.date;
                                                const dateScale = scaleBand({
                                                    domain: proData.map(getDate),
                                                    padding: 0.2
                                                });

                                                const cityScale = scaleBand({
                                                    domain: keys,
                                                    padding: 0.1
                                                });

                                                const tempScale = scaleLinear({
                                                    domain: [0, max(proData, (d) => max(keys, (key) => Number(d[key])))]
                                                });

                                                const colorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: [blue, green, purple]
                                                });

                                                var margin = defaultMargin;
                                                var xMax = evWidth - margin.left - margin.right - 400;



                                                // if (refReady){
                                                //     ////console.log(evBarRef);
                                                //     xMax = 
                                                // } else {
                                                //     xMax = 1100 - margin.left - margin.right;
                                                // }

                                                const yMax = height - margin.top - margin.bottom;

                                                dateScale.rangeRound([0, yMax]);
                                                cityScale.rangeRound([0, dateScale.bandwidth()]);
                                                tempScale.rangeRound([0, xMax]);


                                                const x = d => d.letter;
                                                const y = d => +d.frequency * 100;

                                                const ordinalColorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: ['#FF5092', '#FF9788', '#FFCB66', '#7d81f6'],
                                                });



                                                return (
                                                    <>
                                                        <div key={c + 90001}>

                                                            <div style={{ padding: '20px 0px 0px 0px' }} key={Math.random()}>
                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '15px' }}>{widget.label}</h6>
                                                            </div>

                                                            <div key={904} >
                                                                <EVBarCustom
                                                                    height={height}
                                                                    background={background}
                                                                    marginTopV={margin.top}
                                                                    marginLeftV={margin.left}
                                                                    getDate={getDate}
                                                                    dateScale={dateScale}
                                                                    cityScale={cityScale}
                                                                    tempScale={tempScale}
                                                                    colorScale={colorScale}
                                                                    proData={proData}
                                                                    keys={keys}

                                                                    green={green}
                                                                    formatDate={formatDate}
                                                                    ordinalColorScale={ordinalColorScale}
                                                                    handleSRSUpdate={handleSRSUpdate}
                                                                    checked={sumReportStatus[indxCounter].bool == true ? true : false}
                                                                />

                                                            </div>

                                                            {validatedLAs.map((la, id) => {
                                                                indxCounter = indxCounter + 2;

                                                                let tempSRS = sumReportStatus;
                                                                //////////console.log(sumReportStatus);
                                                                let keyzz = Object.keys(sumReportStatus);
                                                                var lastItem = keyzz.pop();
                                                                let v = parseInt(0);
                                                                v = v + parseInt(id) + parseInt(lastItem) + 1;
                                                                //////////console.log("id:" +v);
                                                                //////////console.log(v);

                                                                let ind1 = indxCounter - 1;
                                                                let ind2 = indxCounter;

                                                                var width = 900;
                                                                var height = 300;

                                                                t = t + 1;
                                                                var barData = [];
                                                                //////////////console.log("t:: " + t);
                                                                ////////////console.log(widget["laChartData"][t]["cData"]);                     
                                                                widget["laChartData"][t]["cData"].forEach(chart => {
                                                                    if (chart[0] == "bar") {
                                                                        barData.push(chart[2]);
                                                                    }
                                                                })
                                                                var proData = barData;

                                                                //////////////console.log(barData);

                                                                var events = true;
                                                                const data = [
                                                                    { 'date': '2011-10-01', 'New York': '63.4', 'San Francisco': '62.7', 'Austin': '20.2' },
                                                                    { 'date': '2011-10-02', 'New York': '58.0', 'San Francisco': '59.9', 'Austin': '67.7' },
                                                                    { 'date': '2011-10-03', 'New York': '53.3', 'San Francisco': '9.1', 'Austin': '2.4' },
                                                                    { 'date': '2011-10-04', 'New York': '5.7', 'San Francisco': '5.8', 'Austin': '5.0' }
                                                                ]

                                                                // barchartcolors#
                                                                const blue = "#FF5092";
                                                                const green = "#FF9788";
                                                                const purple = "#FFCB66";
                                                                const background = "rgba(255, 255, 255, 0)";
                                                                const defaultMargin = { top: 0, right: 20, bottom: 0, left: 50 };



                                                                function max(arr, fn) {
                                                                    return Math.max(...arr.map(fn));
                                                                }
                                                                const keys = Object.keys(proData[0]).filter((d) => d !== "date");
                                                                const getDate = (d) => d.date;
                                                                const dateScale = scaleBand({
                                                                    domain: proData.map(getDate),
                                                                    padding: 0.2
                                                                });
                                                                const cityScale = scaleBand({
                                                                    domain: keys,
                                                                    padding: 0.1
                                                                });
                                                                const tempScale = scaleLinear({
                                                                    domain: [0, max(proData, (d) => max(keys, (key) => Number(d[key])))]
                                                                });
                                                                const colorScale = scaleOrdinal({
                                                                    domain: keys,
                                                                    range: [blue, green, purple]
                                                                });
                                                                var margin = defaultMargin;

                                                                const xMax = 530 - margin.left - margin.right;
                                                                const yMax = 150 - margin.top - margin.bottom;

                                                                dateScale.rangeRound([0, yMax]);
                                                                cityScale.rangeRound([0, dateScale.bandwidth()]);
                                                                tempScale.rangeRound([0, xMax]);

                                                                const x = d => d.letter;
                                                                const y = d => +d.frequency * 100;

                                                                // bounds
                                                                // scales

                                                                const xScale = scaleBand({
                                                                    rangeRound: [0, xMax],
                                                                    domain: data.map(x),
                                                                    padding: 0.4
                                                                });
                                                                const yScale = scaleLinear({
                                                                    rangeRound: [yMax, 0],
                                                                    domain: [0, max(data, y)]
                                                                });
                                                                ////////////console.log(proData);

                                                                return (
                                                                    <div style={{ marginBottom: '20px' }}>

                                                                        <div style={{ padding: '20px 0px 0px 0px' }} key={9000001}>
                                                                            <h6 className="sub-title sub-sub-title" style={{ marginBottom: '15px' }}>Registered ultra low emission cars and vans - {la} </h6>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                            <div style={{ width: '48%', backgroundColor: '#ededed' }} >

                                                                                <div style={{ width: '100%', height: '350px', backgroundColor: '#ededed', position: 'relative' }}>
                                                                                    <div style={{ position: 'absolute', top: '0', right: '20px' }} onChange={handleSRSUpdate}><label className="radioBContainer favorit" style={{ padding: '0' }}><input type="checkbox" name="overlaySelectedVar" value={ind1} checked={sumReportStatus[ind1].bool == true ? true : false} /> <span className="checkmark checkmarkWidget"></span></label></div>
                                                                                    {/* <div style={{ position:'absolute', top:'0', right:'20px'}}><label className="radioBContainer" style={{padding:'0'}}><input type="radio" value="residentialStreets" name="whereChoice" /> <span className="checkmark"></span></label></div> */}

                                                                                    <XYChartt widget={widget} index={t} width={350} height={350} />

                                                                                </div>
                                                                            </div>

                                                                            <div style={{ width: '48%', backgroundColor: '#ededed' }}>
                                                                                <div style={{ position: 'relative' }}>
                                                                                    <div style={{ position: 'absolute', top: '0', right: '20px' }} onChange={handleSRSUpdate}><label className="radioBContainer favorit" style={{ padding: '0' }}><input type="checkbox" name="overlaySelectedVar" value={ind2} checked={sumReportStatus[ind2].bool == true ? true : false} /> <span className="checkmark checkmarkWidget"></span></label></div>
                                                                                </div>
                                                                                <div style={{ width: '100%', height: '350px' }}>
                                                                                    <MyPie widget={widget} index={t} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                        </div>
                                                    </>

                                                );


                                            } else if (widget.label == "Vehicle types") {
                                                indxCounter = indxCounter + 1;


                                                let completeTabel = [];
                                                let cv2 = 0;
                                                widget.laChartData.forEach(la => {
                                                    completeTabel.push({});
                                                    completeTabel[cv2]["laName"] = la.laName;
                                                    completeTabel[cv2]["Cars"] = la.cData[0][2]["Cars"];
                                                    completeTabel[cv2]["HeavyGoodsVehicles"] = la.cData[0][2]["HeavyGoodsVehicles"];
                                                    completeTabel[cv2]["LightGoodsVehicles"] = la.cData[0][2]["LightGoodsVehicles"];
                                                    completeTabel[cv2]["Motorcycles"] = la.cData[0][2]["Motorcycles"];

                                                    cv2 = cv2 + 1;
                                                })
                                                ////console.log("data2");
                                                ////console.log(completeTabel);
                                                var width = 900;
                                                var height = 220 * numberOfLAs;

                                                var height2 = 95 * numberOfLAs;

                                                var proData = completeTabel;
                                                ////console.log("data1");
                                                ////console.log(proData);

                                                var events = true;

                                                const blue = "#FF5092";
                                                const green = "#FF9788";
                                                const purple = "#FFCB66";
                                                const four = '#7d81f6';
                                                const background = "rgba(255, 255, 255, 0)";
                                                const defaultMargin = { top: 0, right: 20, bottom: 0, left: 95 };

                                                function max(arr, fn) {
                                                    return Math.max(...arr.map(fn));
                                                }
                                                const keys = Object.keys(proData[0]).filter((d) => d !== "laName");

                                                const formatDate = (date) => date;
                                                const getDate = (d) => d.laName;
                                                const dateScale = scaleBand({
                                                    domain: proData.map(getDate),
                                                    padding: 0.2
                                                });

                                                const cityScale = scaleBand({
                                                    domain: keys,
                                                    padding: 0.1
                                                });

                                                const tempScale = scaleLinear({
                                                    domain: [0, max(proData, (d) => max(keys, (key) => Number(d[key])))]
                                                });

                                                const colorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: [blue, green, purple, four]
                                                });

                                                var margin = defaultMargin;

                                                const xMax = 1100 - margin.left - margin.right;
                                                const yMax = height - margin.top - margin.bottom;

                                                dateScale.rangeRound([0, yMax]);
                                                cityScale.rangeRound([0, dateScale.bandwidth()]);
                                                tempScale.rangeRound([0, xMax]);


                                                const x = d => d.letter;
                                                const y = d => +d.frequency * 100;

                                                const ordinalColorScale = scaleOrdinal({
                                                    domain: keys,
                                                    range: ['#FF5092', '#FF9788', '#FFCB66', '#7d81f6'],
                                                });
                                                return (
                                                    <>
                                                        <div style={{ padding: '20px 0px 0px 0x' }} key={Math.random()}>
                                                            <h6 className="sub-title sub-sub-title" style={{ marginBottom: '15px' }}>{widget.label}</h6>
                                                        </div>

                                                        <div key={904}>
                                                            <EVBarCustom
                                                                height={height}
                                                                background={background}
                                                                marginTopV={margin.top}
                                                                marginLeftV={margin.left}
                                                                getDate={getDate}
                                                                dateScale={dateScale}
                                                                cityScale={cityScale}
                                                                tempScale={tempScale}
                                                                colorScale={colorScale}
                                                                proData={proData}
                                                                keys={keys}

                                                                green={green}
                                                                formatDate={formatDate}
                                                                ordinalColorScale={ordinalColorScale}
                                                                handleSRSUpdate={handleSRSUpdate}
                                                                checked={sumReportStatus[indxCounter].bool == true ? true : false}
                                                            />

                                                        </div>
                                                    </>
                                                )
                                                // PARMINDER, data above ^ 
                                            } else if (widget.label == "Forcast Data") {
                                                ////console.log("qwer");
                                                ////console.log(widget);
                                                indxCounter = indxCounter + 2;
                                                let ind1 = indxCounter - 1;
                                                let ind2 = indxCounter;

                                                return (
                                                    <>
                                                        <div key={c + 90002}>

                                                            <div style={{ padding: '20px 0px 0px 0px' }} key={Math.random()}>
                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '15px' }}>Forecast charge points required</h6>
                                                            </div>
                                                            <div style={{ marginBottom: '20px' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                    <div style={{ width: '100%', backgroundColor: '#ededed' }} >

                                                                        <div style={{ width: '100%', height: '350px', backgroundColor: '#ededed', position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', top: '0', right: '20px' }} onChange={handleSRSUpdate}><label className="radioBContainer favorit" style={{ padding: '0' }}><input type="checkbox" name="overlaySelectedVar" value={ind1} checked={sumReportStatus[ind1].bool == true ? true : false} /> <span className="checkmark checkmarkWidget"></span></label></div>
                                                                            <XYChart3 widget={widget} sw={1} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div style={{ padding: '20px 0px 0px 0px' }} key={Math.random()}>
                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '15px' }}>Forecast on street vehicles</h6>
                                                            </div>
                                                            <div style={{ marginBottom: '20px' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                    <div style={{ width: '100%', backgroundColor: '#ededed' }} >

                                                                        <div style={{ width: '100%', height: '350px', backgroundColor: '#ededed', position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', top: '0', right: '20px' }} onChange={handleSRSUpdate}><label className="radioBContainer favorit" style={{ padding: '0' }}><input type="checkbox" name="overlaySelectedVar" value={ind2} checked={sumReportStatus[ind2].bool == true ? true : false} /> <span className="checkmark checkmarkWidget"></span></label></div>
                                                                            <XYChart3 widget={widget} sw={2} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>

                                                );
                                            }
                                        }
                                    })}
                                </div>
                                <div style={{ width: '48%' }}>
                                    <div style={{ width: '48%' }}>
                                        <button type={"button"} className={"nextButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { handleHistory(); }}>
                                            <span className="favorit">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            {/* ----second tab code start---- */}
                            <div className={tBools[1] == true ? 'classOn' : 'classOff'} style={{ marginBottom: '0px' }}>

                                <h6 className="sub-title" style={{ fontSize: "32px", marginBottom: "0px" }}>Maps</h6>

                                <div style={{ margin: '0px 0px 40px 0px' }} key={90000}>
                                    <div style={{ padding: '60px 0px 0px 0px' }} key={9001}>
                                        <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px !important', fontSize: "24px" }}>Sites selected</h6>
                                    </div>

                                    <div style={{ padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
                                            <div style={{ width: '75%', height: '600px' }}>
                                                <img src={allLaMap} alt="BigCo Inc. logo" style={{ width: '100%', height: '100%', objectFit: "cover" }} />
                                            </div>

                                            <div style={{ width: '24%' }}>
                                                <div style={{ position: 'relative' }}>
                                                    {/* <div style={{position:'absolute', top:'0', right:'20px'}} onChange={handleSRSUpdate}><label className="radioBContainer favorit"  style={{padding:'0'}}><input type="checkbox" name="overlaySelectedVar" value={indxCounter+1} checked={sumReportStatus[indxCounter+1].bool == true? true : false} /> <span className="checkmark checkmarkWidget"></span></label></div> */}

                                                    <div style={{ position: 'absolute', top: '0', right: '20px' }} onChange={handleSRSUpdate}><label className="radioBContainer favorit" style={{ padding: '0' }}><input type="checkbox" name="overlaySelectedVar" value={indxCounter + 1} checked={true} /> <span className="checkmark checkmarkWidget"></span></label></div>
                                                </div>
                                                <div>
                                                    {LAs.map((la, id) => {
                                                        //////////console.log(la);
                                                        //////////console.log(id);
                                                        return (
                                                            <div className="freightText" style={{ margin: '40px 0px', color: '#291351', display: "flex", justifyContent: "space-between" }} key={id}>
                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px', marginLeft: "10px", left: "20px" }}>{la}</h6>
                                                                <h6 className="sub-title sub-sub-title" style={{ marginBottom: '0px', marginRight: "10px", right: "20px", color: "#cc4884" }}>{laCounts[id]}</h6>
                                                            </div>

                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: '48%', paddingBottom: "20px" }}>
                                    <div style={{ width: '48%' }}>
                                        <button type={"button"} className={"nextButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { handleHistory(); }}>
                                            <span className="favorit">Next</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            {oneLong.map(z => {
                                indxCounter = indxCounter + 1;
                            })}

                            {/* ----third tab code start---- */}
                            <div className={tBools[2] == true ? 'classOn' : 'classOff'} style={{ marginBottom: '40px' }}>

                                <h6 className="sub-title" style={{ fontSize: "32px" }}>Site score</h6>

                                <div style={{ margin: '40px 0' }}>
                                    {keyss.map(aLA => {

                                        c = c + 1;
                                        return (
                                            <div key={c + 250}>

                                                <div>
                                                    {/*<h6 className="sub-title">{data2Return[aLA][1].length == 0 ? null : data2Return[aLA][0]}</h6>*/}
                                                    <div className='sumRepPins'>
                                                        {processedRankData2Return[aLA][1].map((card, index) => {
                                                            indxCounter = indxCounter + 1;
                                                            //////console.log(sumReportStatus);
                                                            //////console.log(processedRankData2Return);

                                                            return (
                                                                <SummaryReportCards key={index} card={card} index={index} c={c} subData2Return={location.state.sD2R} setSubData2Return={setSubData2Return} laIndex={aLA} isLoading={isLoading} handleSRSUpdate={handleSRSUpdate} value={indxCounter} checked={sumReportStatus[indxCounter].bool == true ? true : false} />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div style={{ width: '48%' }}>
                                    <div style={{ width: '48%' }}>
                                        <button type={"button"} className={"nextButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { handleHistory(); }}>
                                            <span className="favorit">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            {/* ----fourth tab code start---- */}
                            <div className={tBools[3] == true ? 'classOn' : 'classOff'} style={{ marginBottom: '40px' }}>

                                <h6 className="sub-title" style={{ fontSize: "32px" }}>Site Scores</h6>

                                <div style={{ margin: '40px 0' }} key={90000}>
                                    <div style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }} key={900001}>
                                        <div style={{ width: '3%', height: "48px" }}>
                                            <h6 className={'sub-title tableHeadings'} style={{ marginBottom: '0px', paddingLeft: "6px" }}>#</h6>
                                        </div>
                                        <div style={{ width: '14%', height: "48px" }}>
                                            <h6 className={hBools[0] == true ? 'sub-title tableHeadings selectedHeading' : 'sub-title tableHeadings'} style={{ marginBottom: '0px', cursor: 'pointer' }} onClick={(e) => { rankHeading('Site name', 0); }}>Site name</h6>
                                        </div>
                                        <div style={{ width: '20%', height: "48px" }}>
                                            <h6 className={hBools[1] == true ? 'sub-title tableHeadings selectedHeading' : 'sub-title tableHeadings'} style={{ marginBottom: '0px', cursor: 'pointer' }} onClick={(e) => { rankHeading('Local authority', 1); }}>Local authority</h6>
                                        </div>
                                        <div style={{ width: '8%', height: "48px" }}>
                                            <h6 className={hBools[2] == true ? 'sub-title tableHeadings selectedHeading' : 'sub-title tableHeadings'} style={{ marginBottom: '0px', cursor: 'pointer' }} onClick={(e) => { rankHeading('Score', 2); }}>Overall score</h6>
                                        </div>
                                        {extraHeadings.map(heading => {
                                            indx2 = indx2 + 1;
                                            //////////console.log(indx2);
                                            let temp = indx2;
                                            //////////console.log(temp);

                                            return (
                                                <div style={{ width: '14%', height: "48px" }} key={Math.random()}>
                                                    <h6 className={hBools[3 + temp] == true ? 'sub-title tableHeadings selectedHeading' : 'sub-title tableHeadings'} style={{ marginBottom: '0px', cursor: 'pointer' }} onClick={(e) => { rankHeading(heading.label, 3 + temp) }}>{heading.label}</h6>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div >
                                        {keyss.map(aLA => {
                                            c = c + 1;
                                            return (
                                                <div key={aLA}>
                                                    {processedRankData2Return[aLA][1].map((card, index) => {
                                                        let tempLightTrue = true;
                                                        if (lightTrue == true) {
                                                            lightTrue = false;
                                                            tempLightTrue = false;
                                                        } else {
                                                            lightTrue = true;
                                                            tempLightTrue = true;
                                                        }
                                                        return (
                                                            <PinsRankList key={index} card={card} lightTrue={tempLightTrue} />
                                                        )
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div style={{ width: '48%' }}>
                                    <div style={{ width: '48%' }}>
                                        <button type={"button"} className={"nextButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { handleHistory(); }}>
                                            <span className="favorit">Next</span>
                                        </button>
                                    </div>
                                </div>

                            </div>


                            {/* ----fifth tab code start---- */}
                            {location.state.original == true ?

                                <div className={tBools[4] == true ? 'classOn' : 'classOff'} style={{ marginBottom: '40px' }}>

                                    {/* <h6 className="sub-title">Site Ranking</h6> */}
                                    <div style={{ display: "flex", justifyContent: "space-between", height: "38px" }}>
                                        <h6 className="sub-title" style={{ fontSize: "32px" }}>Data included in report</h6>
                                        <div>
                                            <label className="radioBContainer favorit" style={{ cursor: 'text' }}><input type="checkbox" name="overlaySelectedVar" checked disabled /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>Include in my report</p>
                                            <label className="radioBContainer favorit" style={{ cursor: 'text' }}><input type="checkbox" name="overlaySelectedVar" disabled /> <span className="checkmark checkmarknoHover"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>Exclude from my report</p>
                                        </div>
                                    </div>

                                    <div style={{ margin: '40px 0px' }} >

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >

                                            <div style={{ width: '48%' }}>

                                                <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px', width: '48%' }}>Background data</h6>
                                            </div>

                                        </div>

                                        <div style={{ padding: '16px 0px 0px 0px' }}>
                                            {srsGroup1.map((ele, id) => {
                                                //increase some counter for other groups continmuity
                                                //////////console.log(ele)
                                                return (
                                                    <div className={'rankListPinDiv customer'}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                                            <div style={{ width: '81%' }} onChange={handleSRSUpdate}>
                                                                <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" value={ele.index} checked={ele.bool == true ? true : false} /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>{ele.componentTitle}</p>
                                                            </div>
                                                            <div >
                                                                <p style={{ marginBottom: '0px', color: '#b2b2b2' }}>{ele.relatedLAs}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div style={{ padding: '60px 0px 0px 0px' }} >
                                            <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px' }}>Maps</h6>
                                        </div>

                                        <div style={{ padding: '16px 0px 0px 0px' }}>
                                            {srsGroup2.map((ele, id) => {
                                                //increase some counter for other groups continmuity
                                                //////////console.log(ele)
                                                return (
                                                    <div className={'rankListPinDiv customer'}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                                            {/* <div style={{width:'48%'}} onChange={handleSRSUpdate}> */}
                                                            <div style={{ width: '81%' }} >
                                                                {/* <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" value={ele.index} checked={ele.bool == true? true : false} /> <span className="checkmark"></span></label><p  style={{ marginBottom: '0px', color:'#291351', marginLeft:'25px' }}>{ele.componentTitle}</p> */}

                                                                <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" value={ele.index} checked={true} /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>{ele.componentTitle}</p>
                                                            </div>
                                                            <div>
                                                                <p style={{ marginBottom: '0px', color: '#b2b2b2' }}>{ele.relatedLAs}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div style={{ padding: '40px 0px 0px 0px ' }} >
                                            <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px' }}>Site score</h6>
                                        </div>

                                        <div style={{ padding: '16px 0px 0px 0px' }}>

                                            {srsGroup3.map((ele, id) => {
                                                //increase some counter for other groups continmuity
                                                //////////console.log(ele)
                                                return (
                                                    <div className={'rankListPinDiv customer'}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                                            <div style={{ width: '81%' }} onChange={handleSRSUpdate}>
                                                                <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" value={ele.index} checked={ele.bool == true ? true : false} /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>{ele.componentTitle}</p>
                                                            </div>
                                                            <div>
                                                                <p style={{ marginBottom: '0px', color: '#b2b2b2' }}>{ele.relatedLAs}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div style={{ padding: '40px 0px 0px 0px' }} >
                                            <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px' }}>Rankings</h6>
                                        </div>

                                        <div style={{ padding: '16px 0px 0px 0px' }}>
                                            {/* {srsGroup4.map((ele, id) => {
                                                    //increase some counter for other groups continmuity
                                                    //////////console.log(ele)
                                                    return(
                                                        <div className={'rankListPinDiv customer'}>
                                                            <div style={{ display:'flex', justifyContent:'space-between'}} >
                                                                <div style={{width:'48%'}} onChange={handleSRSUpdate}>
                                                                    <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" value={ele.index} checked={ele.bool == true? true : false} /> <span className="checkmark"></span></label><p  style={{ marginBottom: '0px', color:'#291351', marginLeft:'25px' }}>{ele.componentTitle}</p>
                                                                </div>
                                                                <div style={{width:'48%'}}>
                                                                    <p  style={{ marginBottom: '0px', color:'#291351' }}>{ele.relatedLAs}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })} */}
                                            <div className={'rankListPinDiv customer'}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                                    {/* <div style={{width:'48%'}} onChange={handleSRSUpdate}> */}
                                                    <div style={{ width: '81%' }} >
                                                        <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" checked={true} /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px' }}>All site rankings</p>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginBottom: '0px', color: '#b2b2b2' }}>{fullLas}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: '1px solid #291351', padding: '40px 0px 20px 0px' }} >
                                            <h6 className={'sub-title sub-sub-title'} style={{ marginBottom: '0px' }}>Send my custom report to:</h6>
                                        </div>

                                        <div style={{ padding: '20px 0px' }}>
                                            <div style={{ width: '60%' }}>
                                                <div style={{ width: '60%' }}>
                                                    <input type="email" className='inputField' placeholder='Enter email addresses' />


                                                    {/*<ContactUs />*/}
                                                    <p style={{ marginBottom: '20px', color: '#291351' }} >Separate multiple addresses with a comma</p>
                                                    <label className="radioBContainer favorit" ><input type="checkbox" name="overlaySelectedVar" /> <span className="checkmark"></span></label><p style={{ marginBottom: '0px', color: '#291351', marginLeft: '25px', width: "200%" }}>I confirm that I understand that by providing these contact details I consent to having….(GDPR statement</p>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div style={{ width: '48%' }} ref={(r) => {
                                        setUpdateMee(1);
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <button type={"button"} className={"nextButton"} style={{ width: "100%", textAlign: "center" }} onClick={(e) => { handleHistory(); }}>
                                                {
                                                    projectNumber != null ?
                                                        <span className="favorit">Upload results.</span>
                                                        :
                                                        <span className="favorit">Generate my custom report</span>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                    {/*<ExportPdfComponent sD2R={subData2Return} widgetGrouppp={widgetGrouppp} laCounts={laCounts} processedRankData2Return={processedRankData2Return} extraHeadings={extraHeadings}/>*/}

                                    {/*<ContactUs sD2R={subData2Return} widgetGrouppp={widgetGrouppp} laCounts={laCounts} processedRankData2Return={processedRankData2Return} extraHeadings={extraHeadings} />*/}



                                </div>
                                : null
                            }

                        </section>

                    </div>
                </div>
            </Layout>


        );
    }
}

export default React.memo(Location);

//another barchart to hold for later

{/*<div key={907} style={{paddingTop: '20px'}}>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', backgroundColor:'#e8e9eb', border:'2px solid #cccccc', alignItem:'center' }}>
                                                                            
                                                                    <div style={{ width: '76%', height:height2}} >
                                                                        <>
                                                                        <svg width={'100%'} height={'100%'} ref={containerRef}>
                                                                            
                                                                            <rect
                                                                                x={0}
                                                                                y={0}
                                                                                width={'100%'}
                                                                                height={'100%'}
                                                                                fill={background}
                                                                                rx={0}
                                                                            />
                                                                            <Group top={margin.top} left={margin.left}>
                                                                                <BarGroupHorizontal
                                                                                    data={proData2}
                                                                                    keys={keys2}
                                                                                    width={"100%"}
                                                                                    y0={getDate}
                                                                                    y0Scale={dateScale2}
                                                                                    y1Scale={cityScale2}
                                                                                    xScale={tempScale2}
                                                                                    color={colorScale2}
                                                                                >
                                                                                    {(barGroups) =>
                                                                                        barGroups.map((barGroup) => {
                                                                                        count15= count15 +1;
                                                                                        count16 = 0;
                                                                                        return(
                                                                                            <Group
                                                                                                key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                                                                                                top={barGroup.y0}
                                                                                            >
                                                                                                {barGroup.bars.map((bar) => {
                                                                                                count16 =count16 +1;
                                                                                                let x = count16
                                                                                                return(
                                                                                                    <Bar
                                                                                                        key={`${barGroup.index}-${bar.index}-${bar.key}`}
                                                                                                        x={bar.x}
                                                                                                        y={bar.y}
                                                                                                        width={bar.width}
                                                                                                        height={'30px'}
                                                                                                        fill={bar.color}
                                                                                                        rx={0}
                                                                                                        onClick={() => {
                                                                                                            
                                                                                                                alert(
                                                                                                                    `${bar.key} (${bar.value}) - ${JSON.stringify(bar)}`
                                                                                                                );
                                                                                                        }}
                                                                                                        onMouseOver={(e,d) => {
                                                                                                            let keysss = Object.keys(proData2[0]);
                                                                                                            handleMouseOver(e,d,bar.value,keysss[x]);
                                                                                                        }}
                                                                                                        onMouseOut={hideTooltip}
                                                                                                    />
                                                                                                )})}
                                                                                                
                                                                                            </Group>
                                                                                        )})
                                                                                    }
                                                                                </BarGroupHorizontal>
                                                                                <AxisLeft
                                                                                    top={0}
                                                                                    scale={dateScale2}
                                                                                    stroke={green}
                                                                                    tickStroke={green}
                                                                                    tickFormat={formatDate}
                                                                                    hideAxisLine = {true}
                                                                                    hideTicks = {true}
                                                                                    tickLabelProps={() => ({
                                                                                    fill: '#291351',
                                                                                    fontSize: 13,
                                                                                    textAnchor: 'end',
                                                                                    dy: '0.33em',
                                                                                    })}
                                                                                />
                                                                                
                                                                                
                                                                                </Group>
                                                                        
                                                                        </svg>
                                                                        {tooltipOpen && (
                                                                            <TooltipWithBounds
                                                                            // set this to random so it correctly updates with parent bounds
                                                                            key={Math.random()}
                                                                            top={tooltipTop}
                                                                            left={tooltipLeft}
                                                                            >
                                                                            {tooltipData}
                                                                            </TooltipWithBounds>
                                                                        )}
                                                                        
                                                                            
                                                                        
                                                                        </>
                                                                    </div>
                                                                    <div style={{ width: '20%'}} >
                                                                        <div style={{padding:'20px',  marginLeft: "25px"}}>
                                                                            <div style={{height:'20px', border:'0px solid #cccccc'}}>
                                                                                <LegendOrdinal scale={ordinalColorScale2} labelFormat={(label) => `${label}`}>
                                                                                    {(labels) => (
                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        {labels.map((label, i) => (
                                                                                            <LegendItem
                                                                                            key={`legend-quantile-${i}`}
                                                                                            margin="10px 5px"
                                                                                            onClick={() => {
                                                                                                if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                                                                            }}
                                                                                            >
                                                                                            <svg width={legendGlyphSize} height={legendGlyphSize}>
                                                                                                <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                                                                            </svg>
                                                                                            <LegendLabel align="left" margin="0 0 0 4px">
                                                                                                {label.text}
                                                                                            </LegendLabel>
                                                                                            </LegendItem>
                                                                                        ))}
                                                                                        </div>
                                                                                    )}
                                                                                </LegendOrdinal>
                                                                            </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>*/}