import React, {useContext, useState, useEffect} from 'react';
import { Marker, Popup, LayersControl, FeatureGroup } from 'react-leaflet';
import {priorityStateContext, typeofChargerStateContext, LAStateContext,legendsAddedContext,layerRefsContext,updateMeContext,legendsContext} from '../../Store';
import L from 'leaflet';
//import {  iconEV  } from './Marker.js';
//import icon from "../images/markers/icon-EV.png";

function LayergeoMarker(props) {
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [chargerType, setchargerType] = useContext(typeofChargerStateContext);   
    const [LAs, setLAs] = useContext(LAStateContext);
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [updateMe, setUpdateMe] = useContext(updateMeContext);
    const [legendsAdded, setLegendsAdded] = useContext(legendsAddedContext);
    const [legends, setLegends] = useContext(legendsContext);


    

    var proPre = props.proPre;
    var attr = props.attr;

    // console.log(attr);
    // console.log(props);
    L.Icon.Default.imagePath='/';
    var iconurl = "";
    if (props.attr == "EvChargers"){
        iconurl = "mapPinBlue.png";
    } else if (props.attr == "NECarParks"){
        iconurl = "tri5092.png";
    } else if (props.attr == "OnStreetParkingNorthumberland"){
        iconurl = ".png";
    } else if (props.attr == "CarClubBaysNE"){
        iconurl = "hexcb66.png";
    } else if (props.attr == "LALeisureCentres"){
        iconurl = "octffdf.png";
    } else if (props.attr == "SOSCIPlannedChargepointsV2"){
        iconurl = "squarecb66.png";
    } else if (props.attr == "NEBusinessParks"){
        iconurl = "squarecb66.png";
    } 
    
    else {
        iconurl = "mapPinBlue.png";
    }

    const bus = new L.Icon({
        iconUrl: "bus.png",
        iconRetinaUrl: "bus.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const airport = new L.Icon({
        iconUrl: "airport.png",
        iconRetinaUrl: "airport.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const car = new L.Icon({
        iconUrl: "car.png",
        iconRetinaUrl: "car.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const ferry = new L.Icon({
        iconUrl: "ferry.png",
        iconRetinaUrl: "ferry.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const taxi = new L.Icon({
        iconUrl: "taxi.png",
        iconRetinaUrl: "taxi.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const train = new L.Icon({
        iconUrl: "train.png",
        iconRetinaUrl: "train.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const metro = new L.Icon({
        iconUrl: "metro.png",
        iconRetinaUrl: "metro.png",
        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    const iconEV = new L.Icon({
        iconUrl: iconurl,
        iconRetinaUrl: iconurl,

        shadowUrl: '',
        iconSize: new L.Point(16, 16),
    });

    useEffect(() => {
        var legendsTemp = legends;

        var legeAdd = legendsAdded;
        var bool = false;
        legeAdd.forEach(legeAddChecker =>{
            if (attr == legeAddChecker){
                bool = true;
            }
        })
        var addLegend = true;
        if (bool == true){
            addLegend = false;
        } else {
            legeAdd.push(attr);
            setLegendsAdded(legeAdd);
        }
        if(addLegend){
            var geoLegend = L.control({position: "bottomright"});
            if (attr == "Stops"){
                geoLegend.onAdd = function(map) {
                    var div = L.DomUtil.create("div", "legend"); 
                    div.innerHTML += (" <img src='/"+"train.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Train stations"+'</span><br>'; 
                    div.innerHTML += (" <img src='/"+"metro.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Metro station"+'</span><br>'; 
                    div.innerHTML += (" <img src='/"+"bus.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Bus stops"+'</span><br>'; 
                    div.innerHTML += (" <img src='/"+"taxi.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Taxi ranks"+'</span><br>'; 
                    div.innerHTML += (" <img src='/"+"ferry.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Ferry terminal"+'</span><br>'; 
                    div.innerHTML += (" <img src='/"+"airport.png"+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + "Airports"+'</span><br>'; 

                    return div;
                };
            } else {
                geoLegend.onAdd = function(map) {
                    var div = L.DomUtil.create("div", "legend"); 
                    
                    div.innerHTML += (" <img src='/"+iconurl+"'  height='17' width='17' className='legemark'><span className='favorit legemark' >") + props.proPre.overlayLabel+'</span><br>'; 
                    
                    return div;
                };
            }
            legendsTemp[attr] = [geoLegend,"marker"];
            setLegends(legendsTemp);
        }
        
    }, []); // Only re-run the effect if count changes

    //Marker Setup
    //==============================================================================================
    // //////////////////console.log(props.mapdata);
    //const indexed = props.mapdata.map((item, id) => Object.assign(item, {id}));
    var full = [];
    // //////////////////console.log(props.attr);
    if (props.attr == "EvChargers"){
        props.mapdata.forEach(la => {
            full = [...full, ...la];
        })
    } else {
        props.mapdata.forEach(la => {
            console.log(la);
            ////////////////////console.log(la[0][1]);
            full = full.concat(la[0][1]);
        })
    }
    
    //////console.log(full);
    const indexed = full.map((item, id) => Object.assign(item, {id}));
    //////console.log("indexed");
    //////console.log(indexed);
    



    // props.mp.on("overlayadd", function(e){
    //     if (e.name == props.proPre.overlayLabel){
    //         //////////////////console.log("added: " + props.proPre.overlayLabel);
    //         geoLegend.addTo(props.mp);
    //     }
    // })

    // props.mp.on("overlayremove", function(e){
    //     if (e.name == props.proPre.overlayLabel){
    //         //////////////////console.log("removed:" + props.proPre.overlayLabel);
    //         props.mp.removeControl(geoLegend);
    //     }
    // })

    return(
        <LayersControl.Overlay name={props.proPre.overlayLabel}>
        <FeatureGroup name= {props.dataName}
             ref={(element) => {
                var bool = false;
                var layerRefsTemp = layerRefs;
                layerRefs.forEach(ref =>{
                    if (ref[0] == attr){
                        bool = true;
                    }
                })
                if (bool == false){
                    layerRefsTemp.push([attr, element, "point"]);
                    //////////////////console.log(layerRefsTemp);
                    setLayerRefs(layerRefsTemp);
                }  
            }}
        >
          {indexed.map(data => (
            <Marker 
                key = {data.id}
                icon = {data.popHeading1 == "Ferry terminal" ? ferry : data.popHeading1 == "Bus and coach station" ? bus : data.popHeading1 == "Train station entrance" || data.popHeading1 == "RLY" ? train : data.popHeading1 == "Airport" ? airport :  data.popHeading1 == "Taxi rank" ? taxi :  data.popHeading1 == "Metro" || data.popHeading1 == "PLT" ? metro :iconEV}
                position={[data.Latitude, data.Longitude]}
            > 
                {typeof data.popHeading1 != "undefined" && typeof data.popHeading2 == "undefined" ?
                        <Popup className={"pops"} autoPan={true}>
                            <p>{data.popHeading1}</p> 
                        </Popup>
                        
                    : 
                    typeof data.popHeading1 != "undefined" && typeof data.popHeading2 != "undefined" ? 
                        <Popup className={"pops"} autoPan={true}>
                            <p>{data.popHeading1}</p> 
                            <p>{data.popHeading2}</p>
                        </Popup>
                    : <Popup className={"pops"} autoPan={true}>
                        Charge point
                    </Popup>
                
                }
                
            </Marker>
            ))}
        </FeatureGroup>
        </LayersControl.Overlay>
    );
}

export default LayergeoMarker;