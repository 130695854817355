import React, { useState, useEffect, useContext, useRef } from "react";
import { LAsShownC, nameInputC } from '../../Store';


function Tooltip() {
    const [boolbox, setBoolBox] = useState(true);

    const [LAsShown, setLAsShown] = useContext(LAsShownC);
    const [nameInput, setNameInput] = useContext(nameInputC);

    function hideMesssageBox() {
        if (boolbox == false) {
            setBoolBox(true);
            setLAsShown(true);
        } else {
            setBoolBox(false);
            setLAsShown(false);
        }
    }
    if (nameInput == "") {
        return (
            <></>
        )
    } else {
        return (
            <div style={{ position: "absolute" }}>
                <p className="tip">or
                    <a href="#" className="link" id="tooltip" onClick={() => { hideMesssageBox() }}>
                        <span data-tip data-for="registerTip"> project number</span>
                    </a>
                </p>

                {/*<ReactTooltip id="registerTip" place="bottom" effect="solid">
            Don't worry about it if you dont have one
            </ReactTooltip>*/}
                <div className={'messageBoxArea'} style={boolbox == true ? { "opacity": '0' } : { "opacity": '1' }}>
                    <p>What’s a project number?</p>
                    <p>Are you using this tool as part of project?</p>
                    <p>You may have been emailed a reference number which you can enter here to unlock bespoke settings.</p>
                </div>
            </div>
        );
    }

}

export default Tooltip;