import React, {useState, useEffect, useContext, useRef} from 'react';
import { aqmzPC,fDateC, legContext, priorityStateContext, datasetConfigStringsContext,   layerRefsContext,  factorsContext, legendsContext, dataBoolContext} from '../Store';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';




function MapMenuArea({mp, setMap, info, clearButtonRef, setClearButtonRef, atr, setatr}){
    const [priorities, setPriorities] = useContext(priorityStateContext);
    const [datasetConfigStrings, setDatasetConfigStrings] = useContext(datasetConfigStringsContext);
    const [factors, setFactors] = useContext(factorsContext); 
    const [layerRefs, setLayerRefs] = useContext(layerRefsContext);
    const [legends, setLegends] = useContext(legendsContext);
    const [dataBool, setDataBool] = useContext(dataBoolContext);
    const [leg, setLeg] = useContext(legContext);
    const [fDate, setFDate] = useContext(fDateC); 
    
    

    const [cloropleths, setCloropleths] = useState([]);
    const [counter1, setCounter1] = useState(105);
    const [counter2, setCounter2] = useState(150);

    var c = 405;
    var c = 450;
    
    useEffect(() => {
        ////console.log("\n\n\n");
        //console.log("here");
        
        var other1 = "";
        var other2 = "";
        var other3 = "";
        var other4 = "";
        var dateSelected = true;
        
        if (fDate == "2025"){
            other1 = "2030";
            other2 = "2035";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2030") {
            other1 = "2025";
            other2 = "2035";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2035") {
            other1 = "2025";
            other2 = "2030";
            other3 = "2040";
            other4 = "2045";
        } else if (fDate == "2040") {
            other3 = "2025";
            other1 = "2030";
            other2 = "2035";
            other4 = "2045";
        } else if (fDate == "2045") {
            other3 = "2025";
            other1 = "2030";
            other2 = "2035";
            other4 = "2040";
        } else if (fDate == "") {
            ////console.log("noDate");
            dateSelected = false;
        }

        var poiTemp =[];
        var cloroplethTemp =[];
        factors.forEach(factor => {
            factor.relatedDatasets.forEach(dset => {
                ////console.log(dset);
                var splitz = dset.split(".");
                let useMe = true;
                if ( atr != null){
                    //console.log(atr);
                    atr.forEach(at => {
                        //console.log(at);
                        //console.log(splitz[3]); 
                        if (at == splitz[3]){
                            useMe = false;
                        }
                    })
                }
                
                if (useMe == true){
                    let endBit = splitz[1].slice(splitz[1].length - 4);
                    let cont = false;
                    if (endBit == "2025" || endBit == "2030" || endBit == "2035" || endBit == "2040" || endBit == "2045"){
                        if (dateSelected == true){
                            if (endBit == other1 || endBit == other2 || endBit == other3 || endBit == other4 ){
                                cont = true;
                            } 
                        } else {
                            cont = true;
                        }
                    }
                    if (cont == true){
                        ////console.log("true");
                        return;  
                    }else {
                        ////console.log("false");
                    }
                    var splitz2 = splitz[16].split("&")
                    if (splitz2[0] == "cloroP"){
                        cloroplethTemp.push(dset);
                    }
                }
                
            })
        })
        var count10 = 0;
        
        ////console.log(cloroplethTemp);
        //placeholders
        setCloropleths(cloroplethTemp);
         
    }, [atr]);   
    
    function handleDSetSelector (event){
        if(dataBool == false){
            setDataBool(true);
            setLeg("fadein") ;
            info.setClass(true);

            layerRefs.forEach(layer => {
            ////////////////console.log(layer[0] + "//" + event.target.value);
                if(layer[0] == event.target.value){
                    layer[1].addTo(mp);
                    
                }
                
            })

            var keys = Object.keys(legends);
            keys.forEach(key => {
                if(key == event.target.value){
                    //////////////console.log("add");
                    legends[key][0].setClass("out");
                    legends[key][0].addTo(mp);
                    
                    setTimeout(function(){
                        legends[key][0].setClass("fadein");
                    }, 1);
                }
            })

            keys.forEach(key => {
                if(legends[key][1] == "poly"){
                    legends[key][0].setClass("out");
                    setTimeout(function(){
                        legends[key][0].setClass("fadein");
                    }, 1);
                }
            })
            
            
            
        } else {
            ////////////////console.log("whereC");
            ////////////////console.log(priorities);
            ////////////////console.log(event.target.value);

            var keys = Object.keys(legends);
            keys.forEach(key => {
                if(legends[key][1] == "poly"){
                    //////////////console.log("remove");
                    //legends[key][0].setClass(false);
                    mp.removeControl(legends[key][0]);
                    
                    //
                }
            })
            
            
            layerRefs.forEach(layer => {
            ////////////////console.log(layer);
                // if(layer[0] != event.target.value){

                // }
                if(layer[2] == "poly"){
                    mp.removeLayer(layer[1]);
                    mp.removeControl(layer[1]);
                }
                
            })
            layerRefs.forEach(layer => {
            ////////////////console.log(layer[0] + "//" + event.target.value);
                if(layer[0] == event.target.value){
                    layer[1].addTo(mp);

                    
                }
                
            })
            keys.forEach(key => {
                if(key == event.target.value){
                    //////////////console.log("add");
                    
                    legends[key][0].addTo(mp);
                    legends[key][0].setClass("fadein");
                }
            })
        }
       
        
        var updateJson = {};
        priorities.forEach(prio => {
            var splitz = prio.split(".");
            if (splitz[1] == event.target.value){
               ////////////////console.log(splitz);
                updateJson = {
                    full: false,
                    attType: splitz[3],
                }
                
            }
        }) 
        info.update(updateJson);//layer.feature.properties

    }
    return(
        <div  key ={401}>
            
            
            <div key ={403}> 
                <h6 className="sub-title">Data Maps</h6>
                {cloropleths.map(cloropleth =>{
                    var splitz = cloropleth.split(".");
                    
                    c = c+1;
                    

                    return(
                        <div style={{fontSize: "18px"}}>
                        <div key ={c} onChange={handleDSetSelector} style={{padding:'10px 0px'}}><label className="radioBContainer favorit" ><input type="radio" value={splitz[1]} name="overlaySelectedVar" /> {splitz[3]}<span className="checkmark"></span></label></div>
                        </div>
                    )
                })}

                <label style={{display:"none"}} className="radioBContainer favorit" ><input  onChange={handleDSetSelector} ref={clearButtonRef} type="radio" value="clear" name="overlaySelectedVar" /> Clear<span className="checkmark"></span></label>
            </div>
            
           
        </div>
    )
}

export default MapMenuArea
