//Welcome to Future Places. It was copied from Site Selector Home (HomepageCPSS) and is a simple embed of a Kumu tool. Built by Emma.  
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import MyPie from '../components/charts/MyPie.js';
import header from '../homepage/header.png';

import cphp from '../homepage/cphp.png';
import numbers from '../homepage/10101.png';
import cpr from '../homepage/cpr.png';

import logo from '../logo.png';
import logo_blue from '../logo_blue.png';

import { nameInputC, orgInputC, emailInputC } from '../Store';

function FuturePlaces() {
     return (
        <Layout>

            <header className='mobHeader' style={{ backgroundImage: `url(${header})` }}>
                <div style={{ position: 'relative' }}>
                    <div className='headerHeadingDiv'>
                        <h1 className='headerHeading'>
                            Horizon scan of future places
                        </h1>

                        <p style={{ fontSize: '24px', width: '80%' }}>
                            For best results, these tools need to be accessed on a desktop monitor.
                        </p>
                        <br />
                        <a href='https://urbanforesight.org/'> <button className='newButton'>By Urban Foresight</button></a>


                    </div>
                </div>
            </header>

            <div>
				<iframe src="https://embed.kumu.io/1685154dd395a0cc57fdbe3e3c71b692" width="100%" height= "900" frameborder="0"></iframe>			
            </div>
            <footer>
                <div className='divContainer'>

                    <div style={{ width: '250px', marginRight: '25px' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <div style={{ position: 'absolute', top: '0' }}>
                                <a href='#'>
                                    <img src={logo} alt="Logo" style={{ height: '42px', width: '172px' }} />
                                </a>
                            </div>
                            <div style={{ position: 'absolute', bottom: '0' }}>
                                <p style={{ color: '#b2b2b2' }}>© Urban Foresight Limited, 2023</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>The Catalyst <br />
                            3 Science Square <br />
                            Newcastle upon Tyne <br />
                            NE4 5TG <br />
                            United Kingdom</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)191 814 2210</p>
                    </div>

                    <div style={{ width: '250px' }}>
                        <p style={{ color: '#b2b2b2' }}>Flour Mill<br />
                            34 Commercial Street <br />
                            Dundee<br />
                            DD1 3EJ <br />
                            Scotland</p>
                        <p style={{ marginTop: '20px', color: '#b2b2b2' }}>T: +44(0)1382 549 947</p>
                    </div>

                    <div className='navigationFooter'>
                        <p style={{ height: '25%', fontSize: '12px', color: '#cc4884' }}>
                            <a href="https://www.linkedin.com/company/urban-foresight" target="_blank" style={{ color: '#cc4884' }}> <i className="fa fa-linkedin"></i>  </a>
                            <a href="https://twitter.com/urbanforesight" target="_blank"> <i style={{ marginLeft: '12px', color: '#cc4884' }} className="fa fa-twitter"></i>  </a>
                            <a href="mailto:hello@urbanforesight.org" target="_blank"> <i style={{ fontSize: '12px', marginLeft: '12px', color: '#cc4884' }} className="material-icons">mail</i> </a>
                        </p>
                        <p style={{ height: '25%' }}><a href="/#tools" target="_blank" style={{ color: 'white' }}> Tools</a> </p>
                        <p style={{ height: '25%' }}> <a href="https://urbanforesight.org/about/" target="_blank" style={{ color: 'white' }}> About</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="https://urbanforesight.org/" target="_blank" style={{ color: 'white' }}> Urban Foresight</a></p>
                        <p style={{ height: '25%', display: 'flex', alignItems: 'flex-end' }}> <a href="/AdminHome" target="_blank" style={{ color: 'white' }}> Admin</a></p>
                    </div>

                </div>
            </footer>
            <div className='footer2'>
                <div>
                    <a href='#'>
                        <img src={logo_blue} alt="Logo" className='footer2Logo' />
                    </a>
                </div>

                <div>
                    <p style={{ color: '#291351' }}>© Urban Foresight Limited, 2023</p>
                </div>
            </div>
        </Layout>
    );
}

export default FuturePlaces;



