import React , {useContext, useState, useEffect} from 'react'
import '../../pages/main.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker,MapConsumer, TileLayer, LayersControl, FeatureGroup, LayerGroup, useMapEvents, Popup,  useMapEvent } from 'react-leaflet';
import L, { featureGroup } from 'leaflet';
import {Card} from 'react-bootstrap';

import {selectedPositionContext} from '../../Store';

import {
    GoogleMap,
    LoadScript,
    LoadScriptNext,
    useGoogleMap,
    StreetViewPanorama
} from "@react-google-maps/api";


const APIkey ="AIzaSyAhwPKfDmXSZMpD-f_ahZUrQnvKWUJPhMY";




function Cards({card, index, toggleCardActive, activeElements, c}) {
    const [slideVisibility, setslideVisibility] = useState(true);

    //////console.log(card);



    // useEffect(() => { 
    //     showSlides(slideIndex) 
    // }, []);



    // var slideIndex = 1;

    // // Next/previous controls
    // function plusSlides(n) {
    //   showSlides(slideIndex += n);
    // }


    // function showSlides(n) {
    //     var i;

    //     var slidesLength = 2;
    //     var tempTBools = slideVisibility;


    //     if (n<1){
    //         slideIndex = slidesLength;
    //     }
    //     if (n>slidesLength){
    //         slideIndex = 1;
    //     }

    //     tempTBools = [false, false];
    //     tempTBools[slideIndex-1] = true;
    //     setslideVisibility(tempTBools);
    // }

    function tog (){
        if(slideVisibility == true){
            setslideVisibility(false);
        } else {
            setslideVisibility(true);
        }
        
    }
    // var list1 = [];
    // var list2 = [];
    // card.forEach(singleBitOfData => {
    //     if (singleBitOfData[0] == )
    // })
    
    return(
        <div className="pinDiv" key = {index}>
                
            <button 
                className= {activeElements[card[0][1]] ? "accordion active favorit" : "accordion favorit"}
                onClick={() => {
                    toggleCardActive(card[0][1]);
                }
                }
            >

                    <span className='sub-sub-title' style={{fontSize:"18px !important"}}>{typeof card[0][5] !== 'undefined' ? card[0][5] : typeof card[0][6].postcode !== 'undefined' ? typeof card[0][6].postcode : card[0][4]}</span>
            </button>
            <div className={activeElements[card[0][1]] ? "panelActive" : "panel"}>

                <div style={{"display":"flex", "justify-content":"space-between"}}>
                    <div style={{"width":"42%"}}>
                        <div style={{"height":"1px", "width": "1px", "position":"relative"}}></div>
                        <div class="slideshow-container">
                            {slideVisibility == true ?
                                <div class="mySlides" style={{"display":'block'}} >
                                    <div class="numbertext">1 / 2</div>
                                        <div style={{width:'100%', border:'1px solid #291351'}}>
                                            <img src={card[0][8]} alt="BigCo Inc. logo" style={{ maxWidth:'100%'}}/>
                                        </div>
                                    {/* <div class="text">Default</div> */}
                                </div>
                                :
                                <div class="mySlides" style={{"display":'block'}} >
                                    <div class="numbertext">2 / 2</div>
                                        <div style={{width:'100%', border:'1px solid #291351'}}>
                                            <img src={card[0][7]} alt="BigCo Inc. logo" style={{ maxWidth:'100%'}}/>
                                        </div>
                                    {/* <div class="text">Satellite</div> */}
                                </div>
                            }
                        </div>
                        
                        <div style={{ display: 'flex', marginTop:'3px'}}>
                            <div style={{width:'50%'}}>
                                <p className="tip">
                                    <a href={"http://maps.google.com/maps?q&layer=c&cbll=" + card[0][2][1] + "," + card[0][2][0] + "&cbp=11,0,0,0,0"} target="_blank" className = "link" id="tooltip"><span data-tip data-for="registerTip"> Open Street View</span></a>
                                </p>
                            </div>

                            <div style={{width:'50%',textAlign:'right'}}>
                                <div class="button b2" id="button-10">
                                    <input type="checkbox" onClick={() => {tog()}} class="checkbox" />
                                    <div class="knobs">
                                        <span >Default</span>
                                    </div>
                                    <div class="layer"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{"width":"56.5%", "display":"flex", "justify-content":"space-between"}}>
                        <div style={{"width":"45%", fontSize:"12px"}}>
                            <div style={{"fontFamily":"Favorit", marginBottom:"18px", color:"#b2b2b2", fontSize: "14px"}}>
                                SITE INFO
                            </div>
                            <div className={'freightText'} style={{display:'flex', justifyContent:'space-between'}}> 
                                <div style={{color:"#b2b2b2"}}>Long/Lat: </div>
                                <div style={{color:"#261b3f"}}>({card[0][2][0].toFixed(3)}, {card[0][2][1].toFixed(3)})</div>
                            </div>
                            <br/>
                            {/*  */}
                            {card[0][0][3] != null? 
                                <div className={'freightText'} style={{display:'flex', justifyContent:'space-between',alignItems:'flex-end'}}>
                                    <div style={{width:'70%', color:"#b2b2b2"}}>Distance to nearest charge point:</div> 
                                    <div style={{color:"#261b3f", display:"flex"}}>
                                        <div style={{float:"right"}}>{card[0][0][3].toFixed(2)}</div>
                                        <div style={{float:"right"}}>miles</div>
                                    </div>
                                </div>
                                :
                                null

                            }
                            <br/>
                            {card[0][0][4] != null && card[0][0][4] == true ? 
                                <div className={'freightText'} style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{color:"#b2b2b2"}}>Air quality managed area:</div> 
                                    <div style={{color:"#261b3f"}}>Yes</div>
                                </div>
                                : card[0][0][4] != null && card[0][0][4] == false ?
                                <div className={'freightText'} style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{color:"#b2b2b2"}}>Air quality managed area:</div> 
                                    <div style={{color:"#261b3f"}}>No</div>
                                </div>:
                                null

                            }
                            <br/>
                            <div className={'freightText'} style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{color:"#b2b2b2"}}>Flood risk:</div> 
                                    <div style={{color:"#261b3f"}}>Low</div>
                            </div>
                        </div>
                        <div style={{"width":"45%"}}>
                            <div style={{"fontFamily":"Favorit", marginBottom:"14px", fontSize:"14px", color:"#b2b2b2"}}>
                                LOCAL AREA INFO
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', flexDirection: "column"}}>
                                {card.map(singleBitOfData => {
                                    c = c +1;
                                    let x = c;
                                    if (singleBitOfData[0][0] != "Air quality management zone"){
                                        return(
                                            <>
                                                <div className={'freightText'} key = {x} style={{ display:'flex', justifyContent:'space-between', fontSize:"12px",alignItems:'flex-end'}}>
                                                    <div style={{color:"#b2b2b2"}}>{singleBitOfData[0][0]}:</div> <div style={{color:"#261b3f", float:"right", marginLeft: "8px"}}>{singleBitOfData[0][1]}</div>
                                                </div>
                                                <br/>
                                            
                                            </>
                                           
                                            
                                        )
                                    }                    
                                })}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Cards);

