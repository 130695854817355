import React , {useContext, useState, useEffect} from 'react'
import {BrowserRouter, useHistory} from 'react-router-dom';


import Layout from '../layout/Layout';
import t1 from '../../homepage/t1.png';
import t1Inactive from '../../homepage/t1Inactive.png';
import t2 from '../../homepage/t2.png';
import t3 from '../../homepage/t3.png';
import t4 from '../../homepage/t4.png';
import t5 from '../../homepage/t5.png';
import logo from '../../logo.png';
import logo_blue from '../../logo_blue.png';

import { isSubStateC } from '../../Store';


function AdminHome (){
  const [isSubmitted, setIsSubmitted] = useContext(isSubStateC);
  const [errorMessages, setErrorMessages] = useState({});
  const [csModalOpen, setCSModalOpen] = useState(false);
  const [dataRepoModalOpen, setDataRepoModalOpen] = useState(false);
  const [superAdminModalOpen, setSuperAdminModalOpen] = useState(false);

  const history = useHistory();

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const getAuth = async (event) => {
    console.log("trig");
    event.preventDefault();
    var { uname, pass } = document.forms[0];
    let urrl = "https://cleanstreetserver.herokuapp.com/auth/"+uname.value + "/" + pass.value;
    console.log(urrl);

    let list = await fetch(urrl, {cache: "no-store"});
    list = await list.json();   
    console.log(list);
    list = list.valid;
    if (list == "true"){
      setIsSubmitted(true);
    } else {
      alert("Invalid auth");
    }
  }
  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );

  function openCSModal(){
    setCSModalOpen(true);
  }

  function closeCSModal(){
    setCSModalOpen(false);
  }

  function openDRModal(){
    setDataRepoModalOpen(true);
  }

  function closeDRModal(){
    setDataRepoModalOpen(false);
  }

  function openSAModal(){
    setSuperAdminModalOpen(true);
  }

  function closeSAModal(){
    setSuperAdminModalOpen(false);
  }

  if (isSubmitted == true){
    return(
      <Layout>
        {csModalOpen == true ? 
          <div style={{position:"fixed", height:"100vh", width:"100vw", justifyContent: "center", display:"flex", zIndex: 9999}}>
            <div style={{height:"13%", width:"21%", top: "40%", backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351", position:"relative", zIndex:"1000", fontSize:"18px"}}>
              <div style={{ position:'absolute', top:'15px', right:'15px', cursor:'pointer'}} onClick={() => {closeCSModal()}}>
                  <i class="fa fa-close" style={{fontSize:'20px', color:'#291351'}}></i>
              </div>
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/ProjectUploader");
              }}>Create/Update a project</a><br />
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/ProjectDelete");
              }}>Delete a project</a><br />
              
                          
            </div>
          </div>
        : ""}
        {dataRepoModalOpen == true ? 
          <div style={{position:"fixed", height:"100vh", width:"100vw", justifyContent: "center", display:"flex", zIndex: 9999}}>
            <div style={{height:"13%", width:"21%", top: "40%", backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351", position:"relative", zIndex:"1000", fontSize:"18px"}}>
              <div style={{ position:'absolute', top:'15px', right:'15px', cursor:'pointer'}} onClick={() => {closeDRModal()}}>
                  <i class="fa fa-close" style={{fontSize:'20px', color:'#291351'}}></i>
              </div>
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/DatasetUpload");
              }}>Create/Update a dataset</a><br />
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/DatasetDelete")
              }}>Delete a dataset</a><br />
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/DataRepositoryPrivate")
              }}>View all datasets</a><br />
                          
            </div>
          </div>
        : ""}
        {superAdminModalOpen == true ? 
          <div style={{position:"fixed", height:"100vh", width:"100vw", justifyContent: "center", display:"flex", zIndex: 9999}}>
            <div style={{height:"13%", width:"21%", top: "40%", backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351", position:"relative", zIndex:"1000", fontSize:"18px"}}>
              <div style={{ position:'absolute', top:'15px', right:'15px', cursor:'pointer'}} onClick={() => {closeSAModal()}}>
                  <i class="fa fa-close" style={{fontSize:'20px', color:'#291351'}}></i>
              </div>
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/TagAdd");
              }}>Add to list of tags</a><br />
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/tagDelete")
              }}>Delete from list of tags</a><br />
              <a style={{cursor:"pointer"}} onClick={() => {
                history.push("/SuperUser")
              }}>SUPER USER TOOLS </a><br />
                          
            </div>
          </div>
        : ""}
        <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}}>
          <h1 style={{fontSize:"30px" }} className="main-title">Admin Home</h1>
        </div>
        <div style={{paddingLeft:"19%", paddingRight:"19%", width:"100%", paddingTop:"46px", fontSize:"18px"}}>
        <div className={'elementBelowLoading'} id='tools'>
                <h6 class="sub-title" style={{color: "#b2b2b2", margin:'0', marginBottom: "20px", fontFamily:'FavoritMonoStd_Light'}}>TOOLS</h6>
                <div className='toolContainer'>

                    <div className='toolTile2'>
                        <a onClick={() => {
                          history.push("/TreeToolUpdater");
                        }}><img src={t2} style={{height:'auto', width:'100%', cursor: "pointer"}} /></a>
                        <div>
                            <a onClick={() => {
                              history.push("/TreeToolUpdater");
                            }} className = "link" style={{textDecoration:'none'}}>
                                <h2 style={{fontSize:'32px', color:'#cc4884', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Data Maps for Welsh Towns <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Edit underlying <b>CSV's</b> that fuel the tree tool</p>
                        </div>
                    </div>

                    {/* <div className='toolTile3'>
                        <a href="http://www.netzeroframework.co.uk/"><img src={t3} style={{height:'auto', width:'100%'}} /></a>
                        <div>
                            <a href="http://www.netzeroframework.co.uk/" className = "link" style={{textDecoration:'none'}}>
                                <h2 style={{fontSize:'32px', color:'#cc4884', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Net Zero Framework <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Actions that can be taken by local authorities to get to net zero.</p>
                        </div>
                    </div> */}

                    <div className='toolTile1'>
                        <a onClick={() => {
                          openCSModal()
                        }}><img src={t1} style={{height:'auto', width:'100%', cursor: "pointer"}} /></a>
                        <div>
                            <a onClick={() =>{
                              openCSModal()
                            }} className = "link" style={{textDecoration:'none', cursor: "pointer"}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Charge Point Site Selector <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                                <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}><b>Create/Update, Delete</b> and <b>View</b> projects</p>
                        </div>
                    </div>

                    <div className='toolTile3'>
                        <a onClick={() =>{
                              openDRModal()
                            }}><img src={t3} style={{height:'auto', width:'100%', cursor: "pointer"}} /></a>
                        <div>
                            <a  onClick={() =>{
                                openDRModal()
                            }} className = "link" style={{textDecoration:'none', pointerEvents: 'none'}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Data Repository <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                                <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}><b>Add, Delete</b> and <b>View</b> all data</p>
                        </div>
                    </div>

                    <div className='toolTile4'>
                        <a onClick={() =>{
                              openSAModal()
                            }}><img src={t3} style={{height:'auto', width:'100%', cursor: "pointer"}} /></a>
                        <div>
                            <a  onClick={() =>{
                                openSAModal()
                            }} className = "link" style={{textDecoration:'none', pointerEvents: 'none'}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Super Admin <i class="fa fa-angle-right"></i>
                                </h2>
                                </a>
                                
                                <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Add/Delete <b>Tags</b> + Super user powers</p>
                        </div>
                    </div>

                    {/* <div className='toolTile4'>
                        <a href="#" style={{cursor:'context-menu'}}><img src={t4} style={{height:'auto', width:'100%'}} /></a>
                        <div>
                            <a href="#" className = "link" style={{textDecoration:'none', cursor:'context-menu'}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                EV Maturity Model
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '16px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Coming soon!</p>
                        </div>
                    </div> */}

                    {/* <div className='toolTile5'>
                        <a href="#" style={{cursor:'context-menu'}}><img src={t5} style={{height:'auto', width:'100%'}} /></a>
                        <div>
                            <a href="#" className = "link" style={{textDecoration:'none', cursor:'context-menu'}}>
                                <h2 style={{fontSize:'32px', color:'#9c98a7', margin: '0', height:'80px', marginTop:'32px', cursor: 'pointer', fontFamily:'FavoritStd_Regular'}}>
                                Green Community Buildings
                                </h2>
                                </a>
                                
                            <p style={{marginTop: '20px', fontSize: '18px', color:'#261b3f', fontFamily:'Freight_Text_Book'}}>Coming soon!</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
      </Layout>
    )
  } else {
    return(
      <Layout >
        <div style={{background:"#ededed", height:"90px", display:"flex", paddingLeft:"19%", alignItems:"center"}}>
          <h1 style={{fontSize:"30px" }} className="main-title">Admin login</h1>
        </div>
        <div style={{paddingLeft:"19%", paddingRight:"19%", width:"100%", paddingTop:"46px", fontSize:"18px"}}>
          <div className="form" style={{display:"flex", justifyContent: "center"}}>
            <form style={{backgroundColor: "rgb(237, 237, 237)", padding :"20px 40px", border:"2px solid #291351"}} onSubmit={getAuth} >
              <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                <label>Username: </label>
                <input style={{float:"right"}} type="text" name="uname" required />
                {renderErrorMessage("uname")}
              </div>
              <div style={{margin:"20px 0px 20px 0px", height: "30px"}}>
                <label>Password: </label>
                <input style={{float:"right"}} type="password" name="pass" required />
                {renderErrorMessage("pass")}
              </div>
              <div style={{margin:"20px 0px 20px 0px"}} className="button-container">
                <input type="submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }

  
}


export default AdminHome