import React, {useState, useEffect, useContext, useRef} from 'react';
import {BrowserRouter, useHistory} from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import '../../pages/main.css';

import Tooltip from './Tooltip';


function GeneralAutoSearch ({itemsList, selectedList, setSelectedList, placeholderTxt}){
    // const [selectedList, setSelectedList] = useState([]);

    const [removedList, setRemovedList] = useState([]);

    const [items, setItems] = useState([]);

    const [keyy, setKey] = useState(0);


    useEffect(() => {
      
      var itemsTTemp = [];
      let i = 0;
      itemsList.forEach(name => {
        itemsTTemp.push({ 'name': name, 'id': i });
        i = i + 1;
      })
  
      setItems(itemsTTemp);
    }, []);

    useEffect(() => {
       console.log(selectedList);
    }, [selectedList]);
   

    const handleOnSearch = (string, results) => {
        //console.log("setString" + string);
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }
    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
       
            console.log(item);
            var selectListTemp = selectedList;
            selectListTemp.push(item);
            setSelectedList(selectListTemp);

            // var selectListIdsTemp = selectedListIds;
            // selectListIdsTemp.push(item.id);
            // selectedListIds(selectListIdsTemp);
     
            var removedListTemp = removedList;
            removedListTemp.push(item);
            setRemovedList(removedListTemp);
            
            
            var itemsTemp = items;
            var c = 0;
            var index2Remove=0;
            itemsTemp.forEach(i =>{
                if(i.name == item.name){
                    index2Remove = c;
                }
                c= c+1;
            })
            ////////////////console.log(index2Remove);
            itemsTemp.splice(index2Remove, 1);
            ////////////////console.log(    itemsTemp);
            setItems(itemsTemp); 
            setKey(keyy +1);           
    }

    function handleDelete (ele){
        console.log(ele);
        var itemsTemp = items;
        itemsTemp.push({ name: ele.name, id: ele.id });
        setItems(itemsTemp);

        var selectedListTemp = selectedList;
        var c = 0;
        var index2Remove=0;
        selectedListTemp.forEach(i =>{
            if(i.name == ele.name){
                index2Remove = c;
            }
            c= c+1;
        })
        
        selectedListTemp.splice(index2Remove, 1);
        setSelectedList(selectedListTemp); 
        setKey(keyy +1);  
    }

    const handleOnFocus = () => {
    }
    const formatResult = (item) => {
        return item;
    }

    
    return(
        <div className='searchInputContainer'>
            <div>
                <div className='searchInputHalf' style={{ width:'100%', position:'relative', marginBottom:"20px", marginTop:"20px", display:"flex"}}>
                    <div style={{display:"inline-block", width:"50%"}}>
                        <ReactSearchAutocomplete
                            items={items}
                            key={keyy}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                            disabled={true}
                            formatResult={formatResult}
                            placeholder={placeholderTxt}
                            styling={{
                                height: "49px",
                                border: "1px solid #291351",
                                borderRadius: "0px",
                                backgroundColor: "white",
                                boxShadow: "rgba(32, 33, 36, 0) 0px 1px 6px 0px",
                                hoverBackgroundColor: "#eee",
                                color: "#291351",
                                fontSize: "16px",
                                fontFamily: "Favorit",
                                iconColor: "grey",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: '3px 14px 0 0',
                                searchIconMargin: '0 0 0 16px',
                                zIndex: '3',
                                display: 'inline-block',
                                width: "20px",
                            }}
                        />
                    </div>

                    <div style={{ width:'50%', display: "inline-block", visibility: true==false? "hidden" : "visible" }}>    
                        {selectedList.map(ele => {
                            return(
                                <div style={{ width:'100%', textAlign:'right', display: "inline-block", height: "51px" }}> 
                                    <span style={{ float:'left', marginRight: "1px", fontSize: "18px", color: "#b2b2b2" }}>
                                        <h6 style={{lineHeight:"51px", display:"inline-block", marginLeft:"20px", marginRight:"20px", color:"#212529"}}> {ele.name}</h6>
                                        <i onClick={(e) => { handleDelete(ele)}} className="fa fa-close" style={{color: '#db0052', marginRight: "2px", cursor: "pointer"}}></i>
                                    </span> 
                                </div>
                            )
                        })}                          
                    </div>

               
                </div>

               


            </div>

        </div>
    )
   
    
}
export default GeneralAutoSearch;