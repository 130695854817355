import MainNavigation from './MainNavigation';
import classes from './Layout.module.css';

function Layout(props) {
  return (
    <div key = {911119}>
      <MainNavigation />
      
      <div key = {911120} >
        {props.children}
      </div>
      
    </div>
  );
}

export default Layout;
