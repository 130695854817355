import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/layout/Layout';
import './main.css';
import ConditionalLAInput from '../components/functional/ConditionalLAInput';

import { useParams, useHistory } from 'react-router-dom'



import MapLaInput from '../components/mapdetails/MapLaInput';

import {vtsC, sumReportStatusC, allLaMapC, factorsContext, minMaxObjContext, evCounterC, LAStateContext, genralDataC } from '../Store';


function MyReport() {

   

    const [LAs, setLAs] = useContext(LAStateContext);
    const [minMaxObj, setMinMaxObj] = useContext(minMaxObjContext);
    const [genralData, setGenralData] = useContext(genralDataC);
    const [evCounter, setEvCounter] = useContext(evCounterC); 
    const [allLaMap, setAllLaMap] = useContext(allLaMapC);
    const [factors2, setFactors2] = useContext(factorsContext); 
    const [sumReportStatus, setSumReportStatus] = useContext(sumReportStatusC); 
    const [vts, setVTS] = useContext(vtsC);

    const { uid } = useParams();
    const history = useHistory();

    async function fetchJson(url){
        let res = await fetch("https://clean-street-json-store.s3.eu-west-2.amazonaws.com/"+url, {cache: "no-store"});
        res = await res.json();
       

        setLAs(res.LAs);
        setMinMaxObj(res.minMaxObj);
        setGenralData(res.genralData);
        setEvCounter(res.evCounter);
        setAllLaMap(res.allLaMap);
        setFactors2(res.factors2);
        setSumReportStatus(res.sumReportStatus);
        setVTS(res.vts);
       
        history.push({ 
            pathname: '/SummaryReport',
            state: { 
                sD2R: res.sD2R,
                original: false
            }
           });
    }
    
    useEffect( () => {
        //console.log(uid);
        fetchJson(uid);
    }, []);

 
    return (
        <Layout >
            
        </Layout>
    );
}
export default MyReport;