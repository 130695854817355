import React, {useState} from 'react';

const LAInitialState = {LAs: [], setLAs: undefined};
const projectNumberLA = {PNLAs: [], setPNLAs: undefined};
const LAMarkerCountState = {LAMC: [], setLAMC: undefined};
const priorityInitialState = {priorities: ["air quality"], setPriorities: undefined};
const typeofChargerState = {chargerType: [], setchargerType: undefined};
const allocatedPointsState = {allocatedpoints: [], setallocatedpoints: undefined};
const selectedMarkerState = {selectedMarker: "fast charger", setselectedMarker: undefined};
const countState = {count: 0, setCount: undefined};
const data2ReturnState = {data2Return: [], setData2Return: undefined};
const LAsConfirmedState = {LAsConfirmed: false, setLAsConfirmed: undefined};
const datasetConfigStringsState = {datasetConfigStrings: {}, setDatasetConfigStrings: undefined};
const categoriesState = {categories: [], setCategories: undefined};
const legendsAddedState = {legendsAdded: [], setLegendsAdded: undefined};
const checkedState = {checkedS: [], setCheckedS: undefined};
const expandedState = {expandedS: ['Edit Datasets'], setExpandedS: undefined};
const selectedPositionState = {sP: [0,0], setSP: undefined};
const markerArrState = {markerArr: [], setMarkerArr: undefined};
const counterSelectedBoolState = {cSBS: true, setCSBS: undefined};
const updateMeState = {updateMe: 0, setUpdateMe: undefined};
const projectNumbBoolState = {pnBool: false, setPNBool: undefined};
const projectNumberStringState = {pnString: false, setPNString: undefined};
const factorsState = {factors: [], setFactors: undefined};
const layerRefsState = {layerRefs: [], setLayerRefs: undefined};
const layerzState = {layerz: [], setLayerz: undefined};
const legendsState = {legends: {}, setLegends: undefined};
const screenObjS = {screenObj: {}, setScreenObj: undefined};
const screenObjS2 = {screenObj2: {}, setScreenObj2: undefined};
const screenObjS3 = {screenObj3: {}, setScreenObj3: undefined};
const lIconsState = {lIcons: [], setLIcons: undefined};
const preventMarkerState = {pms: false, setPMS: undefined};
const dataBoolState = {dataBool: false, setDataBool: undefined};
const legState = {leg: null, setLeg: undefined};
const minMaxObjState = {minMaxObj: [], setMinMaxObj: undefined};
const rankingsState  = {rankings: [], setRankings: undefined};
const genralDataS  = {genralData: [], setGenralData: undefined};
const evCounterState  = {evCounter: [], setEvCounter: undefined};
const allLaMapState  = {allLaMap: null, setAllLaMap: undefined};
const mapVarsState  = {mapVars: null, setMapVars: undefined};
const sumReportStatusState  = {sumReportStatus: {}, setSumReportStatus: undefined};
const fDateState  = {fDate: "", setFDate: undefined};
const aqmzPState  = {aqmzP: true, setAQMZP: undefined};
const vtsS  = {vts: false, setVTS: undefined};
const LMS  = {LM: true, setLM: undefined};
const selectedPNS = {PN: null , setPN:undefined};
const projectNumberState  = {projectNumber: null, setProjectNumber: undefined};
const LAsShownState  = {LAsShown: true, setLAsShown: undefined};
const isSubState  = {isSubmitted: false, setIsSubmitted: undefined};
const nameInputState  = {nameInput: "", setNameInput: undefined};
const orgInputState  = {orgInput: "", setOrgInput: undefined};
const emailInputState  = {emailInput: "", setEmailInput: undefined};


export const LAStateContext = React.createContext(LAInitialState);
export const PNLAStateContext = React.createContext(projectNumberLA);
export const LAMarkerCountContext = React.createContext(LAMarkerCountState);
export const priorityStateContext = React.createContext(priorityInitialState);
export const typeofChargerStateContext = React.createContext(typeofChargerState);
export const allocatedPointsStateContext = React.createContext(allocatedPointsState);
export const selectedMarkerStateContext = React.createContext(selectedMarkerState);
export const countContext = React.createContext(countState);
export const data2ReturnContext = React.createContext(data2ReturnState);
export const LAsConfirmedContext = React.createContext(LAsConfirmedState);
export const datasetConfigStringsContext = React.createContext(datasetConfigStringsState);
export const categoriesContext = React.createContext(categoriesState);
export const legendsAddedContext = React.createContext(legendsAddedState);
export const checkedContext = React.createContext(checkedState);
export const expandedContext = React.createContext(expandedState);
export const selectedPositionContext = React.createContext(selectedPositionState);
export const markerArrContext = React.createContext(markerArrState);
export const cSBSContext = React.createContext(counterSelectedBoolState);
export const updateMeContext = React.createContext(updateMeState);
export const projectNumberBoolContext = React.createContext(projectNumbBoolState);
export const projectNumberStringContext = React.createContext(projectNumberStringState);
export const factorsContext = React.createContext(factorsState);
export const layerRefsContext = React.createContext(layerRefsState);
export const layerzContext = React.createContext(layerzState);
export const legendsContext = React.createContext(legendsState);
export const screenObjC = React.createContext(screenObjS);
export const screenObjC2 = React.createContext(screenObjS2);
export const screenObjC3 = React.createContext(screenObjS3);
export const lIconContext = React.createContext(lIconsState);
export const preventMarkeContext = React.createContext(preventMarkerState);
export const dataBoolContext = React.createContext(dataBoolState);
export const legContext = React.createContext(legState);
export const minMaxObjContext = React.createContext(minMaxObjState);
export const rankingsContext = React.createContext(rankingsState);
export const genralDataC = React.createContext(genralDataS);
export const evCounterC = React.createContext(evCounterState);
export const allLaMapC = React.createContext(allLaMapState);
export const mapVarsC = React.createContext(mapVarsState);
export const sumReportStatusC = React.createContext(sumReportStatusState);
export const fDateC = React.createContext(fDateState);
export const aqmzPC = React.createContext(aqmzPState);
export const vtsC = React.createContext(vtsS);
export const LMC = React.createContext(LMS);
export const PNC = React.createContext(selectedPNS);
export const projectNumberC = React.createContext(projectNumberState);
export const LAsShownC = React.createContext(LAsShownState);
export const isSubStateC = React.createContext(isSubState);
export const nameInputC = React.createContext(nameInputState);
export const orgInputC = React.createContext(orgInputState);
export const emailInputC = React.createContext(emailInputState);




const Store = ({ children }) => {
    const [LAs, setLAs] = useState(LAInitialState.LAs);
    const [PNLAs, setPNLAs] = useState(LAInitialState.LAs);
    const [LAMC, setLAMC] = useState(LAInitialState.LAs);
    const [priorities, setPriorities] = useState(priorityStateContext.priorities);
    const [chargerType, setchargerType] = useState(typeofChargerStateContext.chargerType);
    const [allocatedpoints, setallocatedpoints] = useState(allocatedPointsStateContext.allocatedpoints);
    const [selectedMarker, setselectedMarker] = useState(selectedMarkerStateContext.allocatedpoints);
    const [count, setCount] = useState(countState.count);
    const [data2Return, setData2Return] = useState(data2ReturnState.data2Return);
    const [LAsConfirmed, setLAsConfirmed] = useState(LAsConfirmedState.LAsConfirmed);
    const [datasetConfigStrings, setDatasetConfigStrings] = useState(datasetConfigStringsState.datasetConfigStrings);
    const [categories, setCategories] = useState(categoriesState.categories);
    const [legendsAdded, setLegendsAdded] = useState(legendsAddedState.legendsAdded);
    const [checkedS, setCheckedS] = useState(checkedState.checkedS);
    const [expandedS, setExpandedS] = useState(checkedState.checkedS);
    const [sP, setSP] = useState(selectedPositionState.sP);
    const [markerArr, setMarkerArr] = useState(markerArrState.markerArr);
    const [cSBS, setCSBS] = useState(counterSelectedBoolState.cSBS);
    const [updateMe, setUpdateMe] = useState(updateMeState.updateMe);
    const [pnBool, setPNBool] = useState(projectNumbBoolState.pnBool);
    const [pnString, setPNString] = useState(projectNumberStringState.pnString);
    const [factors, setFactors] = useState(factorsState.factors);
    const [layerRefs, setLayerRefs] = useState(layerRefsState.layerRefs);
    const [layerz, setLayerz] = useState(layerzState.layerz);
    const [legends, setLegends] = useState(legendsState.legends);
    const [screenObj, setScreenObj] = useState(screenObjS.screenObj);
    const [screenObj2, setScreenObj2] = useState(screenObjS2.screenObj2);
    const [screenObj3, setScreenObj3] = useState(screenObjS3.screenObj3);
    const [lIcons, setLIcons] = useState(lIconsState.lIcons);
    const [pms, setPMS] = useState(preventMarkerState.pms);
    const [dataBool, setDataBool] = useState(dataBoolState.dataBool);
    const [leg, setLeg] = useState(legState.leg);
    const [minMaxObj, setMinMaxObj] = useState(minMaxObjState.minMaxObj);
    const [rankings, setRankings] = useState(rankingsState.rankings);
    const [genralData, setGenralData] = useState(genralDataS.genralData);
    const [evCounter, setEvCounter] = useState(evCounterState.evCounter);
    const [allLaMap, setAllLaMap] = useState(allLaMapState.allLaMap);
    const [mapVars, setMapVars] = useState(mapVarsState.mapVars);
    const [sumReportStatus, setSumReportStatus] = useState(sumReportStatusState.sumReportStatus);
    const [fDate, setFDate] = useState(fDateState.fDate);
    const [aqmzP, setAQMZP] = useState(aqmzPState.aqmzP);
    const [vts, setVTS] = useState(vtsS.vts);
    const [LM, setLM] = useState(LMS.LM);
    const [PN, setPN] = useState(selectedPNS.PN);
    const [projectNumber, setProjectNumber] = useState(projectNumberState.projectNumber);
    const [LAsShown, setLAsShown] = useState(LAsShownState.LAsShown);
    const [isSubmitted, setIsSubmitted] = useState(isSubState.isSubmitted);
    const [nameInput, setNameInput] = useState(nameInputState.nameInput);
    const [orgInput, setOrgInput] = useState(orgInputState.orgInput);
    const [emailInput, setEmailInput] = useState(emailInputState.emailInput);
   
    
    return (
        <LAStateContext.Provider value={[LAs, setLAs]}>
          <typeofChargerStateContext.Provider value ={[chargerType, setchargerType]}>
            <priorityStateContext.Provider value ={[priorities, setPriorities]}>
              <allocatedPointsStateContext.Provider value ={[allocatedpoints, setallocatedpoints]}>
                <selectedMarkerStateContext.Provider value ={[selectedMarker, setselectedMarker]}>
                  <countContext.Provider value ={[count, setCount]}>
                    <data2ReturnContext.Provider value ={[data2Return, setData2Return]}>
                      <LAsConfirmedContext.Provider value ={[LAsConfirmed, setLAsConfirmed]}>
                        <datasetConfigStringsContext.Provider value ={[datasetConfigStrings, setDatasetConfigStrings]}>
                          <categoriesContext.Provider value ={[categories, setCategories]}>
                            <legendsAddedContext.Provider value ={[legendsAdded, setLegendsAdded]}>
                              <checkedContext.Provider value ={[checkedS, setCheckedS]}>
                                <LAMarkerCountContext.Provider value ={[LAMC, setLAMC]}>
                                  <expandedContext.Provider value ={[expandedS, setExpandedS]}>
                                    <selectedPositionContext.Provider value ={[sP, setSP]}>
                                      <markerArrContext.Provider value ={[markerArr, setMarkerArr]}>
                                        <cSBSContext.Provider value ={[cSBS, setCSBS]}>
                                          <updateMeContext.Provider value ={[updateMe, setUpdateMe]}>
                                            <projectNumberBoolContext.Provider value ={[pnBool, setPNBool]}>
                                              <projectNumberStringContext.Provider value ={[pnString, setPNString]}>
                                                <factorsContext.Provider value ={[factors, setFactors]}>
                                                  <layerRefsContext.Provider value ={[layerRefs, setLayerRefs]}>
                                                    <layerzContext.Provider value ={[layerz, setLayerz]}>
                                                      <legendsContext.Provider value ={[legends, setLegends]}>
                                                        <screenObjC.Provider value ={[screenObj, setScreenObj]}>
                                                          <screenObjC2.Provider value ={[screenObj2, setScreenObj2]}>
                                                            <screenObjC3.Provider value ={[screenObj3, setScreenObj3]}>
                                                              <lIconContext.Provider value ={[lIcons, setLIcons]}>
                                                                <preventMarkeContext.Provider value ={[pms, setPMS]}>
                                                                  <dataBoolContext.Provider value ={[dataBool, setDataBool]}>
                                                                    <legContext.Provider value ={[leg, setLeg]}>
                                                                      <minMaxObjContext.Provider value ={[minMaxObj, setMinMaxObj]}>
                                                                        <rankingsContext.Provider value ={[rankings, setRankings]}>
                                                                          <genralDataC.Provider value ={[genralData, setGenralData]}>
                                                                            <evCounterC.Provider value ={[evCounter, setEvCounter]}>
                                                                              <allLaMapC.Provider value ={[allLaMap, setAllLaMap]}>
                                                                                <mapVarsC.Provider value ={[mapVars, setMapVars]}>
                                                                                  <sumReportStatusC.Provider value ={[sumReportStatus, setSumReportStatus]}>
                                                                                    <fDateC.Provider value ={[fDate, setFDate]}>
                                                                                      <aqmzPC.Provider value ={[aqmzP, setAQMZP]}>
                                                                                        <vtsC.Provider value ={[vts, setVTS]}>
                                                                                          <LMC.Provider value ={[LM, setLM]}>
                                                                                            <PNC.Provider value ={[PN, setPN]}>
                                                                                              <projectNumberC.Provider value ={[projectNumber, setProjectNumber]}>
                                                                                                <PNLAStateContext.Provider value ={[PNLAs, setPNLAs]}>
                                                                                                  <LAsShownC.Provider value ={[LAsShown, setLAsShown]}>
                                                                                                    <isSubStateC.Provider value ={[isSubmitted, setIsSubmitted]}>
                                                                                                        <nameInputC.Provider value ={[nameInput, setNameInput]}>
                                                                                                            <orgInputC.Provider value ={[orgInput, setOrgInput]}>
                                                                                                                <emailInputC.Provider value ={[emailInput, setEmailInput]}>
                                                                                                                    {children}
                                                                                                                </emailInputC.Provider>
                                                                                                            </orgInputC.Provider>
                                                                                                        </nameInputC.Provider>
                                                                                                    </isSubStateC.Provider>
                                                                                                  </LAsShownC.Provider>
                                                                                                </PNLAStateContext.Provider>
                                                                                              </projectNumberC.Provider>
                                                                                            </PNC.Provider>
                                                                                          </LMC.Provider>
                                                                                        </vtsC.Provider>
                                                                                      </aqmzPC.Provider>
                                                                                    </fDateC.Provider>
                                                                                  </sumReportStatusC.Provider>
                                                                                </mapVarsC.Provider>
                                                                              </allLaMapC.Provider>
                                                                            </evCounterC.Provider>
                                                                          </genralDataC.Provider>
                                                                        </rankingsContext.Provider>
                                                                      </minMaxObjContext.Provider>
                                                                    </legContext.Provider>
                                                                  </dataBoolContext.Provider>
                                                                </preventMarkeContext.Provider>
                                                              </lIconContext.Provider>
                                                            </screenObjC3.Provider>
                                                          </screenObjC2.Provider>
                                                        </screenObjC.Provider>
                                                      </legendsContext.Provider>
                                                    </layerzContext.Provider>
                                                  </layerRefsContext.Provider>
                                                </factorsContext.Provider>
                                              </projectNumberStringContext.Provider>
                                            </projectNumberBoolContext.Provider>
                                          </updateMeContext.Provider>
                                        </cSBSContext.Provider>
                                      </markerArrContext.Provider>
                                    </selectedPositionContext.Provider>
                                  </expandedContext.Provider>
                                </LAMarkerCountContext.Provider>
                              </checkedContext.Provider>
                            </legendsAddedContext.Provider>
                          </categoriesContext.Provider>
                        </datasetConfigStringsContext.Provider>
                      </LAsConfirmedContext.Provider>
                    </data2ReturnContext.Provider>
                  </countContext.Provider>
                </selectedMarkerStateContext.Provider>
              </allocatedPointsStateContext.Provider>
            </priorityStateContext.Provider>
          </typeofChargerStateContext.Provider>
        </LAStateContext.Provider>
    );
};

export default Store;