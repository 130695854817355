import React from "react";
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AreaStack,
  AreaSeries,
  AnimatedLineSeries,
  AnimatedBarSeries,
  XYChart,
  Tooltip,
  buildChartTheme,
  DataContext
} from "@visx/xychart";
import { Axis } from '@visx/axis'
import { AnimatedAxis as AnAxis } from '@visx/react-spring'
import { scaleLinear } from "@visx/scale";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { string } from "mathjs";


const accessors = {
  xAccessor: (d) => d.label,
  yAccessor: (d) => d.value
};


const customTheme = buildChartTheme({
  colors: ["rgba(255, 152, 136, 1)",
  "rgba(255, 204, 102, 1)",
  "rgba(255, 80, 147, 1)",
  "rgba(65, 255, 223, 1)"], // categorical colors, mapped to series via `dataKey`s
  backgroundColor: 'white',
  tickLength: 4,
  gridColor: "rgba(255, 255, 255, 0)",
  gridColorDark: 'rgba(255, 255, 255, 0)', // used for axis baseline if x/yxAxisLineStyles not set
});

function XYc({widget, sw}){
  var outPutData = [
    
  ];

  ////console.log(widget["laChartData"]);
  var dataOne = [];
  var dataTwo = [];
  widget["laChartData"].forEach(laData => {
    let la = laData.laName;
    let filteredData = [];
    if (sw == 1){
      laData.cData[0][2].forEach(dPoint => {
        if (dPoint.x == "OnStreetInfrastructureRequired2025"){
          filteredData.push({label: 2025, value: dPoint.y});
        } else if (dPoint.x == "OnStreetInfrastructureRequired2030"){
          filteredData.push({label: 2030, value: dPoint.y});
        }else if (dPoint.x == "OnStreetInfrastructureRequired2035"){
          filteredData.push({label: 2035, value: dPoint.y});
        }else if (dPoint.x == "OnStreetInfrastructureRequired2040"){
          filteredData.push({label: 2040, value: dPoint.y});
        }else if (dPoint.x == "OnStreetInfrastructureRequired2045"){
          filteredData.push({label: 2045, value: dPoint.y});
        }
      })
      outPutData.push({laName: la, data: filteredData});

    } else if (sw == 2){
      laData.cData[0][2].forEach(dPoint => {
      

        if (dPoint.x == "PredictedOnStreetEVs2025"){
          filteredData.push({label: 2025, value: dPoint.y});
        } else if (dPoint.x == "PredictedOnStreetEVs2030"){
          filteredData.push({label: 2030, value: dPoint.y});
        }else if (dPoint.x == "PredictedOnStreetEVs2035"){
          filteredData.push({label: 2035, value: dPoint.y});
        }else if (dPoint.x == "PredictedOnStreetEVs2040"){
          filteredData.push({label: 2040, value: dPoint.y});
        }else if (dPoint.x == "PredictedOnStreetEVs2045"){
          filteredData.push({label: 2045, value: dPoint.y});
        }
      })
      outPutData.push({laName: la, data: filteredData});
    }
    
  })
  const data1 = [
    { x: "2020", y: 50 },
    { x: "2021", y: 10 },
    { x: "2022", y: 20 }
  ];
  
  const data2 = [
    { x: "2020", y: 30 },
    { x: "2021", y: 40 },
    { x: "2022", y: 80 }
  ];
  
  ////console.log(dataOne);
  ////console.log(dataTwo);
  

  const Ax2 = () => {
    const { innerWidth, innerHeight, margin } = React.useContext(DataContext);
    ////console.log(innerWidth);
  
    function max(arr, fn) {
      return Math.max(...arr.map(fn));
    }
   
    const keys = Object.keys(outPutData[0].data).filter((d) => d !== "value");
    
    const scaleX = scaleLinear({
      domain: [0, max( outPutData[0].data, (d) => max(keys, (key) => (d[key])))],
      range: [0, innerWidth],
      fill: '#291351'
    });
    let w = innerWidth * 0.98;
    return (
     
      <AnimatedAxis 
      orientation="right"
      stroke={'#291351'}
      hideAxisLine={true}
      labelOffset={80}
      
      scale={scaleX}
      left={w}
      tickLabelProps={() => ({
        fill: '#291351',
        fontSize: 16,
        textAnchor: 'middle',
        opacity: 1,
        dx: '3em',
      })}
      
    />
    );
  };
 
  return (
    <XYChart
      theme={customTheme}
      height={330 }
      xScale={{ type: "band", paddingInner: 0.5 }}
      yScale={{ type: "linear" }}
    >
      
      {/* <AnimatedAxis 
        orientation="right"
        stroke={'#291351'}
        hideAxisLine={false}
        labelOffset={8}
        
      /> */}
      <Ax2 />
      <AnimatedAxis   
        orientation="bottom"
        stroke={'#291351'}
        tickStroke={'#291351'}
          tickLabelProps={() => ({
              fill: '#291351',
              fontSize: 16,
              textAnchor: 'middle',
              opacity: 1,
              dy: '0.63em',
          })}
          hideAxisLine={true}
      />
      

      {/* <AnimatedAxis hideAxisLine={true} hideTicks={true} orientation="right" 
      tickLabelProps={() => ({
          fill: '#291351',
          fontSize: 11
        })}
        /> */}
      <AnimatedGrid rows={true} columns={false} numTicks={16} />
      <AreaStack >
      {outPutData.map(laa =>{
        return (
         
          <AreaSeries
            dataKey={laa.laName}
            data={laa.data}
            {...accessors}
            fillOpacity={0.9}
          />
          
        )
      })}

      </AreaStack>
      
      

      <Tooltip
        // snapTooltipToDatumX
        // snapTooltipToDatumY
        // showVerticalCrosshair
        // showSeriesGlyphs
        renderTooltip={({ tooltipData, colorScale }) => (
          <div>
            <div style={{ color: '#666666', fontWeight:'500' }}>
              {tooltipData.nearestDatum.key}
            </div>
            <span style={{ color: '#666666', fontWeight:'500' }}>
            {accessors.xAccessor(tooltipData.nearestDatum.datum).toString()}
            {", "}
            {accessors.yAccessor(tooltipData.nearestDatum.datum)}
            </span>
          </div>
        )}
      />
    </XYChart>
  )
}



export default React.memo(XYc);
