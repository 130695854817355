import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Main from './pages/Main';
import HomepageCPSS from './pages/homepageCPSS';
import Areas from './pages/Areas';
import Priorities from './pages/Priorities';
import Location from './pages/Location';
import Report1 from './pages/Report1';
import Report2 from './pages/Report2';
import MyReportWrapper from './pages/MyReport';
import PrintReport from './pages/PrintReport';
import ProjectMap from './pages/ProjectMap';
import FuturePlaces from './pages/FuturePlaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


import AdminHome from './components/admin/AdminHome';
import SuperUser from './components/admin/SuperUser';
//treeToolInterface
import TreeToolUpdater from './components/admin/TreeToolUpdater';
//project add/ del (projects are containers for a group of datasets + extra controls for clean street)
import NewProjectUpload from './components/admin/NewProjectUpload';
import ProjectDelete from './components/admin/ProjectDelete';
//tags for filtering datasets
import TagAdd from './components/admin/TagListUpload';
import TagDelete from './components/admin/TagListDelete';
//data uploading/ deleteing + data repo's
import DatasetUpload from './components/admin/DatasetUpload';
import DatasetDelete from './components/admin/DatasetDelete';
import DataRepositoryPublic from './components/admin/DataRepositoryPublic';
import DataRepositoryPrivate from './components/admin/DataRepositoryPrivate';

import Testing from './components/functional/GenralAutoSearch';


function App() {
  return (
      <Switch>
        <Route path='/' exact>
          <Main />
        </Route>
        <Route path='/SiteSelectorHome' exact>
          <HomepageCPSS />
        </Route>
		<Route path='/FuturePlaces' exact>
          <FuturePlaces />
        </Route>
        <Route path='/areas'>
          <Areas />
        </Route>
        <Route path='/priorities'>
          <Priorities />
        </Route>
        <Route path='/location'>
          <Location />
        </Route>
        <Route path='/EvaluateSites'>
          <Report1 />
        </Route>
        <Route path='/SummaryReport'>
          <Report2 />
        </Route>
        <Route path='/printReport'>
          <PrintReport />
        </Route>
        <Route path='/MyReport/:uid'>
          <MyReportWrapper />
        </Route>
        <Route path='/ProjectMap'>
          <ProjectMap />
        </Route>
       
        <Route path='/ProjectUploader'>
          <NewProjectUpload />
        </Route>
        <Route path='/ProjectDelete'>
          <ProjectDelete />
        </Route>
        <Route path='/TreeToolUpdater'>
          <TreeToolUpdater />
        </Route>
        <Route path='/AdminHome'>
          <AdminHome />
        </Route>
        <Route path='/testing'>
          <Testing />
        </Route>
        <Route path='/SuperUser'>
          <SuperUser />
        </Route>
        <Route path='/DatasetUpload'>
          <DatasetUpload />
        </Route>
        <Route path='/DatasetDelete'>
          <DatasetDelete />
        </Route>
        <Route path='/DataRepositoryPublic'>
          <DataRepositoryPublic />
        </Route>
        <Route path='/DataRepositoryPrivate'>
          <DataRepositoryPrivate />
        </Route>
        <Route path='/TagAdd'>
          <TagAdd />
        </Route>
        <Route path='/TagDelete'>
          <TagDelete />
        </Route>
      </Switch>
  );
}

export default App;
